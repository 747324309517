<template>
    <!--<div class="spaced-container">-->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_specification_inventory_master') || permissionNameArray.includes('can_view_inventory_master')">
            <div class="container-fluid xyz">
                <!--<div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <span>{{pageTitle}}</span>
                    </div>
                </div>-->
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>

                    <div>
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" style="background: #098de1; border: 1px solid #098de1; border-radius: 5px; border-bottom: 2px solid #076eb0;" class="btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="buttonBack">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" style="background: #098de1; border: 1px solid #098de1; border-radius: 5px; border-bottom: 2px solid #076eb0;" class="btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="addAssetSpecification()">
                    </div>

                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!--Odometer-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.odometer_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="odometerError = ''" id="odometer" v-model="odometer" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{odometer}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{odometerError}}</span>
                                </div>
                            </div>

                            <!--Exhaust -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exhaust_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="exhaustId"
                                                @change="changedValue"
                                                @input="exhaustError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="exhaustArr"
                                                :placeholder="staticMessages.exhaust_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{exhaustId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{exhaustError}}</span>
                                </div>
                            </div>

                            <!--Ecmmiles-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.ecmmiles_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="ecmmilesError = ''" id="ecmmiles" v-model="ecmmiles" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{ecmmiles}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{ecmmilesError}}</span>
                                </div>
                            </div>

                            <!--Breathers -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.breathers_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="breathersId"
                                                @change="changedValue"
                                                @input="breathersError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="breathersArr"
                                                :placeholder="staticMessages.breathers_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{breathersId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{breathersError}}</span>
                                </div>
                            </div>

                            <!--GVW-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.gvw_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="gvwError = ''" id="gvw" v-model="gvw" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{gvw}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{gvwError}}</span>
                                </div>
                            </div>

                            <!--Suspension -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.suspension_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="suspensionId"
                                                @change="changedValue"
                                                @input="suspensionError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="suspensionArr"
                                                :placeholder="staticMessages.suspension_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{suspensionId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{suspensionError}}</span>
                                </div>
                            </div>

                            <!--Brake Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.brake_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="brakeTypeId"
                                                @change="changedValue"
                                                @input="brakeTypeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="brakeTypeArr"
                                                :placeholder="staticMessages.brake_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{brakeTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{brakeTypeError}}</span>
                                </div>
                            </div>
                            
                            <!--Tire Size-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tire_size_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="tiresizeError = ''" id="tiresize" v-model="tiresize" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{tiresize}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{tiresizeError}}</span>
                                </div>
                            </div>

                            <!--Rear Wheel-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_wheels_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <!-- <input @input="rearWheelError = ''" id="rearWheel" v-model="rearWheel" class="form-control" type="text"/> -->
                                        <v-select
                                            v-model="rearWheel"
                                            @input="rearWheelError = ''"
                                            name="rearWheel"
                                            id="rearWheel"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="rearWheelArr"
                                            :placeholder="staticMessages.rear_wheels_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{rearWheel}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{rearWheelError}}</span>
                                </div>
                            </div>

                            <!--Rear Ratio-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_ratio_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="rearRatioError = ''" id="rearRatio" v-model="rearRatio" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{rearRatio}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{rearRatioError}}</span>
                                </div>
                            </div>

                            <!--Empty Weight-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.empty_weight_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="emptyWeightError = ''" id="emptyWeight" v-model="emptyWeight" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{emptyWeight}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{emptyWeightError}}</span>
                                </div>
                            </div>

                            <!--Locking Rears-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.locking_rears_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="lockingRearsError = ''" id="lockingRears" v-model="lockingRears" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{lockingRears}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{lockingRearsError}}</span>
                                </div>
                            </div>

                            <!--Engine Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="engineMakeId"
                                                @change="changedEngineMakeValue"
                                                @input="engineMakeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="engineMakeArr"
                                                :placeholder="staticMessages.engine_make_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{engineMakeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{engineMakeError}}</span>
                                </div>
                            </div>

                            <!--Axle Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.axle_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="axleTypeId"
                                                @change="changedValue"
                                                @input="axleTypeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="axleTypeArr"
                                                :placeholder="staticMessages.axle_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{axleTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{axleTypeError}}</span>
                                </div>
                            </div>

                            <!--Engine Model-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="engineModel"
                                                @change="changedValue"
                                                @input="modelError = ''"
                                                name="engineModel"
                                                id="engineModel"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="engineModelArr"
                                                :placeholder="staticMessages.engine_model_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isModelRecordNotFound">
                                                    {{staticMessages.engine_model_search}}
                                                </template>
                                                <template v-else>
                                                    {{staticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{engineModel.text}}</span>
                                    </template>
                                    <!--<input @input="engineModelError = ''" id="engineModel" v-model="engineModel" class="form-control" type="text"/>-->
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{engineModelError}}</span>
                                </div>
                            </div>

                            <!--Steer Axle Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.steer_axle_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="steerAxleCapacityError = ''" id="steerAxleCapacity" v-model="steerAxleCapacity" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{steerAxleCapacity}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{steerAxleCapacityError}}</span>
                                </div>
                            </div>

                            <!--Driver Axle Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.driver_axle_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="driverAxleCapacityError = ''" id="driverAxleCapacity" v-model="driverAxleCapacity" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{driverAxleCapacity}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{driverAxleCapacityError}}</span>
                                </div>
                            </div>

                            <!--Drop Axle Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.drop_axle_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="dropAxleCapacityError = ''" id="dropAxleCapacity" v-model="dropAxleCapacity" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{dropAxleCapacity}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{dropAxleCapacityError}}</span>
                                </div>
                            </div>

                            <!--Engine Size-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_size_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="engineSizeError = ''" id="engineSize" v-model="engineSize" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{engineSize}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{engineSizeError}}</span>
                                </div>
                            </div>

                            <!--Engine HP-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_hp_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="engineHpError = ''" id="engineHp" v-model="engineHp" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{engineHp}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{engineHpError}}</span>
                                </div>
                            </div>

                            <!--Drop Axle Config -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.drop_axle_config_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="dropAxleConfigId"
                                                @change="changedValue"
                                                @input="dropAxleConfigError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="dropAxleConfigArr"
                                                :placeholder="staticMessages.drop_axle_config_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{dropAxleConfigId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{dropAxleConfigError}}</span>
                                </div>
                            </div>

                            <!-- Engine Brake -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_brake_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="engineBrakeId"
                                                @change="changedValue"
                                                @input="engineBrakeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="engineBrakeArr"
                                                :placeholder="staticMessages.engine_brake_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{engineBrakeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{engineBrakeError}}</span>
                                </div>
                            </div>

                            <!-- APU Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.apu_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="apuMakeId"
                                                @change="changedValue"
                                                @input="apuMakeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="apuMakeArr"
                                                :placeholder="staticMessages.apu_make_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{apuMakeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{apuMakeError}}</span>
                                </div>
                            </div>

                            <!--Wheelbase-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.wheelbase_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="wheelbaseError = ''" id="wheelbase" v-model="wheelbase" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{wheelbase}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{wheelbaseError}}</span>
                                </div>
                            </div>

                            <!--APU Hours-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.apu_hours_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="apuHoursError = ''" id="apuHours" v-model="apuHours" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{apuHours}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{apuHoursError}}</span>
                                </div>
                            </div>


                            <!--APU Serials-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.apu_serials_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="apuSerialsError = ''" id="apuSerials" v-model="apuSerials" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{apuSerials}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{apuSerialsError}}</span>
                                </div>
                            </div>

                            <!--Trans Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="transMakeId"
                                                @change="changedMakeValue"
                                                @input="transMakeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="transMakeArr"
                                                :placeholder="staticMessages.trans_make_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{transMakeId.text}}</span>
                                    </template>                                    
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{transMakeError}}</span>
                                </div>
                            </div>

                            <!--Tire Trade-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tire_trade_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="tireTradeError = ''" id="tireTrade" v-model="tireTrade" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{tireTrade}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{tireTradeError}}</span>
                                </div>
                            </div>

                            <!--Trans Model-->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="transModelError = ''" id="transModel" v-model="transModel" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{transModel}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>--
                                    <span class="help is-danger">{{transModelError}}</span>
                                </div>
                            </div> -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="transModel"
                                                @change="changedValue"
                                                @input="transModelError = ''"
                                                name="transModel"
                                                id="transModel"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="transModelArr"
                                                :placeholder="staticMessages.trans_model_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{transModel.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{transModelError}}</span>
                                </div>
                            </div>

                            <!--Tire Config-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tire_config_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="tireConfigError = ''" id="tireConfig" v-model="tireConfig" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{tireConfig}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{tireConfigError}}</span>
                                </div>
                            </div>

                            <!--Trans Speed -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_speed_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="transSpeedId"
                                                @change="changedValue"
                                                @input="transSpeedError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="transSpeedArr"
                                                :placeholder="staticMessages.trans_speed_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{transSpeedId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{transSpeedError}}</span>
                                </div>
                            </div>

                            <!--Front Wheel-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.front_wheels_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <!-- <input @input="frontWheelError = ''" id="frontWheel" v-model="frontWheel" class="form-control" type="text"/> -->
                                        <v-select
                                            v-model="frontWheel"
                                            @input="frontWheelError = ''"
                                            name="frontWheel"
                                            id="frontWheel"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="frontWheelArr"
                                            :placeholder="staticMessages.front_wheels_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{frontWheel}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{frontWheelError}}</span>
                                </div>
                            </div>

                            <!--Trans Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="transTypeId"
                                                @change="changedValue"
                                                @input="transTypeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="transTypeArr"
                                                :placeholder="staticMessages.trans_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{transTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{transTypeError}}</span>
                                </div>
                            </div>


                            <!--Fuel Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="fuelTypeId"
                                                @change="changedValue"
                                                @input="fuelTypeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="fuelTypeArr"
                                                :placeholder="staticMessages.fuel_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{fuelTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{fuelTypeError}}</span>
                                </div>
                            </div>

                            <!--Is Piloted Wheels -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_piloted_wheels_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="isPilotedWheelsId"
                                                @change="changedValue"
                                                @input="isPilotedWheelsError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="isPilotedWheelsArr"
                                                :placeholder="staticMessages.is_piloted_wheels_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{isPilotedWheelsId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{isPilotedWheelsError}}</span>
                                </div>
                            </div>

                            <!--Fuel Tanks-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_tanks_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="fuelTanksError = ''" id="fuelTanks" v-model="fuelTanks" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{fuelTanks}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{fuelTanksError}}</span>
                                </div>
                            </div>

                            <!--Is Fifth Wheel-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_fifth_wheel_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="isFifthWheelError = ''" id="isFifthWheel" v-model="isFifthWheel" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{isFifthWheel}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{isFifthWheelError}}</span>
                                </div>
                            </div>

                            <!--Fuel Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="fuelCapacityError = ''" id="fuelCapacity" v-model="fuelCapacity" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{fuelCapacity}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{fuelCapacityError}}</span>
                                </div>
                            </div>

                            <!--Cab to Axle-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cab_to_axle_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="cabToAxleError = ''" id="cabToAxle" v-model="cabToAxle" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{cabToAxle}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cabToAxleError}}</span>
                                </div>
                            </div>

                            <!--King Pin-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.king_pin_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="kingPinError = ''" id="kingPin" v-model="kingPin" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{kingPin}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{kingPinError}}</span>
                                </div>
                            </div>

                            <!--Wet Line-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.wet_line_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="wetLineId"
                                                @change="changedValue"
                                                @input="wetLineError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="wetLineArr"
                                                :placeholder="staticMessages.wet_line_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{wetLineId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{wetLineError}}</span>
                                </div>
                            </div>

                            <!--Sleeper Size-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sleeper_size_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="sleeperSizeError = ''" id="sleeperSize" v-model="sleeperSize" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{sleeperSize}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{sleeperSizeError}}</span>
                                </div>
                            </div>

                            <!--Bunk Type-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.bunk_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="bunkTypeError = ''" id="bunkType" v-model="bunkType" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{bunkType}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{bunkTypeError}}</span>
                                </div>
                            </div>

                            <!--Bunk Heater-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.bunk_heater_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="bunkHeaterError = ''" id="bunkHeater" v-model="bunkHeater" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{bunkHeater}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{bunkHeaterError}}</span>
                                </div>
                            </div>

                            <!--Cab Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cab_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="cabTypeId"
                                                @change="changedValue"
                                                @input="cabTypeError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="cabTypeArr"
                                                :placeholder="staticMessages.cab_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{cabTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cabTypeError}}</span>
                                </div>
                            </div>

                            <!--Cab Style -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cab_style_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="cabStyleId"
                                                @change="changedValue"
                                                @input="cabStyleError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="cabStyleArr"
                                                :placeholder="staticMessages.cab_style_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{cabStyleId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cabStyleError}}</span>
                                </div>
                            </div>

                            <!--Is Air Conditioning -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_air_conditioning_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="isAirConditioningId"
                                                @change="changedValue"
                                                @input="isAirConditioningError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="isAirConditioningArr"
                                                :placeholder="staticMessages.is_air_conditioning_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{isAirConditioningId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{isAirConditioningError}}</span>
                                </div>
                            </div>

                            <!--Interior Color-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.interior_color_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="interiorColorError = ''" id="interiorColor" v-model="interiorColor" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{interiorColor}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{interiorColorError}}</span>
                                </div>
                            </div>


                            <!--Heated Mirrors -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.heated_mirrors_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="heatedMirriorsId"
                                                @change="changedValue"
                                                @input="heatedMirriorsError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="heatedMirriorsArr"
                                                :placeholder="staticMessages.is_heated_mirrors_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{heatedMirriorsId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{heatedMirriorsError}}</span>
                                </div>
                            </div>

                            <!--Fairings -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fairings_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="fairingId"
                                                @change="changedValue"
                                                @input="fairingError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="fairingArr"
                                                :placeholder="staticMessages.is_fairings_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{fairingId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{fairingError}}</span>
                                </div>
                            </div>

                            <!--Visor -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.visor_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="visorId"
                                                @change="changedValue"
                                                @input="visorError = ''"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="visorArr"
                                                :placeholder="staticMessages.visor_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{visorId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{visorError}}</span>
                                </div>
                            </div>
                            <!-- Body Type -->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.body_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                            v-model="bodyTypeId"
                                            @change="changedValue"
                                            @input="bodyTypeIdError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="bodyTypeArr"
                                            :placeholder="staticMessages.body_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{bodyTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{bodyTypeIdError}}</span>
                                </div>
                            </div>
                            <!--Roof Type -->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.roof_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="roofTypeId"
                                                @change="changedValue"
                                                @input="roofTypeError = ''"
                                                name="roofTypeId"
                                                id="roofTypeId"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="roofTypeArr"
                                                :placeholder="staticMessages.floor_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{roofTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{roofTypeError}}</span>
                                </div>
                            </div>
                            <!--Floor Type -->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.floor_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="floorTypeId"
                                                @change="changedValue"
                                                @input="floorTypeError = ''"
                                                name="floorTypeId"
                                                id="floorTypeId"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="floorTypeArr"
                                                :placeholder="staticMessages.floor_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{floorTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{floorTypeError}}</span>
                                </div>
                            </div>
                            <!--Rear Door Type -->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_door_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="rearDoorTypeId"
                                                @change="changedValue"
                                                @input="rearDoorTypeError = ''"
                                                name="rearDoorTypeId"
                                                id="rearDoorTypeId"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="rearDoorTypeArr"
                                                :placeholder="staticMessages.rear_door_type_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{rearDoorTypeId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{rearDoorTypeError}}</span>
                                </div>
                            </div>
                            <!--Reefer Serial-->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.reefer_serial_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="reeferSerialError = ''" id="reeferSerial" v-model="reeferSerial" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{reeferSerial}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{reeferSerialError}}</span>
                                </div>
                            </div>
                            <!--Exterior Color-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exterior_color_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="exteriorColor"
                                                @change="changedValue"
                                                @input="exteriorColorError = ''"
                                                name="exteriorColor"
                                                id="exteriorColor"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="exteriorColorArr"
                                                :placeholder="staticMessages.select_exterior_color">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{exteriorColor}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{exteriorColorError}}</span>
                                </div>
                            </div>
                            <!--Skirt -->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.skirt_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="skirt"
                                                @change="changedValue"
                                                @input="skirtError = ''"
                                                name="skirt"
                                                id="skirt"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="skirtArr"
                                                :placeholder="staticMessages.select_yes_no">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{skirt.text}}</span>
                                    </template>
                                    <span class="help is-danger">{{skirtError}}</span>
                                </div>
                            </div>
                            <!--Exterior Width-->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exterior_width_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="exteriorWidthError = ''" id="exteriorWidth" v-model="exteriorWidth" class="form-control" type="text" maxlength="190"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{exteriorWidth}}</span>
                                    </template>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{exteriorWidthError}}</span>
                                </div>
                            </div>
                            <!--Exterior Height-->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exterior_height_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="exteriorHeightError = ''" id="exteriorHeight" v-model="exteriorHeight" class="form-control" type="text" maxlength="190"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{exteriorHeight}}</span>
                                    </template>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{exteriorHeightError}}</span>
                                </div>
                            </div>

                            <!--Exterior Lenght-->
                            <div v-if="showTrailerSpec" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exterior_lenght_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="exteriorLengthError = ''" id="exteriorLength" v-model="exteriorLength" class="form-control" type="text" maxlength="190"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{exteriorLength}}</span>
                                    </template>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{exteriorLengthError}}</span>
                                </div>
                            </div>
                            <!-- Fleet -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fleet_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                            v-model="fleetId"
                                            @change="changedValue"
                                            @input="fleetError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="fleetArr"
                                            :placeholder="staticMessages.fleet_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{fleetId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{fleetError}}</span>
                                </div>
                            </div>
                            <!-- DTR Trade Terms -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dtr_trade_terms_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                            v-model="dtrTradeTermsId"
                                            @change="changedValue"
                                            @input="dtrTradeTermsError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="dtrTradeTermsArr"
                                            :placeholder="staticMessages.dtr_trade_terms_place_holder">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{dtrTradeTermsId.text}}</span>
                                    </template>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{dtrTradeTermsError}}</span>
                                </div>
                            </div>
                            <!--Dimensions-->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dimensions_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input @input="dimensionsError = ''" id="dimensions" v-model="dimensions" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{dimensions}}</span>
                                    </template> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{dimensionsError}}</span>
                                </div>
                            </div> -->

                            <!--Lane Mitigation -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lane_mitigation_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <v-select
                                                v-model="laneMitigation"
                                                @change="changedValue"
                                                name="laneMitigation"
                                                id="laneMitigation"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="laneMitigationArr"
                                                :placeholder="staticMessages.select_yes_no">
                                        </v-select>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{laneMitigation.text}}</span>
                                    </template>
                                </div>
                            </div>

                            <!-- Seat Capacity -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">Seat Capacity</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <input id="seatCapacity" v-model="seatCapacity" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{seatCapacity}}</span>
                                    </template>
                                </div>
                            </div>

                        </div>
                        <!--Error Alert-->
                        <!--<div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    &lt;!&ndash;<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>&ndash;&gt;
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>-->
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
                            <div class="button-demo">
                                <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAssetSpecification()">
                                <!--<input id="addAndContinueAssetBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addAssetSpecification(flag='Save and Continue')">-->
                                <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                            </div>
                        </template>
                    </div>


                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <!--<app-footer></app-footer>-->
    </div>
    <!-- Page Content Wrapper -->
    <!--</div>-->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import vSelect from 'vue-select';
    
    Vue.component('v-select', vSelect);
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
        },
        mixins: [commonMixins],
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                dimensions : "",
                dimensionsError : "",
                reeferSerial : "",
                reeferSerialError : "",
                odometer : "",
                odometerError : "",
                exhaustId : "",
                exhaustArr : [],
                exhaustError : "",
                skirt : "",
                skirtArr : [{ text : "Yes", value : "yes"}, { text : "No", value : "no"}],
                skirtError : "",
                rearDoorTypeId : "",
                rearDoorTypeArr : [],
                rearDoorTypeError : "",
                floorTypeId : "",
                floorTypeArr : [],
                floorTypeError : "",
                roofTypeId : "",
                roofTypeArr : [],
                roofTypeError : "",
                ecmmiles : "",
                ecmmilesError : "",
                breathersId : "",
                breathersArr : [],
                breathersError : "",
                gvw : "",
                gvwError : "",
                rearRatio : "",
                rearRatioError : "",
                emptyWeight : "",
                emptyWeightError : "",
                lockingRears : "",
                lockingRearsError : "",
                engineMakeId : "",
                engineMakeArr : [],
                engineMakeError : "",
                axleTypeId : "",
                axleTypeArr : [],
                axleTypeError : "",
                engineModel : "",
                engineModelArr : [],
                engineModelError : "",
                steerAxleCapacity : "",
                steerAxleCapacityError : "",
                driverAxleCapacity : "",
                driverAxleCapacityError : "",
                dropAxleCapacity : "",
                dropAxleCapacityError : "",
                engineSize : "",
                engineSizeError : "",
                engineHp : "",
                engineHpError : "",
                dropAxleConfigId : "",
                dropAxleConfigArr : [],
                dropAxleConfigError : "",
                engineBrakeArr : [ { text : "Yes", value : "yes"}, { text : "No", value : "no"}],
                engineBrakeId : "",
                engineBrakeError : "",
                suspensionId : "",
                suspensionArr : [],
                suspensionError : "",
                apuMakeId : "",
                apuMakeArr : [],
                apuMakeError : "",
                wheelbase : "",
                wheelbaseError : "",
                apuHours : "",
                apuHoursError : "",
                brakeTypeId : [],
                brakeTypeArr : [],
                brakeTypeError : "",
                apuSerials : "",
                apuSerialsError : "",
                tiresize : "",
                tiresizeError : "",
                transMakeId : "",
                transMakeArr : [],
                transMakeError : "",
                tireTrade : "",
                tireTradeError : "",
                transModel : "",
                transModelArr : [],
                transModelError : "",
                tireConfig : "",
                tireConfigError : "",
                transSpeedId : "",
                transSpeedArr : [],
                transSpeedError : "",
                frontWheel : "",
                frontWheelArr : [],
                frontWheelError : "",
                transTypeId : "",
                transTypeArr : [],
                transTypeError : "",
                rearWheel : "",
                rearWheelArr : [],
                rearWheelError : "",
                fuelTypeId : "",
                fuelTypeArr : [],
                fuelTypeError : "",
                isPilotedWheelsArr : [ { text : "Yes", value : "yes"}, { text : "No", value : "no"}],
                isPilotedWheelsId : "",
                isPilotedWheelsError : "",
                fuelTanks : "",
                fuelTanksError : "",
                isFifthWheel : "",
                isFifthWheelError : "",
                fuelCapacity : "",
                fuelCapacityError : "",
                cabToAxle : "",
                cabToAxleError : "",
                kingPin : "",
                kingPinError : "",
                wetLineId : "",
                wetLineArr : [ { text : "Yes", value : "yes"}, { text : "No", value : "no"} ],
                wetLineError : "",
                sleeperSize : "",
                sleeperSizeError : "",
                bunkType : "",
                bunkTypeError : "",
                bunkHeater : "",
                bunkHeaterError : "",
                cabTypeId : "",
                cabTypeArr : [],
                cabTypeError : "",
                cabStyleId : "",
                cabStyleArr : [],
                cabStyleError : "",
                isAirConditioningArr : [ { text : "Yes", value : "yes"}, { text : "No", value : "no"} ],
                isAirConditioningId : "",
                isAirConditioningError : "",
                interiorColor : "",
                interiorColorError : "",
                exteriorColor : "",
                exteriorColorError : "",
                heatedMirriorsId : "",
                heatedMirriorsArr : [ { text : "Yes", value : "yes"}, { text : "No", value : "no"} ],
                heatedMirriorsError : "",
                fairingId : "",
                fairingArr : [],
                fairingError : "",
                visorId : "",
                visorArr : [ { text : "Yes", value : "yes"}, { text : "No", value : "no"} ],
                visorError : "",
                bodyTypeId: "",
                bodyTypeIdError: "",
                bodyTypeArr: [],
                fleetId: "",
                fleetError: "",
                fleetArr: [],
                dtrTradeTermsId: "",
                dtrTradeTermsError: "",
                dtrTradeTermsArr: [],
                exteriorHeight: "",
                exteriorHeightError: "",
                exteriorWidth: "",
                exteriorWidthError: "",
                exteriorLength: "",
                exteriorLengthError: "",
                exteriorColorArr: [],

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Inventory",
                //permissionNameArray : ['can_specification_inventory_master'],
                isModelRecordNotFound: true,
                showTrailerSpec : false,

                laneMitigation : [{ text : "No", value : "no"}],
                laneMitigationArr : [{ text : "Yes", value : "yes"}, { text : "No", value : "no"}],
                
                seatCapacity: '',
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            permissionNameArray:"",
            asset : {
                type : Object,
                required : false,
                default : undefined
            }
        },
        mounted: function() {
            
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            
            EventBus.$emit("menu-selection-changed", "asset-list");
            $("#alertError").hide();
            
            self.getAssetSpecificationDetailsById(self.asset);
            
            var type = (self.asset.content && self.asset.content.asset_data && self.asset.content.asset_data.vehicle_type) ? self.asset.content.asset_data.vehicle_type : 'used';
            
            self.loadAllData(['Exhaust', 'Breathers', 'EngineMake', 'AxleType', 'DropAxleConfig', 'ApuMake', 'Suspension', 'BrakeType'
                , 'TransMake', 'TransSpeed', 'TransType', 'FuelType', 'CabType', 'CabStyle', 'Fairings', 'BodyType'
                , 'RearDoorType', 'FloorType', 'RoofType', 'FrontWheels', 'RearWheels'
                , 'ExteriorColor'
            ], false, self.loadTypeMasterDataCallback, "undefined", '', '', '', '', '', '', '', type.toLowerCase());

            self.loadAllData(['Fleet', 'DtrTradeTerms'], false, self.loadMasterDataCallback, "undefined", '');
            
            if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
              self.buttonNames.save = "Update";
            }
        },
        methods: {
            buttonBack() {
                self.$router.push( {name : "ListAsset"});
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            changedMakeValue : function(){
                let make_id = "";
                if (typeof(self.transMakeId) != "undefined" && self.transMakeId != null && self.transMakeId != "" && self.transMakeId != 0) {
                    make_id = self.transMakeId.value;
                }
                self.loadAllData(['TransModel'], false, self.loadTransModelCallback, "undefined", '', make_id);
            },
            loadTransModelCallback : function(callbackData) {
                self.transModelArr = callbackData.TransModel;
                var transmodelAvailable = false;
                $.each(self.transModelArr, function (j) {
                    if (self.transModelArr[j].value == self.transModel.value) {
                        transmodelAvailable = true;
                    }
                });
                if(!transmodelAvailable){
                    self.transModel = "";
                }
            },
            returnData : function() {
                let transMakeID = "";
                if (self.transMakeId != null && typeof self.transMakeId.value != "undefined" && self.transMakeId.value != "") {
                    transMakeID = self.transMakeId.value;
                }
                let transModelID = "";
                if (self.transModel != null && typeof self.transModel.value != "undefined" && self.transModel.value != "") {
                    transModelID = self.transModel.value;
                }
                var data = {
                    exterior_height : self.exteriorHeight,
                    exterior_width : self.exteriorWidth,
                    exterior_length : self.exteriorLength,
                    dimensions : self.dimensions,
                    reefer_serial : self.reeferSerial,
                    odometer : self.odometer,
                    skirt : (self.skirt == null || !self.skirt.value) ? "" : self.skirt.value,
                    rear_door_type_id : (self.rearDoorTypeId == null || !self.rearDoorTypeId.value) ? "" : self.rearDoorTypeId.value,
                    floor_type_id : (self.floorTypeId == null || !self.floorTypeId.value) ? "" : self.floorTypeId.value,
                    roof_type_id : (self.roofTypeId == null || !self.roofTypeId.value) ? "" : self.roofTypeId.value,
                    exhaust_id : (self.exhaustId == null || !self.exhaustId.value) ? "" : self.exhaustId.value,
                    ecmmiles : self.ecmmiles,
                    breathers_id : (self.breathersId == null || !self.breathersId.value) ? "" : self.breathersId.value,
                    gvw :self.gvw,
                    rear_ratio : self.rearRatio,
                    empty_weight : self.emptyWeight,
                    locking_rears : self.lockingRears,
                    engine_make_id : (self.engineMakeId == null || !self.engineMakeId.value) ? "" : self.engineMakeId.value,
                    axle_type_id : (self.axleTypeId == null || !self.axleTypeId.value) ? "" : self.axleTypeId.value,
                    engine_make_model : (self.engineModel == null || !self.engineModel.value) ? "" : self.engineModel.value,
                    steer_axle_capacity : self.steerAxleCapacity,
                    driver_axle_capacity : self.driverAxleCapacity,
                    drop_axle_capacity : self.dropAxleCapacity,
                    engine_size : self.engineSize,
                    engine_hp : self.engineHp,
                    drop_axle_config_id : (self.dropAxleConfigId == null || !self.dropAxleConfigId.value) ? "" : self.dropAxleConfigId.value,
                    engine_brake_id : (self.engineBrakeId == null || !self.engineBrakeId.value) ? "" : self.engineBrakeId.value,
                    suspension_id : (self.suspensionId == null || !self.suspensionId.value) ? "" : self.suspensionId.value,
                    apu_make_id : (self.apuMakeId == null || !self.apuMakeId.value) ? "" : self.apuMakeId.value,
                    wheel_base : self.wheelbase,
                    apu_hours : self.apuHours,
                    brake_type_id : (self.brakeTypeId == null || !self.brakeTypeId.value) ? "" : self.brakeTypeId.value,
                    apu_serials : self.apuSerials,
                    tire_size : self.tiresize,
                    trans_make_id : transMakeID,
                    trans_model : transModelID,
                    trans_speed_id : (self.transSpeedId == null || !self.transSpeedId.value) ? "" : self.transSpeedId.value,
                    trans_type_id : (self.transTypeId == null || !self.transTypeId.value) ? "" : self.transTypeId.value,
                    tire_trade : self.tireTrade,
                    tire_config : self.tireConfig,
                    frontwheel : (self.frontWheel == null || !self.frontWheel.value) ? "" : self.frontWheel.value,
                    rear_wheel : (self.rearWheel == null || !self.rearWheel.value) ? "" : self.rearWheel.value,
                    fuel_type_id : (self.fuelTypeId == null || !self.fuelTypeId.value) ? "" : self.fuelTypeId.value,
                    fuel_tanks : self.fuelTanks,
                    fuel_capacity : self.fuelCapacity,
                    is_piloted_wheel_id  : (self.isPilotedWheelsId == null || !self.isPilotedWheelsId.value) ? "" : self.isPilotedWheelsId.value,
                    fuel_tanks : self.fuelTanks,
                    is_fifth_wheel : self.isFifthWheel,
                    cab_to_axle : self.cabToAxle,
                    kingpin : self.kingPin,
                    wet_line: (self.wetLineId == null || !self.wetLineId.value) ? "" : self.wetLineId.value,
                    sleeper_size : self.sleeperSize,
                    bunk_type : self.bunkType,
                    bunk_heater : self.bunkHeater,
                    cab_type_id : (self.cabTypeId == null || !self.cabTypeId.value) ? "" : self.cabTypeId.value,
                    cab_style_id : (self.cabStyleId == null || !self.cabStyleId.value) ? "" : self.cabStyleId.value,
                    // rear_wheel : self.rearWheel,
                    is_airconditioning_id : (self.isAirConditioningId == null || !self.isAirConditioningId.value) ? "" : self.isAirConditioningId.value,
                    interior_color : self.interiorColor,
                    exterior_color_id : (self.exteriorColor == null || !self.exteriorColor.value) ? "" : self.exteriorColor.value,
                    heated_mirriors_id : (self.heatedMirriorsId == null || !self.heatedMirriorsId.value) ? "" : self.heatedMirriorsId.value,
                    fairing_id : (self.fairingId == null || !self.fairingId.value) ? "" : self.fairingId.value,
                    visor_id : (self.visorId == null || !self.visorId.value) ? "" : self.visorId.value,
                    body_type_id : (self.bodyTypeId == null || !self.bodyTypeId.value) ? "" : self.bodyTypeId.value,
                    fleet_id : (self.fleetId == null || !self.fleetId.value) ? "" : self.fleetId.value,
                    dtr_trade_terms_id : (self.dtrTradeTermsId == null || !self.dtrTradeTermsId.value) ? "" : self.dtrTradeTermsId.value,
                    lane_mitigation : (self.laneMitigation == null || !self.laneMitigation.value) ? "no" : self.laneMitigation.value,
                    seat_capacity: self.seatCapacity,
                };
                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                    data.id = self.$route.params.id;
                }
                return data;
            },
            buttonBack : function () {
                self.$router.push( { name : "ListAsset"});
            },
            loadTypeMasterDataCallback: function(callbackData) {
                self.frontWheelArr = callbackData.FrontWheels;
                self.rearWheelArr = callbackData.RearWheels;
                self.floorTypeArr = callbackData.FloorType;
                self.roofTypeArr = callbackData.RoofType;
                self.rearDoorTypeArr = callbackData.RearDoorType;
                self.exhaustArr = callbackData.Exhaust;
                self.breathersArr = callbackData.Breathers;
                self.engineMakeArr = callbackData.EngineMake;
                self.axleTypeArr = callbackData.AxleType;
                self.dropAxleConfigArr = callbackData.DropAxleConfig;
                self.apuMakeArr = callbackData.ApuMake;
                self.suspensionArr = callbackData.Suspension;
                self.brakeTypeArr = callbackData.BrakeType;
                self.transMakeArr = callbackData.TransMake;
                self.transSpeedArr = callbackData.TransSpeed;
                self.transTypeArr = callbackData.TransType;
                self.fuelTypeArr = callbackData.FuelType;
                self.cabTypeArr = callbackData.CabType;
                self.cabStyleArr = callbackData.CabStyle;
                self.fairingArr = callbackData.Fairings;
                self.bodyTypeArr = callbackData.BodyType;
                self.exteriorColorArr = callbackData.ExteriorColor;
            },
            loadMasterDataCallback: function(callbackData) {
                self.fleetArr = callbackData.Fleet;
                self.dtrTradeTermsArr = callbackData.DtrTradeTerms;
            },
            changedEngineMakeValue : function() {
                let make_id = "";
                if (typeof(self.engineMakeId) != "undefined" && self.engineMakeId != null && self.engineMakeId != "" && self.engineMakeId != 0) {
                    make_id = self.engineMakeId.value;
                    self.engineModel = "";
                }
                self.loadAllData(['EngineModel'], false, self.loadEngineModelCallback, "undefined", '', make_id);
            },
            loadEngineModelCallback: function(callbackData) {
                self.engineModelArr = callbackData.EngineModel;
            },
            /* loadExhaustCallback : function(callbackData) {
                self.exhaustArr = callbackData.Exhaust;
            },
            loadBreathersCallback: function(callbackData) {
                self.breathersArr = callbackData.Breathers;
            },
            loadEngineMakeCallback : function(callbackData) {
                self.engineMakeArr = callbackData.EngineMake;
            },
            loadAxleTypeCallback: function(callbackData) {
                self.axleTypeArr = callbackData.AxleType;
            },
            loadDropAxleConfigCallback: function(callbackData) {
                self.dropAxleConfigArr = callbackData.DropAxleConfig;
            },
            laodApuMakeCallback : function(callbackData) {
                self.apuMakeArr = callbackData.ApuMake;
            },
            loadSuspensionCallback: function(callbackData) {
                self.suspensionArr = callbackData.Suspension;
            },
            loadBrakeTypeCallback: function(callbackData) {
                self.brakeTypeArr = callbackData.BrakeType;
            },
            loadTransMakeCallback : function(callbackData) {
                self.transMakeArr = callbackData.TransMake;
            },
            loadTransSpeedCallback : function(callbackData) {
                self.transSpeedArr = callbackData.TransSpeed;
            },
            loadTransTypeCallback : function(callbackData) {
                self.transTypeArr = callbackData.TransType;
            },
            loadFuelTypeCallback : function(callbackData) {
                self.fuelTypeArr = callbackData.FuelType;
            },
            loadCabTypeCallback: function(callbackData) {
                self.cabTypeArr = callbackData.CabType;
            },
            loadCabStyleCallback: function(callbackData) {
                self.cabStyleArr = callbackData.CabStyle;
            },
            loadFairingCallback: function(callbackData) {
                self.fairingArr = callbackData.Fairings;
            },
            loadBodyTypeCallback : function(callbackData) {
                self.bodyTypeArr = callbackData.BodyType;
            },
            loadFleetCallback : function(callbackData) {
                self.fleetArr = callbackData.Fleet;
            },
            loadDTRTradeTermsCallback : function(callbackData) {
                self.dtrTradeTermsArr = callbackData.DtrTradeTerms;
            }, */
            getAssetSpecificationDetailsById : function (data) {
                if(data.content['asset_data'].is_truck == 'truck') {
                    self.showTrailerSpec = false;
                }
                if(data.content['asset_data'].is_truck == 'trailer') {
                    self.showTrailerSpec = true;
                }
                self.dimensions = data.content['asset_data'].dimensions;
                self.reeferSerial = data.content['asset_data'].reefer_serial;
                self.odometer = data.content['asset_data'].odometer;
                self.exteriorHeight = data.content['asset_data'].exterior_height;
                self.exteriorWidth = data.content['asset_data'].exterior_width;
                self.exteriorLength = data.content['asset_data'].exterior_length;
                if(data.content['asset_data'].skirt != null && data.content['asset_data'].skirt != 'null' && data.content['asset_data'].skirt != ''){
                    self.skirt = {text: self.capitalizeFirstLetter(data.content['asset_data'].skirt), value: data.content['asset_data'].skirt};
                }
                if(data.content['asset_data'].rear_door_type_id != null && data.content['asset_data'].rear_door_type_id != 'null' && data.content['asset_data'].rear_door_type_id != ''){
                    self.rearDoorTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].rear_door_type_name), value: data.content['asset_data'].rear_door_type_id};
                }
                if(data.content['asset_data'].floor_type_id != null && data.content['asset_data'].floor_type_id != 'null' && data.content['asset_data'].floor_type_id != ''){
                    self.floorTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].floor_name), value: data.content['asset_data'].floor_type_id};
                }
                if(data.content['asset_data'].roof_type_id != null && data.content['asset_data'].roof_type_id != 'null' && data.content['asset_data'].roof_type_id != ''){
                    self.roofTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].roof_name), value: data.content['asset_data'].roof_type_id};
                }
                if(data.content['asset_data'].exhaust_id != null && data.content['asset_data'].exhaust_id != 'null' && data.content['asset_data'].exhaust_id != ''){
                    self.exhaustId = {text: self.capitalizeFirstLetter(data.content['asset_data'].exhaust_name), value: data.content['asset_data'].exhaust_id};
                }
                self.ecmmiles = data.content['asset_data'].ecmmiles;
                if(data.content['asset_data'].breathers_id != null && data.content['asset_data'].breathers_id != 'null' && data.content['asset_data'].breathers_id != ''){
                    self.breathersId = {text: self.capitalizeFirstLetter(data.content['asset_data'].breathers_name), value: data.content['asset_data'].breathers_id};
                }
                self.gvw = data.content['asset_data'].gvw;
                self.rearRatio = data.content['asset_data'].rear_ratio;
                self.emptyWeight = data.content['asset_data'].empty_weight;
                self.lockingRears = data.content['asset_data'].locking_rears;
                if(data.content['asset_data'].engine_make_id != null && data.content['asset_data'].engine_make_id != 'null' && data.content['asset_data'].engine_make_id != ''){
                    self.engineMakeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].enigne_make_name), value: data.content['asset_data'].engine_make_id};
                }
                if(data.content['asset_data'].axle_type_id != null && data.content['asset_data'].axle_type_id != 'null' && data.content['asset_data'].axle_type_id != ''){
                    self.axleTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].axle_type_name), value: data.content['asset_data'].axle_type_id};
                }
                self.steerAxleCapacity = data.content['asset_data'].steer_axle_capacity;
                self.driverAxleCapacity = data.content['asset_data'].driver_axle_capacity;
                self.dropAxleCapacity = data.content['asset_data'].drop_axle_capacity;
                self.engineSize = data.content['asset_data'].engine_size;
                self.engineHp = data.content['asset_data'].engine_hp;
                if(data.content['asset_data'].drop_axle_config_id != null && data.content['asset_data'].drop_axle_config_id != 'null' && data.content['asset_data'].drop_axle_config_id != ''){
                    self.dropAxleConfigId = {text: self.capitalizeFirstLetter(data.content['asset_data'].drop_axle_config_name), value: data.content['asset_data'].drop_axle_config_id};
                }
                if(data.content['asset_data'].engine_brake != null && data.content['asset_data'].engine_brake != 'null' && data.content['asset_data'].engine_brake != ''){
                    self.engineBrakeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].engine_brake), value: data.content['asset_data'].engine_brake};
                }
                if(data.content['asset_data'].suspension_id != null && data.content['asset_data'].suspension_id != 'null' && data.content['asset_data'].suspension_id != ''){
                    self.suspensionId = {text: self.capitalizeFirstLetter(data.content['asset_data'].suspension_name), value: data.content['asset_data'].suspension_id};
                }
                if(data.content['asset_data'].apu_make_id != null && data.content['asset_data'].apu_make_id != 'null' && data.content['asset_data'].apu_make_id != ''){
                    self.apuMakeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].apu_make_name), value: data.content['asset_data'].apu_make_id};
                }
                self.wheelbase = data.content['asset_data'].wheelbase;
                self.apuHours = data.content['asset_data'].apu_hours;
                if(data.content['asset_data'].break_type_id != null && data.content['asset_data'].break_type_id != 'null' && data.content['asset_data'].break_type_id != ''){
                    self.brakeTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].break_type_name), value: data.content['asset_data'].break_type_id};
                }
                self.apuSerials = data.content['asset_data'].apu_serial;
                self.tiresize = data.content['asset_data'].tire_size;
                if(data.content['asset_data'].trans_make_id != null && data.content['asset_data'].trans_make_id != 'null' && data.content['asset_data'].trans_make_id != ''){
                    self.transMakeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].trans_make_name), value: data.content['asset_data'].trans_make_id};
                    self.changedMakeValue();
                }
                self.tireTrade = data.content['asset_data'].tire_tread;
                self.tireConfig = data.content['asset_data'].tire_config;
                if(data.content['asset_data'].trans_speed_id != null && data.content['asset_data'].trans_speed_id != 'null' && data.content['asset_data'].trans_speed_id != ''){
                    self.transSpeedId = {text: self.capitalizeFirstLetter(data.content['asset_data'].trans_speed_name), value: data.content['asset_data'].trans_speed_id};
                }
                if(data.content['asset_data'].front_wheels_id != null && data.content['asset_data'].front_wheels_id != 'null' && data.content['asset_data'].front_wheels_id != ''){
                    self.frontWheel = {text: self.capitalizeFirstLetter(data.content['asset_data'].front_wheels), value: data.content['asset_data'].front_wheels_id};
                }
                if(data.content['asset_data'].trans_type_id != null && data.content['asset_data'].trans_type_id != 'null' && data.content['asset_data'].trans_type_id != ''){
                    self.transTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].trans_type_name), value: data.content['asset_data'].trans_type_id};
                }
                if(data.content['asset_data'].rear_wheels_id != null && data.content['asset_data'].rear_wheels_id != 'null' && data.content['asset_data'].rear_wheels_id != ''){
                    self.rearWheel = {text: self.capitalizeFirstLetter(data.content['asset_data'].rear_wheels), value:data.content['asset_data'].rear_wheels_id};
                }
                if(data.content['asset_data'].fuel_type_id != null && data.content['asset_data'].fuel_type_id != 'null' && data.content['asset_data'].fuel_type_id != ''){
                    self.fuelTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].fuel_type_name), value: data.content['asset_data'].fuel_type_id};
                }
                if(data.content['asset_data'].is_piloted_wheels != null && data.content['asset_data'].is_piloted_wheels != 'null' && data.content['asset_data'].is_piloted_wheels != ''){
                    self.isPilotedWheelsId = {text: self.capitalizeFirstLetter(data.content['asset_data'].is_piloted_wheels), value: data.content['asset_data'].is_piloted_wheels};
                }
                self.fuelTanks = data.content['asset_data'].fuel_tanks;
                self.isFifthWheel = data.content['asset_data'].is_fifth_wheel;
                self.fuelCapacity = data.content['asset_data'].fuel_capacity;
                self.cabToAxle = data.content['asset_data'].cab_to_axle;
                self.kingPin = data.content['asset_data'].king_pin;
                if(data.content['asset_data'].wet_line != null && data.content['asset_data'].wet_line != 'null' && data.content['asset_data'].wet_line != ''){
                    self.wetLineId = {text: self.capitalizeFirstLetter(data.content['asset_data'].wet_line), value: data.content['asset_data'].wet_line};
                }
                self.sleeperSize = data.content['asset_data'].sleeper_size;
                self.bunkType = data.content['asset_data'].bunk_type;
                self.bunkHeater = data.content['asset_data'].bunk_heater;
                if(data.content['asset_data'].cab_type_id != null && data.content['asset_data'].cab_type_id != 'null' && data.content['asset_data'].cab_type_id != ''){
                    self.cabTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].cab_type_name), value: data.content['asset_data'].cab_type_id};
                }
                if(data.content['asset_data'].cab_style_id != null && data.content['asset_data'].cab_style_id != 'null' && data.content['asset_data'].cab_style_id != ''){
                    self.cabStyleId = {text: self.capitalizeFirstLetter(data.content['asset_data'].cab_style_name), value: data.content['asset_data'].cab_style_id};
                }
                if(data.content['asset_data'].is_air_conditioning != null && data.content['asset_data'].is_air_conditioning != 'null' && data.content['asset_data'].is_air_conditioning != ''){
                    self.isAirConditioningId = {text: self.capitalizeFirstLetter(data.content['asset_data'].is_air_conditioning), value: data.content['asset_data'].is_air_conditioning};
                }
                self.interiorColor = data.content['asset_data'].interior_color;
                if(data.content['asset_data'].ext_color_id != null && data.content['asset_data'].ext_color_id != 'null' && data.content['asset_data'].ext_color_id != ''){
                    self.exteriorColor = {text: self.capitalizeFirstLetter(data.content['asset_data'].ext_color_name), value: data.content['asset_data'].ext_color_id};
                }
                if(data.content['asset_data'].heated_mirrors != null && data.content['asset_data'].heated_mirrors != 'null' && data.content['asset_data'].heated_mirrors != ''){
                    self.heatedMirriorsId = {text: self.capitalizeFirstLetter(data.content['asset_data'].heated_mirrors), value: data.content['asset_data'].heated_mirrors};
                }
                if(data.content['asset_data'].fairing_id != null && data.content['asset_data'].fairing_id != 'null' && data.content['asset_data'].fairing_id != ''){
                    self.fairingId = {text: self.capitalizeFirstLetter(data.content['asset_data'].fairing_name), value: data.content['asset_data'].fairing_id};
                }
                if(data.content['asset_data'].visor != null && data.content['asset_data'].visor != 'null' && data.content['asset_data'].visor != ''){
                    self.visorId = {text: self.capitalizeFirstLetter(data.content['asset_data'].visor), value: data.content['asset_data'].visor};
                }
                if(data.content['asset_data'].body_type_id != null && data.content['asset_data'].body_type_id != 'null' && data.content['asset_data'].body_type_id != ''){
                    self.bodyTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].bodyTypeName), value: data.content['asset_data'].body_type_id};
                }
                if(data.content['asset_data'].fleet_id != null && data.content['asset_data'].fleet_id != 'null' && data.content['asset_data'].fleet_id != ''){
                    self.fleetId = {text: self.capitalizeFirstLetter(data.content['asset_data'].fleet_name), value: data.content['asset_data'].fleet_id};
                }
                if(data.content['asset_data'].dtr_trade_terms_id != null && data.content['asset_data'].dtr_trade_terms_id != 'null' && data.content['asset_data'].dtr_trade_terms_id != ''){
                    self.dtrTradeTermsId = {text: self.capitalizeFirstLetter(data.content['asset_data'].dtr_trade_terms_name), value: data.content['asset_data'].dtr_trade_terms_id};
                }
                //self.transModel = data.content['asset_data'].trans_model;
                self.$nextTick(() => {
                    if(data.content['asset_data'].engine_model_id != null && data.content['asset_data'].engine_model_id != 'null' && data.content['asset_data'].engine_model_id != ''){
                        self.engineModel = {text: data.content['asset_data'].engine_model, value: data.content['asset_data'].engine_model_id};
                    }
                    self.transModel = {};
                    if(data.content['asset_data'].trans_model_id != null && data.content['asset_data'].trans_model_id != 'null' && data.content['asset_data'].trans_model_id != ''){
                        self.transModel = {text: self.capitalizeFirstLetter(data.content['asset_data'].trans_model), value: data.content['asset_data'].trans_model_id};
                    }
                });

                if(data.content['asset_data'].lane_mitigation != null && data.content['asset_data'].lane_mitigation != 'null' && data.content['asset_data'].lane_mitigation != ''){
                    self.laneMitigation = {text: self.capitalizeFirstLetter(data.content['asset_data'].lane_mitigation), value: data.content['asset_data'].lane_mitigation};
                }

                if(data.content['asset_data'].seat_capacity != null && data.content['asset_data'].seat_capacity != 'null' && data.content['asset_data'].seat_capacity != ''){
                    self.seatCapacity = data.content['asset_data'].seat_capacity;
                }

                self.seatCapacity
            },

            checkForm : function() {
                var checked = true;
                if (self.odometer && !self.numberFormateValidation(self.odometer)) {
                    self.odometerError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }

                if (self.ecmmiles && !self.numberFormateValidation(self.ecmmiles)) {
                    self.ecmmilesError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }

                if (self.gvw && !self.numberFormateValidation(self.gvw)) {
                    self.gvwError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }

                if (self.rearRatio && !self.numberFormateValidation(self.rearRatio)) {
                    self.rearRatioError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }

                /* if (self.emptyWeight && !self.numberFormateValidation(self.emptyWeight)) {
                    self.emptyWeightError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */

                /* if (self.lockingRears && !self.numberFormateValidation(self.lockingRears)) {
                    self.lockingRearsError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */

                /* if (self.steerAxleCapacity && !self.numberFormateValidation(self.steerAxleCapacity)) {
                    self.steerAxleCapacityError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */

                /* if (self.driverAxleCapacity && !self.numberFormateValidation(self.driverAxleCapacity)) {
                    self.driverAxleCapacityError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */

                /* if (self.dropAxleCapacity && !self.numberFormateValidation(self.dropAxleCapacity)) {
                    self.dropAxleCapacityError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */

                /* if (self.engineSize && !self.numberFormateValidation(self.engineSize)) {
                    self.engineSizeError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (self.engineHp && !self.numberFormateValidation(self.engineHp)) {
                    self.engineHpError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }

                if (self.apuHours && !self.numberFormateValidation(self.apuHours)) {
                    self.apuHoursError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }

                /* if (self.apuSerials && !self.numberFormateValidation(self.apuSerials)) {
                    self.apuSerialsError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (self.tiresize && !self.numberFormateValidation(self.tiresize)) {
                    self.tiresizeError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.frontWheel && !self.numberFormateValidation(self.frontWheel)) {
                    self.frontWheelError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.rearWheel && !self.numberFormateValidation(self.rearWheel)) {
                    self.rearWheelError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (self.fuelTanks && !self.numberFormateValidation(self.fuelTanks)) {
                    self.fuelTanksError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.fuelCapacity && !self.numberFormateValidation(self.fuelCapacity)) {
                    self.fuelCapacityError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (self.kingPin && !self.numberFormateValidation(self.kingPin)) {
                    self.kingPinError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */


                return checked;
            },

            addAssetSpecification (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();

                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/asset/add/update/specification", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;

                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                                self.$emit('updateDisplayData',3);
                                                setTimeout(function() {
                                                    self.getAssetSpecificationDetailsById(self.asset);
                                                }, 1500);
                                            } else {
                                                self.buttonBack();
                                            }
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                  if (flag == "Save and Continue") {
                                      self.buttonNames.saveAndContinue = "Save and Continue";
                                      self.disableButtons.save = false;
                                      self.disableButtons.saveAndContinue = false;
                                      self.disableButtons.cancel = false;
                                      self.clearDataOnSaveAndContinue();
                                  } else {
                                      self.buttonNames.save = "Save";
                                      self.disableButtons.save = false;
                                      self.disableButtons.saveAndContinue = false;
                                      self.disableButtons.cancel = false;
                                  }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        },
    }
</script>