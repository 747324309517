<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area add-offer-submission">
        <template v-if="permissionNameArray.includes('can_add_offer_submission_form')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <template v-if="permissionNameArray.includes('can_list_offer_submission_form')">
                        <button id="backBtn" type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </template>
                </div>

                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!--Seles Person -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.seles_person}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="userId"
                                            name="asset"
                                            @input="userError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="userArr"
                                            @search="onSearchUser"
                                            :clearable="false"
                                            :placeholder="staticMessages.sales_person_place_holder"
                                            :disabled="true">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{userError}}</span>
                                </div>
                            </div>

                            <!-- Buyer Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.buyer_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="buyerNameError = ''" id="buyerName" v-model="buyerName" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{buyerNameError}}</span>
                                </div>
                            </div>

                            <div class="row col-12 forms-block">
                                <button class="btn btn-success" @click="validateInput(false)"> <i class="fa fa-plus" aria-hidden="false"></i> </button>
                                <span class="pl-2">{{staticInputLabelMessages.click_to_add_more}}</span>
                            </div>
                            <div class="clearfix"></div>

                            <template v-for="(input, index) in inputs">
                                <div class="forms-block" :key=index>
                                  <div class="row">

                                    <template v-if="plusCount != 0">
                                      <div :key="index" class="col-1 forms-block">
                                          <button class="btn btn-danger" @click="deleteRow(index)"><i class="fa fa-times"></i></button>
                                      </div>
                                    </template>
                                    <template v-else>
                                      <div :key="index" class="col-1 forms-block"></div>
                                    </template>

                                    <div class="col-5 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <v-select
                                                    v-model="input.asset"
                                                    name="asset"
                                                    @input="input.assetError = ''"
                                                    label="text"
                                                    :filterable="false"
                                                    :options="assetArr"
                                                    @search="onSearchAsset"
                                                    @change="onChangeAsset(input.asset, index)"
                                                    :clearable="false"
                                                    :placeholder="staticMessages.asset_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isAssetRecordNotFound">
                                            {{staticMessages.asset_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                            </v-select>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{input.assetError}}</span>
                                        </div>
                                    </div>

                                    <!-- Location -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <span class="form-control">{{ input.location }}</span>
                                        </div>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-1 forms-block">
                                    </div>
                                    <!-- Fleet -->
                                    <div class="col-5 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.fleet_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <span class="form-control">{{ input.fleet }}</span>
                                        </div>
                                    </div>

                                    <!--Offer Price -->
                                    <div class="col-6  forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.offer_price_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <input @input="input.offerPriceError = ''" id="offerPrice" v-model="input.offerPrice" class="form-control" type="text" maxlength="17" @change="calculateNetFromOffer(input)"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{input.offerPriceError}}</span>
                                        </div>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-1 forms-block">
                                    </div>
                                    <!-- Estimate of Expenses -->
                                    <div class="col-5 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.estimate_of_expenses_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <input @input="input.estimateOfExpenseError = ''" id="estimateOfExpense" v-model="input.estimateOfExpense" class="form-control" type="text" maxlength="17" @change="calculateNetFromOffer(input)"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{input.estimateOfExpenseError}}</span>
                                        </div>
                                    </div>

                                    <!-- Estimate of Expense Types -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.estimate_of_expense_types_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0 estimate-expense-type">
                                            <v-select
                                                    multiple
                                                    v-model="input.estimateOfExpenseType"
                                                    name="estimateOfExpenseType"
                                                    @input="input.estimateOfExpenseTypeError = ''"
                                                    label="text"
                                                    :filterable="true"
                                                    :options="estimateOfExpenseTypeArr"
                                                    :clearable="true"
                                                    :placeholder="staticMessages.estimate_of_expense_types_place_holder">
                                            </v-select>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{input.estimateOfExpenseTypeError}}</span>
                                        </div>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-1 forms-block">
                                    </div>
                                    <!--Retail Price -->
                                    <div class="col-5  forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.retail_price_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <input @input="input.retailPriceError = ''" id="retailPrice" v-model="input.retailPrice" class="form-control" type="text" maxlength="17"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{input.retailPriceError}}</span>
                                        </div>
                                    </div>

                                    <!-- Current Expenses -->
                                    <div class="col-6  forms-block current-expense-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.current_expenses_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <input @input="input.currentExpensesError = ''" id="currentExpenses" v-model="input.currentExpenses" class="form-control" type="text" maxlength="17" @change="calculateNetFromOffer(input)"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{input.currentExpensesError}}</span>
                                            <a href="javascript:void(0)" class="mar-right-0 expense-setting" @click.stop.prevent="openContextMenu($event, input.asset.value, index)">
                                                <i class="exceptEdit fa fa-cog" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                            <!-- Vue Context Menu Start -->
                                            <vue-context ref="menu" :close-on-click="closeOnClick"
                                                        :close-on-scroll="closeOnScroll"
                                                        :item-selector="itemSelector"
                                                        class="expense-menu"
                                                        >
                                              <div class="col-12 paddingnone list-common-actions" v-if="input.selectedRows.length >= 1">
                                                  <span class="color-cc9636">{{input.selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection(index)">X</a></span>
                                                  <button type="button" @click.stop.prevent="submitAssetExpenses($event, index, input)" class="btn btn-primary waves-effect pull-right ml-2 mt-1">Submit</button>
                                              </div>
                                              <div class="table-listing white-bg border-blue border-radius-top-right">
                                                  <table class="table custom-table jsmartable" aria-describedby="custom-selectbox-block">
                                                      <tbody>
                                                      <template v-if="assetWiseExpensesArr && assetWiseExpensesArr.length > 0">
                                                          <tr v-for="(assetexpenses, assetIndex) in assetWiseExpensesArr" :key="assetIndex">
                                                              <td class="">
                                                                  <div class="custom-control custom-checkbox">
                                                                      <input type="checkbox" v-model="input.selectedRows" :value="assetexpenses.id" @change="inputCheckbox($event, index)" class="custom-control-input" :id="'customCheck' + index + '-' + assetexpenses.id"  name="case[]">
                                                                          <label class="custom-control-label" :for="'customCheck' + index + '-' + assetexpenses.id"></label>
                                                                  </div>
                                                              </td>
                                                              <td :class="rowClass.excede_po">{{assetexpenses.excede_po}}</td>
                                                              <td :class="rowClass.expense_category">{{assetexpenses.expense_category}}</td>
                                                              <td :class="rowClass.actual_cost">{{assetexpenses.actual_cost}}</td>
                                                          </tr>
                                                      </template>
                                                      <template v-else>
                                                          <tr>
                                                              <td :colspan="headers.length + 1" class="text-alignment-center">
                                                                  {{allStaticMessages.data_not_available}}
                                                              </td>
                                                          </tr>
                                                      </template>
                                                      </tbody>
                                                      <thead>
                                                      <tr>
                                                          <th :id="'selectAllCheckBox-' + input.asset.value">
                                                              <div class="custom-control custom-checkbox">
                                                                  <input v-model="input.selectAll" type="checkbox" class="custom-control-input" :id="'selectAllCheckBox' + index" name="example1" @change="clickHeaderCheckBox($event, index)">
                                                                  <label class="custom-control-label" :for="'selectAllCheckBox' + index"></label>
                                                              </div>
                                                          </th>
                                                          <template v-for="(data, headerIndex) in headers">
                                                              <th :class="data.column_full_class + ' column-header'" :key="headerIndex" data-breakpoint="xs">{{data.name}}</th>
                                                          </template>
                                                      </tr>
                                                      </thead>
                                                  </table>
                                              </div>
                                              <!-- Pagination Start -->
                                              <div class="vgt-wrap__footer crm p-3 bottom-pagination-section">
                                                  <div class="row">
                                                      <div class="col-sm-12 col-md-2 PerPage">
                                                        <v-select title="Select Per Page"
                                                                v-model="input.perPage"
                                                                @input="changePerPage(index)"
                                                                name="customPerPage"
                                                                id="customPerPage"
                                                                label="text"
                                                                :clearable="false"
                                                                :searchable="false"
                                                                :filterable="false"
                                                                :options="paginationDropDownArr"
                                                                placeholder="Select Per Page">
                                                        </v-select>
                                                      </div>

                                                      <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                                        <p class="pageRowCount">
                                                            {{input.startEndRecord}} of {{input.totalRecords}}
                                                        </p>
                                                      </div>

                                                      <div class="col-sm-12 col-md-5 Pagination">
                                                          <b-pagination v-if="input.totalRecords > 0" align="center" :limit="4" prev-text="‹ " next-text="›" :total-rows="input.totalRecords" v-model="input.currentPage" @input="changepage(index)" :per-page="input.perPageValue"></b-pagination>
                                                      </div>

                                                      <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                                          <p class="pageJumpLabel" style="white-space: nowrap;">Page : </p>
                                                      </div>
                                                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                                                          <input v-model="input.jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor(index)" placeholder="Page">
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- Pagination End -->
                                            </vue-context>
                                            <!-- Vue Context Menu End -->
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-1 forms-block">
                                    </div>

                                    <!-- Net From Offer -->
                                    <div class="col-5  forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.net_from_offer_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <input id="netFromOffer" v-model="input.netFromOffer" class="form-control" type="text" maxlength="17" disabled/>
                                        </div>
                                    </div>

                                    <!-- Retail Ready -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.retail_ready_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <span class="form-control">{{ input.retail_ready }}</span>
                                        </div>
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                            </template>

                            <div class="clearfix"></div>

                            <!-- Is Buyer Financing with us? -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_buyer_financing_with_us_label}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="buyerFinancingWithUs"
                                                        :options="isBuyerFinancingOptions"
                                                        name="buyerFinancingWithUs">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{buyerFinancingWithUsError}}</span>
                                </div>
                            </div>

                            <!-- Has Customer Seen Truck? -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.has_customer_seen_truck_label}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                     <b-form-radio-group v-model="customerSeenTruck"
                                                        :options="hasCustomerSeenTruckOptions"
                                                        name="customerSeenTruck">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{customerSeenTruckError}}</span>
                                </div>
                            </div>

                            <!-- As-is? -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.as_is_label}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="asIs"
                                                        :options="asIsOptions"
                                                        name="asIs">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{asIsError}}</span>
                                </div>
                            </div>

                            <!--Additional notes-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.additional_notes}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="additionalNotes" v-model="additionalNotes" class="form-control" maxlength="50000"></textarea>
                                </div>
                            </div>

                            <!--Attach approval or deposit check image-->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.attach_approval_or_deposite_check_image}}</label>
                                    <vue-dropzone
                                            ref="dropzone"
                                            id="dropzone"
                                            :options="dropzoneOptions"
                                            @vdropzone-success = "uploadDocument"
                                            @vdropzone-removed-file="removedSelectedFile"
                                            @vdropzone-error="fileError"
                                            @vdropzone-file-added="fileAdd"
                                            @vdropzone-total-upload-progress="progressOfUload"
                                            :useCustomSlot=true
                                            :destroyDropzone=false
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                            <div class="subtitle">(Select .jpg, .jpeg, .png or .pdf files from your computer.)</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>


                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display: none;">
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addUpdateOfferSubmissionBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUpdateOfferSubmission()">
                            <input v-if="permissionNameArray.includes('can_list_offer_submission_form')" id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>


                    </div>


                    <!-- Forms -->
                </div>
            </div>
            <!--page content-->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    let filterColumn = "id";
    let filterType = "desc";

    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage"

    import { VueContext } from 'vue-context';
    import 'vue-context/dist/css/vue-context.css';

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddOfferSubmission',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
            VueContext,
        },
        mixins: [commonMixins],
        data() {
            return {
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".jpg, .jpeg, .png,.pdf",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },

                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                fileUploadError : "",

                buyerFinancingWithUs : "yes",
                buyerFinancingWithUsDefaultValue : "yes",
                buyerFinancingWithUsError : "",
                customerSeenTruck : "",
                customerSeenTruckDefaultValue : "yes",
                customerSeenTruckError : "",
                asIs : "",
                asIsDefaultValue : "yes",
                asIsError : "",
                additionalNotes  : '',
                salesManagerId : "",
                salesManagerArr : [],
                salesManagerError : "",

                estimateOfExpense : "",

                staticMessages: "",
                staticInputLabelMessages: "",
                contentClass: "admin-page-content",
                inputs: [
                  {
                    asset: '',
                    assetError : '',

                    location : '',
                    fleet : '',

                    offerPrice : "",
                    offerPriceError : '',

                    estimateOfExpense : "",
                    estimateOfExpenseError : '',

                    estimateOfExpenseType : '',
                    estimateOfExpenseTypeError : '',

                    retailPrice : "",
                    retailPriceError : '',

                    currentExpenses : "",
                    currentExpensesError : '',

                    netFromOffer : "",
                    retail_ready : "",

                    selectedRows : [],
                    startEndRecord: "",
                    jumpToPage: 1,
                    perPageValue: 5,
                    perPage: 5,
                    currentPage: 1,
                    totalRecords: 0,
                    selectAll: true,
                    selectedDeleteRows: [],
                    previousSelectedRows: [],
                  }
                ],

                tempInputsObject : {
                  asset: '',
                  assetError : '',

                  location : '',
                  fleet : '',

                  offerPrice : "",
                  offerPriceError : '',

                  estimateOfExpense : "",
                  estimateOfExpenseError : '',

                  estimateOfExpenseType : '',
                  estimateOfExpenseTypeError : '',

                  retailPrice : "",
                  retailPriceError : '',

                  currentExpenses : "",
                  currentExpensesError : '',

                  netFromOffer : "",
                  retail_ready : "",

                  selectedRows : [],
                  startEndRecord: "",
                  jumpToPage: 1,
                  perPageValue: 5,
                  perPage: 5,
                  currentPage: 1,
                  totalRecords: 0,
                  selectAll: true,
                  selectedDeleteRows: [],
                  previousSelectedRows: [],
                },

                asset : "",
                assetArr : [],
                userId : "",
                userArr : [],
                userError : "",
                isAssetRecordNotFound: false,
                isUserRecordNotFound : false,
                isSalesManagerRecordNotFound : false,
                offerPrice : "",

                buyerName : "",
                buyerNameError : "",

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                maxInputLength : 2,
                userArrayLength : 0,
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Offer Submission Form",
                isBuyerFinancingOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                hasCustomerSeenTruckOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                asIsOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                permissionNameArray : ['can_add_offer_submission_form'],
                retailPrice : '',
                currentExpenses : '',
                totalRetailPrice : 0,
                totalCurrentExpenses : 0,
                totalOfferPrice : 0,
                closeOnClick: false, // When set to true, the context  menu will close when clicked on
                closeOnScroll: false, // When set to true, the context  menu will close when the window is scrolled
                itemSelector: ['.custom-item-class'], // This is how the component is able to find each menu item. Useful if you use non-recommended markup

                assetId: 0,
                perPageValue: 5,
                perPage: 5,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 20],
                headers:  [
                  {
                      "name": "Excede Po",
                      "type": "text",
                      "field": "",
                      "column_name": "excede_po",
                      "column_class": "text-left",
                      "sort": "sort",
                      "filter_able": "yes",
                      "display": true,
                      "column_full_class": "excede_po sort text-left"
                  },
                  {
                      "name": "Expense Category",
                      "type": "date",
                      "field": "",
                      "column_name": "expense_category",
                      "column_class": "text-left",
                      "sort": "sort",
                      "filter_able": "yes",
                      "display": true,
                      "column_full_class": "expense_category sort text-left"
                  },
                  {
                      "name": "Actual Cost",
                      "type": "text",
                      "field": "",
                      "column_name": "actual_cost",
                      "column_class": "text-right",
                      "sort": "sort",
                      "filter_able": "yes",
                      "display": true,
                      "column_full_class": "actual_cost sort text-right"
                  }
                ],
                filters: {
                },
                appliedHeaders: [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                selectedDeleteRows: [],
                startEndRecord: "",
                selectAll: true,
                assetWiseExpensesArr: [],
                selectedExpenses : [],
                totalInvExpenses : [],
                previousSelectedRows: [],
                estimateOfExpenseTypeArr : [],
                plusCount : 0,
            }
        },
        beforeMount() {
            self = this;
        },
        watch : {
            inputs: {
                handler(val){
                    self.totalOfferPrice = 0;

                    for (const element of val) {
                        self.assetArr = self.removeByAttr(self.assetArr, "text", element.asset.text)
                    }
                },
                deep: true
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            let userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.selectDefaultUser();

            self.buyerFinancingWithUs = self.buyerFinancingWithUsDefaultValue;
            self.customerSeenTruck = self.customerSeenTruckDefaultValue;
            self.asIs = self.asIsDefaultValue;

            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('offer_submission_form');
            EventBus.$emit("menu-selection-changed", "offer_submission_form");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.contentClass = "spaced-container";
                self.buttonNames.save = "Update";
                $("#addAndContinueAssetBtn").hide();
                self.pageTitle = "Edit Inventory";
            }
            self.loadAllData(['User', 'OfferExpenseType'], true, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.fileUploadError = "";
                self.imagesPaths.push(response.content.file_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                // this.$refs.dropzone.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
                // this.$refs.dropzone.setOption('maxFiles', null);
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                let removeFile = "";
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file) {
                    for (let i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    // this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    let filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path;
                }
                for (let i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                // this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                //console.log(filePath);return false;
                let config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removedocument', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.cancel = false;
                    self.disableButtons.saveAndContinue = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.cancel = true;
                    self.disableButtons.saveAndContinue = true;
                }
            },
            removeByAttr : function(arr, attr, value){
                let i = arr.length;
                while(i--){
                    if( arr[i]
                        && arr[i].hasOwnProperty(attr)
                        && (arguments.length > 2 && arr[i][attr] === value ) ){

                        arr.splice(i,1);

                    }
                }
                return arr;
            },

            selectDefaultUser : function () {
                let userObj = JSON.parse(localStorage.getItem("user_obj"));
                let firstName = "";
                let lastName = "";
                let userId = 0;
                let fullName = "";
                if (userObj != null && userObj != "" && typeof userObj != "undefined") {
                    firstName = userObj['first_name'];
                    lastName = userObj['last_name'];
                    userId = userObj['id'];
                    fullName = firstName + " " + lastName;

                    self.userId = {text : fullName , value : userId};
                }
            },
            deleteRow(index) {
                this.plusCount = this.plusCount + 1;
                this.inputs.splice(index,1);
            },
            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            onChangeAsset : function(assetData, index) {
              if(assetData && assetData != '' && typeof assetData !== 'undefined') {
                // Check if an asset value is valid
                const isAssetValueValid = (asset) => {
                  return asset && asset.value !== "" && asset.value !== null && typeof asset.value !== "undefined";
                };

                // Check for duplicate asset value
                let count = 0;
                let isDupAssetSelected = false;
                for (const element of self.inputs) {
                  if (isAssetValueValid(element.asset) && element.asset.value == assetData.value) {
                    count++;

                    if(count > 1) {
                      isDupAssetSelected = true;
                      break;
                    }
                  }
                }

                if(isDupAssetSelected) {
                  this.$swal({
                    text: "Selected inventory is already available!",
                    type: 'info',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    allowOutsideClick : false
                  }).then((result) => {
                    if (result.value) {
                      // Clear the current block selected values when 'ok' is selected
                      self.inputs[index].asset = '';
                      self.inputs[index].offerPrice = "";
                      self.inputs[index].estimateOfExpense = "";
                      self.inputs[index].retailPrice = "";
                      self.inputs[index].currentExpenses = "";
                      self.inputs[index].selectedRows = [];
                      self.inputs[index].startEndRecord = "";
                      self.inputs[index].jumpToPage = 1;
                      self.inputs[index].perPageValue = 5;
                      self.inputs[index].perPage = 5;
                      self.inputs[index].currentPage = 1;
                      self.inputs[index].totalRecords = 0;
                      self.inputs[index].selectAll = true;
                      self.inputs[index].selectedDeleteRows = [];
                      self.inputs[index].previousSelectedRows = [];
                      self.inputs[index].assetError = '';
                      self.inputs[index].offerPriceError = '';
                      self.inputs[index].estimateOfExpenseError = '';
                      self.inputs[index].estimateOfExpenseTypeError = '';
                      self.inputs[index].retailPriceError = '';
                      self.inputs[index].currentExpensesError = '';
                      self.inputs[index].location = '';
                      self.inputs[index].fleet = '';
                      self.inputs[index].retail_ready = '';
                    }
                  })

                } else {
                  let config = {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                      id: assetData.value
                    }
                  };
                  HTTP.get('/asset/get', config)
                    .then(response => {
                        self.inputs[index].offerPrice = "";
                        self.inputs[index].estimateOfExpense = "";
                        self.inputs[index].currentExpenses = "";
                        self.inputs[index].selectedRows = [];
                        self.inputs[index].startEndRecord = "";
                        self.inputs[index].jumpToPage = 1;
                        self.inputs[index].perPageValue = 5;
                        self.inputs[index].perPage = 5;
                        self.inputs[index].currentPage = 1;
                        self.inputs[index].totalRecords = 0;
                        self.inputs[index].selectAll = true;
                        self.inputs[index].selectedDeleteRows = [];
                        self.inputs[index].previousSelectedRows = [];
                        self.inputs[index].assetError = '';
                        self.inputs[index].retailPriceError = '';
                        self.inputs[index].currentExpensesError = '';
                        self.inputs[index].location = (response.data.content.asset_data.location_name != null) ? response.data.content.asset_data.location_name : '';
                        self.inputs[index].fleet = (response.data.content.asset_data.fleet_name != null) ? response.data.content.asset_data.fleet_name : '';
                        self.inputs[index].retailPrice = (response.data.content.asset_data.retail_price != null) ? response.data.content.asset_data.retail_price : '';
                        self.inputs[index].retail_ready = (response.data.content.asset_data.retail_ready != null) ? self.capitalizeFirstLetter(response.data.content.asset_data.retail_ready) : 'No';
                        self.inputs[index].currentExpenses = (response.data.content.asset_data.running_cost != null) ? response.data.content.asset_data.running_cost : '';
                        //self.loadAssetExpenses(assetData.value, index, false);
                        // self.netTotal = '';
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
                }
              }
            },
            calculateNetFromOffer : function(input) {
              /**
               * Date : 14th Mar,2024
               * Reason : Based on request from Drew Harrell, subtracting "Estimate of expense" inside net from offer formula
               * New Formula : Net From Offer = Offer price - Estimate of expense - Current expense
               * Old Formula : Net From Offer = Offer price - Current expense
               */

              input.netFromOffer = self.getValidPrice(input.offerPrice)
                              - self.getValidPrice(input.estimateOfExpense)
                              - self.getValidPrice(input.currentExpenses)
              input.netFromOffer = self.roundAndFormat(input.netFromOffer);
            },
            onSearchUser : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadUserTypeCallback, loading, search);
                }
            },
            onSearchSalesManager : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalesManagerTypeCallback, loading, search);
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.isUserRecordNotFound = self.isSalesManagerRecordNotFound = false;
                self.userArr = self.salesManagerArr = callbackData.User;
                if (self.userArr.length == 0) {
                    self.isUserRecordNotFound = self.isSalesManagerRecordNotFound = true;
                }
                self.estimateOfExpenseTypeArr = callbackData.OfferExpenseType;
            },
            loadUserTypeCallback: function (callbackData) {
                self.isUserRecordNotFound = false;
                self.userArr = callbackData.User;
                if (self.userArr.length == 0) {
                    self.isUserRecordNotFound = true;
                }
            },
            loadSalesManagerTypeCallback: function (callbackData) {
                self.isSalesManagerRecordNotFound = false;
                self.salesManagerArr = callbackData.User;
                if (self.salesManagerArr.length == 0) {
                    self.isSalesManagerRecordNotFound = true;
                }
            },
            returnData: function () {
                let data = {
                    user : self.userId,
                    buyer_name : self.buyerName,
                    inputs : self.inputs,
                    estimate_of_expense : self.estimateOfExpense,
                    is_buyer_financing_with_us : self.buyerFinancingWithUs,
                    has_customer_seeen_truck : self.customerSeenTruck,
                    as_is : self.asIs,
                    additional_notes : self.additionalNotes,
                    imagePath: self.imagesPaths,
                    imageSize: self.imagesSize,
                    imageType: self.imagesType
                };

                return data;
            },

            changedValue: function (value) {
                /*console.log(value);*/
            },
            buttonBack() {
                self.$router.push({name: "ListOfferSubmission"});
            },
            clearDataOnSaveAndContinue: function () {
                self.selectDefaultUser();
                self.buyerName = "";
                self.buyerFinancingWithUs = self.buyerFinancingWithUsDefaultValue;
                self.customerSeenTruck = self.customerSeenTruckDefaultValue;
                self.asIs = self.asIsDefaultValue;
                self.estimateOfExpense = "";

                for (let i = (self.inputs.length - 1); i >= 0; i--) {
                    self.deleteRow(i);
                }
                self.additionalNotes = "";

                self.inputs = [
                  {
                    asset: '',
                    assetError : '',

                    location : '',
                    fleet : '',

                    offerPrice : "",
                    offerPriceError : '',

                    estimateOfExpense : "",
                    estimateOfExpenseError : '',

                    estimateOfExpenseType : '',
                    estimateOfExpenseTypeError : '',

                    retailPrice : "",
                    retailPriceError : '',

                    currentExpenses : "",
                    currentExpensesError : '',

                    netFromOffer : '',

                    selectedRows : [],
                    startEndRecord: "",
                    jumpToPage: 1,
                    perPageValue: 5,
                    perPage: 5,
                    currentPage: 1,
                    totalRecords: 0,
                    selectAll: true,
                    selectedDeleteRows: [],
                    previousSelectedRows: [],
                  }
                ];

                self.totalOfferPrice = 0;
                self.totalRetailPrice = 0;
                self.totalCurrentExpenses = 0;
                /* self.netTotal = '';
                self.netFromOffer = ''; */

                self.imagesPaths = [];
                self.imagesType = [];
                self.imagesSize = [];
                self.fileUploadError = "";
                self.$refs.dropzone.removeAllFiles();
                self.plusCount = 0;
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },
            validateInput: function (isCheckForm = true) {
                let checked = true;
                for (const element of self.inputs) {
                    element.assetError = "";
                    element.offerPriceError = "";
                    element.estimateOfExpenseError = "";
                    element.retailPriceError = "";
                    element.currentExpensesError = "";
                    element.estimateOfExpenseTypeError = "";

                    if (element.asset.value === "" || element.asset.value === null || typeof element.asset.value === "undefined") {
                        element.assetError = "Please select VIN No.";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if (!element.offerPrice) {
                        element.offerPriceError = "Please enter offer price";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.offerPrice && !self.numberFormateValidation(element.offerPrice)) {
                        element.offerPriceError = "Please enter numeric value for offer price";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.offerPrice && self.numberFormateValidation(element.offerPrice) && element.offerPrice <= 0) {
                        element.offerPriceError = "Please enter valid offer price";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if (!element.estimateOfExpense) {
                        element.estimateOfExpenseError = "Please enter estimate of expenses";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.estimateOfExpense && !self.numberFormateValidation(element.estimateOfExpense)) {
                        element.estimateOfExpenseError = "Please enter numeric value for estimate of expenses";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.estimateOfExpense && self.numberFormateValidation(element.estimateOfExpense) && element.estimateOfExpense < 0) {
                        element.estimateOfExpenseError = "Please enter valid estimate of expenses";
                        if (checked) {
                            checked = false;
                        }
                    }

                    if (!element.retailPrice) {
                        element.retailPriceError = "Please enter retail price";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.retailPrice && !self.numberFormateValidation(element.retailPrice)) {
                        element.retailPriceError = "Please enter numeric value for retail price";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.retailPrice && self.numberFormateValidation(element.retailPrice) && element.retailPrice <= 0) {
                        element.retailPriceError = "Please enter valid retail price";
                        if (checked) {
                            checked = false;
                        }
                    }

                    if (!element.currentExpenses) {
                        element.currentExpensesError = "Please select current expenses";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.currentExpenses && !self.numberFormateValidation(element.currentExpenses)) {
                        element.currentExpensesError = "Please enter numeric value for current expenses";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.currentExpenses && self.numberFormateValidation(element.currentExpenses) && element.currentExpenses < 0) {
                        element.currentExpensesError = "Please enter valid current expenses";
                        if (checked) {
                            checked = false;
                        }
                    }

                    if (element.estimateOfExpenseType.length == 0) {
                        element.estimateOfExpenseTypeError = "Please select estimate of expense types";
                        if (checked) {
                            checked = false;
                        }
                    }  else {
                      /*
                        Description:
                          - Iterate through each expense type to check if an estimate of expenses is provided.
                          - If estimate of expenses is not provided and there are other expense types selected other than "No Expenses",
                          - Then, sets an error message and update the checked flag.
                          - Else If, estimate of expenses is provided and "No Expenses" option is selected,
                          - Then, set an error message and update the checked flag.
                        Parameters:
                          - element: The object containing expense-related information.
                          - checked: A boolean flag indicating whether the form is checked or not.
                      */
                      for (let i = 0; i < element.estimateOfExpenseType.length; i++) {
                        if (!element.estimateOfExpense || element.estimateOfExpense == 0 || element.estimateOfExpense === null || typeof element.estimateOfExpense ==='undefined') {

                          const hasOtherExpenses = element.estimateOfExpenseType.some(expense => expense.text !== 'No Expenses');
                          if(hasOtherExpenses) {
                            element.estimateOfExpenseTypeError = "Please enter an estimate of expenses to add other estimate of expense types.";
                            if (checked) {
                              checked = false;
                            }
                          }
                        } else if(element.estimateOfExpense && element.estimateOfExpense > 0) {
                          const hasOtherExpenses = element.estimateOfExpenseType.some(expense => expense.text === 'No Expenses');
                          if(hasOtherExpenses) {
                            element.estimateOfExpenseTypeError = "Selected estimate of expenses is non-zero, therefore no expenses option can't be added.";
                            if (checked) {
                              checked = false;
                            }
                          }
                        }
                      }
                    }
                }

                if (isCheckForm) {
                  if (self.userId == null || !self.userId.value) {
                      self.userError = "Please select Salesman Name";
                      if (checked) {
                          checked = false;
                      }
                  }
                  if (!self.buyerName) {
                      self.buyerNameError = "Please enter buyer name";
                      if (checked) {
                          checked = false;
                      }
                  }
                }

                if(checked && !isCheckForm) {
                  self.inputs.push(Vue.util.extend({}, self.tempInputsObject));
                  self.plusCount = self.plusCount - 1;
                }

                return checked;
            },
            addUpdateOfferSubmission(flag) {
                setTimeout(() => {
                    if (self.validateInput()) {
                      self.$swal({
                        title: "",
                        text: self.allStaticMessages.offer_create_expense_add_info_msg,
                        type: "info",
                        confirmButtonText: "OK",
                        showCancelButton: true,
                        showLoaderOnConfirm: false
                      }).then((result) => {
                        if (result.value) {
                          let config = {
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("api_token")
                              }
                          };
                          let data = self.returnData();
                          if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                              self.buttonNames.save = "Updating ...";
                              self.disableButtons.save = true;
                              self.disableButtons.cancel = true;
                          } else {
                              if (flag == "Save and Continue") {
                                  self.buttonNames.saveAndContinue = "Saving ...";
                              } else {
                                  self.buttonNames.save = "Saving ...";
                              }
                              self.disableButtons.save = true;
                              self.disableButtons.saveAndContinue = true;
                              self.disableButtons.cancel = true;
                          }
                          HTTP.post("/offersubmission/add/update/vtc", data, config)
                            .then(function (response) {
                                if ( typeof(response.data.email_error) != 'undefined' ) {
                                    alert(response.data.email_error);
                                }
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {

                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        self.clearDataOnSaveAndContinue();
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            if (self.permissionNameArray.includes('can_list_offer_submission_form')) {
                                                self.buttonBack();
                                            }
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        let err = "";
                                        for (const element of response.data.content) {
                                            err += element + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                        }
                      });
                    }
                }, 200);

            },
            /**
             * Opens a context menu based on the provided event, asset ID, and index.
             * If no asset ID is provided, it displays an informational message.
             *
             * @param {Event} e - The event triggering the context menu.
             * @param {string} id - The asset ID.
             * @param {number} index - The index of the context menu to open.
             */
            openContextMenu: function (e, id, index) {
                if(typeof id === 'undefined' || id == 'null' || id == null || id == '') {
                  self.showSAlert('No inventory selected!', 'info');
                } else {
                  self.assetId = id;
                  self.loadAssetExpenses(self.assetId, index, false);

                  if(self.inputs.length > 0) {
                    for(let i=0; i < self.inputs.length; i++) {
                      self.$refs.menu[i].close(e);
                    }
                  }
                  self.$refs.menu[index].open(e);
                }
            },
            // Reset pagination for a specific asset and load expenses accordingly
            changePerPage : function(index){
                self.inputs[index].currentPage = 1;
                self.inputs[index].perPageValue = self.inputs[index].perPage;
                self.loadAssetExpenses(self.assetId, index, false);
            },
            changepage : function(index){
                if(!self.listExecutionState){
                    self.loadAssetExpenses(self.assetId, index, true);
                }
            },
            clearSelection: function (index) {
                self.inputs[index].selectAll = false;
                self.inputs[index].selectedRows = [];
            },
            keyMonitor: function (index) {
                let tempNumber = self.jumpToInputPage(self.inputs[index].jumpToPage, self.inputs[index].totalRecords, self.inputs[index].perPage);
                self.inputs[index].currentPage = self.inputs[index].jumpToPage = parseInt(tempNumber);
            },
            // Handle the selection or deselection of rows when a specific asset header checkbox is clicked
            clickHeaderCheckBox: function (e, index) {
                setTimeout(function () {
                    if(e.target.checked) {
                      self.inputs[index].selectAll = true;
                      self.inputs[index].selectedRows = [];

                      for (let i in self.totalInvExpenses) {
                          self.inputs[index].selectedRows.push(self.totalInvExpenses[i].id);
                      }
                    } else {
                      self.inputs[index].selectAll = false;
                      self.inputs[index].selectedRows = [];
                    }
                }, 100);
            },
            inputCheckbox: function (e, index) {
                setTimeout(function () {
                    self.inputs[index].selectAll = true;
                    if(self.inputs[index].selectedRows.length != self.totalInvExpenses.length) {
                      self.inputs[index].selectAll = false;
                    }
                }, 100);
            },
            loadAssetExpenses: function (assetId, index, isChangePage) {
                self.listExecutionState = true;
                let config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                let fields = {
                    filters: self.filters,
                    per_page: self.inputs[index].perPage,
                    page: self.inputs[index].currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    asset_id: assetId
                };
                HTTP.post("/assetwiseexpenses/list", fields, config)
                        .then(function (data) {
                            self.listExecutionState = false;
                            if (data.status == 200) {
                                // self.clearSelection();
                                self.inputs[index].totalRecords = data.data.content.result;
                                self.assetWiseExpensesArr = data.data.content.data;
                                if(self.assetWiseExpensesArr.length > 0) {

                                    self.totalInvExpenses =  data.data.content.totalInvExpensedata;
                                    // Check if there were no previously selected rows
                                    if( self.inputs[index].previousSelectedRows.length <= 0 ) {

                                      // If 'selectAll' is true, populate selectedRows and calculate current expenses
                                      if(self.inputs[index].selectAll) {
                                        self.inputs[index].selectedRows = [];
                                        
                                        // comment this as loaded expense from asset API 
                                        //self.inputs[index].currentExpenses = 0;

                                        for(const element of self.totalInvExpenses) {
                                          if( element.id != '' && element.is_excluded == 0 ) {

                                                self.inputs[index].selectedRows.push(element.id);
                                                
                                                // comment this as loaded expense from asset API 
                                                // if(element.actual_cost_wo_fmt != '') {
                                                //     self.inputs[index].currentExpenses += parseFloat(element.actual_cost_wo_fmt); // Calculate Current Expenses
                                                // }
                                          }
                                        }
                                        // comment this as loaded expense from asset API 
                                        // if(self.inputs[index].currentExpenses != 0) {
                                        //   self.inputs[index].currentExpenses = Math.round(self.inputs[index].currentExpenses *100)/100;
                                        // }
                                        self.inputs[index].previousSelectedRows = self.inputs[index].selectedRows;
                                      }
                                    } else {
                                      // Check if all rows are selected or not for specific asset
                                      if(self.inputs[index].selectedRows.length == self.totalInvExpenses.length) {
                                        self.inputs[index].selectAll = true;
                                      } else {
                                        self.inputs[index].selectAll = false;
                                      }

                                      // If not a page change, restore selectedRows from previousSelectedRows
                                      if(!isChangePage) {
                                        self.inputs[index].selectedRows = self.inputs[index].previousSelectedRows;
                                      }
                                    }
                                }

                                self.addColumnClass(self.headers);

                                let testArray = [
                                    {
                                        'text': 1,
                                        'value': '2020-02-01'
                                    },
                                    {
                                        'text': 2,
                                        'value': '2020-02-02'
                                    },
                                    {
                                        'text': 3,
                                        'value': '2020-02-03'
                                    }
                                ]
                                for (const element of self.headers) {
                                    if (element['type'] == 'drop_down') {
                                        element['options_array'] = testArray;
                                    }
                                }
                                self.inputs[index].perPage = parseInt(data.data.content.per_page);
                                self.inputs[index].jumpToPage = parseInt(data.data.content.current_page);
                                // self.setAlreadyAppliedFilters();
                                self.inputs[index].startEndRecord = self.calculateStartRecordEndRecord(self.inputs[index].currentPage, self.inputs[index].perPage, self.inputs[index].totalRecords);
                                self.sortFieldDisplay(self.columnName, self.sortType);
                            }
                        })
                        .catch(function (error) {
                            self.listExecutionState = false;
                            self.catchHandler(error, function () {});
                        })
            },
            /**
             * Function to handle asset expense submission which
             * 1) Update selection status
             * 2) Calculate total expenses
             * 3) Close a menu referenced by an index.
             * @param {Event} e - The event triggering the context menu.
             * @param {number} index - The index of the context menu to close.
             */
            submitAssetExpenses: function (e, index, input) {
                if(self.inputs[index].selectedRows.length == self.totalInvExpenses.length) { // 1
                  self.inputs[index].selectAll = true;
                } else {
                  self.inputs[index].selectAll = false;
                }
                
                self.inputs[index].currentExpenses = 0;
                if(self.inputs[index].selectedRows.length > 0) {
                  for (const element of self.inputs[index].selectedRows) {
                    const hasExpObjectWithId = self.totalInvExpenses.find(expenseObj => expenseObj.id === element);
                    if (hasExpObjectWithId) {
                      self.inputs[index].currentExpenses += parseFloat(hasExpObjectWithId.actual_cost_wo_fmt); // 2
                      self.inputs[index].currentExpensesError = '';
                    }
                  }

                  if(self.inputs[index].currentExpenses != 0) {
                    self.inputs[index].currentExpenses = Math.round(self.inputs[index].currentExpenses *100)/100;
                  }

                  self.inputs[index].previousSelectedRows = self.inputs[index].selectedRows;
                }

                self.$refs.menu[index].close(e); // 3

                self.calculateNetFromOffer(input);
            },
            /**
            Get the price from the input, handling various edge cases.
            @param {any} input - The input value to be processed.
            @param {number} [defaultValue=0] - The default value to return if the input is invalid.
            @returns {number} - The processed price or the default value.
            */
            getValidPrice: function(input, defaultValue = 0) {

              if (input === null || input === undefined) { // Check for null or undefined
                return defaultValue;
              }

              const price = parseFloat(input); // Convert input to a number
              if (isNaN(price)) { // Check if the result is NaN (Not a Number)
                return defaultValue;
              }

              return price; // Return the valid price
            },
            // Function to round a number to two decimal places and remove trailing '.00'
            roundAndFormat: function(number) {
              var formattedOutput = '';
              if(number) {
                formattedOutput = (Math.round(number*100)/100).toFixed(2).replace(/\.00$/, '');
              }
              return formattedOutput;
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (const element of permissions) {
                self.permissionNameArray.push(element.permission_name);
            }
        }
    });
</script>

<style>
    .spanClass {
        margin-left: 10px;
    }
    .add-offer-submission .expense-setting{
        position: absolute;
        right: -6px;
        top: 10px;
    }
    .add-offer-submission .current-expense-block .expense-menu{
        position: absolute;
        left:unset !important;
        right: 0;
        top: 40px !important;
        max-height: 400px;
    }
    .add-offer-submission .current-expense-block .pagination{
        border: 0;
        position: unset;
        max-height: unset;
        padding: 0;
        box-shadow: unset;
        min-width: unset;
        display: flex;
    }
    .add-offer-submission .current-expense-block .bottom-pagination-section {
      margin: auto !important;
      font-size:15px;
    }
    .add-offer-submission .pagination .page-item span.page-link {
      font-size:15px;
    }

    .add-offer-submission .estimate-expense-type .v-select .vs__selected-options {
      flex-wrap: wrap !important;
    }
</style>
