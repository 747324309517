<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_repair_order_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>Repair Orders</span>
                            <p v-if="totalRecords > 0">{{totalRecords}} Results found</p>
                        </div>
                        <a
                                id='refreshList'
                                :title='"Sync next " + records + " Orders"'
                                class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                                v-on:click.prevent="refreshList()">
                            <span>Sync next {{records}} Orders</span>
                        </a>
                    </div>
                    <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="filterheaders" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                    </div>
                    <div class="col-12 paddingnone">
                        <common-loader
                        refId="commonLoaderListRepairOrder"
                        ref="commonLoaderListRepairOrder"
                        >
                        </common-loader>
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                <template v-if="locationArr && locationArr.length > 0">
                                    <tr v-for="(location, index) in locationArr" :key="index">
                                        <td class="">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="selectedRows" :value="location.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + location.id" name="case[]">
                                                <label class="custom-control-label" :for="'customCheck' + location.id"></label>
                                            </div>
                                        </td>
                                        <td v-show="actionDisplay">
                                            <div class="actions">
                                                <router-link v-show="permissionNameArray.includes('can_list_repair_order_master')" class="mar-right-0" title='View Repair Order Ops' v-bind:to="{name: 'ListRepairOrderOps', params:{sls_id: location.id}}">
                                                    <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                </router-link>
                                            </div>
                                        </td>
                                        <td :class="rowClass.sls_id" >{{location.sls_id}}</td>
                                        <td :class="rowClass.sls_typ_id" >{{location.sls_typ_id}}</td>
                                        <td :class="rowClass.unt_id">{{location.unt_id}}</td>
                                        <!--<td>{{location.website_name}}</td>-->
                                        <!--<td>{{location.address}}</td>-->
                                        <td :class="rowClass.vin">{{location.vin}}</td>
                                        <td :class="rowClass.fleet_unt_id">{{location.fleet_unt_id}}</td>
                                        <td :class="rowClass.cus_name">{{location.cus_name}}</td>
                                        <td :class="rowClass.cus_phone">{{location.cus_phone}}</td>
                                        <td :class="rowClass.email">{{location.email}}</td>
                                        <td :class="rowClass.created_at">{{location.created_at}}</td>
                                        <td :class="rowClass.updated_at">{{location.updated_at}}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td :colspan="headers.length + 1" class="text-alignment-center">
                                            {{allStaticMessages.data_not_available}}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                                <thead>
                                <tr>
                                    <th class="">
                                        <div class="custom-control custom-checkbox">
                                            <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                            <label class="custom-control-label" for="selectAllCheckBox"></label>
                                        </div>
                                    </th>
                                    <template v-for="(data, headerIndex) in headers">
                                        <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                    </template>
                                    <template v-for="(data, headerIndex) in headers">
                                        <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                    </template>
                                </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "created_at";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import CommonLoader from "@/components/partials/CommonLoader.vue";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'ListRepairOrder',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
            "common-loader": CommonLoader,
        },
        data () {
            return {
                perPageValue: 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                locationArr: [],
                headers: [],
                filterheaders: [],
                filters : {
                },
                appliedHeaders : [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord: "",
                selectAll: false,
                permissionNameArray : ['can_list_repair_order_master'],
                actionDisplay : true,
                jumpToHistoryPage : 1,
                filterCount : 0,
                records : 2500
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            /*selectAll: function (selectAll) {
                self.clickHeaderCheckBox();
            },*/
            /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadRepairOrders();
            },
            currentPage: function () {
                self.loadRepairOrders();
            }*/
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('repairorder-list');
            EventBus.$emit("menu-selection-changed", "repairorder-list");
            var listHistoryData = self.loadFilters('ListRepairOrder', self);
            self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.listExecutionState = false;
            self.loadRepairOrders(1);
        },
        methods: {
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadRepairOrders();
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadRepairOrders();
                }
            },
            refreshList : function() {
                self.isActivityTypeOther = false;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        records : self.records
                    }
                };
                HTTP.get('/repairorder/sync', config)
                    .then(response => {
                        self.jumpToPage = 1;
                        self.keyMonitor();
                        self.loadRepairOrders();
                    })
                    .catch(function (err) {

                    });
            },
            clearSelection : function() {
                self.selectAll = false;
                self.selectedRows = [];
            },
            keyMonitor: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function() {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.locationArr) {
                            self.selectedRows.push(self.locationArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function(e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.locationArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            addLocation: function() {
                // self.$router.push('/add/location');
            },
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data;
                self.loadRepairOrders();
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeaders.length > 0) {
                    self.filterheaders = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadRepairOrders: function(first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadRepairOrders();
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters : self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType
                };
                this.storeFilters('ListRepairOrder', fields, false);
                $("#commonLoaderListRepairOrder")
                .find(".loading-container.lodingTable")
                .show();
                self.filterCount = self.filterCountFunction(self.filters);
                HTTP.post("/repairorder/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPage = self.jumpToHistoryPage;
                        }
                        $("#commonLoaderListRepairOrder")
                        .find(".loading-container.lodingTable")
                        .hide();
                        if (data.status == 200) {
                            self.clearSelection();
                            self.headers = [];
                            self.totalRecords = data.data.content.result;
                            if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                // alert(self.totalRecords);
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPage = 500;
                                    self.loadRepairOrders();
                                }, seconds);
                                /* self.$swal({
                                    title: '',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    type: 'error',
                                    confirmButtonText: 'OK',
                                    showCancelButton: true,
                                    showLoaderOnConfirm: true
                                }).then((result) => {
                                    if (result.value) {
                                        self.logout();
                                    } else {
                                        self.perPage = 15;
                                        self.loadRepairOrders();
                                    }
                                }); */
                            }
                            self.locationArr = data.data.content.data;
                            self.headers = data.data.content.headers;
                            self.filterheaders = data.data.content.filterheaders;
                            self.loadFilterInputs(data.data.content.filterheaders,self.filters);

                            self.addColumnClass(self.headers);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.headers.length; i++) {
                                if (self.headers[i]['type'] == 'drop_down') {
                                    self.headers[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecords) {
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPage == 'All'){
                                    self.jumpToPage = 1;
                                }
                                self.perPageValue = self.totalRecords;
                                self.currentPage = 1;
                            }
                            self.setAlreadyAppliedFilters();
                            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                            self.sortFieldDisplay(self.columnName,self.sortType);
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        $("#commonLoaderListRepairOrder")
                        .find(".loading-container.lodingTable")
                        .hide();
                        self.catchHandler(err, function () {});
                    })
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadRepairOrders();
                }
            },
            deleteLocation: function(id) {
                let deleteArray = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if(id != 0) {
                    deleteArray.push(id);
                } else {
                    deleteArray = self.selectedRows;
                }
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to delete this Location(s)!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    // showCloseButton: true,
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                            data : {
                                deleteIdArr: deleteArray,
                            }
                        };
                        HTTP.delete("/location/delete", config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.loadRepairOrders();
                                }
                            })
                            .catch(function (error) {
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            },
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            /* if (!self.permissionNameArray.includes('can_add_location_master') && !self.permissionNameArray.includes('can_delete_location_master')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            } */
        }
    });
</script>