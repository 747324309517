<template>
  <div class="spaced-container">
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
              <ul class="nav nav-tabs">
                <li
                  class="nav-item"
                  v-if="permissionNameArray.includes('can_add_location_master')"
                >
                  <a
                    v-on:click="activetab = 1"
                    v-bind:class="
                      'nav-link ' + [activetab === 1 ? 'active' : '']
                    "
                    >Edit Location
                  </a>
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes(
                      'can_list_set_by_branch_defaults'
                    )
                  "
                >
                  <a
                    v-on:click="activetab = 2"
                    v-bind:class="{ 'nav-link': true, active: activetab === 2 }"
                    >Set By Branch Default
                  </a>
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_list_cal_vehicle_prices')
                  "
                >
                  <a
                    v-on:click="activetab = 3"
                    v-bind:class="{ 'nav-link': true, active: activetab === 3 }"
                    >Vehicle Price
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    v-on:click="activetab = 4"
                    v-bind:class="{ 'nav-link': true, active: activetab === 4 }"
                    >Location Contacts
                  </a>
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes(
                      'can_list_area_on_yard_location_master'
                    )
                  "
                >
                  <a
                    v-on:click="activetab = 5"
                    v-bind:class="{ 'nav-link': true, active: activetab === 5 }"
                    >Area On Yard
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    v-on:click="activetab = 6"
                    v-bind:class="{ 'nav-link': true, active: activetab === 6 }"
                    >Printing Documents Fields
                  </a>
                </li>
              </ul>

              <div
                v-if="activetab === 1"
                class="tabcontent"
                id="location-activity-logs1"
              >
                <update-location
                  ref="locationAddUpdateForm"
                  @setPageTitle="setPageTitle"
                ></update-location>
              </div>
              <div
                v-if="activetab === 2"
                class="tabcontent"
                id="location-activity-logs2"
              >
                <list-set-by-branch></list-set-by-branch>
              </div>
              <div
                v-if="activetab === 3"
                class="tabcontent"
                id="location-activity-logs3"
              >
                <list-cal-vehicle-price></list-cal-vehicle-price>
              </div>
              <div
                v-if="activetab === 4"
                class="tabcontent"
                id="location-activity-logs4"
              >
                <list-location-manager></list-location-manager>
              </div>
              <div
                v-if="activetab === 5"
                class="tabcontent"
                id="location-activity-logs5"
              >
                <list-area-on-yard :locid="locationId"></list-area-on-yard>
              </div>
              <div
                v-if="activetab === 6"
                class="tabcontent"
                id="location-activity-logs6"
              >
                <printing-documents-fields :locid="locationId"></printing-documents-fields>
              </div>
            </div>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
      <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
  </div>
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import UpdateLocation from "@/components/LocationMaster/AddUpdateLocation";
import ListStaticSetByBranch from "@/components/AssetMaster/NewTransactionSheet/LocationSetDefaultTabs/ListBranchDefaults";
import ListVehiclePrices from "@/components/AssetMaster/NewTransactionSheet/LocationSetDefaultTabs/ListVehiclePrices";
import ListLocationManager from "@/components/LocationMaster/ListLocationManager.vue";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import ListAreaOnYard from "./ListAreaOnYard.vue";
import PrintingDocumentsFields from "@/components/LocationMaster/PrintingDocumentsFields.vue";

Vue.component("app-footer", Footer);

export default {
  name: "ViewAsset",
  components: {
    "no-access": NoAccessPage,
    "update-location": UpdateLocation,
    "list-set-by-branch": ListStaticSetByBranch,
    "list-cal-vehicle-price": ListVehiclePrices,
    "list-location-manager": ListLocationManager,
    "list-area-on-yard": ListAreaOnYard,
    "printing-documents-fields": PrintingDocumentsFields,
  },

  mixins: [commonMixins],
  data() {
    return {
      activetab: 1,
      pageTitle: "",
      permissionNameArray: [
        "can_add_location_master",
        "can_list_set_by_branch_defaults",
        "can_add_set_by_branch_defaults",
        "can_list_cal_vehicle_prices",
        "can_add_cal_vehicle_prices",
        "can_list_area_on_yard_location_master",
      ],
      setByBranchData: [],
      calVehiclePriceData: [],
      locationId: 0,
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    if (typeof self.$route.params.branch != "undefined") {
      self.pageTitle = self.$route.params.branch + " Branch";
    } else {
      self.pageTitle = "Edit Location";
    }
    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.locationId = self.$route.params.id;
    }
    if (
      typeof self.$route.query.activeTab != "undefined" &&
      self.$route.query.activeTab != "" &&
      self.$route.query.activeTab != 0
    ) {
      self.activetab = parseInt(self.$route.query.activeTab);
    }
  },
  methods: {
    buttonBack() {
      self.$router.push({ name: "ListLocation" });
    },
    setPageTitle(branchName) {
      self.pageTitle = branchName + " Branch";
    },
  },
  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newValue) {
        if (!newValue || newValue === self.activetab) {
          return;
        }

        self.activetab = newValue;
      },
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
