<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_print_doc_option_settings')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin form-group-wrapper">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="group_name">Select Form Group</label>
                                    <v-select
                                        v-model="groupName"
                                        name="groupName"
                                        placeholder="Select Form Group"
                                        label="text"
                                        :options="groupsListArr"
                                        @change="changedGroupName"
                                    >
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <template v-if="docOptionsListArr && docOptionsListArr.length > 0">
                                    <vue-draggable-group
                                    v-model="docOptionsListArr"
                                    group="columns"
                                    class="draggable-list"
                                    >
                                    <template v-for="(doc, index) in docOptionsListArr">
                                        <div class="custom-checkbox-v draggable-item" :key="index">
                                            <span class="inline-block">{{ doc.doc_name }}</span>
                                            <i class="fa fa-arrows pull-right" aria-hidden="true"></i>
                                        </div>
                                    </template>
                                    </vue-draggable-group>
                                </template>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="col-12 mt-3">
                            <input id="saveBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="saveDocOptionSettings()">
                        </div>
                    </div>
                    <!-- Forms -->

                    
                </div>
                
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer v-if="!isEdit"></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from "../event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import VueDraggable from 'vuedraggable';
    Vue.component("app-footer", Footer);

    export default {
        name: 'PrintDocOptionSettings',
        components: {
            "app-footer" : Footer,
            'no-access' : NoAccessPage,
            "vue-draggable-group": VueDraggable,
        },
        props: {
            hasParent: false,
            parentId: null
        },
        mixins: [commonMixins],
        data () {
            return {
                isEdit: false,
                id: this.$route.params.id,
                staticInputLabelMessages: "",
                staticMessages: "",

                buttonNames : {
                    save : 'Save',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Print Doc Option Settings",
                permissionNameArray : ['can_list_print_doc_option_settings'],
                docOptionsListArr : [],
                groupsListArr : [],
                groupName : '',
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {

            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.getLoginIdPermissions();
            self.isEdit = false;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('print-doc-option-settings');
            EventBus.$emit("menu-selection-changed", "print-doc-option-settings");
            self.getPrintDocOptions();
        },
        methods: {
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            changedGroupName: function(data) {
                // Load Print Doc Options on Group Selection
                self.getPrintDocOptions();
            },
            getPrintDocOptions(){
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var data = new FormData();
                data.append('group_name', self.groupName);
                HTTP.post("/print-doc-option-settings/list", data, config)
                    .then(function (response) {
                        if (response.data.status == "success") {
                            console.log(response.data.content);
                            self.docOptionsListArr = response.data.content;
                            self.groupsListArr = response.data.groups_list;
                            if(self.docOptionsListArr.length <= 1){
                                self.disableButtons.save = true;
                            }else{
                                self.disableButtons.save = false;
                            }
                        }
                    })
                    .catch(function (err) {
                    
                    });
            },
            saveDocOptionSettings() {
                self.disableButtons.save = true;
                setTimeout(() => {
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    var data = new FormData();     
                    data.append('print_doc_options_order', JSON.stringify(self.docOptionsListArr));
                    HTTP.post("/print-doc-option-settings/store", data, config)
                        .then(function (response) {
                            if (response.data.status == "success") {
                                self.disableButtons.save = false;
                                self.showSAlert(response.data.message,'success',false ,3);
                            } else {
                                self.disableButtons.save = false;
                            }
                        })
                        .catch(function (err) {
                            self.disableButtons.save = false;
                        });                    
                    
                }, 200);
            },
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>
<style scoped>
.forms-container .row {
    display: inherit;
}
.form-group-wrapper {
    max-width: 500px;
    min-height: 350px;
}
</style>