<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_activity_master')">
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>Activity</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <router-link
              v-show="permissionNameArray.includes('can_add_activity_master')"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Add Activity"
              :to="{
                name: 'AddUpdateActivity',
                query: {
                  redirectUrl: redirectUrl,
                  tab: activetab,
                  inventory: inventory,
                  customer: customer,
                },
              }"
            >
              <span>Add Activity</span>
            </router-link>

            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
            <a
              id="exportActivityId"
              title="Export"
              v-show="
                permissionNameArray.includes('can_export_activity_master')
              "
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="exportActivityFuntion($event)"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <!-- <button type="button" v-on:click.prevent="deleteActivity()" class="btn btn-primary waves-effect pull-right ml-2">Delete {{selectedRows.length}} Record(s)</button> -->
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListActivity"
              ref="commonLoaderListActivity"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable list-activity-table">
                <tbody>
                  <template v-if="activityArr && activityArr.length > 0">
                    <tr v-for="(activity, index) in activityArr" :key="index">
                      <td class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            v-model="selectedRows"
                            :value="activity.id"
                            @change="inputCheckbox($event)"
                            class="custom-control-input"
                            :id="'customCheck' + activity.id"
                            name="case[]"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customCheck' + activity.id"
                          ></label>
                        </div>
                      </td>
                      <td v-show="actionDisplay">
                        <div class="actions" style="width: 90px">
                          <router-link
                            v-show="
                              permissionNameArray.includes(
                                'can_add_activity_master'
                              ) && activity.created_by == userId
                            "
                            class="mar-right-0"
                            title="Edit Activity"
                            v-bind:to="{
                              name: 'UpdateActivity',
                              params: { id: activity.id },
                              query: {
                                redirectUrl: redirectUrl,
                                tab: activetab,
                              },
                            }"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-angle-right forward_icon"
                            ></i>
                          </router-link>
                          <router-link
                            v-show="
                              permissionNameArray.includes(
                                'can_list_activity_master'
                              )
                            "
                            class="mar-right-0"
                            title="View Activity"
                            v-bind:to="{
                              name: 'ViewUpdateActivity',
                              params: { id: activity.id },
                              query: {
                                redirectUrl: redirectUrl,
                                tab: activetab,
                              },
                            }"
                          >
                            <i
                              aria-hidden="true"
                              class="exceptEdit fa fa-eye"
                            ></i>
                          </router-link>

                          <button
                            type="submit"
                            id="viewCommentBtn"
                            title="View Comments"
                            class="btn edit-btn-vvg waves-effect"
                            v-on:click.prevent="
                              commentSectionShowHide(true, activity.id, $event)
                            "
                          >
                            <i aria-hidden="true" class="fa fa-comment"></i>
                          </button>

                          <a
                            href="javascript:void(0)"
                            class="mar-right-0"
                            title="Activity Settings"
                            @click.stop.prevent="
                              openContextMenu(
                                $event,
                                activity.id,
                                activity.subject
                              )
                            "
                          >
                            <i
                              class="exceptEdit fa fa-cog"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </td>
                      <td :class="rowClass.module">
                        {{ activity.department_name }}
                      </td>
                      <!-- <td :class="rowClass.module">{{activity.module}}</td> -->
                      <td :class="rowClass.contact_name">
                        {{ activity.customer_name }}
                      </td>
                      <td :class="rowClass.contact_name">
                        {{ activity.customer_contact_name }}
                      </td>
                      <td :class="rowClass.vin_no">{{ activity.vin_no }}</td>
                      <td :class="rowClass.department">
                        {{ activity.department }}
                      </td>
                      <td :class="rowClass.location">
                        {{ activity.location }}
                      </td>
                      <td :class="rowClass.type">{{ activity.type }}</td>
                      <td :class="rowClass.priority">
                        {{ activity.priority }}
                      </td>
                      <td :class="rowClass.direction">
                        {{ activity.direction }}
                      </td>
                      <td
                        :class="rowClass.subject"
                        style="width: 160px; word-wrap: break-word"
                      >
                        {{ activity.subject_short }}
                      </td>
                      <td :class="rowClass.status">
                        {{ activity.status }}
                      </td>
                      <td :class="rowClass.assign_user">
                        {{ activity.assign_user }}
                      </td>
                      <td :class="rowClass.activity_time">
                        {{ activity.activity_time }}
                      </td>
                      <td :class="rowClass.updated_by">
                        {{ activity.user_full_name }}
                      </td>
                      <td :class="rowClass.updated_at">
                        {{ activity.updated_at }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <!--module sort text-right-->
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->

            <!-- Vue Context Menu Start -->
            <vue-context
              ref="menu"
              :close-on-click="closeOnClick"
              :close-on-scroll="closeOnScroll"
              :item-selector="itemSelector"
            >
              <li>
                <router-link
                  v-show="
                    permissionNameArray.includes('can_list_activity_master')
                  "
                  class="mar-right-0"
                  title="View Activity"
                  v-bind:to="{
                    name: 'ViewUpdateActivity',
                    params: { id: selectedActivityId },
                    query: { redirectUrl: redirectUrl, tab: activetab },
                  }"
                >
                  <i aria-hidden="true" class="exceptEdit fa fa-eye"></i> View
                  Activity
                </router-link>
                <a
                  v-show="
                    permissionNameArray.includes('can_add_customer_master')
                  "
                  href="javascript:void(0)"
                  title="Click To Add Follow UP Activity"
                  class="mar-right-0"
                  v-on:click="
                    openAddActivityPopup(
                      selectedActivityId,
                      selectedActivitySubject
                    )
                  "
                >
                  <i
                    class="fa fa-plus-square exceptEdit"
                    aria-hidden="true"
                  ></i>
                  Add Followup Activity
                </a>
              </li>
            </vue-context>
            <!-- Vue Context Menu End -->
          </div>
        </div>
        <add-update-activity-popup
          @call-close-add-activity-popup="callCloseAddActivityPopup"
          ref="addUpdateActivityPopupRef"
          openAt="popupView"
        ></add-update-activity-popup>
        <!-- Export Modal -->
        <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="exportModalLabel">
                  Export Activity
                </h4>
              </div>
              <form
                class="form-inline"
                id="exportModalForm"
                enctype="multipart/form-data"
              >
                <common-loader
                  refId="commonLoaderExportActivity"
                  ref="commonLoaderExportActivity"
                >
                </common-loader>
                <div class="col-md-12 modal-body" id="modal-checkbox">
                  <template v-if="exportHeaders && exportHeaders.length > 0">
                    <!-- Select All -->
                    <!-- <button 
                                            type = "button" 
                                            class = "btn btn-primary"
                                            @click="selectAllExportHeaders()"> {{staticInputLabelMessages.select_all_label}} </button> -->
                    <div class="col-12 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          type="checkbox"
                          v-model="isSelectAllExportHeader"
                          @click="selectAllExportHeaders()"
                          class="form-control"
                          id="ExportList"
                        />
                        <strong v-if="isSelectAllExportHeader"
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.deselect_all_label }}
                          </p></strong
                        >
                        <strong v-else
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.select_all_label }}
                          </p></strong
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <template v-for="(exportOpp, exportIndex) in exportHeaders">
                      <div
                        :key="exportIndex"
                        class="col-md-6 col-sm-12 paddingleft0 custom-checkbox-v"
                      >
                        <label class="container">
                          <!-- <input :value="exportIndex" class="form-control" type="checkbox" @click="checkedExportHeaders($event, exportIndex, exportOpp)" /> {{exportOpp['name']}} -->
                          <input
                            :value="exportOpp"
                            class="form-control"
                            type="checkbox"
                            v-model="exportSelectedHeaders"
                            @change="updateCheckedExportHeaders()"
                          />
                          <p class="modal-check-lbl">{{ exportOpp["name"] }}</p>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="row clearfix"></div>
                <div class="col-md-12 modal-footer">
                  <button
                    type="button"
                    v-on:click="exportSelectedHeadersData($event)"
                    class="btn general-button btn-success waves-effect"
                  >
                    Export
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- Side Note View Section -->
        <div class="page-sidebar" v-if="windowWidth > 767">
          <div class="noteDetails">
            <div>
              <div class="forms-block">
                <div class="pull-right ml-2">
                  <a
                    id="cancelNote"
                    title="Close"
                    v-on:click.prevent="cancelComment()"
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </a>
                </div>

                <div class="row clearfix"></div>
                <div class="form-label mt-4">Comment :</div>
                <div class="">
                  <textarea
                    name="activityComment"
                    id="activityComment"
                    v-model="newComment"
                    class="form-control"
                  ></textarea>
                  <!-- <div class="help is-danger">{{}}</div> -->
                </div>
                <div class="button-demo mt-3">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style="float: left"
                    v-on:click.prevent="addComment()"
                  >
                    Save
                  </button>
                  <button
                    type="submit"
                    class="ml-2 btn btn-primary"
                    style="float: left"
                    v-on:click.prevent="cancelComment()"
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div class="mt-3 sidebar-notes">Comment List :</div>
              <template v-if="commentArr && commentArr.length > 0">
                <div class="col-md-12 sidebar-notes-details mt-0">
                  <template v-for="(note, index) in commentArr">
                    <div
                      :key="index"
                      :id="'each-comment-section-' + note['record_id']"
                    >
                      <div class="mb-3">
                        <div class="username-letter text-center">
                          {{ note["imgString"] }}
                        </div>
                        <div
                          class="comment-details"
                          style="display: inline-block; margin-left: 10px"
                        >
                          <p
                            class="mb-0 user-name"
                            style="
                              color: #098de1;
                              font-family: 'SanFranciscoDisplayMedium';
                            "
                          >
                            {{ note["name"] }}
                          </p>
                          <p
                            class="mb-0 comment-date"
                            style="font-size: 14px; color: #8b8b8b"
                          >
                            {{ note["date"] }}
                          </p>

                          <button
                            type="submit"
                            v-if="note.permission_edit"
                            title="Edit Comment"
                            class="btn edit-btn-vvg waves-effect comment-view-section"
                            v-on:click.prevent="enableEditCommentSection(note)"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-pencil"
                              style="font-size: 16px"
                            ></i>
                          </button>
                          <button
                            type="submit"
                            v-if="note.permission_delete"
                            title="Delete Comment"
                            class="btn edit-btn-vvg waves-effect"
                            v-on:click.prevent="deleteComment(note)"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-trash-o"
                              style="font-size: 16px"
                            ></i>
                          </button>
                        </div>
                      </div>
                      <p class="mb-4 comment-view-section">
                        {{ note["comment"] }}
                      </p>

                      <div class="comment-edit-section hide">
                        <textarea
                          class="width-full"
                          v-model="editComment"
                        ></textarea>
                      </div>

                      <input
                        type="submit"
                        value="Update"
                        class="btn btn-primary comment-edit-section hide"
                        v-on:click.prevent="updateComment()"
                      />
                    </div>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="sidebar-notes-details">
                  {{ noCommentDataMessaage }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- Side Note View Section -->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
//import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import datePicker from "vue-bootstrap-datetimepicker";
import Multiselect from "vue-multiselect";
import { VueContext } from "vue-context";
import "vue-context/dist/css/vue-context.css";
import AddUpdateActivityPopup from "../ContactMaster/AddUpdateActivityPopup.vue";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
//Vue.component("v-select", vSelect);

export default {
  name: "ListActivity",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    datePicker,
    Multiselect,
    VueContext,
    "add-update-activity-popup": AddUpdateActivityPopup,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      userId: "",
      staticMessages: "",
      staticInputLabelMessages: "",
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      activityArr: [],
      headers: [],
      filterheaders: [],
      filters: {
        module: "",
        vin_no: "",
        department: "",
        location: "",
        assign_user: "",
        activity_time: "",
        updated_at: "",
      },
      datePickerOptions: {
        range: true,
        onlyDate: true,
        outputFormat: "YYYY-MM-DD",
        inputFormat: "YYYY-MM-DD",
        formatted: "MMM DD, YYYY" /*'YYYY-MM-DD',*/,
        noClearButton: true,
        maxDate: "",
      },
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_activity_master"],
      actionDisplay: true,
      exportActivity: false,
      jumpToHistoryPage: 1,
      filterCount: 0,
      isOpenFilterBlock: false,
      userCompany: [],
      filterCompanyOptions: [],
      filterlocationOptions: [],
      appliedDepartmentFilter: [],
      appliedLocationFilter: [],
      strDepartmentId: "",
      loginUserLocation: [],
      isDepartmentSelected: false,
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      commentArr: [],
      noCommentDataMessaage: "Comment are not available",
      windowWidth: 0,
      showCommentSection: true,
      newComment: "",
      sidebarActivityId: "",
      editComment: "",
      editCommentId: "",
      editId: "",
      /* when set to true, the context  menu will close when clicked on */
      closeOnClick: true,
      /* when set to true, the context  menu will close when the window is scrolled */
      closeOnScroll: true,
      /* This is how the component is able to find each menu item. Useful if you use non-recommended markup */
      itemSelector: [".custom-item-class"],
      selectedActivityId: "",
      selectedActivitySubject: "",
      exportActivityInstance: null,
    };
  },
  beforeMount() {
    self = this;
  },
  props: {
    // permissionNameArray:"",
    customer: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    opportunityMasterId: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    asset: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    inventory: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    redirectUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    activetab: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  watch: {
    /*selectAll: function (selectAll) {
                self.clickHeaderCheckBox();
            },*/
    /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadActivity();
            },
            currentPage: function () {
                self.loadActivity();
            }*/
    userCompany: function () {
      self.loadAllData(
        ["Company"],
        false,
        self.loadCompanyDataCallback,
        "undefined",
        "",
        self.userCompany.join(",")
      );
    },
    "filters.department": function () {
      self.isDepartmentSelected = false;
      if (self.filters.department && self.filters.department.length > 0) {
        self.isDepartmentSelected = true;
      }
      var userObj = localStorage.getItem("user_obj");
      /* if user logged in than remove login button and registration button */
      if (userObj != null) {
        var userJson = JSON.parse(userObj);
        var userRole = userJson["roles"][0].id;
        if (
          userRole > 2 &&
          self.loginUserLocation &&
          self.loginUserLocation.length > 0
        ) {
          self.filterlocationOptions = [];
          self.$nextTick(function () {
            self.filters.location = "";
          });
          self.filterlocationOptions = [];
          var temp = [];
          $.each(self.filters.department, function (i) {
            var selectedCompanyId = self.filters.department[i].value;
            $.each(self.loginUserLocation, function (j) {
              var objLoc = self.loginUserLocation[j];
              if (selectedCompanyId == objLoc.companyId) {
                if (!temp.includes(objLoc.value)) {
                  temp.push(objLoc.value);
                  self.filterlocationOptions.push(objLoc);
                }
              }
            });
          });
          return false;
        }
      }
      self.strDepartmentId = "";
      self.appliedDepartmentFilter = [];
      $.each(self.filters.department, function (i) {
        self.appliedDepartmentFilter.push(self.filters.department[i].value);
        self.strDepartmentId += self.filters.department[i].value + ",";
      });
      self.strDepartmentId = self.strDepartmentId.substring(
        0,
        self.strDepartmentId.length - 1
      );
      if (self.strDepartmentId != "") {
        self.loadAllData(
          ["Location"],
          false,
          self.loadCityDataCallback,
          "undefined",
          "",
          self.strDepartmentId
        );
      }
    },
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    } else {
      var userJson = JSON.parse(userObj);
      self.userId = userJson.id;
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;

    /* Set the left sidebar activity menu active only when it is opened from the left sidebar */
    /* Otherwise it is mounted from the customer tab so there is no need to set the activity menu as active */
    if (this.$route.params.id == undefined){
      self.setActiveMenu("activity-list");
      EventBus.$emit("menu-selection-changed", "activity-list");
    }
    
    var listHistoryData = self.loadFilters("listActivity", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;

    const activeTime = this.$route.query.activeTime;
    if(activeTime){
      if (!self.filters['activity_time']) {
        self.filters['activity_time'] = {};
      }
        self.filters['activity_time']['start']=activeTime;
        self.filters['activity_time']['end']=null;
    }

    self.loadActivity(1);

    self.windowWidth = $(window).width();
    $(window).resize(function () {
      self.windowWidth = $(window).width();
    });
    self.$nextTick(() => {
      $(".page-sidebar").hide();
      $(".app-sidebar-right").removeClass("comment-sidebar");
    });
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.exportActivityInstance = new bootstrap.Modal(
        document.getElementById("exportModal")
      );
    },
    /* Context Menu Logic Start */
    openContextMenu: function (e, id, subject) {
      self.selectedActivityId = id;
      self.selectedActivitySubject = subject;
      self.$refs.menu.open(e);
    },
    callCloseAddActivityPopup: function () {
      //Action after activity added
      self.loadActivity();
    },
    openAddActivityPopup: function (id, subject) {
      //Open Add Activity popup code goes here
      let reqData = {
        id: id,
        name: subject,
        flag: "addFollowUpActivity",
      };
      this.$refs.addUpdateActivityPopupRef.openAddActivityPopup(reqData);
    },
    updateComment() {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      let data = {
        comment: self.editComment,
        record_for: "Activity",
        record_id: self.editId,
      };

      HTTP.post("/comment/update/" + self.editCommentId, data, config)
        .then(function (response) {
          //re-load comments
          self.fetchComments();

          if (response.data.status == "success") {
            self.newComment = "";

            let sectionId = "#each-comment-section-" + self.editCommentId;

            $(sectionId + " .comment-view-section").removeClass("hide");
            $(sectionId + " .comment-edit-section").addClass("hide");
            self.editComment = "";
            self.editCommentId = "";

            self.showSAlert(response.data.message, "success", false, 3);
          } else if (response.data.code == 201) {
            if (
              response.data.content &&
              typeof response.data.content.comment != "undefined"
            ) {
              self.showSAlert("Comment is missing.", "error", false, 3);
            } else {
              self.showSAlert("Exception occurred", "error", false, 3);
            }
          }
        })
        .catch(function (err) {
          self.catchHandler(err, function () {});
        });
    },
    enableEditCommentSection(commentData) {
      //Edit comment section
      self.editComment = commentData.comment;
      self.editCommentId = commentData.record_id;
      self.editId = commentData.activity_id;
      let sectionId = "#each-comment-section-" + self.editCommentId;

      $(sectionId + " .comment-view-section").addClass("hide");
      $(sectionId + " .comment-edit-section").removeClass("hide");
    },
    deleteComment(commentData) {
      //Delete comment Request
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Comment!",
          type: "info",
          confirmButtonText: "Yes",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              params: {
                comment_id: commentData.record_id,
              },
            };

            HTTP.delete("/comment/delete/" + commentData.record_id, config)
              .then(function (response) {
                //re-load comments
                self.fetchComments();
                if (response.data.status == "success") {
                  self.showSAlert(response.data.message, "success", false, 3);
                } else if (response.data.code == 201) {
                  if (
                    response.data.content &&
                    typeof response.data.content.comment != "undefined"
                  ) {
                    self.showSAlert("Comment is missing.", "error", false, 3);
                  } else {
                    self.showSAlert("Exception occurred", "error", false, 3);
                  }
                }
              })
              .catch(function (err) {
                if (err.response.status == 0) {
                  self.error = "Remote server can not be reachable";
                } else {
                  //redirect to login page if user not authenticated
                  if (err.response.status == 401) {
                    //clear previous data
                    localStorage.removeItem("user_obj");
                    localStorage.removeItem("api_token");
                    localStorage.removeItem("profile_image");
                    self.$router.push("/login");
                    //EventBus.$emit("logged-in", true, "", "");
                  }
                }
              });
          }
        });
    },
    addComment() {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      let data = {
        comment: self.newComment,
        record_for: "Activity",
        record_id: self.sidebarActivityId,
      };

      HTTP.post("/comment/new", data, config)
        .then(function (response) {
          //re-load comments
          self.fetchComments();

          if (response.data.status == "success") {
            self.newComment = "";
            self.showSAlert(response.data.message, "success", false, 3);
          } else if (response.data.code == 201) {
            if (
              response.data.content &&
              typeof response.data.content.comment != "undefined"
            ) {
              self.showSAlert("Comment is missing.", "error", false, 3);
            } else {
              self.showSAlert("Exception occurred", "error", false, 3);
            }
          }
        })
        .catch(function (err) {
          self.catchHandler(err, function () {});
        });
    },
    fetchComments() {
      if (self.sidebarActivityId) {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
          params: {
            record_for: "Activity",
            record_id: self.sidebarActivityId,
          },
        };

        HTTP.get("/comments", config)
          .then(function (response) {
            if (response.data.status == "success") {
              self.commentArr = [];
              self.commentArr = response.data.content.comments;
            } else if (response.data.code == 201) {
            }
          })
          .catch(function (err) {
            self.catchHandler(err, function () {});
          });
      }
    },
    cancelComment() {
      self.commentSectionShowHide(false, "");
    },
    commentSectionShowHide: function (showHideCommentSection, activityId, e) {
      self.showCommentSection = showHideCommentSection;
      if (self.showCommentSection && activityId) {
        $(".page-sidebar").show();
        $(".app-sidebar-right").addClass("comment-sidebar");
        self.sidebarActivityId = activityId;
        self.fetchComments();
      } else {
        self.sidebarActivityId = "";
        self.commentArr = [];
        $(".page-sidebar").hide();
        $(".app-sidebar-right").removeClass("comment-sidebar");
      }
    },
    toggleFilterBlock: function () {
      $(window).scrollTop($(window).scrollTop() + 1);
      self.isOpenFilterBlock = !self.isOpenFilterBlock;
    },
    resetFilters: function (flag, date) {
      if (flag == "date") {
        if (date == "updated_at") {
          self.filters.updated_at = "";
        } else if (date == "activity_time") {
          self.filters.activity_time = "";
        }
        self.$nextTick(() => {
          self.pageLoaded = true;
          self.changepage();
        });
      } else {
        self.filters.module = "";
        self.filters.vin_no = "";
        self.filters.department = "";
        self.filters.location = "";
        self.filters.assign_user = "";
        self.filters.activity_time = "";
        self.filters.updated_at = "";
      }
      self.loadActivity(1);
    },
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadActivity();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadActivity();
      }
    },
    selectAllExportHeaders: function () {
      self.exportSelectedHeaders = [];
      if (!self.isSelectAllExportHeader) {
        for (let key in self.exportHeaders) {
          self.exportSelectedHeaders.push(self.exportHeaders[key]);
        }
      }
      /* console.log(JSON.stringify(self.exportSelectedHeaders)); */
    },
    updateCheckedExportHeaders: function () {
      /* console.log("Selected export header : " + self.exportSelectedHeaders.length);
                console.log("Total export header : " + self.exportHeaders.length); */
      if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
        self.isSelectAllExportHeader = true;
      } else {
        self.isSelectAllExportHeader = false;
      }
    },
    /* checkedExportHeaders: function (e, index, value) {
                if (e.target.checked) {
                    // Vue.set(self.exportSelectedHeaders, index, value);
                    self.exportSelectedHeaders.push(Vue.util.extend({}, value));
                } else {
                    // Vue.delete(self.exportSelectedHeaders, index);
                    for (var i = 0; i < self.exportSelectedHeaders.length; i++) {
                        if (self.exportSelectedHeaders[i]['name'] == value['name']) {
                            self.exportSelectedHeaders.splice(i, 1);
                        }
                    }
                }
                // console.log(JSON.stringify(self.exportSelectedHeaders));
            }, */
    exportActivityFuntion: function () {
      self.exportActivityInstance.show();
      /* self.exportActivity = true;
                if (self.exportActivity == true) {
                    $('#exportActivityId').prop("disabled", true);
                    self.loadActivity();
                } */
    },
    exportSelectedHeadersData: function () {
      // if(Object.keys(self.exportSelectedHeaders).length == 0) {
      if (self.exportSelectedHeaders.length == 0) {
        self.showSAlert("Please Select at least one checkbox", "info", false);
        return false;
      }
      /* $('#modal-checkbox input:checkbox').prop('checked', false); */
      self.exportActivity = true;
      if (self.exportActivity == true) {
        $("#exportActivityId").prop("disabled", true);
        self.loadActivity();
        /* self.isSelectAllExportHeader = false; */
      }
      if (
        self.exportSelectedHeaders != null &&
        self.exportSelectedHeaders != "undefined" &&
        self.exportSelectedHeaders.length > 0
      ) {
        self.storeExportHeaders("exportActivity", self.exportSelectedHeaders);
      } else {
        self.exportSelectedHeaders = [];
      }
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadActivity();
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.activityArr) {
            self.selectedRows.push(self.activityArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.activityArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadActivity();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    changeLocationFilter: function () {
      /* self.appliedLocationFilter = [];
                for (let i = 0; i < self.filters.location.length; i++) {
                    self.appliedLocationFilter.push(self.filters.location[i].value);
                } */
    },
    clearLocationFilter: function () {
      self.filters.location = "";
      self.appliedLocationFilter = [];
    },
    clearDepartmentFilter: function () {
      self.filters.department = "";
      self.appliedDepartmentFilter = [];
    },
    changeDepartmentFilter: function () {
      /* self.appliedDepartmentFilter = [];
                for (let i = 0; i < self.filters.department.length; i++) {
                    self.appliedDepartmentFilter.push(self.filters.department[i].value);
                } */
    },
    loadCityDataCallback: function (callbackData) {
      self.filterlocationOptions = callbackData.Location;
    },
    loadCompanyDataCallback: function (callbackData) {
      self.filterCompanyOptions = callbackData.Company;
    },
    loadActivity: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadActivity();
      }
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: self.exportActivity,
        export_selected_headers: self.exportSelectedHeaders,
        asset_id: typeof self.asset === "undefined" ? 0 : self.asset,
        customer_id: typeof self.customer === "undefined" ? 0 : self.customer,
        opportunity_master_id:
          typeof self.opportunityMasterId === "undefined"
            ? 0
            : self.opportunityMasterId,
        browser_timezone: localStorage.getItem("browserTimeZone"),
      };
      this.storeFilters("listActivity", fields, false);
      if (self.exportActivity) {
        $("#commonLoaderExportActivity")
          .find(".loading-container.lodingTable")
          .show();  
      } else {
        $("#commonLoaderListActivity")
          .find(".loading-container.lodingTable")
          .show();
      }  
      self.filterCount = self.filterCountFunction(self.filters);
      if (self.filterCount) {
        self.isOpenFilterBlock = true;
      }
      HTTP.post("/activity/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          if (self.exportActivity) {
            $("#commonLoaderExportActivity")
              .find(".loading-container.lodingTable")
              .hide();  
          } else {
            $("#commonLoaderListActivity")
              .find(".loading-container.lodingTable")
              .hide();
          }  
          /* self.exportSelectedHeaders = []; */
          self.exportSelectedHeaders = self.loadExportHeaders(
            "exportActivity",
            self
          );
          self.exportSelectedHeaders =
            self.exportSelectedHeaders === null ||
            typeof self.exportSelectedHeaders === "undefined" ||
            self.exportSelectedHeaders == ""
              ? []
              : self.exportSelectedHeaders;
          $("#exportActivityId").prop("disabled", false);
          self.exportActivity = false;
          self.exportActivityInstance.hide();
          if (data.status == 200) {
            if (data.data.message == "success export") {
              self.downloadFile(data.data.content.web);
              // location.href = data.data.content.web;
            } else {
              self.clearSelection();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              if (
                self.perPage == "All" &&
                self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
              ) {
                // alert(self.totalRecords);
                let seconds = 5000;
                self.$swal({
                  type: "error",
                  html: self.allStaticMessages
                    .too_many_data_to_display_browser_can_not_load,
                  showConfirmButton: false,
                  timer: seconds,
                });
                setTimeout(() => {
                  self.perPage = 500;
                  self.loadActivity();
                }, seconds);
                /* self.$swal({
                                        title: '',
                                        html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                        type: 'error',
                                        confirmButtonText: 'OK',
                                        showCancelButton: true,
                                        showLoaderOnConfirm: true
                                    }).then((result) => {
                                        if (result.value) {
                                            self.logout();
                                        } else {
                                            self.perPage = 15;
                                            self.loadActivity();
                                        }
                                    }); */
              }
              self.activityArr = data.data.content.data;
              $("#selectAllCheckBox").prop("checked", false);
              self.headers = data.data.content.headers;
              self.filterheaders = data.data.content.filterheaders;
              self.exportHeaders = data.data.content.exportheaders;
              if (
                self.exportSelectedHeaders.length == self.exportHeaders.length
              ) {
                self.isSelectAllExportHeader = true;
              }
              self.loadFilterInputs(data.data.content.filterheaders, self.filters);
              self.loginUserLocation = data.data.content.logged_user_location;
              self.addColumnClass(self.headers);

              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#exportActivityId").prop("disabled", false);
          self.exportActivity = false;
          if (self.exportActivity) {
            $("#commonLoaderExportActivity")
              .find(".loading-container.lodingTable")
              .hide();  
          } else {
            $("#commonLoaderListActivity")
              .find(".loading-container.lodingTable")
              .hide();
          }
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadActivity();
      }
    },
    deleteActivity: function (id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Activity!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                deleteIdArr: deleteArray,
              },
            };
            HTTP.delete("/activity/delete", config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.loadActivity();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (!self.permissionNameArray.includes("can_add_activity_master")) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
EventBus.$on("login-company", function (companies) {
  if (typeof self != "undefined") {
    self.userCompany = [];
    for (let i = 0; i < companies.length; i++) {
      self.userCompany.push(companies[i].company);
      // console.log(JSON.stringify(companies[i]));
    }
    if (self.userCompany && self.userCompany.length == 1) {
      setTimeout(function () {
        self.isDepartmentSelected = true;
        self.filters.department = [{ value: self.userCompany[0], text: "" }];
      }, 300);
    }
  }
});

EventBus.$on("menu-selection-changed", function (menuref) {
  $(".page-sidebar").hide();
  $(".app-sidebar-right").removeClass("comment-sidebar");
});
</script>
<style scoped>
.right-sidebar .page-header-top-container,
.right-sidebar .admin-page-content {
  padding-right: 340px;
}
.right-sidebar .location-listing .admin-page-content.sticky {
  right: 340px;
}
.noteDetails {
  float: left;
  width: 100%;
  overflow-x: auto;
  height: 90%;
  padding-right: 20px;
}

.page-sidebar {
  float: right;
  width: 315px;
  padding: 30px 20px 30px 30px;
  position: fixed;
  right: 0;
  background: #fff;
  /*box-shadow: 1px -10px 20px rgba(0, 0, 0, 0.8);*/
  height: 100%;
  z-index: 100;
  top: 60px;
}
.admin-page-content {
  float: left;
  width: 100%;
  padding: 0 25px 50px 25px;
}

.admin-page-content.sidebar-right {
  width: 70%;
}

.sidebar-notes {
  float: left;
  width: 100%;
  /* color: #098de1; */
  font-size: 20px;
  line-height: normal;

  background: #32a3b0;
  padding: 8px 12px;
  border-radius: 5px;
  color: #fff;
}

.sidebar-notes-details {
  float: left;
  width: 100%;
  font-size: 15px;
  color: #7a7a7a;
  margin: 15px 0 20px 0;
  padding-top: 15px;
}

.zeroWidth {
  width: 0px !important;
  padding: 65px 0px 0px 0px !important;
}
</style>
