<template>
  <div
    class="modal fade"
    id="vehiclePurchaseItemModal"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content add-itm-modal-content veh-pur-item">
        <div class="modal-header add-itm-modal-row">
          <div id="add-itm-modal-header-title">
            Vehicle Purchase Item
          </div>
          <div>
            <button id="add-itm-close-btn" @click="closeModal()">Close</button>
            <button id="add-itm-save-btn" v-if="canUpdateDealDetails && isPushedPO==false" @click="saveItem()">
              {{ !isEditItem ? "Save" : "Update" }}
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-row">
            <div class="modal-column">
              <div class="fm-group">
                <label for="itemType">Item Type <span class="po-item-required" title="REQUIRED FIELD">*</span></label>
                <v-select
                  v-bind="moneyMask"
                  v-model="item"
                  name="item"
                  label="itm_typ_name"
                  :filterable="false"
                  :options="itemList"
                  :clearable="true"
                  class="custom-ci-auto-search vhc-select"
                  @input="itemError=''"
                />
                <span class="po-item-required-alert" v-if="itemError !== ''">{{ itemError }}</span>
              </div>
            </div>
          </div>

          <div class="modal-row">
            <div class="modal-column">
              <div class="fm-group">
                <label for="description">Description <span class="description-required" title="REQUIRED FIELD">*</span></label>
                <textarea
                  id="item-description"
                  v-model="description"
                  @input="descriptionError=''"
                >
              </textarea>
              <span class="description-required-alert" v-if="descriptionError !== ''">{{ descriptionError }}</span>
              </div>
            </div>
          </div>

          <div class="modal-row">
            <div class="modal-column">
              <div>
                <div class="fm-group">
                  <label for="costPrice">Cost Price</label>
                  <money
                    v-bind="moneyMask"
                    v-model="costPrice"
                    type="text"
                    class="modal-input"
                    @keyup.native="
                      (event) => {
                        incrementDecrementNumber(event, 'costPrice');
                      }
                    "
                  />
                </div>
                <div class="fm-group">
                  <label for="salesPrice">Sales Price</label>
                  <money
                    v-bind="moneyMask"
                    v-model="salesPrice"
                    type="text"
                    class="modal-input"
                    @keyup.native="
                      (event) => {
                        incrementDecrementNumber(event, 'salesPrice');
                      }
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-row">
            <div class="modal-column">
              <div>
                <input type="checkbox" v-model="addToTotalPrice" />
                <span>Add to Total Price</span>
              </div>
              <div>
                <input type="checkbox" v-model="showOnDocuments" />
                <span>Show On Documents</span>
              </div>
              <div>
                <input type="checkbox" v-model="fetExempt" />
                <span>FET Exempt</span>
              </div>
              <div>
                <input type="checkbox" v-model="doNotAddToGp" />
                <span>Do Not Add To GP</span>
              </div>
            </div>
          </div>
        </div>
        <common-loader refId="commonLoaderPOItem" ref="commonLoaderPOItem" />
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../../event-bus";
import { Money } from "v-money";
import { HTTP } from "../../../../../http-common";
import CommonLoader from "@/components/partials/CommonLoader.vue";

export default {
  name: "VehiclePurchaseItem",
  components: {
    Money,
    CommonLoader,
  },
  props: ["isAddItem", "editPOItem","parentIds", "canUpdateDealDetails", "isPushedPO"],
  data() {
    return {
      item: "",
      itemList: [],
      costPrice: 0.0,
      salesPrice: 0.0,
      addToTotalPrice: true,
      showOnDocuments: true,
      fetExempt: false,
      doNotAddToGp: false,
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        precision: 2,
      },
      description: "",
      isEditItem: false,
      itemError: "",
      descriptionError: "",
    };
  },
  methods: {
    closeModal() {
      this.clearData();
      eventBus.$emit("close-purchase-item", false);
    },
    incrementDecrementNumber(event, dataInstance) {
      switch (event.key) {
        case "ArrowUp":
          this[dataInstance] += 1;
          break;
        case "ArrowDown":
          this[dataInstance] -= 1;
          break;
      }
    },
    saveItem() {
      if(this.isValidForm()) {
        var data = {
          itm_typ_id: this.item.itm_typ_id,
          po_item_type: {itm_typ_name: this.item.itm_typ_name},
          itm_typ_name: this.item.itm_typ_name,
          description: this.description,
          cost: this.costPrice,
          price: this.salesPrice,
          is_add_to_total_price: this.addToTotalPrice,
          is_show_on_documents: this.showOnDocuments,
          is_fet_exempt: this.fetExempt,
          is_do_not_add_to_gp: this.doNotAddToGp,
          parent_ids: this.parentIds,
        };
        var isUpdate = false;
        $("#commonLoaderPOItem").find(".loading-container.lodingTable").show();
        if (this.isEditItem && Object.keys(this.editPOItem).includes('id')) {
          const id = this.editPOItem.id;
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token"),
            },
          };
          HTTP.put("purchase-order-item/" + id, data, config)
            .then((response) => {
              data = response.data.content;
              isUpdate = true;
              eventBus.$emit("save-purchase-item", data, isUpdate, false);
              $("#commonLoaderPOItem").find(".loading-container.lodingTable").hide();
            })
            .catch((err) => {
              $("#commonLoaderPOItem").find(".loading-container.lodingTable").hide();
              this.catchHandler(err, function () {});
            });
        } else if(this.isEditItem) {
          isUpdate = true;
          eventBus.$emit("save-purchase-item", data, isUpdate, true);
          $("#commonLoaderPOItem").find(".loading-container.lodingTable").hide();
        } else if(this.parentIds !== null) {
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token"),
            },
          };
          HTTP.post("purchase-order-item", data, config)
            .then((response) => {
              data = response.data.content;
              isUpdate = false;
              eventBus.$emit("save-purchase-item", data, isUpdate, false);
              $("#commonLoaderPOItem").find(".loading-container.lodingTable").hide();
            })
            .catch((err) => {
              $("#commonLoaderPOItem").find(".loading-container.lodingTable").hide();
              this.catchHandler(err, function () {});
            });
        } else {
          eventBus.$emit("save-purchase-item", data, isUpdate, true);
          $("#commonLoaderPOItem").find(".loading-container.lodingTable").hide();
        }

        this.clearData();
      }
    },
    clearData() {
      this.item = "";
      this.costPrice = 0.0;
      this.salesPrice = 0.0;
      this.addToTotalPrice = true;
      this.showOnDocuments = true;
      this.fetExempt = false;
      this.doNotAddToGp = false;
      this.description = "";
      this.isEditItem= false;
      this.itemError = "";
      this.descriptionError = "";
      return true;
    },
    getEditItemData() {
      const item = this.itemList.filter(
        (itm) => itm.itm_typ_id == this.editPOItem.itm_typ_id
      );
      this.item = item[0];
      this.costPrice = this.editPOItem.cost;
      this.salesPrice = this.editPOItem.price;
      this.addToTotalPrice = this.editPOItem.is_add_to_total_price;
      this.showOnDocuments = this.editPOItem.is_show_on_documents;
      this.fetExempt = this.editPOItem.is_fet_exempt;
      this.doNotAddToGp = this.editPOItem.is_do_not_add_to_gp;
      this.description = this.editPOItem.description;
    },

    isValidForm() {
      var valid = true;
      if (this.item == "" || this.item == null || this.item == undefined) {
          this.itemError = "This field is required!";
          if (valid) {
              valid = false;
          }
      } else {
          this.itemError = '';
      }
      
      if (this.description == "" || this.item == null || this.item == undefined) {
        this.descriptionError = "Description is required!";
          if(valid) {
            valid = false;
          }
      } else {
        this.descriptionError = '';
      }

      return valid;
    }
  },
  computed: {
    getItems() {
      $("#commonLoaderPOItem").find(".loading-container.lodingTable").show();
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };

      HTTP.get("/get/vehicle/purchase/items", config).then((response) => {
        this.itemList = response.data.content;
        $("#commonLoaderPOItem").find(".loading-container.lodingTable").hide();
      });
    },
  },
  watch: {
    isAddItem(status) {
      if (status) {
        this.clearData();
        this.getItems;
        if (Object.keys(this.editPOItem).length > 0) {
          this.isEditItem = true;
          setTimeout(() => {
            this.getEditItemData();
          }, 1000);
        } else {
          this.item = this.itemList.find(item => item.itm_typ_name === "Expense") || null;
        }
      }
    },
    itemList: {
      handler(itemList) {
        if (!this.item) {
          this.item = itemList.find(item => item.itm_typ_name === "Expense") || null;
        }
      },
    immediate: true,
  }
  },
};
</script>

<style scoped>
.add-itm-modal-content {
  padding: 0 10px;
}

.add-itm-modal-row > div:first-child {
  display: flex;
  align-items: center;
}

#add-itm-modal-header-title {
  color: #506b71;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.add-itm-modal-row > div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

#add-itm-close-btn {
  width: 80px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #dbe0e7;
}

#add-itm-save-btn {
  width: 80px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  border: unset;
  background: #076eb0;

  /* text */
  color: #fff;
  text-align: center;
  font-family: SF UI Display, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
}
.modal-row {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 12px;
}

.modal-column {
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-column-gap: 10px;
}

.fm-group {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
}

.modal-input {
  height: 32px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #cecece;
  text-align: right;
}

.modal-row:nth-child(3) > .modal-column > div {
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-column-gap: 10px;
}

.modal-row:last-child > .modal-column {
  grid-template-columns: 4fr 4fr 4fr 4fr;
  grid-column-gap: 20px;
}

#item-description {
  height: 59px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #cecece;
}

.po-item-required {
  color: #ff0000;
  font-weight: bolder;
}

.po-item-required-alert {
  color: #ff0000;
  font-weight: lighter;
}

.description-required {
  color: #ff0000;
  font-weight: bolder;
}

.description-required-alert {
  color: #ff0000;
  font-weight: lighter;
}

/* .veh-pur-item .v-money {
  text-align: right;
} */
</style>
