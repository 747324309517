<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div :id="openAt == 'pageView' ? 'page-content-wrapper' : ''" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_finance_company_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div>

                            <!--Address-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.address_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="addressError = ''" id="address" v-model="address" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{addressError}}</span>
                                </div>
                            </div>

                            <!-- State -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.state_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            v-model="state" 
                                            name="state"
                                            label="text" 
                                            :filterable="false" 
                                            :options="stateArr"
                                            @search="onSearchState" 
                                            :clearable="false" 
                                            @input="stateError = ''"
                                            :placeholder="staticMessages.state_place_holder" 
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isStateRecordNotFound">
                                                {{staticMessages.state_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{stateError}}</span>
                                </div>
                            </div>

                            <!-- City -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="city" name="city"
                                              label="text" :filterable="false" :options="cityArr"
                                              @input="cityError = ''"
                                              @search="onSearchCity"
                                              :placeholder="staticMessages.city_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCityRecordNotFound">
                                                {{staticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cityError}}</span>
                                </div>
                            </div>

                            <!--Zipcode -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.zip_code_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="zipcodeError = ''" id="zipcode" v-model="zipcode" class="form-control" type="text" maxlength="7"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{zipcodeError}}</span>
                                </div>
                            </div>

                            <!-- Comment -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.comment_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="commentError = ''" id="comment" v-model="comment" class="form-control"></textarea>
                                    <span class="help is-danger">{{commentError}}</span>
                                </div>
                            </div>

                            <!--Send doc to-->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.send_docs_to_diff_address_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="sendDocTo" v-model="sendDocTo" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div> -->

                            <!--Address2-->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.addr2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="address2" v-model="address2" class="form-control" type="text"/>
                                </div>
                            </div> -->

                            <!-- State2 -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.state2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            v-model="state2" 
                                            name="state2"
                                            label="text" 
                                            :filterable="false" 
                                            :options="state2Arr"
                                            @search="onSearchState2" 
                                            :clearable="false" 
                                            :placeholder="staticMessages.state_place_holder" 
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isState2RecordNotFound">
                                                {{staticMessages.state_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div> -->

                            <!-- City2 -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.city2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            v-model="city2" 
                                            name="city2"
                                            label="text" 
                                            :filterable="false" 
                                            :options="city2Arr"
                                            @search="onSearchCity2"
                                            :placeholder="staticMessages.city_place_holder" 
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCity2RecordNotFound">
                                                {{staticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div> -->

                            <!--Zipcode2 -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.zipcode2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="zipcode2" v-model="zipcode2" class="form-control" type="text"/>
                                </div>
                            </div> -->

                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addFinanceGroupBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addFinanceGroup ()">
                            <input v-if="openAt == 'pageView'" v-show="showSaveAndContinue" id="addAndContinueFinanceGroupBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addFinanceGroup (flag='Save and Continue')">
                            <input id="cancelFinanceGroupBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>

                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
        <!-- </div> -->
        <!-- Page Content Wrapper -->
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from "../event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    Vue.component("app-footer", Footer);
    Vue.component("no-access", NoAccessPage);

    export default {
        name: 'AddUpdateFinanceGroup',
        mixins: [commonMixins],
        props: {
            openAt: {
                type: [String, Number],
                required: false,
                default: 'pageView'
            },
        },
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",

                isEdit: false,
                
                name : "",
                nameError : "",
                address : "",
                addressError : "",
                state : "",
                stateArr : [],
                stateError : "",
                isStateRecordNotFound : false,
                city : "",
                cityArr : [],
                cityError : "",
                isCityRecordNotFound : false,
                zipcode : "",
                zipcodeError : "",
                comment : "",
                commentError : "",
                /* sendDocTo : "",
                address2 : "",
                state2 : "",
                state2Arr : [],
                isState2RecordNotFound : false,
                city2 : "",
                city2Arr : [],
                isCity2RecordNotFound : false,
                zipcode2 : "", */

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Finance Company",
                permissionNameArray : ['can_add_finance_company_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('financecompany-list');
            EventBus.$emit("menu-selection-changed", "financecompany-list");
            self.getLoginIdPermissions();
            $("#alertError").hide();
            self.isEdit = false;
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.isEdit = true;
                self.getFinanceGroupDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
                self.pageTitle = "Edit Finance Company";
            }
        },
        methods: {
            buttonBack() {
                if(self.openAt == 'popupView') {
                    self.clearDataOnSaveAndContinue();
                    self.disableButtons.save = false;
                    self.disableButtons.cancel = false;
                    EventBus.$emit("close-add-financial-company", false);
                } else {
                    self.$router.push({ name: 'ListFinanceGroup'});
                }
            },
            onSearchState(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search);
                }
            },
            loadStateDataCallback: function (callbackData) {
                self.isStateRecordNotFound = false;
                self.stateArr = callbackData.States;
                if(self.stateArr.length == 0) {
                    self.isStateRecordNotFound = true;
                }
            },
            onSearchCity(search, loading) {
                if (self.state == null || !self.state.value) {
                    self.showSAlert('Please Select State First!', 'info', false);
                } else {
                    if (search.length > 2) {
                        loading(true);
                        self.loadAllData(['Cities'], true, self.loadCityDataCallback, loading, search, self.state.value);
                    }
                }
            },
            loadCityDataCallback: function (callbackData) {
                self.isCityRecordNotFound = false;
                self.cityArr = callbackData.Cities;
                if(self.cityArr.length == 0) {
                    self.isCityRecordNotFound = true;
                }
            },
            /* onSearchState2(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadState2DataCallback, loading, search);
                }
            },
            loadState2DataCallback: function (callbackData) {
                self.isState2RecordNotFound = false;
                self.state2Arr = callbackData.States;
                if(self.state2Arr.length == 0) {
                    self.isState2RecordNotFound = true;
                }
            },
            onSearchCity2(search, loading) {
                if (self.state == null || !self.state.value) {
                    self.showSAlert('Please Select State2 First!', 'info', false);
                } else {
                    if (search.length > 2) {
                        loading(true);
                        self.loadAllData(['Cities'], true, self.loadCity2DataCallback, loading, search, self.state2.value);
                    }
                }
            },
            loadCity2DataCallback: function (callbackData) {
                self.isCity2RecordNotFound = false;
                self.city2Arr = callbackData.Cities;
                if(self.city2Arr.length == 0) {
                    self.isCity2RecordNotFound = true;
                }
            }, */
            clearDataOnSaveAndContinue : function () {
                self.name = '';
                self.address = '';
                self.state = '';
                self.stateArr = [];
                self.isStateRecordNotFound = false;
                self.city = '';
                self.cityArr = [];
                self.isCityRecordNotFound = false;
                self.zipcode = '';
                self.comment = '';
                /* self.sendDocTo = '';
                self.address2 = '';
                self.state2 = '';
                self.state2Arr = [];
                self.isState2RecordNotFound = false;
                self.city2 = '';
                self.city2Arr = [];
                self.isCity2RecordNotFound = false;
                self.zipcode2 = ''; */
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getFinanceGroupDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };
                HTTP.get('/financegroup/get', config)
                    .then(response => {
                        console.log(response.data.content);
                        self.name = response.data.content.name;
                        self.address = response.data.content.address; 
                        if(response.data.content.state_id != null && response.data.content.state_id != '') {
                            self.state = { value : response.data.content.state_id,text :  response.data.content.state_name };
                        }
                        if(response.data.content.city_id != null && response.data.content.city_id != '') {
                            self.city = { value : response.data.content.city_id, text :  response.data.content.city_name };
                        }
                        self.zipcode = response.data.content.zip_code; 
                        self.comment = response.data.content.comment; 

                       /*  self.sendDocTo = response.data.content.send_doc_to_other;
                        self.address2 = response.data.content.address2; 
                        if(response.data.content.state_id2 != null && response.data.content.state_id2 != '') {
                            self.state2 = { value : response.data.content.state_id2,text :  response.data.content.state_name2 };
                        }
                        if(response.data.content.city_id2 != null && response.data.content.city_id2 != '') {
                            self.city2 = { value : response.data.content.city_id2, text :  response.data.content.city_name2 };
                        }
                        self.zipcode2 = response.data.content.zip_code2; */ 
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;

                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.address) {
                    self.addressError = "Please enter address";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.state == null || !self.state.value) {
                    self.stateError = "Please select state";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.city == null || !self.city.value) {
                    self.cityError = "Please select city";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.zipcode) {
                    self.zipcodeError = "Please enter zipcode";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.comment != undefined && self.comment.length > 160) {
                    self.commentError = 'Comment exceeds the maximum limit of 160 characters.';
                    if (checked) {
                        checked = false;
                    }
                }
                
                return checked;
            },

            addFinanceGroup (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        var state = "";
                        if (self.state == null || !self.state.value) {
                            state = "";
                        }else{
                            state = self.state.value;
                        }

                        var city = "";
                        if (self.city == null || !self.city.value) {
                            city = "";
                        }else{
                            city = self.city.value;
                        }

                        /* var state2 = "";
                        if (self.state2 == null || !self.state2.value) {
                            state2 = "";
                        }else{
                            state2 = self.state2.value;
                        }

                        var city2 = "";
                        if (self.city2 == null || !self.city2.value) {
                            city2 = "";
                        }else{
                            city2 = self.city2.value;
                        } */
                        data.append('name',self.name);
                        data.append('address',self.address);
                        data.append('state',state);
                        data.append('city',city);
                        data.append('zip_code',self.zipcode);
                        data.append('comment',self.comment);
                        /* data.append('send_doc_to',self.sendDocTo);
                        data.append('address2',self.address2);
                        data.append('state2',state2);
                        data.append('city2',city2);
                        data.append('zip_code2',self.zipcode2); */
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('company_code', self.companyCode);
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/financegroup/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if(self.openAt=="popupView") {
                                        const data ={
                                            value: response.data.content.id,
                                            text: response.data.content.name,
                                            address: response.data.content.address,
                                            city_id: response.data.content.city_id,
                                            state_id: response.data.content.state_id,
                                            zip_code: response.data.content.zip_code,
                                            comment: response.data.content.comment,
                                        }
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        EventBus.$emit("finance-company-added-data", data);
                                        return true;
                                    }
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>