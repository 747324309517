import { render, staticRenderFns } from "./ListSaleType.vue?vue&type=template&id=2901bf28&"
import script from "./ListSaleType.vue?vue&type=script&lang=js&"
export * from "./ListSaleType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports