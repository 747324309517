<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_company_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div>
                            <!-- Full Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.full_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="fullname" v-model="fullname" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>
                            <!-- Country -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.country_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                        v-model="countryId"
                                        @change="changeCountry"
                                        @input="countryError = ''"
                                        name="countryId"
                                        id="countryId"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="countryArr"
                                        :placeholder="staticMessages.country_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCountryRecordNotFound">
                                                {{staticMessages.country_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{countryError}}</span>
                                </div>
                            </div>
                            <!-- State -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.state_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="state" name="state"
                                              label="text" :filterable="false" :options="stateArr"
                                              @search="onSearchState" :clearable="false" @input="stateError = ''"
                                              :placeholder="staticMessages.state_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isStateRecordNotFound">
                                                {{staticMessages.state_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{stateError}}</span>
                                </div>
                            </div> -->
                            <!-- City -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="city" name="city"
                                              label="text" :filterable="false" :options="cityArr"
                                              @search="onSearchCity" :clearable="false" @input="cityError = ''"
                                              :placeholder="staticMessages.city_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCityRecordNotFound">
                                                {{staticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cityError}}</span>
                                </div>
                            </div> -->
                            <!-- zip code -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.zip_code_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="zipCodeError = ''" id="zipCode" v-model="zipCode" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{zipCodeError}}</span>
                                </div>
                            </div> -->
                            <!-- Invoice Note -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.invoice_note_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="invoiceNoteError = ''" id="invoiceNote" v-model="invoiceNote" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{invoiceNoteError}}</span>
                                </div>
                            </div>
                            <!-- invoice_cc -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.invoice_cc_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="invoiceCCError = ''" id="invoiceCC" v-model="invoiceCC" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{invoiceCCError}}</span>
                                </div>
                            </div>
                            <!-- expire_stale_opportunities_day -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.expire_stale_oppor_day_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="expireStaleOpporDayError = ''" id="expireStaleOpporDay" v-model="expireStaleOpporDay" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{expireStaleOpporDayError}}</span>
                                </div>
                            </div> -->
                            <!-- expire_opportunities_notify_email -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.expire_stale_oppor_notify_email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="expireStaleOpporNotifyEmailError = ''" id="expireStaleOpporNotifyEmail" v-model="expireStaleOpporNotifyEmail" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{expireStaleOpporNotifyEmailError}}</span>
                                </div>
                            </div> -->
                            <!-- interest_rate -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.interest_rate_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="interestRateError = ''" id="interestRate" v-model="interestRate" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{interestRateError}}</span>
                                </div>
                            </div> -->
                            <!-- retail_parts_markup -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.retail_parts_markup_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="retailPartsMarkupError = ''" id="retailPartsMarkup" v-model="retailPartsMarkup" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{retailPartsMarkupError}}</span>
                                </div>
                            </div> -->
                            <!-- shop_parts_markup -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.shop_parts_markup_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="shopPartsMarkupError = ''" id="shopPartsMarkup" v-model="shopPartsMarkup" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{shopPartsMarkupError}}</span>
                                </div>
                            </div> -->
                            <!-- labor_rate -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.labor_rate_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="laborRateError = ''" id="laborRate" v-model="laborRate" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{laborRateError}}</span>
                                </div>
                            </div> -->
                            <!-- freight_markup -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.freight_markup_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="freightMarkupError = ''" id="freightMarkup" v-model="freightMarkup" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{freightMarkupError}}</span>
                                </div>
                            </div> -->

                            <!-- Company Status -->
                            <div v-show="permissionNameArray.includes('can_active_inactive_company_master')" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.company_status}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Role"
                                              v-model="companyStatusId"
                                              @change="changedValue"
                                              @input="companyStatusError = ''"
                                              name="roleId"
                                              id="roleId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="companyStatusArr"
                                              :placeholder="staticMessages.company_status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyStatusError}}</span>
                                </div>
                            </div>
                            <!-- Location -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <multiselect 
                                        v-model="locationId" 
                                        :options="locationArr" 
                                        :multiple="true" 
                                        :close-on-select="false" 
                                        :clear-on-select="false" 
                                        :preserve-search="true" 
                                        :placeholder="staticMessages.location_place_holder"
                                        label="text" 
                                        track-by="text" 
                                        :preselect-first="true"
                                        class="form-control"
                                        @input="locationError = ''"
                                        >
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedLocationSelection.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                        </template>
                                    </multiselect>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>
                            <!-- Address -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.address_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="addressError = ''" id="address" v-model="address" class="form-control" type="text"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{addressError}}</span>
                                </div>
                            </div>
                            <!-- phone -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.phone_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="phoneError = ''" id="phone" v-model="phone" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{phoneError}}</span>
                                </div>
                            </div>
                            <!-- Email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emailError}}</span>
                                </div>
                            </div>
                            <!-- Excede Branch -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.excede_branch_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <multiselect 
                                        v-model="excedeBranchId" 
                                        :options="excedeBranchArr" 
                                        :multiple="true" 
                                        :close-on-select="false" 
                                        :clear-on-select="false" 
                                        :preserve-search="true" 
                                        :placeholder="staticMessages.excede_branch_place_holder"
                                        label="text" 
                                        track-by="text" 
                                        :preselect-first="true"
                                        class="form-control"
                                        @input="excedeBranchError = ''"
                                        >
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedExcedeBranchSelection.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <!-- Select Website -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.select_website}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <multiselect 
                                        label="text" 
                                        class="form-control"
                                        v-model="websiteId" 
                                        :options="websiteArr" 
                                        :multiple="true" 
                                        :close-on-select="false" 
                                        :clear-on-select="false" 
                                        :preserve-search="true" 
                                        track-by="text" 
                                        :preselect-first="true"
                                        :placeholder="staticMessages.website_place_holder"
                                        >
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedWebsiteSelection.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>

                            <!-- On Hold Salesperson Email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.on_hold_salesperson_eml}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <vue-tags-input
                                        v-model="holdSalespersonEml"
                                        :tags="holdSalespersonEmlTagArr"
                                        :validation="validation"
                                        @tags-changed="handleHoldSalespersonEmlTagsChanged"
                                        @before-adding-tag="checkHoldSalespersonEmlTag"
                                        placeholder=""
                                        @input="holdSalespersonEmlError=''"
                                    />
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{holdSalespersonEmlError}}</span>
                                </div>
                            </div>

                            <!-- Upload Logo -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.upload_logo_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <vue-dropzone
                                        id="drop1"
                                        :options="dropzoneOptions"
                                        ref="dropzone"
                                        :destroyDropzone="false"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-success="uploadDocument"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUloadBanner"
                                        name="file"
                                        :useCustomSlot=true
                                        >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticMessages.drop_file_here_drop_zone_msg}}</h4>
                                            <div class="subtitle">({{staticMessages.select_files_from_computer}})</div>
                                        </div>
                                    </vue-dropzone>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fileUploadError}}</span>
                                </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addCompanyBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addCompany()">
                            <input id="addAndContinueCompanyBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addCompany(flag='Save and Continue')">
                            <input id="cancelCompany" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import Multiselect from 'vue-multiselect';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import VueTagsInput from '@johmun/vue-tags-input';
    Vue.component("app-footer", Footer);
    Vue.component("no-access", NoAccessPage);

    export default {
        name: 'AddUpdateCompany',
        mixins: [commonMixins],
        components: { 
            Multiselect,
            'vueDropzone': vue2Dropzone,
            VueTagsInput
        },
        data () {
            return {
                editCompanyId : 0,
                staticInputLabelMessages: "",
                staticMessages: "",
                name: "",
                fullname : "",
                abbrevation: "",
                websiteName: "",
                address: "",
                city: "",
                cityArr: [],
                isCityRecordNotFound: false,
                state: "",
                stateArr: [],
                isStateRecordNotFound: false,
                zipCode: "",
                phone: "",
                email: "",
                invoiceNote: "",
                invoiceCC: "",
                expireStaleOpporDay: "",
                expireStaleOpporNotifyEmail: "",
                interestRate: "",
                retailPartsMarkup: "",
                shopPartsMarkup: "",
                laborRate: "",
                freightMarkup: "",

                nameError: "",
                abbrevationError: "",
                websiteNameError: "",
                addressError: "",
                stateError: "",
                cityError: "",
                zipCodeError: "",
                phoneError: "",
                emailError: "",
                invoiceNoteError: "",
                invoiceCCError: "",
                expireStaleOpporDayError: "",
                expireStaleOpporNotifyEmailError: "",
                interestRateError: "",
                retailPartsMarkupError: "",
                shopPartsMarkupError: "",
                laborRateError: "",
                freightMarkupError: "",

                countryId: "",
                countryArr: [],
                countryError: "",
                isCountryRecordNotFound: false,

                companyStatusId : "Active",
                companyStatusError : "",
                companyStatusArr : [
                    {
                        text : "Active",
                        value : 'active'
                    },
                    {
                        text : "Inactive",
                        value : 'inactive'
                    }
                ],

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Company",
                permissionNameArray : ['can_add_company_master'],

                locationId: '',
                locationArr: [],
                appliedLocationSelection : [],
                locationError: '',

                excedeBranchId: '',
                excedeBranchArr: [],
                appliedExcedeBranchSelection : [],
                excedeBranchError: '',

                websiteId: '',
                websiteArr: [],
                appliedWebsiteSelection : [],
                //DropZone
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: 'POST',
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    maxFiles: 1, //ToDo : to decide number of files allowed
                    thumbnailWidth: 150, // px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    duplicateCheck: true,
                    acceptedFiles: ".png",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    dictFileTooBig: "File is too big ({{filesize}}mb). Max allowed file size is {{maxFilesize}}mb",
                    dictInvalidFileType: "Invalid File Type",
                    dictCancelUpload: "Cancel",
                    dictRemoveFile: "Remove",
                    dictMaxFilesExceeded: "Only 1 file is allowed",
                    dictDefaultMessage: "Drop files here to upload",
                },

                imageType: '',
                imageSize: '',
                imagePath: '',
                imageWidth: 0,
                imageHeight: 0,
                isFileUploaded: false,
                fileUploadError : "",
                holdSalespersonEml: '',
                holdSalespersonEmlError: '',
                holdSalespersonEmlTagArr: [],
                validation: [
                    {
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    }
                ],
                isValidHoldSalespersonEmlTag :true,
                isHoldSalespersonEmlEmpty: true
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            state: function (state) {
                if (state === null || typeof state.value === "undefined" || state.value === null || state.value == '') {
                } else {
                    self.loadAllData(['Cities'], true, self.loadCityDataCallback, "undefined", "", state.value);
                }
            },
            countryId: function (countryId) {
                if (countryId === null || typeof countryId.value === "undefined" || countryId.value === null || countryId.value == '') {
                } else {
                    self.loadAllData(['States'], true, self.loadStateDataCallback, "undefined", "", self.countryId.value);
                }
            },
            locationId: function(){
                self.appliedLocationSelection = [];
                $.each(self.locationId, function (i) {
                    self.appliedLocationSelection.push(self.locationId[i].value);
                });
            },
            excedeBranchId: function(){
                self.appliedExcedeBranchSelection = [];
                $.each(self.excedeBranchId, function (i) {
                    self.appliedExcedeBranchSelection.push(self.excedeBranchId[i].value);
                });
            },
            websiteId: function(){
                self.appliedWebsiteSelection = [];
                $.each(self.websiteId, function (i) {
                    self.appliedWebsiteSelection.push(self.websiteId[i].value);
                });
            },
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('company-list');
            EventBus.$emit("menu-selection-changed", "company-list");

            $("#alertError").hide();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.editCompanyId = self.$route.params.id;
                self.getCompanyDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueCompanyBtn").hide();
                self.pageTitle = "Edit Company";
            } else {
                self.countryId = self.prodCountryObj();
            }
            self.getLoginIdPermissions();
            self.$nextTick(function () {
                self.loadAllData(['Countries', 'Location','Website'], false, self.loadMasterDataCallBack, "undefined", '');
                self.loadAllData(['Location'], true, self.loadExcedeBranchDataCallback, "undefined", '', 0, self.editCompanyId, 0, 0, 0, 1);
            });
        },
        methods: {
            buttonBack() {
                self.$router.push('/company/list');
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            changeCountry: function (value) {
            },
            loadMasterDataCallBack: function (callbackData) {
                self.isCountryRecordNotFound = false;
                self.countryArr = callbackData.Countries;
                if (self.countryArr.length == 0) {
                    self.isCountryRecordNotFound = true;
                }
                self.locationArr = callbackData.Location;
                self.websiteArr = callbackData.Website;
            },
            /* loadLocationDataCallback: function (callbackData) {
                self.locationArr = callbackData.Location;
            }, */
            loadExcedeBranchDataCallback: function (callbackData) {
                self.excedeBranchArr = callbackData.Location;
            },

            loadWebsiteDataCallback: function (callbackData) {
                self.websiteArr = callbackData;
            },
            /*loadCountryCallBack: function (callbackData) {
                self.isCountryRecordNotFound = false;
                self.countryArr = callbackData.Countries;
                if (self.countryArr.length == 0) {
                    self.isCountryRecordNotFound = true;
                }
            }, */
            onSearchState(search, loading) {
                if (self.countryId == null || !self.countryId.value) {
                    self.showSAlert('Please Select Country First!', 'info', false);
                } else {
                    if (search.length > 1) {
                        loading(true);
                        self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search, self.countryId.value);
                    }
                }
            },
            onSearchState(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search);
                }
            },
            onSearchCity(search, loading) {
                if (self.state == null || !self.state.value) {
                    self.showSAlert('Please Select State First!', 'info', false);
                } else {
                    if (search.length >= 1) {
                        loading(true);
                        self.loadAllData(['Cities'], true, self.loadCityDataCallback, loading, search, self.state.value);
                    }
                }
            },
            loadStateDataCallback: function(callbackData) {
                self.isStateRecordNotFound = false;
                self.stateArr = callbackData.States;
                if(self.stateArr.length == 0) {
                    self.isStateRecordNotFound = true;
                }
            },
            loadCityDataCallback: function(callbackData) {
                self.isCityRecordNotFound = false;
                self.cityArr = callbackData.Cities;
                if(self.cityArr.length == 0) {
                    self.isCityRecordNotFound = true;
                }
            },
            clearDataOnSaveAndContinue : function () {
                self.name = "";
                self.fullname = "";
                self.websiteName = "";
                self.address = "";
                self.state = "";
                self.city = "";
                self.zipCode = "";
                self.phone = "";
                self.email = "";
                self.invoiceNote = "";
                self.invoiceCC = "";
                self.expireStaleOpporDay = "";
                self.expireStaleOpporNotifyEmail = "";
                self.interestRate = "";
                self.retailPartsMarkup = "";
                self.shopPartsMarkup = "";
                self.laborRate = "";
                self.freightMarkup = "";
                self.countryId = "";
                self.isCountryRecordNotFound = false;
                isFileUploaded = false;
                self.imagePath = "";
                self.imageType = "";
                self.imageSize = "";
                self.locationId = "";
                self.appliedLocationSelection = [],
                self.excedeBranchId = "";
                self.appliedExcedeBranchSelection = [];
                self.websiteId = "";
                self.appliedWebsiteSelection = [];
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getCompanyDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/company/get', config)
                    .then(response => {
                        self.name = response.data.content.name;
                        self.fullname = response.data.content.full_name;
                        self.websiteName = response.data.content.website_name;
                        self.address = response.data.content.address;
                        self.state = {text: response.data.content.stateName, value: response.data.content.state_id}
                        self.city = {text: response.data.content.cityName, value: response.data.content.city_id}
                        self.zipCode = response.data.content.zip_code;
                        self.phone = response.data.content.phone;
                        self.email = response.data.content.email;
                        self.invoiceNote = response.data.content.invoice_note;
                        self.invoiceCC = response.data.content.invoice_cc;
                        self.expireStaleOpporDay = response.data.content.expire_stale_opportunities_day;
                        self.expireStaleOpporNotifyEmail = response.data.content.expire_opportunities_notify_email;
                        self.interestRate = response.data.content.interest_rate;
                        self.retailPartsMarkup = response.data.content.retail_parts_markup;
                        self.shopPartsMarkup = response.data.content.shop_parts_markup;
                        self.laborRate = response.data.content.labor_rate;
                        self.freightMarkup = response.data.content.freight_markup;
                        self.companyStatusId = response.data.content.is_active == 1 ? {text: "Active", value: "active"} :  {text: "Inactive", value: "inactive"};
                        self.countryId = {text: response.data.content.countryName, value: response.data.content.country_id};
                        self.locationId = [];
                        if(response.data.content.locationDepartmentArr && response.data.content.locationDepartmentArr.length > 0) {
                            for (var i = 0; i < response.data.content.locationDepartmentArr.length; i++) {
                                self.locationId.push({'text': response.data.content.locationDepartmentArr[i].locName, 'value': response.data.content.locationDepartmentArr[i].locId});
                            }
                        }
                        self.excedeBranchId = [];
                        if(response.data.content.excedeBranchArr && response.data.content.excedeBranchArr.length > 0) {
                            for (var i = 0; i < response.data.content.excedeBranchArr.length; i++) {
                                self.excedeBranchId.push({'text': response.data.content.excedeBranchArr[i].locName, 'value': response.data.content.excedeBranchArr[i].locId});
                            }
                        }
                        self.websiteId = [];
                        if(response.data.content.websiteArr && response.data.content.websiteArr.length > 0) {
                            for (var i = 0; i < response.data.content.websiteArr.length; i++) {
                                self.websiteId.push({'text': response.data.content.websiteArr[i].websiteName, 'value': response.data.content.websiteArr[i].websiteId});
                            }
                        }
                        self.imagePath = response.data.content.image_path;
                        if (self.imagePath != '') {
                            self.isFileUploaded = true;
                            self.imageSize = response.data.content.image_size;
                            /* self.imageWidth = 1200;
                            self.imageHeight = 450; */
                            self.uploadManually();
                        }

                        if (response.data.content.hold_salesperson_emails && response.data.content.hold_salesperson_emails != 'NULL') {
                            var holdSalespersonEmlArr = response.data.content.hold_salesperson_emails.split(",");
                            if (holdSalespersonEmlArr.length > 0) {
                                self.holdSalespersonEmlTagArr = holdSalespersonEmlArr.map(email => ({
                                    "text": email.trim(), 
                                    "tiClasses": ["ti-valid"]
                                }));
                                self.isHoldSalespersonEmlEmpty = false;
                            } else {
                                self.isHoldSalespersonEmlEmpty = true;
                            }
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.address) {
                    self.addressError = "Please enter address";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.state == null || !self.state.value) {
                    self.stateError = "Please select state";
                    if (checked) {
                        checked = false;
                    }
                } */
                //alert(self.city.value);
                /* if (self.city == null || !self.city.value) {
                    self.cityError = "Please select city";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.zipCode) {
                    self.zipCodeError = "Please enter zip code";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (!self.phone) {
                    self.phoneError = "Please enter phone no";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.phone && !self.validContactNumber(self.phone)) {
                    self.phoneError = "Please enter valid phone no";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.phone && !self.checkContactNoLength(self.phone)) {
                    self.phoneError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.email) {
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please enter valid email";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (!self.invoiceNote) {
                    self.invoiceNoteError = "Please enter invoice note";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.invoiceCC) {
                    self.invoiceCCError = "Please enter invoice CC";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.expireStaleOpporDay) {
                    self.expireStaleOpporDayError = "Please enter Expire Stale Oppor Day";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (self.expireStaleOpporDay && !self.numberFormateValidation(self.expireStaleOpporDay)) {
                    self.expireStaleOpporDayError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.expireStaleOpporNotifyEmail) {
                    self.expireStaleOpporNotifyEmailError = "Please enter Expire Stale Oppor Notify Email";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (self.expireStaleOpporNotifyEmail && !self.validEmail(self.expireStaleOpporNotifyEmail)) {
                    self.expireStaleOpporNotifyEmailError = "Please enter valid expire Stale Oppor Notify Email";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.interestRate) {
                    self.interestRateError = "Please enter interest rate";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.retailPartsMarkup) {
                    self.retailPartsMarkupError = "Please enter retail Parts Markup";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.shopPartsMarkup) {
                    self.shopPartsMarkupError = "Please enter shop Parts Markup";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.laborRate) {
                    self.laborRateError = "Please enter labor Rate";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.freightMarkup) {
                    self.freightMarkupError = "Please enter freight Markup";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (self.imagePath == '')  {
                    self.fileUploadError = "Please upload file";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.countryId == null || !self.countryId.value) {
                    self.countryError = "Please select country";
                    if (checked) {
                        checked = false;
                    }
                }
                if(self.locationId.length == 0) {
                    self.locationError = "Please select location";
                    if (checked) {
                        checked = false;
                    }
                }
               
                if (self.isHoldSalespersonEmlEmpty) {
                    self.holdSalespersonEmlError = "Please enter on hold salesperson email";
                    if (checked) {
                        checked = false;
                    }
                } else if (!self.isValidHoldSalespersonEmlTag) {
                    self.holdSalespersonEmlError = "Please enter valid on hold salesperson email";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addCompany(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var location_id = '';
                        if( self.locationId && self.locationId.length > 0 ) {
                            for (var i = 0; i < self.locationId.length; i++) {
                                location_id += (self.locationId[i].value) + ',';
                            }
                            location_id = location_id.replace(/,\s*$/, "");
                        }

                        var excedeBranch_id = '';
                        if( self.excedeBranchId && self.excedeBranchId.length > 0 ) {
                            for (var i = 0; i < self.excedeBranchId.length; i++) {
                                excedeBranch_id += (self.excedeBranchId[i].value) + ',';
                            }
                            excedeBranch_id = excedeBranch_id.replace(/,\s*$/, "");
                        }

                        var webSite_id = '';
                        if( self.websiteId && self.websiteId.length > 0 ) {
                            for (var i = 0; i < self.websiteId.length; i++) {
                                webSite_id += (self.websiteId[i].value) + ',';
                            }
                            webSite_id = webSite_id.replace(/,\s*$/, "");
                        }

                        var holdSalespersonEmlData = "";
                        if(self.holdSalespersonEmlTagArr && self.holdSalespersonEmlTagArr.length > 0) {
                            for(var index = 0; index < self.holdSalespersonEmlTagArr.length; index++) {
                                if(!holdSalespersonEmlData.includes(self.holdSalespersonEmlTagArr[index].text)) {
                                    holdSalespersonEmlData += (self.holdSalespersonEmlTagArr[index].text) + ',';
                                }
                            }
                        }
                        holdSalespersonEmlData = holdSalespersonEmlData.replace(/,\s*$/, "");

                        var data = new FormData();
                        data.append('name', self.name);
                        data.append('address', self.address);
                        data.append('full_name', self.fullname);
                        data.append('country_id', self.countryId.value);
                        /* data.append('state_id', self.state.value);
                        data.append('city_id', self.city.value);
                        data.append('zip_code', self.zipCode); */
                        data.append('phone', self.phone);
                        data.append('email', self.email);
                        data.append('invoice_note', self.invoiceNote);
                        data.append('invoice_cc', self.invoiceCC);
                        /* data.append('expire_stale_opportunities_day', self.expireStaleOpporDay);
                        data.append('expire_opportunities_notify_email', self.expireStaleOpporNotifyEmail);
                        data.append('interest_rate', self.interestRate);
                        data.append('retail_parts_markup', self.retailPartsMarkup);
                        data.append('shop_parts_markup', self.shopPartsMarkup);
                        data.append('labor_rate', self.laborRate);
                        data.append('freight_markup', self.freightMarkup); */
                        data.append('company_status', self.companyStatusId.value);
                        data.append('department_location_id', location_id);
                        data.append('excede_branch_id', excedeBranch_id);
                        data.append('website_id', webSite_id);
                        data.append('image_path', self.imagePath);
                        data.append('image_type', self.imageType);
                        data.append('image_size', self.imageSize);
                        data.append('hold_salesperson_eml', holdSalespersonEmlData);

                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/company/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            removeAllFiles() {
                self.$refs.dropzone.removeAllFiles();
                self.isFileUploaded = false;
            },
            uploadDocument: function (file, response) {
                self.imagePath = response.content.file_path;
                // self.isFileUploaded = true;
                self.imageType = file.type;
                self.imageSize = file.size;
                self.imageWidth = file.width;
                self.imageHeight = file.height;
                self.fileUploadError = '';
                /* if (self.isFileUploaded == true && self.imageHeight % 225 != 0 || !(self.imageHeight >= 225) || !(self.imageHeight <= 450)
                        || self.imageWidth % 600 != 0 || !(self.imageWidth >= 600) || !(self.imageWidth <= 1200)) {
                    self.removeAllFiles();
                    self.fileUploadError = "Please Upload Image In This Dimensions Only.(Height : 450 pixel, Width 1200 pixel)";
                } */
            },
            removedSelectedFile(file, xhr, error) {
                var self = this;
                var removeFile = "";
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
                if(file.accepted || file.manuallyAdded){
                    self.imagePath = '';
                    self.imageHeight = 0;
                    self.imageWidth = 0;
                }
                if ("manuallyAdded" in file == false) {
                    if(file && file.xhr != undefined) {
                        var filePath = JSON.parse(file.xhr.response);
                        removeFile = filePath.content;
                        self.removeFileFromServer(removeFile);
                        removeFile = removeFile.media_path
                    }
                }
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    /* console.log("File");
                    console.log(file);
                    console.log("XHR");
                    console.log(xhr.code);
                    console.log(xhr.status);
                    console.log(xhr.message); */
                    self.showSAlert(xhr.message, 'error', false);
                    /* console.log(xhr.content);
                    console.log("Error");
                    console.log(error);
                    console.log(self.imagesPaths); */
                }
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                /* if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', null);
                } */
            },
            progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.saveAndContinue = true;
                    self.disableButtons.cancel = true;
                }
            },
            uploadManually() {
                if (self.imagePath != '') {
                    var file_name = self.getFileName(self.imagePath);
                    let file_type = self.getFileType(file_name);
                    let url = self.imagePath;
                    let file_size = self.imageSize;
                    /*console.log("File Name : " + file_name + " , File Type : " + file_type + " , URL : " + url + " , File Size : " + file_size);*/
                    let file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                    self.$refs.dropzone.manuallyAddFile(file_data, url);
                }
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
            },
            getFileName: function (file) {
                var src = file.split('/');
                var file_name = src[src.length - 1];
                return file_name;
            },
            getFileType: function (file_name) {
                var type = file_name.split('.');
                var file_type = type[type.length - 1];
                return 'image/' + file_type;
            },
            handleHoldSalespersonEmlTagsChanged: function(newTags) {
                self.holdSalespersonEmlError = "";
                self.holdSalespersonEmlTagArr = newTags;
                self.isHoldSalespersonEmlEmpty = false;
                self.isValidHoldSalespersonEmlTag = true;

                if (self.holdSalespersonEmlTagArr.length === 0) {
                    self.isHoldSalespersonEmlEmpty = true;
                } else {
                    let invalidTagFound = self.holdSalespersonEmlTagArr.some(tag => !self.validEmail(tag.text));
                    if (invalidTagFound) {
                        self.isValidHoldSalespersonEmlTag = false;
                    }
                }
            },
            checkHoldSalespersonEmlTag: function(obj) {
                self.holdSalespersonEmlError = "";
                self.isValidHoldSalespersonEmlTag = true;
                self.isHoldSalespersonEmlEmpty = false;

                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!tagEmail[1]) {
                    self.isHoldSalespersonEmlEmpty = true;
                } else if(tagEmail[1] && !self.validEmail(tagEmail[1])) {
                    self.isValidHoldSalespersonEmlTag = false;
                } else obj.addTag();
            }
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>