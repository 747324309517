var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spaced-container"},[_c('div',{staticClass:"admin-content-area",attrs:{"id":"page-content-wrapper"}},[_c('div',{staticClass:"container-fluid xyz"},[_c('div',{staticClass:"page-header-top-container"},[_c('div',{staticClass:"page-name"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('button',{staticClass:"btn btn-primary waves-effect pull-right",attrs:{"type":"submit"},on:{"click":function($event){return _vm.buttonBack()}}},[_vm._v(" Back ")])]),_c('div',{staticClass:"admin-page-content"},[_c('div',{staticClass:"forms-container add_user_forms_container vtc-dealer-forms"},[_c('div',{staticClass:"row no-margin"},[_c('ul',{staticClass:"nav nav-tabs"},[(_vm.permissionNameArray.includes('can_add_location_master'))?_c('li',{staticClass:"nav-item"},[_c('a',{class:'nav-link ' + [_vm.activetab === 1 ? 'active' : ''],on:{"click":function($event){_vm.activetab = 1}}},[_vm._v("Edit Location ")])]):_vm._e(),(
                  _vm.permissionNameArray.includes(
                    'can_list_set_by_branch_defaults'
                  )
                )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{ 'nav-link': true, active: _vm.activetab === 2 },on:{"click":function($event){_vm.activetab = 2}}},[_vm._v("Set By Branch Default ")])]):_vm._e(),(
                  _vm.permissionNameArray.includes('can_list_cal_vehicle_prices')
                )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{ 'nav-link': true, active: _vm.activetab === 3 },on:{"click":function($event){_vm.activetab = 3}}},[_vm._v("Vehicle Price ")])]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{class:{ 'nav-link': true, active: _vm.activetab === 4 },on:{"click":function($event){_vm.activetab = 4}}},[_vm._v("Location Contacts ")])]),(
                  _vm.permissionNameArray.includes(
                    'can_list_area_on_yard_location_master'
                  )
                )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{ 'nav-link': true, active: _vm.activetab === 5 },on:{"click":function($event){_vm.activetab = 5}}},[_vm._v("Area On Yard ")])]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{class:{ 'nav-link': true, active: _vm.activetab === 6 },on:{"click":function($event){_vm.activetab = 6}}},[_vm._v("Printing Documents Fields ")])])]),(_vm.activetab === 1)?_c('div',{staticClass:"tabcontent",attrs:{"id":"location-activity-logs1"}},[_c('update-location',{ref:"locationAddUpdateForm",on:{"setPageTitle":_vm.setPageTitle}})],1):_vm._e(),(_vm.activetab === 2)?_c('div',{staticClass:"tabcontent",attrs:{"id":"location-activity-logs2"}},[_c('list-set-by-branch')],1):_vm._e(),(_vm.activetab === 3)?_c('div',{staticClass:"tabcontent",attrs:{"id":"location-activity-logs3"}},[_c('list-cal-vehicle-price')],1):_vm._e(),(_vm.activetab === 4)?_c('div',{staticClass:"tabcontent",attrs:{"id":"location-activity-logs4"}},[_c('list-location-manager')],1):_vm._e(),(_vm.activetab === 5)?_c('div',{staticClass:"tabcontent",attrs:{"id":"location-activity-logs5"}},[_c('list-area-on-yard',{attrs:{"locid":_vm.locationId}})],1):_vm._e(),(_vm.activetab === 6)?_c('div',{staticClass:"tabcontent",attrs:{"id":"location-activity-logs6"}},[_c('printing-documents-fields',{attrs:{"locid":_vm.locationId}})],1):_vm._e()])])])]),_c('app-footer')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }