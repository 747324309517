<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template
      v-if="permissionNameArray.includes('can_list_vendor_item_vendor_master')"
    >
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>{{ allLabelMessages.vendor_item }}</span>
              <p v-if="totalRecords > 0">
                {{ totalRecords }} {{ allLabelMessages.results_found }}
              </p>
            </div>
            <a
              v-show="
                permissionNameArray.includes(
                  'can_add_vendor_item_vendor_master'
                )
              "
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="addVendorItem()"
            >
              <span>{{ allLabelMessages.add_vendor_itam }}</span>
            </a>
            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="headers"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }}
              {{ allLabelMessages.rows_selected }}
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <button
              v-show="
                permissionNameArray.includes(
                  'can_delete_vendor_item_vendor_master'
                )
              "
              type="button"
              v-on:click.prevent="deleteVendorItem()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              {{ deleteRecord }} {{ selectedRows.length }} {{ records }}
            </button>
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListVendorItem"
              ref="commonLoaderListVendorItem"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">
                    {{ allLabelMessages.page }}
                  </p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="vendorItemArr && vendorItemArr.length > 0">
                    <tr
                      v-for="(vendorItem, index) in vendorItemArr"
                      :key="index"
                    >
                      <td class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            v-model="selectedRows"
                            :value="vendorItem.id"
                            @change="inputCheckbox($event)"
                            class="custom-control-input"
                            :id="'customCheck' + vendorItem.id"
                            name="case[]"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customCheck' + vendorItem.id"
                          ></label>
                        </div>
                      </td>
                      <td v-show="actionDisplay">
                        <div class="actions" style="width: 80px">
                          <router-link
                            v-show="
                              permissionNameArray.includes(
                                'can_add_vendor_item_vendor_master'
                              )
                            "
                            class="mar-right-0"
                            title="Edit Vendor Item"
                            v-bind:to="{
                              name: 'UpdateVendorItem',
                              params: { id: vendorId, venid: vendorItem.id },
                            }"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-angle-right forward_icon"
                            ></i>
                          </router-link>
                          <a
                            v-show="
                              permissionNameArray.includes(
                                'can_delete_vendor_item_vendor_master'
                              )
                            "
                            href="javascript:void(0)"
                            title="Delete Vendor Item"
                            class="mar-right-0"
                            v-on:click="deleteVendorItem(vendorItem.id)"
                          >
                            <i
                              class="exceptEdit fa fa-trash-o"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </td>
                      <td :class="rowClass.title">{{ vendorItem.title }}</td>
                      <td :class="rowClass.price">{{ vendorItem.price }}</td>
                      <td :class="rowClass.updated_at">
                        {{ vendorItem.updated_at }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">
                    {{ allLabelMessages.page }}
                  </p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
  </div>
</template>

<script>
let self;
let filterColumn = "id";
let filterType = "desc";
import Vue from "vue";
//import vSelect from "vue-select";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import NoAccessPage from "../NoAccessPage/NoAccessPage";

//Vue.component("v-select", vSelect);

export default {
  name: "ListVendorItem",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "common-loader": CommonLoader,
    "no-access": NoAccessPage,
  },
  props: {
    vendorId: 0,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      vendorItemArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_vendor_item_vendor_master"],
      actionDisplay: true,
      jumpToHistoryPage: 1,
      filterCount: 0,
      deleteRecord: "Delete",
      records: "Record(s)",
    };
  },
  beforeMount() {
    self = this;
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  watch: {},
  mounted: function () {
    /* Redirect to login page logic start */
    const userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    if (self.vendorId) {
      const listHistoryData = self.loadFilters("listVendorItem", self);
      self.jumpToHistoryPage =
        listHistoryData === null ||
        typeof listHistoryData === "undefined" ||
        listHistoryData == ""
          ? 1
          : listHistoryData.page;
      self.listExecutionState = false;
      self.loadVendorItem(self.vendorId,1);

      let headersNames = [];
      headersNames["filters"] = self.filters;
    }
  },
  methods: {
    addVendorItem: function () {
      self.$router.push({ name: "AddUpdateVendorItem", params: self.vendorId });
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadVendorItem(self.vendorId);
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadVendorItem(self.vendorId);
    },
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadVendorItem(self.vendorId);
      }
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadVendorItem(self.vendorId);
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    keyMonitor: function () {
      const tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.vendorItemArr) {
            self.selectedRows.push(self.vendorItemArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.vendorItemArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },

    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {},
    deleteVendorItem: function (id) {
      let deleteArray = [];
      if (id > 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Vendor Item!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            const config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                deleteArr: deleteArray,
              },
            };

            HTTP.delete("/vendoritem/delete", config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.loadVendorItem(self.vendorId);
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
    loadVendorItem: function (vendorId, first = null) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        return false;
      }
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          vendor_id: self.$route.params.id,
        },
      };
      const fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        vendorId: vendorId,
      };
      this.storeFilters("listVendorItem", fields, false);
      $("#commonLoaderListVendorItem")
        .find(".loading-container.lodingTable")
        .show();
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/vendoritem/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          $("#commonLoaderListVendorItem")
            .find(".loading-container.lodingTable")
            .hide();
          if (data.status == 200) {
            if (data.data.message == "success export") {
              self.downloadFile(data.data.content.web);
            } else {
              self.clearSelection();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              self.vendorItemArr = data.data.content.data;
              self.headers = data.data.content.headers;
              self.loadFilterInputs(data.data.content.headers, self.filters);

              self.addColumnClass(self.headers);

              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#commonLoaderListVendorItem")
            .find(".loading-container.lodingTable")
            .hide();
          console.log(error);
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadVendorItem(self.vendorId);
      }
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (
      !self.permissionNameArray.includes("can_add_vendor_item_vendor_master") &&
      !self.permissionNameArray.includes("can_delete_vendor_item_vendor_master")
    ) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
</script>
