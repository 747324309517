<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_task_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Priority -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.priority_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                     <v-select
                                            v-model="priority"
                                            name="priority"
                                            @input="priorityError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="priorityArr"
                                            :clearable="false"
                                            :placeholder="staticMessages.select_priority">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{priorityError}}</span>
                                </div>
                            </div>
                            <!-- Status -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                     <v-select
                                            v-model="status"
                                            name="asset"
                                            @input="statusError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="statusArr"
                                            :clearable="false"
                                            :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{statusError}}</span>
                                </div>
                            </div>
                            <!--Customer -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                        v-model="customerId"
                                        @change="changedValue"
                                        @input="customerError = ''"
                                        name="customerId"
                                        id="customerId"
                                        label="text"
                                        @search="onSearchCustomer"
                                        :clearable="false"
                                        :searchable="true"
                                        :filterable="false"
                                        :options="customerArr"
                                        :placeholder="staticMessages.customer_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{customerError}}</span>
                                </div>
                            </div>
                            <!-- User -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.user_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="userId"
                                        name="asset"
                                        @input="userError = ''"
                                        label="text"
                                        :filterable="false"
                                        :options="userArr"
                                        @search="onSearchUser"
                                        :clearable="false"
                                        :placeholder="staticMessages.user_place_holder"
                                        >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{userError}}</span>
                                </div>
                            </div>
                            <!-- Subject -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="subjectError = ''" id="subject" v-model="subject" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{subjectError}}</span>
                                </div>
                            </div>
                            <!-- Due DateTime -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.due_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                        v-model="dueDate"
                                        :config="options"
                                        class="form-control"
                                        :placeholder="staticMessages.due_date_time_place_holder"
                                        name="dueDate"
                                        @input="changeDueDateTime()"
                                        >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dueDateError}}</span>
                                </div>
                            </div>
                            <!-- Description -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="description" v-model="description" class="form-control" type="text"></textarea>
                                </div>
                            </div>
                            <template v-if="permissionNameArray.includes('can_send_customer_email_notification_task_master') || permissionNameArray.includes('can_send_user_email_notification_task_master')">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.send_notification_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <template v-if="permissionNameArray.includes('can_send_customer_email_notification_task_master')">
                                            <label class="roleLabel container">
                                                <input v-model="notifyCustomer" class="checkbox" type="checkbox" id="notifyCustomer" name="notifyCustomer">
                                                <!-- <span class="checkmark"></span> -->
                                                <span class="pl-2">{{staticInputLabelMessages.notification_customer}}</span>
                                            </label>
                                        </template>
                                        <template v-if="permissionNameArray.includes('can_send_user_email_notification_task_master')">
                                            <label class="roleLabel container">
                                                <input v-model="notifyUser" class="checkbox" type="checkbox" id="notifyUser" name="notifyUser">
                                                <!-- <span class="checkmark"></span> -->
                                                <span class="pl-2">{{staticInputLabelMessages.notification_user}}</span>
                                            </label>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addTaskBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addTask()">
                            <input id="addAndContinueTaskBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addTask(flag='Save and Continue')">
                            <input id="cancelTask" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'AddUpdateTaskMaster',
        components: {
            datePicker,
        },
        mixins: [commonMixins],
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                priority: {value: "normal", text: "Normal"},
                priorityArr: [
                    {
                        value: "high",
                        text: "High"
                    },
                    {
                        value: "normal",
                        text: "Normal"
                    },
                    {
                        value: "low",
                        text: "Low"
                    }
                ],
                priorityError: "",
                status: {value: "open", text: "Open"},
                statusArr: [
                    {
                        value: "open",
                        text: "Open"
                    },
                    {
                        value: "in_progress",
                        text: "In Progress"
                    },
                    {
                        value: "closed",
                        text: "Closed"
                    }
                ],
                statusError: "",
                customerId: "",
                customerArr: [],
                customerError: "",
                userId : "",
                userArr : [],
                userError : "",
                isUserRecordNotFound : false,
                subject: "",
                subjectError: "",
                dueDate: "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },
                dueDateError: "",
                description: "",
                notifyCustomer: 0,
                notifyUser: 0,

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Task",
                permissionNameArray : ['can_add_task_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('task-list');
            EventBus.$emit("menu-selection-changed", "task-list");

            self.loadAllData(['Contact'], true, self.loadContactCallback, "undefined", '', 0, 0, 1);
            self.loadAllData(['User'], true, self.loadUserTypeCallback, "undefined", '');

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getTaskDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueTaskBtn").hide();
                self.pageTitle = "Edit Task";
            }
        },
        methods: {
            buttonBack() {
                self.$router.push('/task/list');
            },
            loadContactCallback: function (callbackData) {
                self.customerArr = callbackData.Contact;
            },
            onSearchCustomer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    // self.loadAllData(['Contact'], true, self.loadContactCallback, loading, search);
                    self.loadAllData(['Contact'], true, self.loadContactCallback, loading, search, 0, 0, 1);
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadUserTypeCallback: function (callbackData) {
                self.isUserRecordNotFound = false;
                self.userArr = callbackData.User;
                if (self.userArr.length == 0) {
                    self.isUserRecordNotFound = true;
                }
            },
            onSearchUser : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadUserTypeCallback, loading, search);
                }
            },
            changeDueDateTime: function () {
                self.dueDateError = "";
            },
            clearDataOnSaveAndContinue : function () {
                self.priority = {value: "normal", text: "Normal"};
                self.status = {value: "open", text: "Open"};
                self.customerId = "";
                self.userId = "";
                self.isUserRecordNotFound = false;
                self.subject = "";
                self.dueDate = "";
                self.description = "";
                self.notifyCustomer = 0;
                self.notifyUser = 0;
            },
            getTaskDetailsById: function(id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/task/get', config)
                    .then(response => {
                        self.priority = {value: response.data.content.priority, text: self.formatString(response.data.content.priority)};
                        self.status = {value: response.data.content.status, text: self.formatString(response.data.content.statusText)};
                        self.customerId = {value: response.data.content.contact_id, text: response.data.content.custName};
                        self.userId = {value: response.data.content.user_id, text: response.data.content.firstName + ' ' + response.data.content.lastName};
                        self.subject = response.data.content.subject;
                        self.dueDate = response.data.content.due_date
                        self.description = response.data.content.description;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                if (self.customerId == null || !self.customerId.value) {
                    self.customerError = "Please select Customer";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.userId == null || !self.userId.value) {
                    self.userError = "Please select User";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.subject) {
                    self.subjectError = "Please enter subject";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dueDate) {
                    self.dueDateError = "Please Select Due Date";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addTask(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('priority', self.priority.value);
                        data.append('status', self.status.value);
                        data.append('customer_id', self.customerId.value);
                        data.append('user_id', self.userId.value);
                        data.append('subject', self.subject);
                        data.append('dueDate', self.dueDate);
                        data.append('description', self.description);
                        data.append('notifyCustomer', self.notifyCustomer ? 1 : 0);
                        data.append('notifyUser', self.notifyUser ? 1 : 0);

                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/task/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false ,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>  