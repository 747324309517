<template>
    <div class="col-md-9 paddingnone float-left vendor-invoice-section bg-white pt-3">
        <label class="fz-larger">{{ allLabelMessages.document_list }}</label>
        <div class="clearfix mb-3"></div>
        <common-loader refId="commonLoaderVendorInvoice" ref="commonLoaderVendorInvoice"></common-loader>
        <div class="table-listing white-bg border-blue border-radius-top-right">
            <table class="table custom-table jsmartable">
                <tbody>
                    <template v-if="invoiceDataArr && invoiceDataArr.length > 0">
                        <tr v-for="(invoice, index) in invoiceDataArr" :key="index">
                            <td>
                                <div class="actions">
                                    <a href="javascript:void(0)" :id="'id-' + index + 'Download'" @click="getSignedUrlPath(invoice.path, 1)" title='Download Invoice' class="mar-right-0">
                                        <i class="exceptEdit fa fa-cloud-download" aria-hidden="true"></i>
                                    </a>
                                    <span class="vertical-bar"></span>
                                    <a href="javascript:void(0)" :id="'id-' + index + 'Delete'" @click="deleteInvoice(invoice.invVendorDocId)" title='Delete Invoice' class="mar-right-0">
                                        <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </td>
                            <td :class="rowClass.doc_type">{{invoice.doc_type}}</td>
                            <td :class="rowClass.invoice_name">{{invoice.file_name}}</td>
                            <td :class="rowClass.updated_date">{{invoice.updated_at}}</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                {{allStaticMessages.data_not_available}}
                            </td>
                        </tr>
                    </template>
                </tbody>
                <thead>
                <tr>
                    <template v-for="(data, headerIndex) in headers">
                        <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                        <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex">{{data.name}}</th>
                    </template>
                </tr>
                </thead>
            </table>
        </div>
    </div>
</template>

<script>
var self;
import {commonMixins} from '../../mixins/commonMixins';
import {HTTP} from '../../http-common';
import CommonLoader from "@/components/partials/CommonLoader.vue";
import EventBus from ".././event-bus";

export default {
    name: 'VendorInvoice',
    mixins: [commonMixins],
    components: {
        "common-loader": CommonLoader
    },
    data () {
        return {
            invoiceDataArr: [],
            headers: [],
            inventoryTrackingId: 0,
            totalInvoice: 0
        }
    },
    beforeMount() {
        self = this;
    },
    props: ['vendorId'],
    created : function () {
        self = this;
    },
    mounted: function() {
       self = this;
    },
    methods: {
        fetchInvoiceData: function(inventoryTrackingId) {
            self.inventoryTrackingId = inventoryTrackingId;
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("vendor_api_token")
                }
            };

            var data = self.returnData(inventoryTrackingId);
            $("#commonLoaderVendorInvoice").find(".loading-container.lodingTable").show();
            HTTP.post("/fetchInvoiceData", data, config)
                .then(function (response) {
                    $("#commonLoaderVendorInvoice").find(".loading-container.lodingTable").hide();
                    if (response.data.status === "success") {
                        self.invoiceDataArr = response.data.content.processedData;

                        self.totalInvoice = response.data.content.processedData.length;
                        EventBus.$emit("total-invoice", self.totalInvoice);

                        self.headers = response.data.content.headers;
                        self.addColumnClass(self.headers);
                    }
                })
                .catch(function (err) {
                    $("#commonLoaderVendorInvoice").find(".loading-container.lodingTable").hide();
                    self.catchHandler(err, function () {}, 1);
                });
        },
        returnData: function(inventoryTrackingId) {
            var data = {
                inventoryTrackingId: inventoryTrackingId
            };
            return data;
        },
        deleteInvoice: function (id) {
            self.$swal({
                title: "",
                text: "Are you sure that you want to delete this Invoice!",
                type: "info",
                confirmButtonText: "OK",
                showCancelButton: true,
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.value) {
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("vendor_api_token")
                        },
                        data: {
                            id: id
                        },
                    };

                    HTTP.delete("/deleteInvoice", config)
                        .then(function (response) {
                            if (response.data.code == "200") {
                                self.$swal({
                                    title: "",
                                    text: response.data.message,
                                    type: "success",
                                    confirmButtonText: "OK",
                                    showCancelButton: false,
                                    showLoaderOnConfirm: true
                                }).then((result) => {
                                    if (result.value) {
                                        self.fetchInvoiceData(self.inventoryTrackingId);
                                    }
                                });
                            }
                        })
                        .catch(function (error) {
                            self.catchHandler(error, function () {}, 1);
                        });
                }
            });
        }
    }
}
</script>
<style lang="css" scoped>
.vendor-invoice-section label.fz-larger {
    margin-bottom: 0;
}
.vendor-invoice-section .vertical-bar{
    width: 1px;
    height: 20px;
    background-color: #ccc;
    margin: 0 8px;
    float: left;
}
</style>