<template>
    <div>
        <div id="page-content-wrapper" class="admin-content-area">
            <template v-if="index != 0">
                <div class="page-break"></div>
            </template>
            <div class="container-fluid xyz">
                <div class="admin-page-content">
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <!-- Div Structure Without Print -->
                        <!-- <div class="specification-table"> -->
                        <template v-for="(assetInf, index) in assetInfo">
                            <!--header section-->
                            <div :key="'SpecTable-Header-section ' + index" class="SpecTable-Header-section">
                                <div class="specification-table-left">
                                    <template v-if="assetInf['city_name'] != ''">
                                        <h4> {{assetInf['company_full_name']}} - {{assetInf['city_name']}}</h4>
                                    </template>
                                    <template v-else>
                                        <h4> {{assetInf['company_full_name']}} </h4>
                                    </template>
                                    <template v-if="assetInf['city_name'] != '' && assetInf['state_code'] != ''">
                                        <p> {{assetInf['city_name']}}, {{assetInf['state_code']}} </p>
                                    </template>
                                    <template v-if="isShowCompanyOrUserDetails">
                                        <template v-if="companyInfo[index]['name'] != '' || companyInfo[index]['contact_no'] != '' || companyInfo[index]['email'] != ''">
                                            <template v-if="publicLoginId == 0">
                                                <h4><br/>Location Details : </h4>
                                            </template>
                                            <template v-else>
                                                <h4><br/>Salesman Contact Details : </h4>
                                            </template>
                                            <template v-if="companyInfo[index]['name'] != ''">
                                                <p class="name-field"> {{companyInfo[index]['name']}} </p>
                                            </template>
                                            <template v-if="companyInfo[index]['contact_no'] != ''">
                                                <p class="fone-field"> {{companyInfo[index]['contact_no']}} </p>
                                            </template>
                                            <template v-if="companyInfo[index]['email'] != ''">
                                                <a class="email-field"  :href="'mailto:'+companyInfo[index]['email']"> {{companyInfo[index]['email']}} </a>
                                            </template>
                                        </template>
                                    </template>
                                    <!-- <template v-if="isShowCompanyOrUserDetails">
                                        <template v-if="assetInf['company_full_name'] != '' || assetInf['company_phone'] != '' || assetInf['company_email'] != ''">
                                            <h4><br/>Department Contact Details : </h4>
                                            <template v-if="assetInf['company_full_name'] != ''">
                                                <p class="name-field"> {{assetInf['company_full_name']}} </p>
                                            </template>
                                            <template v-if="assetInf['company_phone'] != ''">
                                                <p class="fone-field"> {{assetInf['company_phone']}} </p>
                                            </template>
                                            <template v-if="assetInf['company_email'] != ''">
                                                <a class="email-field"  :href="'mailto:'+assetInf['company_email']"> {{assetInf['company_email']}} </a>
                                            </template>
                                        </template>
                                    </template> -->
                                </div>

                                <div class="specification-table-right">
                                    <!-- <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales"> </a> -->
                                    <a href="javascript:void(0)" target="_blank"> <img :src="assetInf['company_logo']" :alt="assetInf['company_logo']"  class="company-logo"> </a>
                                </div>
                            </div>
                            <!--header section-->
                            <div :key="'stock-detail-section ' + index" class="stock-detail-section">
                                <div class="stock-detail-section-left">
                                    <h5> STOCK
                                        <template v-if="assetInf['unit']">
                                            {{assetInf['unit']}}
                                        </template>
                                        <template v-else>
                                            -
                                        </template>
                                    </h5>
                                    <p> {{ assetInf['year'] }} {{ assetInf['make_name'] }} {{ assetInf['model_name'] }} </p>
                                    <template v-if="assetInf['media_path']">
                                        <img :src="assetInf['media_path']" alt="Central California Truck and Trailer Sales">
                                    </template>
                                    <template v-else-if="assetInf['media_url']">
                                        <img :src="assetInf['media_url']" alt="Central California Truck and Trailer Sales">
                                    </template>
                                </div>

                                <div class="stock-detail-section-right">
                                    <template v-if="assetInf['retail_price']">
                                        <p class="amount-section"> {{assetInf['retail_price_format']}} </p>
                                    </template>
                                    <template v-if="isShowCompanyOrUserDetails">
                                        <template v-if="assetInf['description'] != NULL">
                                            <p class="des-text"> DESCRIPTION </p>
                                            <p class="name-field">{{assetInf['description']}}</p>
                                        </template>
                                        <!-- <template v-if="companyInfo[index]['name'] != '' || companyInfo[index]['contact_no'] != '' || companyInfo[index]['email'] != ''">
                                            <p class="des-text"> DESCRIPTION </p>
                                            <template v-if="companyInfo[index]['name'] != ''">
                                                <p class="name-field"> {{companyInfo[index]['name']}} </p>
                                            </template>
                                            <template v-if="companyInfo[index]['contact_no'] != ''">
                                                <p class="phone-field"> {{companyInfo[index]['contact_no']}} </p>
                                            </template>
                                            <template v-if="companyInfo[index]['email'] != ''">
                                                <a class="email-field" :href="'mailto:'+companyInfo[index]['email']"> {{companyInfo[index]['email']}} </a>
                                            </template>
                                        </template> -->
                                    </template>
                                </div>
                            </div>
                            <div :key="'specification-detail ' + index" class="specification-detail">
                                <h4> Specifications </h4>
                                <div class="specification-left-section">
                                    <!-- <div class="spec-info-main">
                                        <p class="specification-label"> Price: </p>
                                        <template v-if="assetInf['retail_price']">
                                            <p class="specification-value"> {{ assetInf['retail_price_format'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> $0 </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Stock#: </p>
                                        <template v-if="assetInf['unit']">
                                            <p class="specification-value"> {{ assetInf['unit'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> VIN: </p>
                                        <p class="specification-value"> {{ assetInf['vin_no'] }} </p>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Miles/Hrs: </p>
                                        <template v-if="assetInf['odometer']">
                                            <p class="specification-value"> {{ assetInf['odometer_display'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Axles: </p>
                                        <template v-if="assetInf['axleName']">
                                            <p class="specification-value"> {{ assetInf['axleName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Frame Type:  </p>
                                        <template v-if="assetInf['frameName']">
                                            <p class="specification-value"> {{ assetInf['frameName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Tire Config: </p>
                                        <template v-if="assetInf['tireConfig']">
                                            <p class="specification-value"> {{ assetInf['tireConfig'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Dimensions: </p>
                                        <template v-if="assetInf['dimensions']">
                                            <p class="specification-value"> {{ assetInf['dimensions'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div> -->
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Stock#: </p>
                                        <template v-if="assetInf['unit']">
                                            <p class="specification-value"> {{ assetInf['unit'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>

                                    <div class="spec-info-main">
                                        <p class="specification-label"> VIN: </p>
                                        <p class="specification-value"> {{ assetInf['vin_no'] }} </p>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Year: </p>
                                        <template v-if="assetInf['year']">
                                            <p class="specification-value"> {{ assetInf['year'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Make: </p>
                                        <template v-if="assetInf['make_name']">
                                            <p class="specification-value"> {{ assetInf['make_name'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Model: </p>
                                        <template v-if="assetInf['model_name']">
                                            <p class="specification-value"> {{ assetInf['model_name'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Miles/Hrs: </p>
                                        <template v-if="assetInf['odometer']">
                                            <p class="specification-value"> {{ assetInf['odometer_display'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Engine Make: </p>
                                        <template v-if="assetInf['engine_make']">
                                            <p class="specification-value"> {{ assetInf['engine_make'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Engine Model: </p>
                                        <template v-if="assetInf['engine_model']">
                                            <p class="specification-value"> {{ assetInf['engine_model'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Engine HP: </p>
                                        <template v-if="assetInf['engine_hp']">
                                            <p class="specification-value"> {{ assetInf['engine_hp'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Transmission Make: </p>
                                        <template v-if="assetInf['transMakeName']">
                                            <p class="specification-value"> {{ assetInf['transMakeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Transmission Model: </p>
                                        <template v-if="assetInf['transModelName']">
                                            <p class="specification-value"> {{ assetInf['transModelName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Color: </p>
                                        <template v-if="assetInf['exteriorColor']">
                                            <p class="specification-value"> {{ assetInf['exteriorColor'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Brake Type: </p>
                                        <template v-if="assetInf['brakeTypeName']">
                                            <p class="specification-value"> {{ assetInf['brakeTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Rear Door Type: </p>
                                        <template v-if="assetInf['rearDoorTypeName']">
                                            <p class="specification-value"> {{ assetInf['rearDoorTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Skirt: </p>
                                        <template v-if="assetInf['skirt']">
                                            <p class="specification-value"> {{ assetInf['skirt'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Exterior Width: </p>
                                        <template v-if="assetInf['exteriorWidth']">
                                            <p class="specification-value"> {{ assetInf['exteriorWidth'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Exterior Length: </p>
                                        <template v-if="assetInf['exteriorLength']">
                                            <p class="specification-value"> {{ assetInf['exteriorLength'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Apu: </p>
                                        <template v-if="assetInf['apuName']">
                                            <p class="specification-value"> {{ assetInf['apuName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Body Notes: </p>
                                        <template v-if="assetInf['bodyNoteName']">
                                            <p class="specification-value"> {{ assetInf['bodyNoteName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> PTO: </p>
                                        <template v-if="assetInf['ptoName']">
                                            <p class="specification-value"> {{ assetInf['ptoName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Liftgate: </p>
                                        <template v-if="assetInf['liftgateName']">
                                            <p class="specification-value"> {{ assetInf['liftgateName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Apu Model: </p>
                                        <template v-if="assetInf['apuModelName']">
                                            <p class="specification-value"> {{ assetInf['apuModelName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Liftgate Model: </p>
                                        <template v-if="assetInf['liftgateModelName']">
                                            <p class="specification-value"> {{ assetInf['liftgateModelName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Concession No: </p>
                                        <template v-if="assetInf['dtnaConcessionNo']">
                                            <p class="specification-value"> {{ assetInf['dtnaConcessionNo'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Order Receive Date: </p>
                                        <template v-if="assetInf['dtnaOrderReceiveDate']">
                                            <p class="specification-value"> {{ assetInf['dtnaOrderReceiveDate'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Schedule Build Date : </p>
                                        <template v-if="assetInf['dtnaScheduleBuildDate']">
                                            <p class="specification-value"> {{ assetInf['dtnaScheduleBuildDate'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Project Delivery Date: </p>
                                        <template v-if="assetInf['dtnaProjDelDate']">
                                            <p class="specification-value"> {{ assetInf['dtnaProjDelDate'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                </div>
                                <div class="specification-right-section">
                                    <!-- <div class="spec-info-main">
                                        <p class="specification-label"> APU Hours: </p>
                                        <template v-if="assetInf['apuHours']">
                                            <p class="specification-value"> {{ assetInf['apuHours'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> GVW: </p>
                                        <template v-if="assetInf['gvw']">
                                            <p class="specification-value"> {{ assetInf['gvw'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Reefer Hours: </p>
                                        <template v-if="assetInf['reeferHours']">
                                            <p class="specification-value"> {{ assetInf['reeferHours'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Trailer Type: </p>
                                        <template v-if="assetInf['trailerTypeName']">
                                            <p class="specification-value"> {{ assetInf['trailerTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> GVW Type: </p>
                                        <template v-if="assetInf['gvwType']">
                                            <p class="specification-value"> {{ assetInf['gvwType'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div> -->
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Rear Ratio: </p>
                                        <template v-if="assetInf['rearRatio']">
                                            <p class="specification-value"> {{ assetInf['rearRatio'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Wheelbase: </p>
                                        <template v-if="assetInf['wheelbase']">
                                            <p class="specification-value"> {{ assetInf['wheelbase'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> GVW: </p>
                                        <template v-if="assetInf['gvw']">
                                            <p class="specification-value"> {{ assetInf['gvw'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Body Type: </p>
                                        <template v-if="assetInf['bodyTypeName']">
                                            <p class="specification-value"> {{ assetInf['bodyTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Cab Type: </p>
                                        <template v-if="assetInf['cabTypeName']">
                                            <p class="specification-value"> {{ assetInf['cabTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Cab Style: </p>
                                        <template v-if="assetInf['cabStyleName']">
                                            <p class="specification-value"> {{ assetInf['cabStyleName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Warranty: </p>
                                        <template v-if="assetInf['warranty_type_name']">
                                            <p class="specification-value"> {{ assetInf['warranty_type_name'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Engine Brake: </p>
                                        <template v-if="assetInf['engine_brake']">
                                            <p class="specification-value"> {{ assetInf['engine_brake'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Axle Type: </p>
                                        <template v-if="assetInf['axleName']">
                                            <p class="specification-value"> {{ assetInf['axleName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> APU Hours: </p>
                                        <template v-if="assetInf['apuHours']">
                                            <p class="specification-value"> ${{ assetInf['apuHours'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> $0 </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Suspension: </p>
                                        <template v-if="assetInf['suspensionName']">
                                            <p class="specification-value"> {{ assetInf['suspensionName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Tires: </p>
                                        <template v-if="assetInf['tireSize']">
                                            <p class="specification-value"> {{ assetInf['tireSize'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Rear Wheels: </p>
                                        <template v-if="assetInf['rearWheels']">
                                            <p class="specification-value"> {{ assetInf['rearWheels'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Reefer Serial: </p>
                                        <template v-if="assetInf['reeferSerial']">
                                            <p class="specification-value"> {{ assetInf['reeferSerial'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Floor Type: </p>
                                        <template v-if="assetInf['floorTypeName']">
                                            <p class="specification-value"> {{ assetInf['floorTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Roof Type:  </p>
                                        <template v-if="assetInf['roofTypeName']">
                                            <p class="specification-value"> {{ assetInf['roofTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Body Type: </p>
                                        <template v-if="assetInf['bodyTypeName']">
                                            <p class="specification-value"> {{ assetInf['bodyTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['is_truck'] == 'trailer'" class="spec-info-main">
                                        <p class="specification-label"> Exterior Height: </p>
                                        <template v-if="assetInf['exteriorHeight']">
                                            <p class="specification-value"> {{ assetInf['exteriorHeight'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <!-- Condition Type Fields -->
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Inv. Order Type: </p>
                                        <template v-if="assetInf['invOrderType']">
                                            <p class="specification-value"> {{ assetInf['invOrderType'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Body Color: </p>
                                        <template v-if="assetInf['bodyColorName']">
                                            <p class="specification-value"> {{ assetInf['bodyColorName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Liftgate Capacity: </p>
                                        <template v-if="assetInf['liftgateCapacityName']">
                                            <p class="specification-value"> {{ assetInf['liftgateCapacityName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Body Length: </p>
                                        <template v-if="assetInf['bodyLengthName']">
                                            <p class="specification-value"> {{ assetInf['bodyLengthName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Body Make: </p>
                                        <template v-if="assetInf['bodyMakeName']">
                                            <p class="specification-value"> {{ assetInf['bodyMakeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Body Model: </p>
                                        <template v-if="assetInf['bodyModelName']">
                                            <p class="specification-value"> {{ assetInf['bodyModelName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Cust No: </p>
                                        <template v-if="assetInf['dtnaCustNo']">
                                            <p class="specification-value"> {{ assetInf['dtnaCustNo'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> PTO Prov Indc: </p>
                                        <template v-if="assetInf['dtnaPtoProvIndc']">
                                            <p class="specification-value"> {{ assetInf['dtnaPtoProvIndc'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Order Release Date: </p>
                                        <template v-if="assetInf['dtnaOrderReleaseDate']">
                                            <p class="specification-value"> {{ assetInf['dtnaOrderReleaseDate'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div v-if="assetInf['condition_name'] == 'New'" class="spec-info-main">
                                        <p class="specification-label"> Request Delivery Date: </p>
                                        <template v-if="assetInf['dtnaRequestDeliveryDate']">
                                            <p class="specification-value"> {{ assetInf['dtnaRequestDeliveryDate'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <footer :key="'footer ' + index" class="footer">
                                <div class="vvg-logo">
                                    <img src="/static/images/logo.png" alt="Velocity Vehicle Group">
                                    <p class="copyright-text">  <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{assetInf['systemYear']}} Velocity Vehicle Group </p>
                                </div>
                            </footer>
                            <div :key="'page ' + index" style="break-after:page"></div>
                            <div :key="'SpecTable-Header-section2 ' + index" class="SpecTable-Header-section">
                                <div class="specification-table-left">
                                    <template v-if="assetInf['city_name'] != ''">
                                        <h4> {{assetInf['company_full_name']}} - {{assetInf['city_name']}}</h4>
                                    </template>
                                    <template v-else>
                                        <h4> {{assetInf['company_full_name']}}</h4>
                                    </template>
                                    <template v-if="assetInf['city_name'] != '' && assetInf['state_code'] != ''">
                                        <p> {{assetInf['city_name']}}, {{assetInf['state_code']}} </p>
                                    </template>
                                    <template v-if="isShowCompanyOrUserDetails">
                                        <template v-if="companyInfo[index]['name'] != '' || companyInfo[index]['contact_no'] != '' || companyInfo[index]['email'] != ''">
                                            <template v-if="publicLoginId == 0">
                                                <h4><br/>Location Details : </h4>
                                            </template>
                                            <template v-else>
                                                <h4><br/>Salesman Contact Details : </h4>
                                            </template>
                                            <template v-if="companyInfo[index]['name'] != ''">
                                                <p class="name-field"> {{companyInfo[index]['name']}} </p>
                                            </template>
                                            <template v-if="companyInfo[index]['contact_no'] != ''">
                                                <p class="fone-field"> {{companyInfo[index]['contact_no']}} </p>
                                            </template>
                                            <template v-if="companyInfo[index]['email'] != ''">
                                                <a class="email-field"  :href="'mailto:'+companyInfo[index]['email']"> {{companyInfo[index]['email']}} </a>
                                            </template>
                                        </template>
                                    </template>
                                    <!-- <template v-if="isShowCompanyOrUserDetails">
                                        <template v-if="assetInf['company_full_name'] != '' || assetInf['company_phone'] != '' || assetInf['company_email'] != ''">
                                            <h4><br/>Department Contact Details : </h4>
                                            <template v-if="assetInf['company_full_name'] != ''">
                                                <p class="name-field"> {{assetInf['company_full_name']}} </p>
                                            </template>
                                            <template v-if="assetInf['company_phone'] != ''">
                                                <p class="fone-field"> {{assetInf['company_phone']}} </p>
                                            </template>
                                            <template v-if="assetInf['company_email'] != ''">
                                                <a class="email-field"  :href="'mailto:'+assetInf['company_email']"> {{assetInf['company_email']}} </a>
                                            </template>
                                        </template>
                                    </template> -->
                                </div>

                                <div class="specification-table-right">
                                    <!-- <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales"> </a> -->
                                    <a href="javascript:void(0)" target="_blank"> <img :src="assetInf['company_logo']" :alt="assetInf['company_logo']" class="company-logo"> </a>
                                </div>
                            </div>

                            <!--second page-->
                            <template v-if="assetInf.docs && assetInf.docs.length > 0">
                                <!--image section-->
                                <template v-if="assetInf.docs && assetInf.docs.length > 0">
                                    <div :key="'btm-img-section ' + index" class="btm-img-section">
                                        <template v-for="(doc, docIndex) in assetInf.docs">
                                            <template v-for="(docInner, indexInner) in assetInf.docs[docIndex]">
                                                <template v-if="docInner['is_360_image'] == 0">
                                                  <div :key="'btm-internal-img ' + indexInner" class="btm-internal-img">
                                                    <img :src="docInner['media_path']" alt="Velocity Vehicle Group">
                                                  </div>
                                                </template>
                                                <template v-else>
                                                  <a class="link-360-block" :href="docInner['360_image_redirection_link']">
                                                    <div :key="'btm-internal-img ' + indexInner" class="btm-internal-img img-360-section" :style="{ 'background-image': 'url(' + docInner['media_path'] + ')' }">
                                                      <div class="img-360-link-section">
                                                        <p>Click To View</p>
                                                        <img src="/static/images/360-image-icon.png" alt="Velocity Truck Centers">
                                                      </div>
                                                    </div>
                                                  </a>
                                                </template>
                                            </template>
                                        </template>
                                    </div>
                                </template>
                                <!--image section-->
                            </template>
                            <footer :key="'footer2 ' + index" class="footer">
                                <div class="vvg-logo">
                                    <img src="/static/images/logo.png" alt="Velocity Vehicle Group">
                                    <p class="copyright-text"> <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{assetInf['systemYear']}} Velocity Vehicle Group </p>
                                </div>
                            </footer>
                            <!-- </div> -->
                            <!-- Div Structure With Print -->
                            <table :key="'print-table ' + index" class="print-table" border="0" cellpadding="0" cellspacing="0" width="100%" style="font-family:Arial, Helvetica, sans-serif; background-color: #ffffff; border-collapse: collapse;" align="center">
                                <tr>
                                    <td align="left" valign="top">
                                        <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                            <!---header section--->
                                            <tr>
                                                <td align="left" valign="top">
                                                    <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                        <tr>
                                                            <td align="left" valign="top">
                                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                    <template v-if="assetInf['city_name'] != ''">
                                                                        <tr>
                                                                            <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{assetInf['company_full_name']}} - {{assetInf['city_name']}}</td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-else>
                                                                        <tr>
                                                                            <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{assetInf['company_full_name']}} </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="assetInf['city_name'] != '' && assetInf['state_code'] != ''">
                                                                        <tr>
                                                                            <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{assetInf['city_name']}}, {{assetInf['state_code']}} </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="isShowCompanyOrUserDetails">
                                                                        <template v-if="companyInfo[index]['name'] != '' || companyInfo[index]['contact_no'] != '' || companyInfo[index]['email'] != ''">
                                                                            <template v-if="companyInfo[index]['name'] != ''">
                                                                                <tr>
                                                                                    <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px; font-weight:bold;"> {{companyInfo[index]['name']}} </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-if="companyInfo[index]['contact_no'] != ''">
                                                                                <tr>
                                                                                    <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{companyInfo[index]['contact_no']}} </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-if="companyInfo[index]['email'] != ''">
                                                                                <tr>
                                                                                    <td style="font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> <a style="color:#098de1; text-decoration: none;" :href="'mailto:' + companyInfo[index]['email']"> {{companyInfo[index]['email']}} </a> </td>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </table>
                                                            </td>
                                                            <td align="right" valign="top">
                                                                <!-- <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales" style="width:40%; height:auto;"> </a> -->
                                                                <a href="javascript:void(0)" target="_blank"> <img :src="assetInf['company_logo']" :alt="assetInf['company_logo']" class="company-logo" style="/* width:40%; */ height:auto;"> </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" align="left" valign="top" height="30px" style="border-bottom:1px solid #dddddd;"></td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" align="left" valign="top" height="30px"> </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <!---header section--->

                                            <!--middle section-->
                                            <tr>
                                                <td align="left" valign="top">
                                                    <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                        <tr>
                                                            <td align="left" valign="top" width="63%">
                                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                    <tr>
                                                                        <td align="left" valign="top" style="color: #c9202b; font-size: 16px; line-height: 20px; font-family:Arial, Helvetica, sans-serif; font-weight: bold;"> STOCK #
                                                                            <template v-if="assetInf['unit']">
                                                                                {{assetInf['unit']}}
                                                                            </template>
                                                                            <template v-else>
                                                                                -
                                                                            </template>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left" valign="top" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial, Helvetica, sans-serif; padding-top: 3px;"> {{ assetInf['year'] }} {{ assetInf['make_name'] }} {{ assetInf['model_name'] }} </td>
                                                                    </tr>
                                                                    <template v-if="assetInf['media_path']">
                                                                        <tr>
                                                                            <td align="left" valign="top"> <img :src="assetInf['media_path']" alt="Central California Truck and Trailer Sales" style="width:100%;"> </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-else-if="assetInf['media_url']">
                                                                        <tr>
                                                                            <td align="left" valign="top"> <img :src="assetInf['media_url']" alt="Central California Truck and Trailer Sales" style="width:100%;"> </td>
                                                                        </tr>
                                                                    </template>
                                                                </table>
                                                            </td>
                                                            <td align="left" valign="top" width="7%"></td>

                                                            <td align="left" valign="top" width="30%">
                                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                    <tr>
                                                                        <td align="left" vliagn="top" width="100%" height="20"> </td>
                                                                    </tr>
                                                                    <template v-if="assetInf['price_paid']">
                                                                        <tr>
                                                                            <td align="right" valign="top" style="color: #c9202b; font-size: 16px; line-height: 20px; font-family:Arial, Helvetica, sans-serif; font-weight: bold; text-align: right;"> {{ assetInf['price_paid_format'] }} </td>
                                                                        </tr>
                                                                    </template>
                                                                    <tr>
                                                                        <td align="left" vliagn="top" width="100%" height="10" style="font-size: 10px; line-height: 10px;"> </td>
                                                                    </tr>
                                                                    <template v-if="isShowCompanyOrUserDetails">
                                                                        <template v-if="assetInf['description'] != NULL">
                                                                            <tr>
                                                                                <td class="bg-color-dddddd" align="left" valign="top" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial, Helvetica, sans-serif; background-color: #dddddd; padding-top:10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px; color-adjust: exact; -webkit-print-color-adjust: exact; print-color-adjust: exact; "> DESCRIPTION  </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" style="color: #333333; font-size: 16px; line-height: 20px; font-family:Arial, Helvetica, sans-serif; padding-top: 8px;">
                                                                                    {{assetInf['description']}}
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <!-- <tr>
                                                                            <td class="bg-color-dddddd" align="left" valign="top" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial, Helvetica, sans-serif; background-color: #dddddd; padding-top:10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px; color-adjust: exact; -webkit-print-color-adjust: exact; print-color-adjust: exact; "> DESCRIPTION  </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" vliagn="top" width="100%" height="15" style="font-size: 15px; line-height: 15px;"> </td>
                                                                        </tr>
                                                                        <template v-if="companyInfo[index]['name'] != '' || companyInfo[index]['contact_no'] != '' || companyInfo[index]['email'] != ''">
                                                                            <template v-if="companyInfo[index]['name'] != ''">
                                                                                <tr>
                                                                                    <td align="left" valign="top" style="color: #333333; font-size: 15px; line-height: 18px; font-family:Arial, Helvetica, sans-serif; font-weight: bold;"> {{companyInfo[index]['name']}}  </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-if="companyInfo[index]['contact_no'] != ''">
                                                                                <tr>
                                                                                    <td align="left" valign="top" style="color: #333333; font-size: 16px; line-height: 20px; font-family:Arial, Helvetica, sans-serif; padding-top: 8px;"> {{companyInfo[index]['contact_no']}}  </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-if="companyInfo[index]['email'] != ''">
                                                                                <tr>
                                                                                    <td style="font-size: 16px; line-height: 20px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> <a style="color:#098de1; padding-top: 8px; text-decoration: none;" :href="'mailto:' + companyInfo[index]['email']"> {{companyInfo[index]['email']}} </a> </td>
                                                                                </tr>
                                                                            </template>
                                                                        </template> -->
                                                                    </template>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>

                                            <!--middle section-->
                                            <tr>
                                                <td align="left" vliagn="top" width="100%" height="40" style="font-size: 40px; line-height: 40px;"> </td>
                                            </tr>

                                            <!--Specification table-->
                                            <tr>
                                                <td align="left" valign="top" width="100%">
                                                    <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                        <tr>
                                                            <td class="bg-color-dddddd" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial, Helvetica, sans-serif;  background-color: #dddddd; padding-top:10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px; color-adjust: exact; -webkit-print-color-adjust: exact; print-color-adjust: exact; ">  SPECIFICATIONS </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left" valign="top" width="100%">
                                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                    <tr>
                                                                        <td colspan="3" align="left" valign="top" width="100%" height="5px" style="font-size: 5px; line-height: 5px;"> </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td align="left" valign="top" width="49%">
                                                                            <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; border-right: 1px solid #dddddd; padding-right: 30px;">
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Stock#: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['unit']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['unit'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> VIN: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['vin_no'] }} </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Year: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['year']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['year'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Make: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['make_name']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['make_name'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Model: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['model_name']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['model_name'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Miles/Hrs: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['odometer_display']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['odometer_display'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Engine Make: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['engine_make']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['engine_make'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Engine Model: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['engine_model']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['engine_model'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Engine HP: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['engine_hp']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['engine_hp'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Transmission Make: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['transMakeName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['transMakeName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Transmission Model: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['transModelName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['transModelName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>

                                                                                <!-- <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Price: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['price_paid']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['price_paid_format'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  $0 </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Stock#: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['unit']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['unit'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> VIN: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['vin_no'] }} </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Axles: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['axleName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['axleName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Suspension: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['suspensionName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['suspensionName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Frame Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['frameName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['frameName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Tire Config: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['tireConfig']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['tireConfig'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Tires: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['tireSize']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['tireSize'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Color: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['exteriorColor']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['exteriorColor'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Dimensions: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['dimensions']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['dimensions'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr> -->
                                                                            </table>
                                                                        </td>

                                                                        <td align="left" valign="top" width="4%"> </td>

                                                                        <td align="left" valign="top" width="47%">
                                                                            <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Rear Ratio: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['rearRatio']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['rearRatio'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Wheelbase: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['wheelbase']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['wheelbase'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> GVW: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['gvw']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['gvw'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Body Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['bodyTypeName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['bodyTypeName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Cab Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['cabTypeName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['cabTypeName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Cab Style: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['cabStyleName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['cabStyleName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Warranty: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['warranty_type_name']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['warranty_type_name'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Engine Brake: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['engine_brake']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['engine_brake'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Axle Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['axleName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['axleName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> APU Hours: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['apuHours']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  ${{ assetInf['apuHours'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  $0 </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <!-- <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> APU Hours: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['apuHours']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  ${{ assetInf['apuHours'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> GVW: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['gvw']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['gvw'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Reefer Hours: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['reeferHours']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInf['reeferHours'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Reefer Serial: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['reeferSerial']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['reeferSerial'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Floor Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['floorTypeName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['floorTypeName'] }}</td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Roof Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['roofTypeName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['roofTypeName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Body Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['bodyTypeName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['bodyTypeName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Trailer Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['trailerTypeName']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['trailerTypeName'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> GVW Type: </td>
                                                                                    <td align="left" valign="top" width="2%"></td>
                                                                                    <template v-if="assetInf['gvwType']">
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInf['gvwType'] }} </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> - </td>
                                                                                    </template>
                                                                                </tr> -->
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <!--Specification table-->

                                            <!--Footer-->
                                            <div class="footer-table-section">
                                                <tr>
                                                    <td align="left" valign="top" width="100%" height="60"> </td>
                                                </tr>

                                                <tr>
                                                    <td align="center" valign="top" width="100%"> <img style="width:170px;" src="/static/images/logo.png" alt="Velocity Vehicle Group"> </td>
                                                </tr>

                                                <tr>
                                                    <td align="left" valign="top" width="100%" height="20"> </td>
                                                </tr>

                                                <tr>
                                                    <td align="center" valign="top" width="100%" style="font-size: 13px; line-height: 17px; color: #33333; font-family:Arial, Helvetica, sans-serif; font-style: italic;"> <span style="font-size: 10px;" with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{assetInf['systemYear']}} Velocity Vehicle Group </td>
                                                </tr>
                                            </div>
                                            <!--Footer-->

                                            <!-- <div class="page-break"> </div> -->

                                            <!---header section--->
                                            <tr>
                                                <td align="left" valign="top">
                                                    <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                        <tr>
                                                            <td align="left" valign="top">
                                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                    <template v-if="assetInf['city_name'] != ''">
                                                                        <tr>
                                                                            <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{assetInf['company_full_name']}} - {{assetInf['city_name']}} </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-else>
                                                                        <tr>
                                                                            <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{assetInf['company_full_name']}} </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="assetInf['city_name'] != '' && assetInf['state_code'] != ''">
                                                                        <tr>
                                                                            <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{assetInf['city_name']}}, {{assetInf['state_code']}}Fontana, CA </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="isShowCompanyOrUserDetails">
                                                                        <template v-if="companyInfo[index]['name'] != '' || companyInfo[index]['contact_no'] != '' || companyInfo[index]['email'] != ''">
                                                                            <template v-if="companyInfo[index]['name'] != ''">
                                                                                <tr>
                                                                                    <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px; font-weight:bold;"> {{companyInfo[index]['name']}} </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-if="companyInfo[index]['contact_no'] != ''">
                                                                                <tr>
                                                                                    <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{companyInfo[index]['contact_no']}} </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-if="companyInfo[index]['email'] != ''">
                                                                                <tr>
                                                                                    <td style="font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> <a style="color:#098de1; text-decoration: none;" :href="'mailto:' + companyInfo[index]['email']"> {{companyInfo[index]['email']}} </a> </td>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </table>
                                                            </td>
                                                            <td align="right" valign="top">
                                                                <!-- <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales" style="width:40%; height:auto;"> </a> -->
                                                                <a href="javascript:void(0)" target="_blank"> <img :src="assetInf['company_logo']" :alt="assetInf['company_logo']" class="company-logo" style="/* width:40%; */ height:auto;"> </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" align="left" valign="top" height="15px" style="border-bottom:1px solid #dddddd;"></td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" align="left" valign="top" height="15px"> </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <!---header section--->

                                            <!--image section-->
                                            <template v-if="assetInf.docs && assetInf.docs.length > 0">
                                                <tr>
                                                    <td slign="left" valign="top">
                                                        <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                            <template v-for="(doc, index) in assetInf.docs">
                                                                <tr :key="'tr ' + index">
                                                                    <template v-for="(docInner, indexInner) in assetInf.docs[index]">
                                                                        <td :key="'indexInner td ' + indexInner" align="left" valign="top" width="32%">
                                                                            <img :src="docInner['thumb_url']" alt="Velocity Vehicle Group Deal Manager" style="width: 100%;">
                                                                        </td>
                                                                        <template v-if="indexInner + 1 != assetInf.docs[index].length">
                                                                            <td :key="'indexInner Space td ' + indexInner" align="left" valign="top" width="2%"></td>
                                                                        </template>
                                                                    </template>
                                                                </tr>
                                                                <tr :key="'space tr ' + index">
                                                                    <td colspan="5" align="left" valign="top" width="100%" height="15"></td>
                                                                </tr>
                                                            </template>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </template>
                                            <!--image section-->

                                            <!--Footer-->
                                            <div class="footer-table-section">
                                                <tr>
                                                    <td align="left" valign="top" width="100%" height="20"> </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="top" width="100%"> <img style="width:170px;" src="/static/images/logo.png" alt="Velocity Vehicle Group Deal Manager"> </td>
                                                </tr>
                                                <tr>
                                                    <td align="left" valign="top" width="100%" height="10"> </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="top" width="100%" style="font-size: 13px; line-height: 17px; color: #33333; font-family:Arial, Helvetica, sans-serif; font-style: italic;"> <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{ assetInf['systemYear']}} Velocity Vehicle Group Deal Manager </td>
                                                </tr>
                                            </div>
                                            <!--Footer-->
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <div :key="'page2 ' + index" style="break-after:page"></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    Vue.component("app-footer", Footer);

    export default {
        name: 'AssetSpecSheet',
        components: {
        },
        mixins: [commonMixins],
        data() {
            return {
                assetInfo: [],
                docs: [],
                companyInfo: [],
                publicLoginId: 0,
                isShowCompanyOrUserDetails: true,
                showTrailerSpec : false,
                isNewConditionType : false,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            self.publicLoginId = 0;
            self.isShowCompanyOrUserDetails = true;
            if (typeof (self.$route.query.id) != 'undefined' && self.$route.query.id != "" && self.$route.query.id != 0) {
                if (typeof (self.$route.query.uid) != 'undefined' && self.$route.query.uid != "" && self.$route.query.uid != 0) {
                    self.publicLoginId = self.$route.query.uid;
                }
                /*if (self.$route.query.key == undefined) {
                    self.isShowCompanyOrUserDetails = false;
                }*/
                self.getAssetData(self.$route.query.id);
            }
        },
        methods: {
            getAssetData(id) {
                var config = {
                    params: {
                        asset_id: id,
                        publicLoginId: self.publicLoginId,
                    }
                };
                HTTP.get('/specsheetassets/get', config)
                    .then(response => {
                        self.assetInfo = response.data.content.assetInfo;
                        self.docs = response.data.content.assetInfo[0].docs;
                        self.companyInfo = response.data.content.companyInfo;
                        for(var i=0; i<self.assetInfo.length; i++) {
                            if (self.assetInfo[i].dtnaOrderReceiveDate != null && self.assetInfo[i].dtnaOrderReceiveDate != '') {
                                self.assetInfo[i].dtnaOrderReceiveDate = self.datePickerDateFormat(self.assetInfo[i].dtnaOrderReceiveDate);
                            }
                            if (self.assetInfo[i].dtnaOrderReleaseDate != null && self.assetInfo[i].dtnaOrderReleaseDate != '') {
                                self.assetInfo[i].dtnaOrderReleaseDate = self.datePickerDateFormat(self.assetInfo[i].dtnaOrderReleaseDate);
                            }
                            if (self.assetInfo[i].dtnaScheduleBuildDate != null && self.assetInfo[i].dtnaScheduleBuildDate != '') {
                                self.assetInfo[i].dtnaScheduleBuildDate = self.datePickerDateFormat(self.assetInfo[i].dtnaScheduleBuildDate);
                            }
                            if (self.assetInfo[i].dtnaRequestDeliveryDate != null && self.assetInfo[i].dtnaRequestDeliveryDate != '') {
                                self.assetInfo[i].dtnaRequestDeliveryDate = self.datePickerDateFormat(self.assetInfo[i].dtnaRequestDeliveryDate);
                            }
                            if (self.assetInfo[i].dtnaProjDelDate != null && self.assetInfo[i].dtnaProjDelDate != '') {
                                self.assetInfo[i].dtnaProjDelDate = self.datePickerDateFormat(self.assetInfo[i].dtnaProjDelDate);
                            }
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
        },
    }
</script>
<style scoped>
.btm-internal-img.img-360-section {
  padding: 8px;
  border: 1px solid #e4ebf3;
  background-size:cover;
  background-repeat:no-repeat;
  cursor: pointer;
  text-decoration:none !important;
}
.btm-internal-img.img-360-section .img-360-link-section {
  display: block;
  text-align:center;
  margin-top: 5rem;
}

.btm-internal-img.img-360-section .img-360-link-section p{
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}

.btm-internal-img.img-360-section .img-360-link-section img{
  width: 38%;
  margin: 0 auto;
  display: block;
  float: none;
}
</style>
