<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="admin-page-content">
                <div class="forms-container add_user_forms_container vtc-dealer-forms view-public-inv">
                        <!-- Div Structure Without Print -->
                        <!-- <div class="specification-table"> -->
                            <!--header section-->
                            <div class="SpecTable-Header-section">
                                <div class="specification-table-left">
                                    <template v-if="assetInfo['city_name'] != ''">
                                        <h4> {{assetInfo['company_full_name']}} - {{assetInfo['city_name']}}</h4>
                                    </template>
                                    <template v-else>
                                        <h4> {{assetInfo['company_full_name']}} </h4>
                                    </template>
                                    <template v-if="assetInfo['city_name'] != '' && assetInfo['state_code'] != ''">
                                        <p> {{assetInfo['city_name']}}, {{assetInfo['state_code']}} </p>
                                    </template>
                                        <template v-if="companyInfo['name'] != '' || companyInfo['contact_no'] != '' || companyInfo['email'] != ''">
                                            <template v-if="publicLoginId == 0">
                                                <h4><br/>Location Details : </h4>
                                            </template>
                                            <template v-else>
                                                <h4><br/>Salesman Contact Details : </h4>
                                            </template>
                                            <template v-if="companyInfo['name'] != ''">
                                                <p class="name-field"> {{companyInfo['name']}} </p>
                                            </template>
                                            <template v-if="companyInfo['contact_no'] != ''">
                                                <p class="fone-field"> {{companyInfo['contact_no']}} </p>
                                            </template>
                                            <template v-if="companyInfo['email'] != ''">
                                                <a class="email-field"  :href="'mailto:'+companyInfo['email']"> {{companyInfo['email']}} </a>
                                            </template>
                                        </template>
                                </div>

                                <div class="specification-table-right">
                                    <!-- <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales"> </a> -->
                                    <a href="javascript:void(0)" target="_blank"> <img :src="assetInfo['company_logo']" :alt="assetInfo['company_logo']" class="company-logo"> </a>
                                </div>
                            </div>
                            <!--header section-->
                            <div class="stock-detail-section">
                                <div class="stock-detail-section-left">
                                    <h5> STOCK
                                        <template v-if="assetInfo['unit']">
                                            {{assetInfo['unit']}}
                                        </template>
                                        <template v-else>
                                            -
                                        </template>
                                    </h5>
                                    <p> {{ assetInfo['year'] }} {{ assetInfo['make_name'] }} {{ assetInfo['model_name'] }} </p>
                                    <template v-if="assetInfo['media_path']">
                                        <img :src="assetInfo['media_path']" alt="Central California Truck and Trailer Sales">
                                    </template>
                                    <template v-else-if="assetInfo['media_url']">
                                        <img :src="assetInfo['media_url']" alt="Central California Truck and Trailer Sales">
                                    </template>
                                </div>

                                <div class="stock-detail-section-right">
                                    <template v-if="assetInfo['retail_price']">
                                        <p class="amount-section"> {{assetInfo['retail_price_format']}} </p>
                                    </template>
                                    <template v-if="assetInfo['description'] != NULL">
                                        <p class="des-text"> DESCRIPTION </p>
                                        <p class="name-field">{{assetInfo['description']}}</p>
                                    </template>
                                </div>
                            </div>
                            <div class="specification-detail">
                                <h4> Specifications </h4>
                                <div class="specification-left-section">
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Stock#: </p>
                                        <template v-if="assetInfo['unit']">
                                            <p class="specification-value"> {{ assetInfo['unit'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>

                                    <div class="spec-info-main">
                                        <p class="specification-label"> VIN: </p>
                                        <p class="specification-value"> {{ assetInfo['vin_no'] }} </p>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Year: </p>
                                        <template v-if="assetInfo['year']">
                                            <p class="specification-value"> {{ assetInfo['year'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Make: </p>
                                        <template v-if="assetInfo['make_name']">
                                            <p class="specification-value"> {{ assetInfo['make_name'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Model: </p>
                                        <template v-if="assetInfo['model_name']">
                                            <p class="specification-value"> {{ assetInfo['model_name'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Miles/Hrs: </p>
                                        <template v-if="assetInfo['odometer']">
                                            <p class="specification-value"> {{ assetInfo['odometer_display'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Engine Make: </p>
                                        <template v-if="assetInfo['engine_make']">
                                            <p class="specification-value"> {{ assetInfo['engine_make'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Engine Model: </p>
                                        <template v-if="assetInfo['engine_model']">
                                            <p class="specification-value"> {{ assetInfo['engine_model'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Engine HP: </p>
                                        <template v-if="assetInfo['engine_hp']">
                                            <p class="specification-value"> {{ assetInfo['engine_hp'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Transmission Make: </p>
                                        <template v-if="assetInfo['transMakeName']">
                                            <p class="specification-value"> {{ assetInfo['transMakeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Transmission Model: </p>
                                        <template v-if="assetInfo['transModelName']">
                                            <p class="specification-value"> {{ assetInfo['transModelName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                </div>
                                <div class="specification-right-section">
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Rear Ratio: </p>
                                        <template v-if="assetInfo['rearRatio']">
                                            <p class="specification-value"> {{ assetInfo['rearRatio'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Wheelbase: </p>
                                        <template v-if="assetInfo['wheelbase']">
                                            <p class="specification-value"> {{ assetInfo['wheelbase'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> GVW: </p>
                                        <template v-if="assetInfo['gvw']">
                                            <p class="specification-value"> {{ assetInfo['gvw'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Body Type: </p>
                                        <template v-if="assetInfo['bodyTypeName']">
                                            <p class="specification-value"> {{ assetInfo['bodyTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Cab Type: </p>
                                        <template v-if="assetInfo['cabTypeName']">
                                            <p class="specification-value"> {{ assetInfo['cabTypeName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Cab Style: </p>
                                        <template v-if="assetInfo['cabStyleName']">
                                            <p class="specification-value"> {{ assetInfo['cabStyleName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Warranty: </p>
                                        <template v-if="assetInfo['warranty_type_name']">
                                            <p class="specification-value"> {{ assetInfo['warranty_type_name'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Engine Brake: </p>
                                        <template v-if="assetInfo['engine_brake']">
                                            <p class="specification-value"> {{ assetInfo['engine_brake'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> Axle Type: </p>
                                        <template v-if="assetInfo['axleName']">
                                            <p class="specification-value"> {{ assetInfo['axleName'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> - </p>
                                        </template>
                                    </div>
                                    <div class="spec-info-main">
                                        <p class="specification-label"> APU Hours: </p>
                                        <template v-if="assetInfo['apuHours']">
                                            <p class="specification-value"> ${{ assetInfo['apuHours'] }} </p>
                                        </template>
                                        <template v-else>
                                            <p class="specification-value"> $0 </p>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <footer class="footer">
                                <div class="vvg-logo">
                                    <img src="/static/images/logo.png" alt="Velocity Vehicle Group">
                                    <p class="copyright-text"> <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{assetInfo['systemYear']}} Velocity Vehicle Group </p>
                                </div>
                            </footer>
                            <div style="break-after:page"></div>
                            <div class="SpecTable-Header-section">
                                <div class="specification-table-left">
                                    <template v-if="assetInfo['city_name'] != ''">
                                        <h4> {{assetInfo['company_full_name']}} - {{assetInfo['city_name']}}</h4>
                                    </template>
                                    <template v-if="assetInfo['city_name'] != '' && assetInfo['state_code'] != ''">
                                        <p> {{assetInfo['city_name']}}, {{assetInfo['state_code']}} </p>
                                    </template>
                                    <template v-if="companyInfo['name'] != '' && companyInfo['contact_no'] != '' && companyInfo['email'] != ''">
                                        <template v-if="publicLoginId == 0">
                                            <h4><br/>Location Details : </h4>
                                        </template>
                                        <template v-else>
                                            <h4><br/>Salesman Contact Details : </h4>
                                        </template>
                                        <template v-if="companyInfo['name'] != ''">
                                            <p class="name-field"> {{companyInfo['name']}} </p>
                                        </template>
                                        <template v-if="companyInfo['contact_no'] != ''">
                                            <p class="fone-field"> {{companyInfo['contact_no']}} </p>
                                        </template>
                                        <template v-if="companyInfo['email'] != ''">
                                            <a class="email-field"  :href="'mailto:'+companyInfo['email']"> {{companyInfo['email']}} </a>
                                        </template>
                                    </template>
                                </div>
                                <div class="specification-table-right">
                                    <!-- <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales"> </a> -->
                                    <a href="javascript:void(0)" target="_blank"> <img :src="assetInfo['company_logo']" :alt="assetInfo['company_logo']" class="company-logo"> </a>
                                </div>
                            </div>
                            <!--second page-->
                            <template v-if="docs && docs.length > 0">
                                <!--image section-->
                                <template v-if="docs && docs.length > 0">
                                    <div class="btm-img-section">
                                        <template v-for="(doc, index) in docs">
                                            <template v-for="(docInner, indexInner) in docs[index]">
                                                <div class="btm-internal-img">
                                                    <img :src="docInner['media_path']" alt="Velocity Vehicle Group">
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </template>
                                <!--image section-->
                            </template>
                            <footer class="footer">
                                <div class="vvg-logo">
                                    <img src="/static/images/logo.png" alt="Velocity Vehicle Group">
                                    <p class="copyright-text"> <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{assetInfo['systemYear']}} Velocity Vehicle Group </p>
                                </div>
                            </footer>
                        <!-- </div> -->
                        <!-- Div Structure With Print -->
                        <table class="print-table" border="0" cellpadding="0" cellspacing="0" width="100%" style="font-family:Arial, Helvetica, sans-serif; background-color: #ffffff; border-collapse: collapse;" align="center">
                            <tr>
                                <td align="left" valign="top">
                                    <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                        <!---header section--->
                                        <tr>
                                            <td align="left" valign="top">
                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                    <tr>
                                                        <td align="left" valign="top">
                                                            <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                <template v-if="assetInfo['city_name'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{assetInfo['company_full_name']}} - {{assetInfo['city_name']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-else>
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{assetInfo['company_full_name']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="assetInfo['city_name'] != '' && assetInfo['state_code'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{assetInfo['city_name']}}, {{assetInfo['state_code']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <tr>
                                                                    <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px; font-weight:bold;"> {{companyInfo['name']}} </td>
                                                                </tr>
                                                                <template v-if="companyInfo['contact_no'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{companyInfo['contact_no']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="companyInfo['email'] != ''">
                                                                    <tr>
                                                                        <td style="font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> <a style="color:#098de1; text-decoration: none;" :href="'mailto:' + companyInfo['email']"> {{companyInfo['email']}} </a> </td>
                                                                    </tr>
                                                                </template>
                                                            </table>
                                                        </td>
                                                        <td align="right" valign="top">
                                                            <!-- <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales" style="width:40%; height:auto;"> </a> -->
                                                            <a href="javascript:void(0)" target="_blank"> <img :src="assetInfo['company_logo']" :alt="assetInfo['company_logo']" class="company-logo" style="/* width:40%; */ height:auto;"> </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" align="left" valign="top" height="30px" style="border-bottom:1px solid #dddddd;"></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" align="left" valign="top" height="30px"> </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <!---header section--->

                                        <!--middle section-->
                                        <tr>
                                            <td align="left" valign="top">
                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                    <tr>
                                                        <td align="left" valign="top" width="63%">
                                                            <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                <tr>
                                                                    <td align="left" valign="top" style="color: #c9202b; font-size: 16px; line-height: 20px; font-family:Arial, Helvetica, sans-serif; font-weight: bold;"> STOCK #
                                                                        <template v-if="assetInfo['unit']">
                                                                            {{assetInfo['unit']}}
                                                                        </template>
                                                                        <template v-else>
                                                                            -
                                                                        </template>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="left" valign="top" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial, Helvetica, sans-serif; padding-top: 3px;"> {{ assetInfo['year'] }} {{ assetInfo['make_name'] }} {{ assetInfo['model_name'] }} </td>
                                                                </tr>
                                                                <template v-if="assetInfo['media_path']">
                                                                    <tr>
                                                                        <td align="left" valign="top"> <img :src="assetInfo['media_path']" alt="Central California Truck and Trailer Sales" style="width:100%;"> </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-else-if="assetInfo['media_url']">
                                                                    <tr>
                                                                        <td align="left" valign="top"> <img :src="assetInfo['media_url']" alt="Central California Truck and Trailer Sales" style="width:100%;"> </td>
                                                                    </tr>
                                                                </template>
                                                            </table>
                                                        </td>
                                                        <td align="left" valign="top" width="7%"></td>

                                                        <td align="left" valign="top" width="30%">
                                                            <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                <tr>
                                                                    <td align="left" vliagn="top" width="100%" height="20"> </td>
                                                                </tr>
                                                                <template v-if="assetInfo['price_paid']">
                                                                    <tr>
                                                                        <td align="right" valign="top" style="color: #c9202b; font-size: 16px; line-height: 20px; font-family:Arial, Helvetica, sans-serif; font-weight: bold; text-align: right;"> {{ assetInfo['price_paid_format'] }} </td>
                                                                    </tr>
                                                                </template>
                                                                <tr>
                                                                    <td align="left" vliagn="top" width="100%" height="10" style="font-size: 10px; line-height: 10px;"> </td>
                                                                </tr>
                                                                <template v-if="assetInfo['description'] != NULL">
                                                                    <tr>
                                                                        <td class="bg-color-dddddd" align="left" valign="top" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial, Helvetica, sans-serif; background-color: #dddddd; padding-top:10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px; color-adjust: exact; -webkit-print-color-adjust: exact; print-color-adjust: exact; "> DESCRIPTION  </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left" valign="top" style="color: #333333; font-size: 16px; line-height: 20px; font-family:Arial, Helvetica, sans-serif; padding-top: 8px;"> {{assetInfo['description']}}  </td>
                                                                    </tr>
                                                                </template>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>

                                        <!--middle section-->
                                        <tr>
                                            <td align="left" vliagn="top" width="100%" height="40" style="font-size: 40px; line-height: 40px;"> </td>
                                        </tr>

                                        <!--Specification table-->
                                        <tr>
                                            <td align="left" valign="top" width="100%">
                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                    <tr>
                                                        <td class="bg-color-dddddd" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial, Helvetica, sans-serif;  background-color: #dddddd; padding-top:10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px; color-adjust: exact; -webkit-print-color-adjust: exact; print-color-adjust: exact; ">  SPECIFICATIONS </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="left" valign="top" width="100%">
                                                            <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                <tr>
                                                                    <td colspan="3" align="left" valign="top" width="100%" height="5px" style="font-size: 5px; line-height: 5px;"> </td>
                                                                </tr>

                                                                <tr>
                                                                    <td align="left" valign="top" width="49%">
                                                                        <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; border-right: 1px solid #dddddd; padding-right: 30px;">
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Stock#: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['unit']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['unit'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>

                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> VIN: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;"> {{ assetInfo['vin_no'] }} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Year: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['year']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['year'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Make: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['make_name']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['make_name'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Model: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['model_name']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['model_name'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Miles/Hrs: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['odometer_display']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['odometer_display'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Engine Make: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['engine_make']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['engine_make'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Engine Model: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['engine_model']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['engine_model'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Engine HP: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['engine_hp']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['engine_hp'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Transmission Make: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['transMakeName']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['transMakeName'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Transmission Model: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['transModelName']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['transModelName'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                        </table>
                                                                    </td>

                                                                    <td align="left" valign="top" width="4%"> </td>

                                                                    <td align="left" valign="top" width="47%">
                                                                        <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Rear Ratio: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['rearRatio']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['rearRatio'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Wheelbase: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['wheelbase']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['wheelbase'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> GVW: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['gvw']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['gvw'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Body Type: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['bodyTypeName']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['bodyTypeName'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Cab Type: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['cabTypeName']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['cabTypeName'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Cab Style: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['cabStyleName']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['cabStyleName'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Warranty: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['warranty_type_name']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['warranty_type_name'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Engine Brake: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['engine_brake']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['engine_brake'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> Axle Type: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['axleName']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  {{ assetInfo['axleName'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  - </td>
                                                                                </template>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="left" valign="top" width="20%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; font-weight: bold; padding-top: 5px; padding-bottom: 5px;"> APU Hours: </td>
                                                                                <td align="left" valign="top" width="2%"></td>
                                                                                <template v-if="assetInfo['apuHours']">
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  ${{ assetInfo['apuHours'] }} </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td align="left" valign="top" width="78%" style="color: #333333; font-size: 14px; line-height: 18px; font-family:Arial,  Helvetica, sans-serif; padding-top: 5px; padding-bottom: 5px;">  $0 </td>
                                                                                </template>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <!--Specification table-->

                                        <!--Footer-->
                                        <div class="footer-table-section">
                                            <tr>
                                                <td align="left" valign="top" width="100%" height="60"> </td>
                                            </tr>

                                            <tr>
                                                <td align="center" valign="top" width="100%"> <img style="width:170px;" src="/static/images/logo.png" alt="Velocity Vehicle Group"> </td>
                                            </tr>

                                            <tr>
                                                <td align="left" valign="top" width="100%" height="20"> </td>
                                            </tr>

                                            <tr>
                                                <td align="center" valign="top" width="100%" style="font-size: 13px; line-height: 17px; color: #33333; font-family:Arial, Helvetica, sans-serif; font-style: italic;"> <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{assetInfo['systemYear']}} Velocity Vehicle Group </td>
                                            </tr>
                                        </div>
                                        <!--Footer-->

                                        <div class="page-break"> </div>

                                        <!---header section--->
                                        <tr>
                                            <td align="left" valign="top">
                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                    <tr>
                                                        <td align="left" valign="top">
                                                            <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                <template v-if="assetInfo['city_name'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{assetInfo['company_full_name']}} - {{assetInfo['city_name']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-else>
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{assetInfo['company_full_name']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="assetInfo['city_name'] != '' && assetInfo['state_code'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{assetInfo['city_name']}}, {{assetInfo['state_code']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <tr>
                                                                    <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px; font-weight:bold;"> {{companyInfo['name']}} </td>
                                                                </tr>
                                                                <template v-if="companyInfo['contact_no'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{companyInfo['contact_no']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="companyInfo['email'] != ''">
                                                                    <tr>
                                                                        <td style="font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> <a style="color:#098de1; text-decoration: none;" :href="'mailto:' + companyInfo['email']"> {{companyInfo['email']}} </a> </td>
                                                                    </tr>
                                                                </template>
                                                            </table>
                                                        </td>
                                                        <td align="right" valign="top">
                                                            <!-- <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales" style="width:40%; height:auto;"> </a> -->
                                                            <a href="javascript:void(0)" target="_blank"> <img :src="assetInfo['company_logo']" :alt="assetInfo['company_logo']" class="company-logo" style="/* width:40%; */ height:auto;"> </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" align="left" valign="top" height="15px" style="border-bottom:1px solid #dddddd;"></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" align="left" valign="top" height="15px"> </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <!---header section--->

                                        <!--image section-->
                                        <template v-if="docs && docs.length > 0">
                                            <tr>
                                                <td slign="left" valign="top">
                                                    <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                        <template v-for="(doc, index) in docs">
                                                            <tr :key="'tr ' + index">
                                                                <template v-for="(docInner, indexInner) in docs[index]">
                                                                    <td :key="'indexInner td ' + indexInner" align="left" valign="top" width="32%">
                                                                        <img :src="docInner['thumb_url']" alt="Velocity Vehicle Group Deal Manager" style="width: 100%;">
                                                                    </td>
                                                                    <template v-if="indexInner + 1 != docs[index].length">
                                                                        <td :key="'indexInner Space td ' + indexInner" align="left" valign="top" width="2%"></td>
                                                                    </template>
                                                                </template>
                                                            </tr>
                                                            <tr :key="'space tr ' + index">
                                                                <td colspan="5" align="left" valign="top" width="100%" height="15"></td>
                                                            </tr>
                                                        </template>
                                                    </table>
                                                </td>
                                            </tr>
                                        </template>
                                        <!--image section-->

                                        <!--Footer-->
                                        <div class="footer-table-section">
                                            <tr>
                                                <td align="left" valign="top" width="100%" height="20"> </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top" width="170%"> <img style="width:170px;" src="/static/images/logo.png" alt="Velocity Vehicle Group Deal Manager"> </td>
                                            </tr>
                                            <tr>
                                                <td align="left" valign="top" width="100%" height="10"> </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top" width="100%" style="font-size: 13px; line-height: 17px; color: #33333; font-family:Arial, Helvetica, sans-serif; font-style: italic;"> <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{ assetInfo['systemYear']}} Velocity Vehicle Group Deal Manager </td>
                                            </tr>
                                        </div>
                                        <!--Footer-->
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <div style="break-after:page"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    Vue.component("app-footer", Footer);

    export default {
        name: 'AssetSpecSheet',
        components: {
        },
        mixins: [commonMixins],
        data() {
            return {
                assetInfo: [],
                docs: [],
                companyInfo: [],
                publicLoginId: 0,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            self.publicLoginId = 0;
            if (typeof (self.$route.query.vin) != 'undefined' && self.$route.query.vin != "" && self.$route.query.vin != 0) {
                self.getAssetData(self.$route.query.vin);
            }
        },
        methods: {
            getAssetData(id) {
                var config = {
                    params: {
                        vin: id,
                        publicLoginId: self.publicLoginId,
                    }
                };
                HTTP.get('/specsheetasset/view', config)
                    .then(response => {
                        self.assetInfo = response.data.content.assetInfo;
                        self.docs = response.data.content.docs;
                        self.companyInfo = response.data.content.companyInfo;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
        },
    }
</script>
