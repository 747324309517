<template>
  <div class="custom-container">
    <!-- Start Top -->
    <div id="top">
      <!-- First Row -->
      <div class="custom-row">
        <!-- Left Column -->
        <div class="custom-column">
          <h4 class="top-head-title">Vehicle Worksheet</h4>
          <span class="sub-title">{{ dealNo }}</span>
        </div>
        <!-- Right Column -->
        <div class="custom-column" id="button-top">
          <button class="close-button" @click="closeWorkSheet()">Close</button>
          <button class="save-close-button" @click="saveCloseWorkSheet()">
            <span>{{
              dealInventoryMapperId !== null || isVehicleEdit
                ? "Update"
                : "Save"
            }}</span>
          </button>
        </div>
      </div>
      <!-- Second Row -->
      <!-- Note here the use of HTML COMMENT between buttons to remove the white space -->
      <div class="custom-row">
        <div class="custom-column">
          <button class="menu-btn trs-btn-disabled">
            <i class="btn-icon"
              ><img
                src="../../../../public/static/images/transaction_sheet/Line.svg"
                alt="btn" /></i
            >Save as Template</button
          ><!-- 
                --><button class="menu-btn trs-btn-disabled">
            <i class="btn-icon"
              ><img
                src="../../../../public/static/images/transaction_sheet/Line.svg"
                alt="btn" /></i
            >Print Page</button
          ><!-- 
                --><button class="menu-btn trs-btn-disabled">
            <i class="btn-icon"
              ><img
                src="../../../../public/static/images/transaction_sheet/Line.svg"
                alt="btn" /></i
            >Move to Deal</button
          ><!-- 
                --><button :class="((dealInventoryMapperId != null || vehicleDataId !== null) && permissionNameArray.includes('can_delete_deal_inventory_mapper')) ? 'menu-btn' : 'menu-btn trs-btn-disabled'" @click="(dealInventoryMapperId != null || vehicleDataId !== null) ? deleteVehicleWorksheet() : ''">
            <i class="btn-icon"
              ><img
                src="../../../../public/static/images/transaction_sheet/Line.svg"
                alt="btn" /></i
            >Delete
          </button>
        </div>
      </div>
    </div>
    <!-- End Top -->
    <hr />
    <!-- Start Main Content -->
    <div class="main">
      <div class="custom-row">
        <div class="vehicle-info-section">
          <div class="custom-accordian">
            <div class="custom-card">
              <div class="custom-card-header" @click="expandCollapse('expandCollapse1',1)">
                <div class="custom-column">Vehicle Information</div>
                <div class="custom-column">
                  <img
                    src="../../../../public/static/images/transaction_sheet/Vector.svg"
                    alt="Vector"
                    :class="expandCollapseImg1"
                  />
                </div>
              </div>
              <div id="vhc-info">
                <div class="custom-card-body" :class="expandCollapse1">
                  <div class="custom-row">
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="vin">VIN <span class="vhc-required" title="REQUIRED FIELD">*</span></label>
                        <div class="custom-input-search">
                          <input type="text" id="vin" v-model="vin" @input="vinError=''" />
                          <div class="search-icon" @click="searchVin"></div>
                        </div>
                        <search-vin-modal
                          v-bind:isClickSearchVinModal="isClickSearchVinModal"
                          v-bind:vin="vin"
                        ></search-vin-modal>
                        <span class="vhc-required-alert" v-if="vinError !== ''">{{ vinError }}</span>
                      </div>
                      <div class="fm-group">
                        <label for="manufacturer">Manufacturer <span class="vhc-required" title="REQUIRED FIELD">*</span></label>
                        <v-select
                          v-model="manufacturerId"
                          name="manufacturerId"
                          label="text"
                          :filterable="true"
                          :options="manufacturerArr"
                          :clearable="true"
                          :placeholder="
                            staticMessages.manufacturer_place_holder
                          "
                          @input="manufacturerError=''"
                          class="custom-ci-auto-search vhc-select"
                        />
                        <span class="vhc-required-alert" v-if="manufacturerError !== ''">{{ manufacturerError }}</span>
                      </div>
                      <div class="fm-group">
                        <label for="model">Model/OEM <span class="vhc-required" title="REQUIRED FIELD">*</span></label>
                        <v-select
                          v-model="modelOEMId"
                          name="modelOEMId"
                          label="text"
                          :filterable="true"
                          :options="modelOEMArr"
                          :clearable="true"
                          :placeholder="staticMessages.model_oem_place_holder"
                          @input="modelError=''"
                          class="custom-ci-auto-search vhc-select"
                        />
                        <span class="vhc-required-alert" v-if="modelError !== ''">{{ modelError }}</span>
                      </div>
                      <div class="fm-group">
                        <label for="stock-type">Stock Type</label>
                        <v-select
                          v-model="stockTypeId"
                          name="stockTypeId"
                          id="stock-type"
                          label="text"
                          :clearable="false"
                          :searchable="false"
                          :filterable="false"
                          :options="stockTypeArr"
                          :placeholder="staticMessages.stock_type_place_holder"
                          class="vhc-select"
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="inner-row-first">
                        <div class="custom-column">
                          <div class="fm-group">
                            <label for="nu">N/U</label>
                            <v-select
                              v-model="typeId"
                              name="typeId"
                              id="nu"
                              label="text"
                              :clearable="false"
                              :searchable="false"
                              :filterable="false"
                              :options="typeArr"
                              :placeholder="staticMessages.type_place_holder"
                              @input="newUsedCheck()"
                              class="vhc-select"
                            />
                          </div>
                          <div class="fm-group">
                            <label for="fuel-type">Fuel Type</label>
                            <v-select
                              v-model="fuelTypeId"
                              name="fuelTypeId"
                              id="fuel-type"
                              label="text"
                              :clearable="false"
                              :searchable="false"
                              :filterable="false"
                              :options="fuelTypeArr"
                              :placeholder="
                                staticMessages.fuel_type_place_holder
                              "
                              class="vhc-select"
                            />
                          </div>
                          <div class="fm-group">
                            <label for="nu">Odometer</label>
                            <input type="text" v-model="odometer" />
                          </div>
                          <div class="fm-group">
                            <label for="nu">Vehicle Description</label>
                            <input type="text" v-model="vehicleDescription" />
                          </div>
                        </div>
                        <!-- ##### UNUSED FIELD ##### -->
                        <div class="custom-column t1">
                          <div class="custom-row"></div>
                          <div class="custom-row"></div>
                          <div class="custom-row">
                            <div id="meter-group">
                              <div>
                                <input
                                  type="radio"
                                  name="si_unit"
                                  class="si_unit"
                                  checked
                                />
                                <label for="mi">MI</label>
                                <input
                                  type="radio"
                                  name="si_unit"
                                  class="si_unit"
                                />
                                <label for="mi">KM</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- END -->
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="inner-row-second">
                        <div class="custom-column">
                          <div class="fm-group">
                            <label for="year">Year</label>
                            <input type="text" v-model="year" />
                          </div>
                          <div class="fm-group">
                            <label for="quantity">Quantity</label>
                            <input type="text" value="1" v-model="quantity" />
                          </div>
                          <div class="fm-group">
                            <label for="unit-id">Unit ID</label>
                            <div class="custom-input-search">
                              <input type="text" v-model="unitId" />
                              <div class="search-icon" @click="searchUnit"></div>
                            </div>
                            <search-unit-modal
                              v-bind:isClickSearchUnitModal="isClickSearchUnitModal"
                              v-bind:unit="unitId"
                            ></search-unit-modal>
                          </div>
                          <div class="fm-group">
                            <label for="tires"># of Tires</label>
                            <input
                              type="text"
                              id="tires"
                              v-model="ofTires"
                              @keyup="
                                (event) => {
                                  incrementDecrementNumber(event, 'ofTires');
                                }
                              "
                              @blur="calTireFee()"
                            />
                          </div>
                        </div>
                        <div class="custom-column t1">
                          <div class="custom-row"></div>
                          <div class="custom-row"></div>
                          <div class="custom-row">
                            <button id="detailBtn" @click="showVhcDetail()">
                              <div>
                                <i><span>?</span></i>
                                Detail
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="color">Color</label>
                        <input type="text" id="color" v-model="color" />
                      </div>
                      <div class="fm-group">
                        <label for="body-type">Body Type</label>
                        <input type="text" id="body-type" v-model="bodyType" />
                      </div>
                      <div class="fm-group">
                        <label for="duty">Duty</label>
                        <v-select
                          v-model="duty"
                          name="duty"
                          id="duty"
                          label="text"
                          :clearable="false"
                          :searchable="false"
                          :filterable="false"
                          :options="dutyArr"
                          :placeholder="staticMessages.duty_place_holder"
                          class="vhc-select"
                        />
                      </div>
                      <div class="fm-group">
                        <label for="stock-order">Stock/Order</label>
                        <v-select
                          v-model="stockOrder"
                          name="stockOrder"
                          id="stock-order"
                          label="text"
                          :clearable="false"
                          :searchable="false"
                          :filterable="false"
                          :options="stockOrderArr"
                          :placeholder="staticMessages.stock_order_place_holder"
                          class="vhc-select"
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="scale-wt">Scale Weight</label>
                        <input
                          type="text"
                          id="scale-wt"
                          v-model="scaleWeight"
                        />
                      </div>
                      <div class="fm-group">
                        <label for="batteries"># of Batteries</label>
                        <input
                          type="text"
                          id="batteries"
                          v-model="ofBatteries"
                        />
                      </div>
                      <div class="fm-group">
                        <label for="axles"># of Axles</label>
                        <input type="text" id="axles" v-model="ofAxles" />
                      </div>
                      <div class="fm-group">
                        <label for="delvery-date">Proposed Delivery Date</label>
                        <div id="date-calendar-fm-group">
                          <date-picker
                            v-model="proposedDeliveryDate"
                            :config="options"
                            class="form-control"
                            autocomplete="off"
                            name="proposedDeliveryDate"
                            id="delivery-date"
                            ref="dateInput"
                          >
                          </date-picker>
                          <i @click="openCalendar" style="cursor: pointer"
                            ><img
                              src="../../../../public/static/images/transaction_sheet/Group4719.svg"
                              alt=""
                          /></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="custom-card-footer" :class="expandCollapse1">
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        class="custom-checkbox"
                        v-model="privateUse"
                        @change="checkUncheck($event, 'privateUse')"
                      /><label for="privateUse">Private Use</label>
                      <input
                        type="checkbox"
                        class="custom-checkbox"
                        v-model="businessUse"
                        @change="checkUncheck($event, 'businessUse')"
                      /><label for="businessUse">Business Use</label>
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      class="custom-checkbox"
                      :checked="isDelivered=='yes' ? 'checked' : ''"
                      @change="checkDelivered($event)"
                    /><label for="delivered">Delivered</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-row">
        <div class="vehicle-info-section">
          <div class="custom-accordian">
            <div class="custom-card">
              <div class="custom-card-header" @click="expandCollapse('expandCollapse2',2)">
                <div class="custom-column">Fees</div>
                <div class="custom-column">
                  <img
                    src="../../../../public/static/images/transaction_sheet/Vector.svg"
                    alt="Vector"
                    :class="expandCollapseImg2"
                  />
                </div>
              </div>
              <div id="fees-info">
                <div class="custom-card-body" :class="expandCollapse2">
                  <div class="custom-row">
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="gvw">GVW/GCWR</label>
                        <input
                          type="text"
                          v-model="gvwGCWR"
                          @blur="calVehiclePrice()"
                          @keyup="
                            (event) => {
                              incrementDecrementNumber(event, 'gvwGCWR');
                            }
                          "
                        />
                      </div>
                      <div class="fm-group">
                        <label for="doc-pp-fees">Doc Preparation Fees</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="docPreparation"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'docPreparation');
                            }
                          "
                          @blur.native="sumFees()"
                        />
                      </div>
                      <div class="fm-group">
                        <label for="license-title">License/Title</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="licenseTitle"
                          :disabled="!customFee"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'licenseTitle');
                            }
                          "
                          @blur.native="sumFees()"
                        />
                      </div>
                      <div class="fm-group-checkbox">
                        <input
                          type="checkbox"
                          class="custom-checkbox"
                          v-model="noLicense"
                          @change="checkUncheck($event, 'noLicense')"
                        />
                        <label for="no-license">No License</label>
                        <input
                          type="checkbox"
                          class="custom-checkbox"
                          v-model="customFee"
                          @change="checkUncheck($event, 'customFee')"
                        />
                        <label for="no-license">Custom Fee</label>
                      </div>
                    </div>

                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="cst_spl_bdy">Customer Supplied Body</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="customerSuppliedBody"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'customerSuppliedBody'
                              );
                            }
                          "
                          @blur.native="sumFees()"
                        />
                      </div>
                      
                      <div class="fm-group">
                        <label for="smog-fee">Smog Fee Paid To Seller</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="smogFeePaidToSeller"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'smogFeePaidToSeller'
                              );
                            }
                          "
                          @blur.native="sumFees()"
                        />
                      </div>

                      <div class="fm-group">
                        <label for="registration">Registration</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="registration"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'registration');
                            }
                          "
                          @blur.native="calVehiclePrice()"
                        />
                      </div>
                    </div>

                    <div class="custom-column">
                      <div class="fm-group">
                        <div class="label-group">
                          <label for="tire-fee">Tire Fee</label>
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="tireTax"
                            @keyup.native="
                              (event) => {
                                incrementDecrementNumber(event, 'tireTax');
                              }
                            "
                          />
                        </div>
                        <div class="inner-grid">
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="tireFee"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="fm-group">
                        <label for="flooring">Flooring</label>
                        <div class="inner-grid">
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="flooring"
                            disabled
                          />
                          <a href="javascript:void(0);" @click="calFlooring()">
                            <img
                              src="../../../../public/static/images/transaction_sheet/calculator1.svg"
                              alt="Calculatior Icon"
                            />
                          </a>
                        </div>
                        <cal-flooring
                          v-bind:isClickCalFlooring="isClickCalFlooring"
                          v-bind:factoryPrice="factoryPrice"
                          v-bind:isVisibleDDC="isVisibleDDC"
                          v-bind:deliveryDelayCredit="deliveryDelayCredit"
                          v-bind:typeId="typeId"
                          v-bind:flooringStartDate="flooringStartDate"
                          v-bind:flooringEndDate="flooringEndDate"
                          v-bind:flooringRate="flooringRate"
                          v-bind:storedFlooring="flooring"
                        ></cal-flooring>
                      </div>
                      <div class="fm-group">
                        <label for="smog-cert"
                          >Smog Certification or Exemption Fee Paid to
                          State</label
                        >
                        <div class="inner-grid">
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="smogCertificationOrExemptionFeePaidToState"
                            @keyup.native="
                              (event) => {
                                incrementDecrementNumber(
                                  event,
                                  'smogCertificationOrExemptionFeePaidToState'
                                );
                              }
                            "
                            @blur.native="sumFees()"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="tire-credit">Tire Credit</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="tireCredit"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'tireCredit');
                            }
                          "
                          @blur.native="sumFees()"
                        />
                      </div>
                      <div class="fm-group">
                        <label for="fet-exempt-items">FET Exempt Items</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="fetExemptItems"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'fetExemptItems');
                            }
                          "
                          @blur.native="sumFees()"
                        />
                      </div>
                      <div class="fm-group">
                        <label for="dmv-electronic "
                          >Fet</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="fet"
                          :disabled="!customFet"
                        />
                      </div>
                      <div class="fm-group-checkbox">
                        <input
                          type="checkbox"
                          class="custom-checkbox"
                          v-model="fetExempt"
                          @change="checkUncheck($event, 'fetExempt')"
                        />
                        <label for="fet-exempt">Fet Exempt</label>
                        <input
                          type="checkbox"
                          class="custom-checkbox"
                          v-model="customFet"
                          @change="checkUncheck($event, 'customFet')"
                        />
                        <label for="custom-fet">Custom Fet</label>
                      </div>
                    </div>
                    
                    
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="dmv-electronic "
                          >DMV Electronic Filling Fee</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="dmvElectronicFillingFee"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'dmvElectronicFillingFee'
                              );
                            }
                          "
                          @blur.native="sumFees()"
                        />
                      </div>

                      <div class="fm-group">
                        <label for="dmv-electronic "
                          >CCTTS Only Admin Fee</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="ccttsOnlyAdminFee"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'ccttsOnlyAdminFee'
                              );
                            }
                          "
                          @blur.native="sumFees()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-row">
        <div class="custom-fields-section">
          <div class="custom-accordian">
            <div class="custom-card">
              <div class="custom-card-header" @click="expandCollapse('expandCollapse3',3)">
                <div class="custom-column">Custom Fields</div>
                <div class="custom-column">
                  <img
                    src="../../../../public/static/images/transaction_sheet/Vector.svg"
                    alt="Vector"
                    :class="expandCollapseImg3"
                  />
                </div>
              </div>
              <div class="custom-fields-info">
                <div class="custom-card-body" :class="expandCollapse3">
                  <div class="custom-row">
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="finance-reserve">Finance Reserve</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="financeReserve"
                          @blur.native="calVehiclePrice()"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'financeReserve');
                            }
                          "
                        />
                      </div>
                      <div class="fm-group">
                        <label for="deal-adjustment">Deal Adjustement NC</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="dealAdjustment"
                          @blur.native="calVehiclePrice()"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'dealAdjustment');
                            }
                          "
                        />
                      </div>
                      <div class="fm-group" v-if="isVisibleDDC">
                        <label for="deal-adjustment">Delivery Delay Credit</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="deliveryDelayCredit"
                          @blur.native="addDeliveryDelayCredit()"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'deliveryDelayCredit');
                            }
                          "
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="sales-tax">IMF/Sales Tax</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="imfSalesTax"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'imfSalesTax');
                            }
                          "
                        />
                      </div>
                      <div class="fm-group">
                        <label for="adot-permit">ADOT Permit#</label>
                        <input
                          type="text"
                          class="input-text"
                          v-model="adotPermit"
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="hold-date">Hold Date</label>
                        <date-picker
                            v-model="holdDate"
                            :config="options"
                            class="form-control"
                            autocomplete="off"
                            name="holdDate"
                            id="hold-date"
                          >
                          </date-picker>
                      </div>
                      <div class="fm-group">
                        <label for="factory-price">Factory Price</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="factoryPrice"
                          @blur.native="calVehiclePrice()"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'factoryPrice');
                            }
                          "
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="fleet-reserve-cost"
                          >Fleet Reserve Cost</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="fleetReserveCost"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'fleetReserveCost'
                              );
                            }
                          "
                        />
                      </div>
                      <div class="fm-group">
                        <label for="body-cost">Body Cost</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="bodyCost"
                          @blur.native="calVehiclePrice()"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(event, 'bodyCost');
                            }
                          "
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="fleet-reserve-ctrl"
                          >Fleet Reserve Ctrl#</label
                        >
                        <input
                          type="text"
                          class="input-text"
                          v-model="fleetReserveCtrl"
                        />
                      </div>
                      <div class="fm-group">
                        <label for="factory-invoice">Factory Inv. Date</label>
                        <date-picker
                            v-model="factoryInvDate"
                            :config="options"
                            class="form-control"
                            autocomplete="off"
                            name="factoryInvDate"
                            id="factory-inv-date"
                          >
                          </date-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-row">
        <div class="vehicle-cost-calculation">
          <div class="custom-accordian">
            <div class="custom-card">
              <div class="custom-card-header" @click="expandCollapse('expandCollapse4',4)">
                <div class="custom-column">Vehicle Cost Calculation</div>
                <div class="custom-column">
                  <img
                    src="../../../../public/static/images/transaction_sheet/Vector.svg"
                    alt="Vector"
                    :class="expandCollapseImg4"
                  />
                </div>
              </div>
              <div id="vhc-cost-cal-info">
                <div class="custom-card-body" :class="expandCollapse4">
                  <div class="custom-row">
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="inventory-cost"
                          >Current Inventory Cost From Procede</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="currentInventoryCostFromProcede"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'currentInventoryCostFromProcede'
                              );
                            }
                          "
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <button class="vhc-btn" @click="vehicleCostSheet()">
                        Cost Sheet
                      </button>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="inventory-cost"
                          >Inventory Cost (Last Update)</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="inventoryCostLastUpdated"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'inventoryCostLastUpdated'
                              );
                            }
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="custom-row">
                    <div class="custom-column">
                      <button class="vhc-btn" @click="addOnModal()">
                        Add On-Lists
                      </button>
                    </div>
                    <div class="custom-column">
                      <button class="vhc-btn" @click="purchaseOrderModal()">
                        Purchase Order
                      </button>
                    </div>
                  </div>
                  <div class="custom-row">
                    <table class="custom-table">
                      <caption>Addon List</caption>
                      <thead>
                        <th>ITEM NAME</th>
                        <th>DESCRIPTION</th>
                        <th>TAX</th>
                        <th>COST</th>
                        <th>PRICE</th>
                        <th>ADD TO PRICE</th>
                        <th>EXCL FROM FET</th>
                        <th>SHOW ON DOC</th>
                        <th>DONOT ADD TO GP</th>
                        <th></th>
                      </thead>
                      <tbody
                        v-if="
                          addOnPoList != undefined && addOnPoList.length > 0
                        "
                      >
                        <tr v-for="(data, index) in addOnPoList" :key="data.id">
                          <td>
                            {{ data.excede_item_id == null ? "PO" : data.excede_item.item }}
                          </td>
                          <td>{{ data.description }}</td>
                          <td>{{ data.tax }}</td>
                          <td>$ {{ data.cost.toFixed(2) }}</td>
                          <td>$ {{ data.price.toFixed(2) }}</td>
                          <td>
                            <input
                              type="checkbox"
                              :checked="
                                parseInt(data.is_add_to_total_price) == 1 ||
                                data.is_add_to_total_price == true
                                  ? true
                                  : false
                              "
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              :checked="
                                parseInt(data.is_fet_exempt) == 1 ||
                                data.is_fet_exempt == true
                                  ? true
                                  : false
                              "
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              :checked="
                                (parseInt(data.is_show_on_documents) ||
                                  data.is_show_on_documents == true) == 1
                                  ? true
                                  : false
                              "
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              :checked="
                                parseInt(data.is_do_not_add_to_gp) == 1 ||
                                data.is_do_not_add_to_gp == true
                                  ? true
                                  : false
                              "
                              disabled
                            />
                          </td>
                          <td>
                            <a
                              v-on:click="editAddOn(data, index)"
                              title="Edit AddOn/PO"
                              href="javascript:void(0)"
                              class="mar-right-0"
                            >
                              <i aria-hidden="true" class="fa fa-edit"></i>
                            </a>
                            <a
                              v-on:click="deleteAddOnItem(data, index)"
                              title="Delete AddOn/PO"
                              href="javascript:void(0)"
                              class="mar-right-0 pl-2"
                            >
                              <i aria-hidden="true" class="fa fa-trash text-danger"></i>
                            </a>
                            <a
                              v-on:click="createPoExcede(data, index)"
                              :title="data.ex_itm_id == null ? 'Create Excede' : 'Update Excede'"
                              href="javascript:void(0)"
                              class="mar-right-0 pl-2"
                              v-if="permissionNameArray.includes('can_add_purchase_order_excede') && data.purchase_order_id && [5,10].includes(dealStatusId)"
                            >
                              <i aria-hidden="true" class="fa fa-plus text-primary"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="3" id="table-colspan">
                            No Records To Display
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="custom-row">
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="total-cost-of-addons"
                          >Total Cost of Addons Above</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="
                            totalInternalLocalInstallationLessOutOfStateDeliveryCost
                          "
                          disabled
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="cost-sub-total-before-package"
                          >Cost Sub-Total Before Package (Including
                          Flooring)</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="
                            costOfVehicleAndEquipmentIncludingFlooringCost
                          "
                          disabled
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <div class="checkbox-group">
                          <label for="total-cost-of-addons"
                            >Custom Holdback</label
                          >
                          <input
                            type="checkbox"
                            v-model="customHoldBack"
                            @change="calVehiclePrice()"
                          />
                        </div>
                      </div>
                      <div class="fm-group">
                        <label for="total-cost-of-addons"
                          >Holdback Fixed</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="holdBackFixed"
                          @blur.native="fctFixedHoldBack()"
                          v-bind:disabled="customHoldBack == false"
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="percentage">Percentage</label>
                        <div class="percentage">
                          <money
                            v-bind="rateMask"
                            type="text"
                            v-model="percentage"
                            @blur.native="fctHoldBackPercentUpdate()"
                          />
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="holdBack"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="totalvehiclecost"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-row">
        <div class="vehicle-fixed-margin">
          <div class="custom-accordian">
            <div class="custom-card">
              <div class="custom-card-header" @click="expandCollapse('expandCollapse5',5)">
                <div class="custom-column">Vehicle Fixed Margin</div>
                <div class="custom-column">
                  <img
                    src="../../../../public/static/images/transaction_sheet/Vector.svg"
                    alt="Vector"
                    :class="expandCollapseImg5"
                  />
                </div>
              </div>
              <div id="vhc-fxd-margin">
                <div class="custom-card-body" :class="expandCollapse5">
                  <div class="custom-row">
                    <div class="custom-column">
                      <div class="fm-group">
                        <label for="gross-profit"
                          >Gross Profit Dollar Amount or Percentage</label
                        >
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="grossProfitDollarAmount"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'grossProfitDollarAmount'
                              );
                            }
                          "
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <money
                          v-bind="rateMask"
                          type="text"
                          class="input-text"
                          v-model="grossProfitPercent"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'grossProfitPercent'
                              );
                            }
                          "
                        />
                      </div>
                    </div>
                    <div class="custom-column">
                      <div class="fm-group">
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="grossProfitSalesPrice"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-row">
        <div class="vehicle-price-calculation">
          <div class="custom-accordian">
            <div class="custom-card">
              <div class="custom-card-header" @click="expandCollapse('expandCollapse6',6)">
                <div class="custom-column">Vehicle Price Calculation</div>
                <div class="custom-column">
                  <img
                    src="../../../../public/static/images/transaction_sheet/Vector.svg"
                    alt="Vector"
                    :class="expandCollapseImg6"
                  />
                </div>
              </div>
              <div id="vhc-price-cal">
                <div class="custom-card-body" :class="expandCollapse6">
                  <div class="custom-row">
                    <div class="custom-column">
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="vehicle-sales-price"
                            >Vehicle Sales Price</label
                          >
                          <div>
                            <input
                              type="checkbox"
                              v-model="packed"
                              :disabled="typeId.text.toLowerCase() == 'used'"
                            />
                            Packed
                          </div>
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="base-sales-price">Base Sales Price</label>
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="vehicleSalesPrice"
                            @blur.native="calVehiclePrice()"
                            @keyup.native="
                              (event) => {
                                incrementDecrementNumber(
                                  event,
                                  'vehicleSalesPrice'
                                );
                              }
                            "
                          />
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="pos-amnt"
                            >PO(‘s) amount to add to Sales Price</label
                          >
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="poAmountToAddToSalesPrice"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="frontent-sales">Frontend Sales</label>
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="frontendSales"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="custom-column"
                      v-if="packed && typeId.text.toLowerCase() !== 'used'"
                    >
                      <div class="fm-group">
                        <label for="base-sales-price">Price Fet Included</label>
                        <money
                          v-bind="moneyMask"
                          type="text"
                          class="input-text"
                          v-model="priceFetIncluded"
                          @blur.native="calVehiclePrice()"
                          @keyup.native="
                            (event) => {
                              incrementDecrementNumber(
                                event,
                                'priceFetIncluded'
                              );
                            }
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="custom-row">
                    <div class="custom-accordian">
                      <div class="custom-card">
                        <div class="custom-card-header" @click="expandCollapse('expandCollapse7',7)">
                          <div class="custom-column">Trade Detail</div>
                          <div class="custom-column">
                            <img
                              src="../../../../public/static/images/transaction_sheet/Vector.svg"
                              alt="Vector"
                              :class="expandCollapseImg7"
                            />
                          </div>
                        </div>
                        <div id="trade-detail">
                          <div class="custom-card-body" :class="expandCollapse7">
                            <div class="custom-row">
                              <div class="custom-column">
                                <button v-if="!tradeDetailsData" class="vhc-btn" @click="tradeDetailModal('Save')">Add Trade</button>
                              </div>
                            </div>
                            <div class="custom-row">
                              <table class="custom-table">
                                <caption>Trade Details</caption>
                                <thead>
                                  <th>YEAR</th>
                                  <th>MAKE</th>
                                  <th>MODEL</th>
                                  <th>GROSS TRADE IN</th>
                                  <th>PAY OFF</th>
                                  <th>ACV</th>
                                  <th>VIN</th>
                                  <th>STOCK#</th>
                                  <th>APPRAISAL</th>
                                  <th></th>
                                </thead>
                                <tbody>
                                  <tr v-if="tradeDetailsData">
                                    <td>{{  tradeDetailsData.year  }}</td>
                                    <td>{{  tradeDetailsData.make.text  }}</td>
                                    <td>{{  tradeDetailsData.model.text  }}</td>
                                    <td>{{  tradeDetailsData.trade_in_value  }}</td>
                                    <td>{{  tradeDetailsData.payoff  }}</td>
                                    <td>{{  tradeDetailsData.trade_acv  }}</td>
                                    <td>{{  tradeDetailsData.vin  }}</td>
                                    <td>{{  tradeDetailsData.stock_id  }}</td>
                                    <td></td>
                                    <td>
                                      <a
                                        v-on:click="tradeDetailModal('Edit')"
                                        title="Edit Trade Detail"
                                        href="javascript:void(0)"
                                        class="mar-right-0"
                                      >
                                        <i aria-hidden="true" class="fa fa-edit"></i>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="3" id="table-colspan">
                                      No Records To Display
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="custom-row">
                    <div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="trade-in-amount">Trade-In Amount</label>
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="tradeInAmount"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="backend-sales">Backend Sales</label>
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="backendSales"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <div class="fm-inner-grid">
                            <div>
                              <label for="dealer-fees">Dealer Fees</label>
                              <money
                                v-bind="moneyMask"
                                type="text"
                                class="input-text"
                                v-model="dealerFees"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <div class="fm-inner-grid">
                            <div>
                              <label for="trade-in-amount">Total Sales</label>
                              <money
                                v-bind="moneyMask"
                                type="text"
                                class="input-text"
                                v-model="totalSales"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="fet">Fet</label>
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="fetTrade"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="other-charges">Other Charges</label>
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text"
                            v-model="otherCharges"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <div class="fm-inner-grid">
                            <div>
                              <label for="dealer-fees">State Tax Amount</label>
                              <money
                                v-bind="moneyMask"
                                type="text"
                                class="input-text"
                                v-model="stateTaxAmount"
                                @keyup.native="
                                  (event) => {
                                    incrementDecrementNumber(
                                      event,
                                      'stateTaxAmount'
                                    );
                                  }
                                "
                              />
                              <div>
                                <input
                                  type="checkbox"
                                  v-model="applySalesTax1"
                                  @change="calVehiclePrice()"
                                />
                                <label for="aoply-sales-tax"
                                  >Apply Sales Tax</label
                                >
                              </div>
                            </div>
                            <div>
                              <label for="state-tax-rate">State Tax Rate</label>
                              <money
                                v-bind="rateMask"
                                type="text"
                                class="input-text"
                                v-model="stateTaxRate"
                                @keyup.native="
                                  (event) => {
                                    incrementDecrementNumber(
                                      event,
                                      'stateTaxRate'
                                    );
                                  }
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <div class="fm-inner-grid">
                            <div>
                              <label for="warranty-tax">Warranty Tax</label>
                              <money
                                v-bind="moneyMask"
                                type="text"
                                class="input-text"
                                v-model="customTaxAmount"
                                @keyup.native="
                                  (event) => {
                                    incrementDecrementNumber(
                                      event,
                                      'customTaxAmount'
                                    );
                                  }
                                "
                              />
                              <div>
                                <input
                                  type="checkbox"
                                  v-model="applySalesTax2"
                                  @change="calVehiclePrice()"
                                />
                                <label for="aoply-sales-tax"
                                  >Apply Sales Tax</label
                                >
                              </div>
                            </div>
                            <div>
                              <label for="state-tax-rate"
                                >Custom Tax Rate</label
                              >
                              <money
                                v-bind="rateMask"
                                type="text"
                                class="input-text"
                                v-model="customTaxRate"
                                @keyup.native="
                                  (event) => {
                                    incrementDecrementNumber(
                                      event,
                                      'customTaxRate'
                                    );
                                  }
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <label for="trade-in-amount">Other Tax</label>
                          <money
                            v-bind="moneyMask"
                            type="text"
                            class="input-text trs-btn-disabled"
                            v-model="otherTax"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="custom-column">
                        <!--#### NOT REQUIRED AS PER CLIENT MEETING HELD ON December 14, 2023 ####
                          <div class="fm-group">
                            <label for="total-cash-down">Total Cash Down</label>
                            <money
                              v-bind="moneyMask"
                              type="text"
                              class="input-text"
                              @blur.native="calVehiclePrice()"
                              v-model="totalCashDown"
                              id="total-cash-down"
                              disabled
                            />
                          </div>
                        ####-->
                      </div>
                      <!-- VVG-DM Total Cash Back not required -->
                      <div class="custom-column">
                        <div class="fm-group">
                          <div class="fm-inner-grid">
                            <div>
                              <label for="total-cash-back"
                                >Total Cash Back</label
                              >
                              <input
                                type="text"
                                class="input-text"
                                value="$0.00"
                                id="total-cash-back"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="custom-column">
                        <div class="fm-group">
                          <div class="fm-inner-grid">
                            <div>
                              <label for="vhc-net-price"
                                >Vehicle Net Price</label
                              >
                              <money
                                v-bind="moneyMask"
                                type="text"
                                class="input-text"
                                v-model="vehicleNetPrice"
                                id="vhc-net-price"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-loader-wrapper loading-container" v-if="showTrnsLoader">
      <div class="loading">
        <img src="/static/images/loading.gif" alt="loader" />
      </div>
    </div>
    <add-on-deal
      v-bind:addOnEditData="addOnEditData"
      v-bind:editAssetId="addOnAssetId"
      v-bind:addOnTempIndex="addOnTempIndex"
      v-bind:customerCountyId="county"
      v-bind:isClickAddOnList="isClickAddOnList"
    ></add-on-deal>
    <vehicle-purchase-order
      v-bind:isClickPurchaseOrder="isClickPurchaseOrder"
      v-bind:unitId="unitId"
      v-bind:vin="vin"
      v-bind:defaultLocation="locationId"
      v-bind:assetId="vehicleId"
      v-bind:editAssetId="poEditAssetId"
      v-bind:poEditData="poEditData"
    ></vehicle-purchase-order>
    <vehicle-cost-modal
      v-bind:assetId="vehicleId"
      v-bind:vin="vin"
      v-bind:isClickCostSheet="isClickCostSheet"
      v-bind:unitId="unitId"
    ></vehicle-cost-modal>
    <trade-detail 
      v-bind:currentVehicleWorksheetId="currentVehicleWorksheetId"
      v-bind:isClickAddTrade="isClickAddTrade"
      v-bind:isEditTradeDetail="isEditTradeDetail"
      v-bind:tradeDetailsData="tradeDetailsData"
    ></trade-detail>
    <vehicle-detail v-bind:isClickVehicleDetail="isClickVehicleDetail" v-bind:vinNo="vin"></vehicle-detail>
    <mail-modal :isClickMail="isClickMail" :id="mailExcedePurId" :mailDescription="mailDescription" :locationId="locationId" :dealStatusId="dealStatusId" :trnsMail="false" :dealNo="dealNo"></mail-modal>
  </div>
  <!-- End Main Content -->
</template>

<script>
let self;
import Vue from "vue";
import { commonMixins } from "../../../mixins/commonMixins";
import EventBus from "../../event-bus";
import { HTTP } from "../../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import moment from "moment";
import { Money } from "v-money";
import searchVinModal from "./Modal/searchVinModal.vue";
//import vSelect from "vue-select";
import AddOnDealModal from "./Modal/AddOnDealModal.vue";
import VehiclePurchaseOrder from "./Modal/PurchaseOrder/VehiclePurchaseOrderModal.vue";
import VehicleCostModal from "./Modal/VehicleCostModal.vue";
import FlooringCalModal from "./Modal/FlooringCalModal.vue";
import searchUnitModal from "./Modal/searchUnitModal.vue";
import Datepicker from 'vuejs-datepicker'; 
import TradeDetail from "./Modal/TradeDetailModal.vue";
import VehicleDetailModal from "./Modal/VehicleDetailModal.vue";
import MailModal from "./Modal/Mail/MailModal.vue";

//Vue.component("v-select", vSelect);

export default {
  name: "VehicleWorkSheet",
  components: {
    datePicker,
    "no-access": NoAccessPage,
    Money,
    "search-vin-modal": searchVinModal,
    "add-on-deal": AddOnDealModal,
    "vehicle-purchase-order": VehiclePurchaseOrder,
    "vehicle-cost-modal": VehicleCostModal,
    "cal-flooring": FlooringCalModal,
    "search-unit-modal": searchUnitModal,
    "trade-detail": TradeDetail,
    "vehicle-detail": VehicleDetailModal,
    "mail-modal": MailModal,
    Datepicker
  },
  mixins: [commonMixins],
  props: {
    editAssetId: 0,
    dealId: 0,
    vehicleData: "",
    isVehicleEdit: false,
    county: "", //customerCountyId Required only for Addons

    cusStateCode: "",
    salesTaxCounty: "",
    locationId: "",
    cusStateTaxRate: "",
    cusTaxRate: "",
    cusSalesTax: true,
    warrantyTax: "",
    dealNo: "",
    permissionNameArray: [],
    vehicleType: "",
    dealStatusId: Number,
  },
  data() {
    return {
      vin: "",
      currentAsset: {},
      isClickSearchVinModal: false,
      isClickSearchUnitModal: false,

      // Old
      moneyMask: {
        // decimal: ',',
        thousands: ",",
        prefix: "$ ",
        // suffix: ' #',
        precision: 2,
      },
      rateMask: {
        decimal: ".",
        suffix: " %",
        precision: 2,
      },
      staticMessages: "",

      // Latest
      initialCal: false,
      isVinChange: false,
      inventoryCostLastUpdated: 0,
      inventoryCostLastUpdatedError: "",
      factoryInvDate: "",
      factoryInvDateError: "",
      custom4: 0,
      factoryPrice: 0,
      factoryPriceError: "",
      financeReserve: 0,
      financeReserveError: "",
      typeId: {
          text: 'New'
      },
      typeArr: [
        {
          text: 'New'
        },
        {
          text: 'Used'
        }
      ],
      typeError: "",
      businessTax: 0,
      ccttsOnlyAdminFee: 0,
      ccttsOnlyAdminFeeError: "",
      businessUse: false,
      privateUse: false,
      docPreparation: 0,
      docPreparationError: "",
      warranty: 0,
      addonscost: 0,
      fleetReserveCost: 0,
      fleetReserveCostError: "",
      customHoldBack: false,
      vehiclePriceCalList: [],
      holdBackFixed: 0,
      holdBackFixedError: "",
      percentage: 0,
      percentageError: "",
      holdBack: 0,
      holdBackError: "",
      warrantycost: 0,
      costOfVehicleAndEquipmentIncludingFlooringCost: 0,
      costOfVehicleAndEquipmentIncludingFlooringCostError: "",
      frontendSales: "",
      frontendSalesError: "",
      addons: 0,
      warrantysales: 0,
      totalvehiclecost: 0,
      totalvehiclecostError: "",
      poAmountToAddToSalesPrice: "",
      poAmountToAddToSalesPriceError: "",
      fetExempt: false,
      packed: false,
      vehicleSalesPrice: "",
      vehicleSalesPriceError: "",
      customFet: false,
      flooringadd: 0,
      fetExemptItems: 0,
      fetExemptItemsError: "",
      outofstate: 0,
      tireCredit: 0,
      tireCreditError: "",
      fet: "",
      fetError: "",
      customFee: false,
      customerSuppliedBody: 0,
      customerSuppliedBodyError: "",
      gfwFee: [],
      gvwGCWR: 0,
      gvwGCWRError: "",
      noLicense: false,
      licenseTitleStatic: 0,
      fuelTypeId: "",
      fuelTypeArr: [],
      fuelTypeError: "",
      licenseTitle: "",
      licenseTitleError: "",
      registration: 0,
      registrationError: "",
      tireFee: 0,
      tireFeeError: "",
      tradeInAmount: "",
      tradeInAmountError: "",
      tradevalue: 0,
      tradepayoff: 0,
      stateTaxRate: 0,
      stateTaxRateError: "",
      customTaxRate: 0,
      customTaxRateError: "",
      otherTax: 0,
      otherTaxError: "",
      // totalCashDown: 0,  // Removed based on client meeting on 12/14/2023
      applySalesTax1: false,
      backendSales: "",
      backendSalesError: "",
      dealerFees: "",
      dealerFeesError: "",
      totalSales: "",
      totalSalesError: "",
      taxexclude: 0,
      dealAdjustment: 0,
      applySalesTax2: false,
      customTaxAmount: 0,
      customTaxAmountError: "",
      stateTaxAmount: 0,
      stateTaxAmountError: "",
      flooring: 0,
      flooringError: "",
      smogFeePaidToSeller: 0,
      smogFeePaidToSellerError: "",
      smogCertificationOrExemptionFeePaidToState: 0,
      smogCertificationOrExemptionFeePaidToStateError: "",
      dmvElectronicFillingFee: 0,
      dmvElectronicFillingFeeError: "",
      imfSalesTax: 0,
      otherCharges: 0,
      otherChargesError: "",
      vehicleNetPrice: "",
      vehicleNetPriceError: "",
      CommissionDeduct: 0,
      flooringexpense: 0,
      bodyCost: 0,
      bodyCostError: "",
      loadCount: 0,
      // setByBranch
      branchDefaultCal: [],
      tireTax: 0,
      // calTireFee
      ofTires: 0,
      ofTiresError: "",
      // calReverseVehicle
      priceFetIncluded: 0,
      priceFetIncludedError: "",
      addOnPoList: [],
      //   addOnCal
      cashDetailWarranty: 0.0,
      fetexclude: 0,
      totalInternalLocalInstallationLessOutOfStateDeliveryCost: "",
      totalInternalLocalInstallationLessOutOfStateDeliveryCostError: "",

      // From form
      adotPermit: "",
      adotPermitError: "",
      fleetReserveCtrl: "",
      fleetReserveCtrlError: "",
      currentInventoryCostFromProcede: 0,
      currentInventoryCostFromProcedeError: "",
      feesTotal: 0.0,
      grossProfitDollarAmount: 0,
      grossProfitDollarAmountError: "",
      grossProfitPercent: 0,
      grossProfitPercentError: "",
      grossProfitSalesPrice: 0,
      grossProfitSalesPriceError: "",
      fetTrade: "",
      fetTradeError: "",
      isClickCalFlooring: false,

      // AddOn
      addOnEditData: {},
      isClickAddOnList: false,
      addOnTempIndex: null,
      // PO
      poEditData: {},
      isClickPurchaseOrder: false,
      unitId: "",
      vehicleId: null,
      // Cost Sheet
      isClickCostSheet: false,
      // VIN
      manufacturerId: "",
      manufacturerArr: [],
      year: "",
      yearError: "",
      modelOEMId: "",
      modelOEMArr: [],
      color: "",
      colorError: "",
      stockTypeId: "",
      stockTypeArr: [
        {
          text: "RV Coach",
          value: "rv coach"
        },
        {
          text: "RV Hauler",
          value: "rv hauler"
        },
        {
          text: "RV SportDeck",
          value: "rv sportdeck"
        },
        {
          text: "RV Trailer",
          value: "rv trailer"
        },
        {
          text: "Tractor",
          value: "tractor"
        },
        {
          text: "Trailer",
          value: "trailer"
        },
        {
          text: "Truck",
          value: "truck"
        },
        {
          text: "Truck/Tractor",
          value: "truck/tractor"
        },
        {
          text: "Used HD",
          value: "used hd"
        },
      ],
      stockTypeError: "",
      bodyType: "",
      bodyTypeError: "",
      odometer: "",
      odometerError: "",
      scaleWeight: "",
      scaleWeightError: "",
      vehicleDescription: "",
      vehicleDescriptionError: "",
      // Unused Fields
      ofAxles: "",
      ofAxlesError: "",
      ofBatteries: "",
      ofBatteriesError: "",
      duty: "",
      dutyArr: [
        {
            text: 'HVY DUTY',
            value: 'hvy duty',
        },
        {
            text: 'LIGHT DUTY',
            value: 'light duty',
        },
        {
            text: 'MED DUTY',
            value: 'med duty',
        },
        {
            text: 'MED DUTY JAPANESE',
            value: 'med duty japanese',
        },
        {
            text: 'WHOLESALE',
            value: 'wholesale',
        },
        {
            text: 'BODY BUILDER',
            value: 'body builder',
        },
      ],
      dutyError: "",
      // getAset
      stockOrder: "",
      stockOrderArr: [
        {
          text: 'Stock',
          value: 'stock'
        },
        {
          text: 'Order',
          value: 'stock'
        },
      ],
      stockOrderError: "",

      // Save
      dealInventoryMapperId: null,
      vehicleDataId: null,
      grandTotalCostSheet: 0,
      proposedDeliveryDate: "",
      proposedDeliveryDateError: "",
      holdDate: "",
      saveNotNull: false,
      holdDateError: "",
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
        // disabledTimeIntervals: false,
      },

      /*##### ACCORDIAN ANIMATION #####*/
      collapseContain: false,
      expandCollapse1: "vhc-expand",
      expandCollapse2: "vhc-expand",
      expandCollapse3: "vhc-expand",
      expandCollapse4: "vhc-expand",
      expandCollapse5: "vhc-expand",
      expandCollapse6: "vhc-expand",
      expandCollapse7: "vhc-expand",
      expandCollapseImg1: '',
      expandCollapseImg2: '',
      expandCollapseImg3: '',
      expandCollapseImg4: '',
      expandCollapseImg5: '',
      expandCollapseImg6: '',
      expandCollapseImg7: 'rotate-accordian-expand',

      // Added
      quantity: 1,
      poEditAssetId: null,
      poData: [],
      addOnAssetId: null,
      poIndex: null,
      manufacturerError: '',
      modelError: '',
      vinError: '',
      deliveryDelayCredit: 0,
      tempDeliveryDelayCredit: 0,
      initFlooring: 0,
      isVisibleDDC: false,
      flooringStartDate: moment(new Date()).format("MM/DD/YYYY"),
      flooringEndDate: moment(new Date()).format("MM/DD/YYYY"),
      flooringRate: 6.50,

      // Trade Detail
      isClickAddTrade: false,
      isEditTradeDetail: false,
      currentVehicleWorksheetId: null,
      tradeDetailsData: null,
      isTradeSave: false,
      showTrnsLoader: false,

      // Vehicle Detail
      isClickVehicleDetail: false,
      isDelivered: "no",

      /*##### UNUSED-FIELDS-COMMISSION-SECTION #####*/
      grossProfit: 0,
      grossProfitError: "",
      lessHolback: "",
      lessHolbackError: "",
      overAllowance: 0,
      overAllowanceError: "",
      amtExcl1: 0,
      amtExclError1: "",
      amtExcl2: "",
      amtExclError2: "",
      amtExcl3: 0,
      amtExclError3: "",
      commissionRate1: 0,
      commissionRateError1: "",
      commissionRate2: 0,
      commissionRateError2: "",
      commissionRate3: 0,
      commissionRateError3: "",
      additionalCommission: 0,
      additionalCommissionError: "",
      fAndIBonusForSalesperson: 0,
      fAndIBonusForSalespersonError: "",
      flatCommissionOnly: 0,
      flatCommissionOnlyError: "",
      totalCommission: 0,
      totalCommissionError: "",
      splitCommision: false,
      noCommision: false,
      mailDescription: {},
      isClickMail: false,
      mailExcedePurId: "",
    };
  },
  beforeMount() {
    self = this;
  },
  created : function () {
      self = this;
      self.getLoginIdPermissions();
  },
  async mounted() {
    window.scrollTo(0, 0);
    /* Redirect to login page logic start */
    let userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }

    if(self.vehicleType !== "" && !self.isVehicleEdit) {
      self.typeId = {
        text: self.vehicleType
      }
    }

    self.showTrnsLoader = true;

    self.loadAllData(
      ["FuelType", "Make", "Model"],
      false,
      self.loadVehicleInformationFields,
      "undefined",
      ""
    );
    self.staticMessages = self.allStaticMessages;
    self.stateTaxRate = self.cusTaxRate;
    self.applySalesTax1 = self.cusSalesTax;
    self.customTaxRate = parseFloat(
      self.numberFormateValidation(self.warrantyTax) === false
        ? 0
        : self.warrantyTax
    );
    if (localStorage.getItem("AddOnTemp") != null) {
      localStorage.removeItem("AddOnTemp");
    }

    self.setActiveMenu("transaction-sheet");
    EventBus.$emit("menu-selection-changed", "transaction-sheet");

    await self.getStaticData();
    /*###### Edit #####*/
    if (self.isVehicleEdit) {
      await self.getAssetDataFromId(self.editAssetId);
    } else {
      self.isVinChange = true;
    }
    self.showTrnsLoader = false;
    
    self.newUsedCheck();
  },
  methods: {
    searchVin() {
      self.isClickSearchVinModal = true;
      $("#searchVinModal").modal({ backdrop: "static", keyboard: false });
    },

    searchUnit() {
      self.isClickSearchUnitModal = true;
      $("#searchUnitModal").modal({ backdrop: "static", keyboard: false });
    },

    /*##### POPULATE VHC-INFO ON SELECT VIN #####*/
    populateAssetData(asset) {
      self.vin = asset.text;
      if (!(asset.text == "" || asset.text == undefined)) {
        self.manufacturerId = "";
        self.modelOEMId = "";
        self.fuelTypeId = "";
        self.odometer = "";
        self.vehicleDescription = "";
        self.ofTires = 0;
        self.year = "";
        self.color = "";
        self.bodyType = "";
        self.scaleWeight = "";
        self.proposedDeliveryDate = "";
        self.populateFields();
      }
    },

    populateFields() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      self.showLoader();

      let data = new FormData();
      data.append("vinNo", self.vin);
      HTTP.post("/get-asset-data-with-vinNo", data, config)
        .then(function (response) {
          self.hideLoader();
          if (response.data.status === "success") {
            let assetData = response.data.content.asset;

            self.vehicleId = assetData.id;
            self.unitId = assetData.unit; // populating the unit Id field
            if (assetData.make !== null) {
              self.manufacturerId = {
                text: assetData.make.name,
                value: assetData.make.id,
              };
            }

            self.year = assetData.year; //populating the year field
            if (assetData.model_type !== null) {
              self.modelOEMId = {
                text: assetData.model_type.name,
                value: assetData.model_type.id,
              };
            }

            self.color = assetData.exterior_color; // populating the color field

            const stockOrderType = self.stockOrderArr.filter((stockType) => {
              return stockType.value == assetData.is_stock;
            });

            self.stockOrder = stockOrderType[0];

            if (assetData.body_type !== null) {
              self.bodyType = assetData.body_type.name; // populating the body Type field
            }

            self.typeId = {
              text: assetData.vehicle_type
            }// populating the N/U field

            if (assetData.fuel_type !== null) {
              const fuelTypeConst = self.fuelTypeArr.filter((fuelType) => {
                return fuelType.text == assetData.fuel_type.name;
              });
              self.fuelTypeId = fuelTypeConst[0];
            }

            self.odometer = assetData.odometer; // populating the odometer field

            self.scaleWeight = assetData.empty_weight; // populating the scaleWeight field

            self.vehicleDescription = assetData.description; // populating the vehicle Description field

            if(self.ofTires == 0 && assetData.tireConfig != null) {
              self.ofTires = assetData.tireConfig;
            }

            if(self.vehicleSalesPrice == 0) {
              self.vehicleSalesPrice = assetData.retail_price;
            }

            self.initialCal = true;
            self.calVehiclePrice();
            self.sumFees();
          }
        })
        .catch(function (err) {
          self.hideLoader();
          console.log("ERROR!!! 2060", err);
        });
    },

    calVehiclePrice: function () {
      try {
        if (self.isVinChange && self.initialCal) {
          self.SetByBranchDefaults();
        }
        self.calTireFee();
        let salesprice = self.calReverseVehiclePrice();
        let cusState = self.cusStateCode;
        let factoryInvoice = self.inventoryCostLastUpdated;
        let County = self.salesTaxCounty;

        /* Used by CA for New vehicles,  */
        if (self.factoryInvDate == "" && self.custom4 != 0) {
          self.custom4 = self.factoryPrice;
        }

        if (factoryInvoice == 0 && self.factoryPrice > 0) {
          factoryInvoice = self.factoryPrice;
        }

        self.calAddOnList();

        let branch = !(self.locationId == "" || self.locationId == undefined)
          ? self.locationId.excedeLocId
          : null;
        if (branch !== null) {
          if (self.financeReserve > 0) {
            if (branch == 115) {
              self.fAndIBonusForSalesperson = self.financeReserve * 0.1;
            } else if (["274", "275", "276", "277", "278", "279"].includes(branch)) {
              self.fAndIBonusForSalesperson = 0;
            } else if (branch == 510) {
              self.fAndIBonusForSalesperson = 0;
            } else if (
              ["360", "355", "367", "366", "361", "362", "363", "364", "365", "397", "398", "372", "374", "395", "396", "373"].includes(branch)
            ) {
              self.fAndIBonusForSalesperson = 0;
            } else if (["370", "380", "390", "381"].includes(branch)) {
              self.fAndIBonusForSalesperson = self.financeReserve * 0.05;
            } else {
              self.fAndIBonusForSalesperson = self.financeReserve * 0.01;
            }
          } else {
            self.fAndIBonusForSalesperson = 0;
          }

          let newused = self.typeId != null ? self.typeId.text.toLowerCase() : "";
          if (["410", "400", "500"].includes(branch) && newused == "used") {
            self.fAndIBonusForSalesperson = 0;
          }

          if (branch == "370") {
            self.businessTax = self.ccttsOnlyAdminFee;
            // if(!self.businessUse && !self.privateUse) // Either businessUse or privateUse at a time. Cant be both check and uncheck at a time.
            if (!self.businessUse && !self.privateUse) {
              // To do
              // confused in one of the samples we got business tax is calculated on sales only
              //var businessTax = (salesprice + poAmount + warrantysales ) * 0.003
              self.businessTax = salesprice * 0.003;
            } else if (self.businessUse) {
              self.businessTax = 0;
            }
            self.ccttsOnlyAdminFee = self.businessTax;
          }

          let TNAddedCost = 0;
          if (["370", "380", "367"].includes(branch)) {
            // added kingsport for ARC-49720011 // missing vtc-kingsport branch
            TNAddedCost += self.GetValue(self.docPreparation);
          }

          if (branch == "370") {
            TNAddedCost += self.businessTax;
          }

          let frtlRebate = 0;
          /*#### USED IN LINE NO 2374 ####
          let vendorRebate = 0;
          */

          let totalinternal = self.warranty + self.addonscost + TNAddedCost;

          /*#### USED IN LINE NO 2907 ####
          let costvehicle =
            factoryInvoice +
            totalinternal +
            frtlRebate +
            vendorRebate +
            self.fleetReserveCost; //+ flooring
          */

          // tbDeliverAddress holds a flag if the holdback was entered manually
          if (self.customHoldBack === false) {
            //JP * 5/3/21 * Add Triad Holdback
            // if([41,42,70,116,117,118,145,120,121,126,127,139,147,168,191,192,193,194,195,196,197,198].includes(branch))
            // {
            self.percentage = 0;
            self.holdBackFixed = 0;
            for (const data of self.vehiclePriceCalList) {
              if (data.is_used == newused) {
                self.holdBackFixed = data.hold_back_fixed;
                self.percentage = data.hold_back_percentage;
                break;
              }
            }
            // }
          }

          /*#### TO BE CONSIDER FOR MANUAL PERCENTAGE ####
            if (self.customHoldBack == true) {
              self.percentage = 0;
            }
          */

          if (self.percentage > 0) {
            //factoryInvoice is the current cost of the vehicle, used vehicles
            //factoryprice is custom field 2, new vehicles

            if (self.percentage == 2) {
              if (self.factoryPrice > 0)
                self.holdBack = parseFloat(self.factoryPrice) * parseFloat(self.percentage / 100);
              else if (["274", "275", "276", "277", "278", "279"].includes(branch)) {
                self.holdBack = parseFloat(salesprice) * parseFloat(self.percentage / 100);
              } else if (branch == "510") {
                self.holdBack = parseFloat(salesprice) * parseFloat(self.percentage / 100);
              } else
                self.holdBack =
                  (parseFloat(factoryInvoice) + parseFloat(frtlRebate)) * parseFloat(self.percentage / 100);
            } else if (self.percentage == 3) {
              if (["274", "275", "276", "277", "278", "279"].includes(branch)) {
                self.holdBack = parseFloat(salesprice) * parseFloat(self.percentage / 100);
              } else if (branch == 510) {
                self.holdBack = parseFloat(salesprice) * parseFloat(self.percentage / 100);
              } else {
                self.holdBack =
                  (parseFloat(factoryInvoice) +
                  parseFloat(totalinternal) +
                  parseFloat(frtlRebate) +
                  parseFloat(self.fleetReserveCost) -
                  parseFloat(self.warrantycost)) *
                  parseFloat(self.percentage / 100);
              }
            } else if (
              self.percentage == 1.5 &&
              ["274", "275", "276", "277", "278", "279"].includes(branch)
            ) {
              // && make.toLowerCase() == "landoll")
              self.holdBack = parseFloat(salesprice) * parseFloat(self.percentage / 100);
            } else if (self.percentage == 1.5 && branch == "510") {
              // && make.toLowerCase() == "landoll")
              self.holdBack = parseFloat(salesprice) * parseFloat(self.percentage / 100);
            } else {
              if (newused == "used") {
                self.holdBack =
                  (parseFloat(factoryInvoice) +
                  parseFloat(totalinternal) +
                  parseFloat(frtlRebate) +
                  parseFloat(self.fleetReserveCost)) *
                  parseFloat(self.percentage / 100);
              } else {
                self.holdBack =
                  (self.factoryPrice + self.fleetReserveCost) *
                  parseFloat(self.percentage / 100);
              }

              if (["410", "400"].includes(branch)) {
                self.holdBack =
                  (parseFloat(factoryInvoice) +
                  parseFloat(totalinternal) +
                  parseFloat(frtlRebate) +
                  parseFloat(self.fleetReserveCost)) *
                  parseFloat(self.percentage / 100);
              }
            }
          } else {
            self.holdBack = self.holdBackFixed;
          }

          self.holdBack = self.holdBack.toFixed(2);
          let cost =
            parseFloat(factoryInvoice) +
            parseFloat(self.addonscost) +
            parseFloat(self.fleetReserveCost) +
            parseFloat(TNAddedCost);
          self.costOfVehicleAndEquipmentIncludingFlooringCost = cost.toFixed(2);

          let totalvehiclecost =
            parseFloat(self.holdBack) +
            parseFloat(self.costOfVehicleAndEquipmentIncludingFlooringCost);
          self.totalvehiclecost = totalvehiclecost.toFixed(2);

          let TotalSalesPrice =
            parseFloat(salesprice) +
            parseFloat(self.addons) -
            parseFloat(self.warrantysales);
          self.frontendSales = TotalSalesPrice.toFixed(2);

          let tbPOAddAmount =
            parseInt(self.addons) - parseInt(self.warrantysales);
          self.poAmountToAddToSalesPrice = tbPOAddAmount.toFixed(2);

          if (newused == "used") {
            self.fetExempt = true;
          }

          // FET calculation
          let fet = 0;
          if (!self.fetExempt) {
            /**#### NO USE OF THIS CONDITION 
            if (self.packed) {
              fet = parseFloat(self.vehicleSalesPrice) * 0.12;
            }
            */
            if (!self.customFet) {
              fet = self.GetValue(
                (parseFloat(salesprice) +
                  parseFloat(self.addons) -
                  parseFloat(self.flooringadd) -
                  parseFloat(self.warrantysales) -
                  parseFloat(self.fetExemptItems) -
                  parseFloat(self.outofstate)) *
                  0.12 -
                  parseFloat(self.tireCredit)
              );
            } else {
              fet = self.GetValue(self.fet);
            }
          } else {
            self.fetExemptItems = 0;
          }
          self.fet = fet;
          self.fetTrade = fet;

          // CAL calculation
          let cal = 0;
          if (!self.customFee) {
            cal =
              (salesprice +
                (self.addons - self.warrantysales - self.flooringadd) +
                fet +
                self.customerSuppliedBody) *
              0.02 *
              0.325;
            if (self.gfwFee.length > 0) {
              let gvwWeight = self.gvwGCWR;
              if(gvwWeight == "" || gvwWeight == null) {
                gvwWeight = 0;
              }
              cal += self.gfwFeeCal(gvwWeight);
            }
            if (self.noLicense === false) {
              cal += self.licenseTitleStatic; // Merged all fee changes.
            }
            // Imp added this line to check county undefined if county not selected from transaction sheet page
            if (County !== "" && County !== null) {
              if (
                County == "Fresno" ||
                County == "Kings" ||
                County == "Madera" ||
                County == "San Joaquin" ||
                County == "Tulare" ||
                County == "Kern"
              ) {
                cal += 12;
              }
              if (County == "Kings" || County == "Los Angeles") {
                cal += 2;
              }
              if (County == "Merced") {
                cal += 24;
              }
              if (County == "Los Angeles") {
                cal += 7;
              }
              if (County == "Tulare") {
                cal += 11;
              }
              if (County == "Kern") {
                cal += 14;
              }
              if (County == "Alameda") {
                cal += 35;
              }
              if (County == "Fresno") {
                cal += 8;
              }
              if (County == "San Bernardino") {
                cal += 12;
              }
              if (County == "Marin" || County == "Sonoma") {
                cal += 2;
              }
              if (County == "San Diego") {
                cal += 5;
              }
              if (County == "Riverside") {
                cal += 3;
              }
              if (County == "Ventura") {
                cal += 4;
              }
              if (County == "San Luis Obispo") {
                cal += 5;
              }
              if (County == "San Mateo") {
                cal += 15;
              }
              if (County == "Santa Barbara") {
                cal += 7;
              }
              if (County == "Imperial") {
                cal += 7;
              }
              if (County == "Alameda") {
                cal -= 12;
              } // Barbara 12-21-22

              // depending on the county we need to add a new value, the values where updated on 01/01/2011
              if (self.gvwGCWR >= 10000) {
                if (newused == "used") {
                  cal += 115;
                  cal -= 4; // licence decrease 7/4/2011
                } else if (
                  County.indexOf("Orange") > -1 ||
                  County.indexOf("Riverside") > -1
                ) {
                  cal += 100; // added +5  10-13-20
                } else if (County.indexOf("Nevada") > -1) {
                  cal += 106;
                } else {
                  cal += 94;
                }
              }
              cal = Math.round(cal);
              let gastype = self.fuelTypeId !== undefined ? self.fuelTypeId.text : "";
              if (
                gastype == "LNG" ||
                gastype == "CNG" ||
                gastype == "Gas" ||
                gastype == "Propane"
              ) {
                cal += 20;
              }
            }
          } else {
            cal = self.GetValue(self.licenseTitle);
          }

          if (self.noLicense === true) {
            cal = 0;
            /*#### COMMENTED AS PER DISCUSSION ####
              self.registration = self.licenseTitleStatic;
            */
          }
          cal = Math.round(cal);
          self.licenseTitle = cal;
          /*#### UNUSED CALCULATION ####
          let TotalInclFees = salesprice + cal + self.tireFee;
          */

          self.tradeInAmount =
            parseFloat(self.tradevalue) - parseFloat(self.tradepayoff);
          self.stateTaxRate = parseFloat(
            !self.numberFormateValidation(self.cusStateTaxRate)
              ? 0
              : self.cusStateTaxRate
          );

          let salestaxrate = self.GetValue(self.cusStateTaxRate);
          /*#### UNUSED VARIABLE ####
          let countytaxrate = self.GetValue(self.cusTaxRate);
          let other2taxrate = self.GetValue(self.otherTax);
          */
          let othertaxrate = self.GetValue(self.customTaxRate);
          /* #### NOT REQUIRED AS PER CLIENT MEETING HELD ON December 14, 2023 ####
          let downpayment = self.GetValue(self.totalCashDown);
          */

          //If the checkbox is unchecked, then zero the sales tax in case the user hasn't done a Save on the Transactionsheet
          if (!self.applySalesTax1) {
            salestaxrate = 0;
          }

          self.backendSales = self.warrantysales;

          if (["277", "278", "275", "276", "274", "279", "249", "510"].includes(branch)) {
            self.docPreparation = self.docPreparation + self.ccttsOnlyAdminFee;
          }

          self.dealerFees = self.docPreparation;

          /*#### UNUSED CALCULATION ####
          let totalSales = self.backendSales + self.docPreparation;
          */
          self.totalSales = self
            .GetValue(
              parseFloat(self.frontendSales) +
                parseFloat(self.backendSales) +
                parseFloat(self.docPreparation) -
                parseFloat(self.tradeInAmount)
            )
            .toFixed(2);

          let statetax = 0;
          salesprice = parseFloat(salesprice);
          self.taxexclude = parseFloat(self.taxexclude);
          self.tradevalue = parseFloat(self.tradevalue);
          self.warrantysales = parseFloat(self.warrantysales);
          salestaxrate = parseFloat(salestaxrate);
          self.addons = parseFloat(self.addons);
          let warrantyTax = 0;

          if (branch == "310") {
            statetax = (
              (salesprice +
                self.addons -
                self.taxexclude -
                self.tradevalue -
                self.warrantysales) *
              0.083
            ); // overwrite tax caluclation for Tolleson, this is just very confusing --- 8.3 is hardcoded, requested by claudia after call on 9-14-2019
            statetax = statetax + 25;
          } else if (["320", "330", "340"].includes(branch)) {
            statetax = (
              (salesprice +
                self.addons -
                self.taxexclude -
                self.tradevalue -
                self.warrantysales) *
              (salestaxrate / 100)
            );
            statetax = statetax + self.warrantysales * warrantyTax; // check is warrantytax is in the table
          } else if (["370", "380", "390", "367"].includes(branch)) {
            //vtc - kingsport missing
            let adjustment = self.GetValue(self.dealAdjustment);
            let selectedTax = County;

            if (branch == "370" && selectedTax != "TN.NASHSA~0.07000") {
              let TaxableAmount =
                salesprice +
                self.addons -
                self.taxexclude -
                self.tradevalue +
                adjustment;
              statetax = TaxableAmount * (salestaxrate / 100);
            } else {
              let TaxableAmount =
                parseFloat(self.frontendSales) -
                self.taxexclude -
                self.tradevalue +
                adjustment; // - warranty docfee is add in the salesprice by default
              let TNTier1TaxAmount = 0;
              let TNTier2TaxAmount = 0;

              statetax = TaxableAmount * (salestaxrate / 100);

              if (selectedTax != "" && selectedTax !== undefined) {
                //Set TN Single Article Tax
                /*#### UNUSED VARIABLE ####
                let Tier1Rate = 0;
                let Tier2Rate = 0;
                */

                if (self.vehiclePriceCalList.length > 0) {
                  TNTier1TaxAmount =
                    self.vehiclePriceCalList[0].tn_tier_one_tax_amt;
                  TNTier2TaxAmount =
                    self.vehiclePriceCalList[0].tn_tier_two_tax_amt;
                }
              }

              if (statetax != 0) {
                statetax = statetax + (TNTier1TaxAmount + TNTier2TaxAmount);
              }
            }
          } else {
            // ARC-48349538
            let TaxableAmount =
              parseFloat(salesprice) +
              parseFloat(self.addons) +
              parseFloat(self.docPreparation) -
              parseFloat(self.taxexclude) -
              (cusState == "CA" ? 0 : parseFloat(self.tradevalue)) -
              parseFloat(self.warrantysales);

            statetax = self.GetValue(TaxableAmount) * (salestaxrate / 100);
          }

          let othertax = 0;
          if (self.applySalesTax2) {
            othertax = self.GetValue(self.warrantysales * (othertaxrate / 100));
          }
          self.customTaxAmount = othertax;

          if (self.applySalesTax1) {
            self.stateTaxAmount = statetax;
          } else {
            self.stateTaxAmount = 0;
            statetax = 0;
            // IMP the commented one to be verified
            // countytax = 0;
            // citytax = 0;
          }

          let OtherFees =
            parseFloat(self.registration) +
            parseFloat(self.smogFeePaidToSeller) +
            parseFloat(self.smogCertificationOrExemptionFeePaidToState) +
            parseFloat(self.dmvElectronicFillingFee) +
            parseFloat(self.flooring) +
            parseFloat(self.tireFee) +
            parseFloat(self.imfSalesTax); //- businessTax;
          self.otherCharges = OtherFees;

          self.otherTax = cal;
          let netsalesprice =
            self.frontendSales -
            self.tradeInAmount +
            fet +
            statetax +
            othertax -
            // parseFloat(downpayment) +   //Removed based on client meeting on 11/30/2023
            parseFloat(self.backendSales) +
            OtherFees +
            cal +
            self.docPreparation +
            parseFloat(self.businessTax);
          if (branch == "370") {
            if (newused == "used") {
              netsalesprice =
                self.frontendSales -
                self.tradeInAmount +
                fet +
                statetax +
                othertax -
                // parseFloat(downpayment) + //Removed based on client meeting on 11/30/2023
                parseFloat(self.backendSales) +
                OtherFees +
                cal;
            }
          }

          self.vehicleNetPrice = self.GetValue(netsalesprice).toFixed(2);
          self.grossProfit = self
            .GetValue(
              parseFloat(self.frontendSales) +
                parseFloat(self.warrantysales) -
                parseFloat(self.totalvehiclecost) -
                parseFloat(self.CommissionDeduct)
            )
            .toFixed(2); // - vehiclecostinclfees - outofstate

          self.lessHolback = self
            .GetValue(parseFloat(self.grossProfit) + parseFloat(self.holdBack))
            .toFixed(2);

          /*#### UNUSED VARIABLE ####
          let CalculatedComissionPer = 0;
          let CalculatedComission = 0;
          let AdditionalCommission = self.GetValue(self.additionalCommission);
          let FIBonus = self.GetValue(self.fAndIBonusForSalesperson);
          */
          let ComissionGrossProfitPer = 0;
          let ComissionGrossProfit = 0;
          /*#### USED IN LINE NO 2873 ####
          let ComissionNextLevel = 0;
          */

          self.amtExcl3 =
            self.GetValue(self.grossprofit) +
            self.GetValue(self.overAllowance) -
            self.GetValue(self.flooringexpense) -
            self.GetValue(self.flooring) +
            self.GetValue(self.flooringadd); //- flooring this was added again requested by Claudia on 9/25/2018
          if (self.totalvehiclecost != 0) {
            ComissionGrossProfitPer =
              (self.GetValue(self.grossprofit) +
                self.GetValue(self.holdBack) +
                self.GetValue(self.flooringadd)) /
              (self.GetValue(self.frontendSales) -
                self.GetValue(self.CommissionDeduct) -
                self.GetValue(self.bodyCost));

            ComissionGrossProfitPer =
              !ComissionGrossProfitPer ||
              Math.abs(ComissionGrossProfitPer) == Infinity
                ? 0
                : ComissionGrossProfitPer; // 7-18-19 HB: Divide by zero (NaN) protection; ARC-28465993 HB: Check for Infinity
          }

          if (self.commissionRate1 == 0 && self.flatCommissionOnly == 0) {
            self.commissionRate2 =
              self.vehiclePriceCalList.length > 0
                ? self.vehiclePriceCalList[0].calculated_commission_per
                : 0;
            /*#### USED IN LINE NO 2900 ####
            ComissionNextLevel =
              self.vehiclePriceCalList.length > 0
                ? self.vehiclePriceCalList[0].commission_next_level
                : 0;
            */
            self.commissionRate3 = self.amtExcl3 * self.commissionRate2;
            self.totalCommission = self.GetValue(
              self.commissionRate3 +
                self.additionalCommission +
                self.fAndIBonusForSalesperson
            );
          } else if (self.commissionRate1 != 0) {
            self.commissionRate3 = self.amtExcl3 * (self.commissionRate1 / 100);
            self.totalCommission =
              self.commissionRate3 +
              self.additionalCommission +
              self.fAndIBonusForSalesperson;
          }

          if (self.flatCommissionOnly != 0) {
            self.commissionRate3 = 0;
            self.commissionRate1 = 0;
            self.totalCommission =
              self.flatCommissionOnly +
              self.additionalCommission +
              self.fAndIBonusForSalesperson;
          }

          /*#### UNUSED VARIABLE ####
          let extwarranty = 0;
          if (ComissionNextLevel != 0) {
            let AddMoneyToLevel =
              costvehicle / ComissionNextLevel -
              self.poAmountToAddToSalesPrice -
              extwarranty;
            if (self.CommissionDeduct > 0) {
              AddMoneyToLevel +=
                self.CommissionDeduct -
                self.CommissionDeduct / ComissionNextLevel;
            }
          }
          */

          if (ComissionGrossProfitPer === Infinity) {
            ComissionGrossProfitPer = 0;
          }

          self.commissionRate3 =
            Math.round(self.commissionRate3 * 10000) / 10000;

          if (self.commissionRate3 < 0 || self.noCommision) {
            self.commissionRate3 = 0;
            self.totalCommission = 0;
            self.flatCommissionOnly = 0;
          }

          if (ComissionGrossProfitPer > 101) {
            ComissionGrossProfitPer = 0;
          }

          self.commissionRate2 = self.commissionRate2 * 100;
          ComissionGrossProfitPer = ComissionGrossProfitPer * 100;
          self.amtExcl3 = ComissionGrossProfit;

          self.amtExcl2 = ComissionGrossProfitPer;
          self.amtExcl1 = self.CommissionDeduct;
          self.totalCommission = self.GetValue(self.totalCommission);
          self.commissionRate3 = self.GetValue(self.commissionRate3);
        }

        if (self.loadCount == 0) {
          self.loadCount++;
        }

        self.isVinChange = true;
        return true;
      } catch (error) {
        console.log("errorCalVehicle=>", error);
      }
    },

    SetByBranchDefaults(data = null) {
      let newused = self.typeId != null ? self.typeId.text.toLowerCase() : "";
      /*#### UNUSED VARIABLE ####
      let branch = !(self.locationId == "" || self.locationId == undefined)
        ? self.locationId.value
        : null;
      */
      let isBranch = false;
      self.initialCal = false;
      if (
        self.branchDefaultCal.length > 0 &&
        self.branchDefaultCal[0].nrid === 0
      ) {
        let branchDefaultData = self.branchDefaultCal[0];
        self.docPreparation = branchDefaultData.doc_prepare_fee;
        self.tireTax = branchDefaultData.tire_tax;
        self.noLicense = false;
        if(branchDefaultData.no_license) {
          self.noLicense = true;
        }
        self.percentage = branchDefaultData.hold_back_percentage;
        self.licenseTitle = branchDefaultData.license_title;
        isBranch = true;
      }
      if (!isBranch) {
        self.docPreparation = newused == "new" ? 85 : 70;
        self.tireTax = 1.75;
      }
      return true;
    },

    calTireFee() {
      try {
        let NoTires = self.GetValue(self.ofTires);
        let Branch = !(self.locationId == "" || self.locationId == undefined)
          ? self.locationId.text.toLowerCase()
          : null;
        if (NoTires > 0 && Branch !== null) {
          self.calTireFeeCondition(NoTires, Branch);
        } else {
          self.tireFee = 0;
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },

    calTireFeeCondition(NoTires, Branch) {
      let NewUsed = self.typeId != null ? self.typeId.text.toLowerCase() : "";
      if (NewUsed == "new") {
        if (
          Branch.indexOf("nashville") > -1 ||
          Branch.indexOf("madison") > -1
        ) {
          if (NoTires <= 4) {
            self.tireFee = 5;
          } else if (NoTires >= 11) {
            self.tireFee = 15;
          } else {
            self.tireFee = 10;
          }
        } else {
          self.tireFee = parseFloat(NoTires * self.tireTax);
        }
      } else {
        self.tireFee = parseFloat(NoTires * self.tireTax);
      }
      return true;
    },

    calReverseVehiclePrice() {
      if (!self.packed) {
        return self.GetValue(self.vehicleSalesPrice);
      } // return existing price;
      let NewUsed = self.typeId != null ? self.typeId.text.toLowerCase() : "";
      let fetCustom = self.customFet;
      /*#### UNUSED VARIABLE ####
      let fetExempt = self.fetExempt;
      */

      if (NewUsed == "used") {
        /* do do nothing
          document.getElementById("tbSalesPrice_tbCurrency").value = totalsales;
          formatCurrency("", "tbSalesPrice_tbCurrency");*/
        console.log("do nothing");
      } else if (!fetCustom) {
        let totalsales = self.GetValue(self.priceFetIncluded);
        let overallowance = self.GetValue(self.vehicleSalesPrice);
        let tirecredit = self.GetValue(self.tireCredit);
        let poamount = self.GetValue(self.poAmountToAddToSalesPrice);
        let fetexclude = 0; //self.poAmountToAddToSalesPrice
        /*#### UNUSED VARIABLE ####
        let factoryInvoice = self.GetValue(self.inventoryCostLastUpdated);
        let holdback = self.GetValue(self.holdBack);
        */
        let hasAddons = true;
        if(self.addOnPoList.length == 0) {
          hasAddons = false;
        } 
        let deductOverAllowance = 0;
        if (hasAddons) {
          /*#### UNUSED VARIABLE ####
          let miscTireCredit = 0;
          */

          let calAddOnList = self.calCreditInternalFetExclude();
          fetexclude = calAddOnList.fetexclude;
          /*#### UNUSED VARIABLE ####
            credits = calAddOnList.credits;
            internals = calAddOnList.internals;
          */

          // Calculate baseprice
          if (overallowance < 0) {
            deductOverAllowance = overallowance * -1;
          }
          /*#### UNUSED VARIABLE ####
          let totalcost =
            factoryInvoice + holdback - credits + internals + poamount;
          let totalcostoverallowance = totalcost + deductOverAllowance;
          */
        }

        /*#### UNUSED CALCULATION ####
        let baseprice =
          (totalsales -
            1.12 * poamount +
            1.12 * deductOverAllowance -
            (fetexclude + tirecredit)) /
          1.12;
        baseprice = Math.round(baseprice * 10000) / 10000; // CDK-133068800 + (0.12 * deductOverAllowance)
        */

        // To-Do on confirmed either to use only inside hasaddons
        let baseprice =
          (totalsales -
            1.12 * poamount +
            1.12 * deductOverAllowance -
            (fetexclude + tirecredit)) /
          1.12;
        baseprice = self.GetValue(Math.round(baseprice * 10000) / 10000); 
        self.vehicleSalesPrice = baseprice;
        return baseprice;
      }

      return self.GetValue(self.vehicleSalesPrice); // return existing price;
    },

    calCreditInternalFetExclude() {
      let credits = 0;
      let internals = 0;
      let fetexclude = 0;
      self.addOnPoList.map((list) => {
        let cost = parseFloat(list.cost);
        let sales = parseFloat(list.price);

        let FETExclude = list.is_fet_exempt;
        /*#### UNUSED VARIABLE ####
        let Description = list.description;
        */
        if (FETExclude == 1) {
          fetexclude += sales;
        }
        if (cost < 0) {
          credits += cost * -1;
        }
        if (cost > 0 && sales == 0) {
          internals += cost;
        }
      });

      return {
        fetexclude: fetexclude,
        credits: credits,
        internals: internals
      };
    },

    calAddOnList() {
      //Check for Procede Addons and pull those amounts
      let hasAddons = true;
      let CommissionDeduct = 0;
      let addonscost = 0;
      let warrantysales = 0;
      let warrantycost = 0;
      let flooringadd = 0;
      let addons = 0;
      let fetexclude = 0;
      let outofstate = 0;
      let taxexclude = 0;
      let transactionWarranty = 0;

      if (self.addOnPoList.length == 0) {
        hasAddons = false;
      }

      let miscTireCredit = 0;
      if (hasAddons) {
        self.addOnPoList.map((list) => {
          let type = list.purchase_order_id == null ? "Adds" : "PO";
          let cost = parseFloat(list.cost);
          let sales = parseFloat(list.price);
          let addonType = list.purchase_order_id == null ? "Adds" : "PO";

          let AddToSalesPrice = list.is_add_to_total_price;
          let DoNotAddToGP = list.is_do_not_add_to_gp;
          let FETExclude = list.is_fet_exempt;
          let Description = list.description;
          if (addonType.toLowerCase() == "tire credit") {
            miscTireCredit += sales;
          }
          if (Description.includes("C-AR") && DoNotAddToGP == 1) {
            cost = 0;
          }

          if (DoNotAddToGP == 1) {
            CommissionDeduct += sales;
          }

          if (Description.substring(0, 2) == "NA") {
            let po = Description.substring(0, 15);
            if (po.substring(11, 12) != ":" && po.substring(14, 15) != "#") {
              addonscost += cost;
            }
          } else {
            addonscost = (Description.indexOf("FINANCE INCOME") == -1 &&
              Description.indexOf("COMM ACCRUAL") == -1 &&
              Description.indexOf("RESERVE") == -1) ? (addonscost + cost) : addonscost;
          }

          if (
            Description.indexOf("W-WFI") > -1 ||
            Description.indexOf("W-WAR") > -1
          ) {
            warrantysales += sales;
            warrantycost += cost;
          }

          if (Description.indexOf("E-FPE") > -1) {
            flooringadd += sales;
          }

          if (
            AddToSalesPrice == 1 &&
            type !== "Service Contract" &&
            type !== "Warranty"
          ) {
            //|| type === "Insurance")
            addons += sales;
          }

          if (
            FETExclude == 1 &&
            type !== "Service Contract" &&
            type !== "Tire Credit"
          ) {
            if (
              Description.indexOf("E-FPE") == -1 &&
              Description.indexOf("E-DEL") == -1 &&
              Description.indexOf("W-WFI") == -1 &&
              Description.indexOf("W-WAR") == -1
            ) {
              fetexclude += sales;
            }
          }

          if (Description.indexOf("E-DEL") > -1) {
            outofstate += sales;
          }

          if (list.tax != null) {
            //this condition is applied because AddOns and PO Item both forms data are stored in same table seperated/flaged by purchase_order_id
            let TaxExclude = list.tax;
            if (
              TaxExclude.substring(0, 2) === "EX" &&
              Description.indexOf("W-WFI") === -1 &&
              Description.indexOf("W-WAR") === -1 &&
              AddToSalesPrice == 1
            ) {
              taxexclude += sales;
            }
          }

          // To emit the warranty value in TransactionSheet Cash Details Warranty field
          if (
            Description == "W-WAR - WARRANTY <ENTER DETAILS> [PRICE & COST]"
          ) {
            transactionWarranty += sales;
          }
        });
      }

      self.cashDetailWarranty = transactionWarranty;

      if (miscTireCredit > 0) {
        self.tireCredit = miscTireCredit.toFixed(2);
      }
      self.CommissionDeduct = CommissionDeduct.toFixed(2);
      self.addonscost = addonscost.toFixed(2);
      self.warrantysales = warrantysales.toFixed(2);
      self.warrantycost = warrantycost.toFixed(2);
      self.flooringadd = flooringadd.toFixed(2);
      self.addons = addons.toFixed(2);
      self.fetexclude = fetexclude.toFixed(2);
      self.outofstate = outofstate.toFixed(2);
      self.taxexclude = taxexclude.toFixed(2);
      self.totalInternalLocalInstallationLessOutOfStateDeliveryCost =
        self.addonscost;
      self.fetExemptItems = self.fetexclude;
    },

    GetValue(value) {
      if (isNaN(value) || value === "" || value === undefined) {
        value = 0;
      }
      return value;
    },

    gfwFeeCal(weight) {
      let val = 0;
      for (let i = 0; i < self.gfwFee.length; i++) {
        let prev_fee = self.gfwFee[i - 1];
        let curr_fee = self.gfwFee[i];
        let weight_range = curr_fee.weight_range.split("-");
        val = curr_fee.cvra_fee;
        if (weight < weight_range[0]) {
          return parseFloat(prev_fee.cvra_fee);
        }
      }
      return val;
    },

    // Mount
    getStaticData: function () {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: self.locationId.value,
        },
      };
      return new Promise(function (resolve, reject) {
        HTTP.get("/get-all-static-data", config)
        .then(function (response) {
          self.branchDefaultCal = response.data.content.datas.branch_defaults;
          self.gfwFee = response.data.content.gfwList;
          if (response.data.content.datas.cal_vehicle_prices.length > 0) {
            self.vehiclePriceCalList =
              response.data.content.datas.cal_vehicle_prices;
            self.dmvElectronicFillingFee =
              self.vehiclePriceCalList[0].dmv_electronic_filling_fee;
            self.licenseTitleStatic = self.vehiclePriceCalList[0].license_title;
          }

          if(!self.isVehicleEdit) {
            self.SetByBranchDefaults();
          }
          resolve(response);
        })
        .catch(function (err) {
          console.log("Error on fetching static data", err);
          self.hideLoader();
          self.catchHandler(err, function () {});
        });
      })
    },

    incrementDecrementNumber(event, dataInstance) {
      switch (event.key) {
        case "ArrowUp":
          this[dataInstance] = parseFloat(this[dataInstance])+1;
          break;
        case "ArrowDown":
          this[dataInstance] = parseFloat(this[dataInstance])-1;
          break;
      }
    },

    checkUncheck: function (event, type) {
      if(event.target.checked) {
        this[type] = true;
      } else {
        this[type] = false;
      }
      if (type == "noLicense" || type == "fetExempt" || type == "customFet") {
        self.sumFees();
      }
      self.calVehiclePrice();
    },

    // Fuel
    loadVehicleInformationFields: function (loadVehicleInformationCallback) {
      loadVehicleInformationCallback.FuelType.map((fuel) => {
        self.fuelTypeArr.push(fuel);
      });

      loadVehicleInformationCallback.Make.map((manufacture) => {
        self.manufacturerArr.push(manufacture);
      });

      loadVehicleInformationCallback.Model.map((model) => {
        self.modelOEMArr.push(model);
      });
    },

    /*##### Show the sum of fees section on its header #####*/
    sumFees: function () {
      self.feesTotal =
        parseFloat(self.docPreparation, 2) +
        parseFloat(self.customerSuppliedBody, 2) +
        parseFloat(self.licenseTitle, 2) +
        parseFloat(self.tireFee, 2) +
        parseFloat(self.registration, 2) +
        parseFloat(self.flooring, 2) +
        parseFloat(self.smogFeePaidToSeller, 2) +
        parseFloat(self.tireCredit, 2) +
        parseFloat(self.smogCertificationOrExemptionFeePaidToState, 2) +
        parseFloat(self.fet, 2) +
        parseFloat(self.dmvElectronicFillingFee, 2) +
        parseFloat(self.ccttsOnlyAdminFee, 2); // According to the mapping Arcadium calculation remove parseFloat(self.fetExemptItems, 2)
      return true;
    },

    /*##### AddOn #####*/
    addOnModal: function () {
      self.isClickAddOnList = true;
      self.addOnEditData = {};
      self.addOnAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
      $("#addOnModal").modal({
          backdrop: "static",
          keyboard: false,
        });
    },

    editAddOn: function (data, index) {
      if(data.itm_typ_id == null) {
        self.addOnEditData = data;
        self.isClickAddOnList = true;
        self.addOnTempIndex = index;
        self.addOnAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
        $("#addOnModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {

        self.poEditAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;

        /*##### EDIT PO WHEN VEHICLE IS NOT SAVED, CONSIDER MULTIPLE POITEM DURING EDIT #####*/
        let editPO = null;
        if(self.poEditAssetId == null) {
          editPO = self.poData.filter((po,key) => {
            return key == data.po_index;
          });
          
          this.poIndex = data.po_index;

          /*## FILTER ADDON TABLE LIST & THEN TAKE DATA WHICH HAVE KEY 'po_index' ##*/
          let poItemList = self.addOnPoList.filter((poList,key) => {
            poList['ind'] = key;
            return Object.keys(poList).includes('po_index');
          });
          /*## MAP ABOVE poItemList DATA WITH SELECTED DATA W.R.T 'po_index' ##*/
          let poItemIndexes = self.poItemIndexMap(poItemList, data.po_index);
          editPO[0]['current_item_index'] = poItemIndexes;
        }
        self.poEditData = (self.poEditAssetId !== null) ? data : editPO[0];
        /*##### END #####*/
        self.isClickPurchaseOrder = true;
        
        $("#vehiclePurchaseModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      }
    },

    poItemIndexMap(poItemList,poIndex) {
      let poItemIndexes = [];
      poItemList.map((item) => {
        if(item.po_index == poIndex) {
          poItemIndexes.push(item.ind);
        }
      });
      return poItemIndexes;
    },

    /*##### PURCHASE ORDER #####*/
    purchaseOrderModal: function () {
      if (self.locationId == "") {
        self.showSAlert(
          "Please Select Branch from TransactionSheet",
          "info",
          false,
          2
        );
      } else if (self.vin == "") {
        self.showSAlert("Please Select Inventory", "info", false, 2);
      } else {
        self.poEditAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
        self.poEditData = {};
        self.isClickPurchaseOrder = true;
        $("#vehiclePurchaseModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      }
    },

    /*##### COST SHEET #####*/
    vehicleCostSheet: function () {
      if (self.vin == "") {
        self.showSAlert("Please Select Inventory", "info", false, 2);
      } else {
        self.isClickCostSheet = true;
        $("#defaultModal").modal({ backdrop: "static", keyboard: false });
      }
    },

    /*##### FLOORING CALCULATION #####*/
    calFlooring: function () {
      self.isClickCalFlooring = true;
      $("#calculateFlooring").modal({ backdrop: "static", keyboard: false });
    },

    /*##### SAVE #####*/
    saveCloseWorkSheet: async function () {
      if (self.validForm()) {
        self.showTrnsLoader = true;
        /*#### UNUSED VARIABLE ####
        let totalAmount = self.grandTotalCostSheet + self.sumOfFees;
        */
        if (
          self.dealId !== null &&
          (self.dealId !== 0) && (self.dealId !== undefined)
        ) {
          await self.saveDealInventory();
          self.storeAddOnPoInBulk();
          if(!self.isTradeSave && self.tradeDetailsData !== null) {
            self.storeTradeDetailsInBulk();
          }
          EventBus.$emit(
            "cash-details-data",
            self.stateTaxAmount,
            self.customTaxAmount,
            self.cashDetailWarranty
          );
          self.showTrnsLoader = false;
          self.saveNotNull = true;
          if (self.vehicleData == "") {
            localStorage.setItem(
              "dealInventoryMapperId",
              self.dealInventoryMapperId
            );
            localStorage.setItem("vehicleId", self.vehicleId);
          }
        } else {
          self.saveNotNull = false;
        }
        self.showTrnsLoader = false;
      }
    },

    storeAddOnPoInBulk() {
      if (
        (localStorage.getItem("AddOnTemp") != null &&
        JSON.parse(localStorage.getItem("AddOnTemp")).length > 0) ||
        self.poData.length > 0
      ) {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };

        let data = new FormData();
        data.append("data", localStorage.getItem("AddOnTemp"));
        data.append("deal_inventory_id", self.dealInventoryMapperId);
        let addOnLocal = false;
        if(localStorage.getItem("AddOnTemp") != null && JSON.parse(localStorage.getItem("AddOnTemp")).length > 0) {
          addOnLocal = true;
        }
        data.append("add_on_local", addOnLocal);
        if(self.poData.length > 0) {
          data.append("po_data", JSON.stringify(self.poData));
        }

        HTTP.post("store/add-on-po", data, config)
          .then(function (response) {
            if(response.data.status == "success") {
              localStorage.removeItem("AddOnTemp");
              self.poData = [];
              self.addOnPoList = response.data.content;
            }
            return true;
          })
          .catch(function (err) {
            console.log("error=>", err);
            self.catchHandler(err, function () {});
            self.hideLoader();
          });
      }
      return true;
    },

    storeTradeDetailsInBulk() {
      self.tradeDetailsData.deal_inventory_mapper_id = self.dealInventoryMapperId;
      let config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
      };
      HTTP.post("trade/details", self.tradeDetailsData, config).then((response) => {
        let content = response.data.content;
        self.tradeDetailsData = content;
        self.isTradeSave = true;
      }); 
      return true;
    },

    saveDealInventory: function () {
      try {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        let data = new FormData();
        // foreign keys
        data.append("deal_id", self.dealId);
        data.append("inventory_id", self.vehicleId !== null ? self.vehicleId : null);
        data.append("makeId", self.manufacturerId.value);
        data.append("modelId", self.modelOEMId.value);
        data.append("prop_delivery_date", self.proposedDeliveryDate);
        data.append("is_delivered", self.isDelivered);

        // Custom Fields
        data.append("gvw", self.gvwGCWR);
        data.append("doc_prepare_fee", self.docPreparation);
        data.append("customer_supply_body", self.customerSuppliedBody);
        data.append("license_title", self.licenseTitle);
        data.append("is_no_license", self.noLicense);
        data.append("is_customer_fee", self.customFee);
        data.append("is_fet_exempt", self.fetExempt);
        data.append("is_custom_fet", self.customFet);
        data.append("is_private_use", self.privateUse);
        data.append("is_business_use", self.businessUse);
        data.append("is_split_commission", self.splitCommision);
        data.append("is_no_commission", self.noCommision);

        data.append("tire_fee", self.tireFee);
        data.append("registration", self.registration);
        if(self.deliveryDelayCredit > self.initFlooring || self.typeId.text.toLowerCase() == "new") {
          data.append("flooring", self.initFlooring);
        } else {
          data.append("flooring", self.flooring);
        }
        data.append("smog_fee_paid_to_seller", self.smogFeePaidToSeller);
        data.append("tire_credit", self.tireCredit);
        data.append(
          "smog_cert_pay_to_state",
          self.smogCertificationOrExemptionFeePaidToState
        );
        data.append("fet_examp_items", self.fetExemptItems);
        data.append("fet", self.fet);
        data.append("dmv_electronic_filling_fee", self.dmvElectronicFillingFee);
        data.append("cctts_admin_fee", self.ccttsOnlyAdminFee);
        data.append("finance_reserved", self.financeReserve);
        data.append("factory_price", self.factoryPrice);
        data.append("fleet_reserve_cost", self.fleetReserveCost);
        data.append("body_cost", self.bodyCost);
        data.append("adot_permit", self.adotPermit);
        data.append("fleet_reserve_ctrl", self.fleetReserveCtrl);
        data.append("hold_date", self.holdDate);
        data.append("factory_invo_date", self.factoryInvDate);

        //Vehicle Cost Calculation
        data.append(
          "current_inventory_cost",
          self.currentInventoryCostFromProcede
        );
        data.append(
          "inventory_cost_last_updated",
          self.inventoryCostLastUpdated
        );
        data.append(
          "local_installation",
          self.totalInternalLocalInstallationLessOutOfStateDeliveryCost
        );
        data.append(
          "veh_cost_equip",
          self.costOfVehicleAndEquipmentIncludingFlooringCost
        );
        data.append("hold_back_fixed", self.holdBackFixed);
        data.append("custom_hold_back", self.customHoldBack);
        data.append("percentage", self.percentage);
        data.append("gross_profit_amt", self.grossProfitDollarAmount);

        // Total Vehicle Cost, Vehicle Fixed Margin
        // Trade Detail
        data.append("trade_amount", self.tradeInAmount);
        data.append("backend_sales", self.backendSales);
        data.append("dealer_fees", self.dealerFees);
        data.append("total_sales", self.totalSales);
        data.append("other_charges", self.otherCharges);
        data.append("veh_fet", self.fetTrade);
        data.append("state_tax_amt", self.stateTaxAmount);
        data.append("state_tax_rate", self.stateTaxRate);
        data.append("state_apply_sales_tax", self.applySalesTax1);
        data.append("warrant_apply_sales_tax", self.applySalesTax2);
        data.append("warranty_tax", self.customTaxAmount);
        data.append("custom_tax_rate", self.customTaxRate);

        data.append("other_tax", self.otherTax);
        // data.append("total_cash_down", self.totalCashDown); // Removed based on client meeting on 12/14/2023
        data.append("vehicle_net_price", self.vehicleNetPrice);

        // Commission Estimate
        data.append("commission_gross_profit_amt", self.grossProfit); // to-do: figure out if self.grossProfit is for gross_profit_amt or commission_gross_profit_amt
        data.append("commission_less_holdback", self.lessHolback);
        data.append("commission_our_allowance", self.overAllowance);

        data.append("commissionable_gross_profit", self.overAllowance);
        data.append("commission_amt_excl", self.amtExcl1);
        data.append("commission_rate", self.commissionRate1);

        data.append("additional_commission", self.additionalCommission);
        data.append("fi_bonus_for_salesperson", self.fAndIBonusForSalesperson);
        data.append("flat_commission_only", self.flatCommissionOnly);
        data.append("total_commission", self.totalCommission);
        data.append("grand_total_cost_sheet", self.grandTotalCostSheet);
        data.append("total_vehicle_cost", self.totalvehiclecost);
        data.append("packed", self.packed);
        data.append("base_sales_price", self.vehicleSalesPrice);
        data.append("price_fet_included", self.priceFetIncluded);
        data.append("of_tires", self.ofTires);
        data.append("gross_profit_percent", self.grossProfitPercent);
        data.append("gross_profit_sales_price", self.grossProfitSalesPrice);
        data.append("tire_tax", self.tireTax);
        data.append("frontend_sales", self.frontendSales);

        /*##### VHC INFO SECTION #####*/
        data.append("vin", self.vin);
        data.append("manufacturer", self.manufacturerId.text);
        data.append('model', self.modelOEMId.text);
        data.append('new_used', self.typeId.text);
        data.append('fuel_type', self.fuelTypeId !== undefined ? self.fuelTypeId.text : null );
        data.append('odometer', self.odometer);
        data.append('vehicle_description', self.vehicleDescription);
        data.append('quantity', self.quantity);
        data.append('unit', self.unitId);
        data.append('year', self.year);
        data.append('color', self.color);
        data.append('body_type', self.bodyType);
        data.append('scale_weight', self.scaleWeight);
        data.append('of_batteries', self.ofBatteries);
        data.append('of_axles', self.ofAxles);
        data.append('duty', self.duty !== undefined ? self.duty.value : null);
        data.append('stock_or_order', self.stockOrder !== undefined ? self.stockOrder.value : null);
        data.append('stock_type', self.stockTypeId !== undefined ? self.stockTypeId.value : null);
        data.append('hold_back', self.holdBack);
        data.append('flooring_start_date', self.flooringStartDate);
        data.append('flooring_end_date', self.flooringEndDate);
        data.append('flooring_rate', self.flooringRate);
        data.append('delivery_delay_credit', self.deliveryDelayCredit);

        if (self.isVehicleEdit) {
          data.append("id", self.vehicleDataId);
        } else if (self.dealInventoryMapperId != null) {
          data.append("id", self.dealInventoryMapperId);
        }

        return new Promise(function (resolve, reject) {
          HTTP.post("/add/deal-inventory-mapper", data, config)
            .then(function (response) {
              const dealInventoryMapperResponse = response.data.content;
              self.dealInventoryMapperId = dealInventoryMapperResponse.id;
              self.showSAlert(
                response.data.message,
                response.data.status,
                false,
                2
              );
              resolve(response);
            })
            .catch(function (err) {
              self.catchHandler(err, function () {});
              self.hideLoader();
              reject(err);
            });
        });
      } catch (error) {
        console.log("saveDealError=>", error);
      }
    },
    /*##### END #####*/

    /*##### EDIT #####*/
    getAssetDataFromId: function (id) {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          'edit': true,
        }
      };
      return new Promise(function (resolve, reject) { 
        HTTP.get("deal/"+id+"/inventory/mapper", config)
        .then(function (response) {
          let vehicleData = response.data.content;
          self.vin = vehicleData.vin;
          // Fees
          self.gvwGCWR = vehicleData.gvw;
          self.docPreparation = vehicleData.doc_prepare_fee;
          self.customerSuppliedBody = parseFloat(
            vehicleData.customer_supply_body
          );
          self.licenseTitle = parseFloat(vehicleData.license_title);
          self.tireFee = vehicleData.tire_fee;
          self.flooring = vehicleData.flooring;
          self.registration = vehicleData.registration;
          self.tireCredit = vehicleData.tire_credit;
          self.smogFeePaidToSeller = vehicleData.smog_fee_paid_to_seller;
          self.smogCertificationOrExemptionFeePaidToState =
            vehicleData.smog_cert_pay_to_state;
          self.dmvElectronicFillingFee = vehicleData.dmv_electronic_filling_fee;
          self.fet = vehicleData.fet;
          self.fetExemptItems = parseFloat(vehicleData.fet_examp_items);
          self.totalvehiclecost = vehicleData.total_vehicle_cost;
          self.noLicense = false;
          if(vehicleData.is_no_license) {
            self.noLicense = true;
          }
          self.customFee = false;
          if(vehicleData.is_customer_fee) {
            self.customFee = true;
          }
          self.customFet = false;
          if(vehicleData.is_custom_fet) {
            self.customFet = true;
          }
          self.fetExempt = false;
          if(vehicleData.is_fet_exempt) {
            self.fetExempt = true;
          }

          // Deal Inventory
          self.ccttsOnlyAdminFee = vehicleData.cctts_admin_fee;
          self.vehicleId = vehicleData.inventory_id !== null ? vehicleData.inventory_id : null;
          self.financeReserve = vehicleData.finance_reserved;
          self.factoryPrice = vehicleData.factory_price;
          self.fleetReserveCost = vehicleData.fleet_reserve_cost;
          self.bodyCost = vehicleData.body_cost;
          self.adotPermit = vehicleData.adot_permit;
          self.fleetReserveCtrl = vehicleData.fleet_reserve_ctrl;
          self.holdDate = vehicleData.hold_date ? moment(vehicleData.hold_date).format("MM/DD/YYYY") : "";
          self.factoryInvDate = vehicleData.factory_invo_date ? moment(vehicleData.factory_invo_date).format("MM/DD/YYYY") : "";
          self.currentInventoryCostFromProcede =
            vehicleData.current_inventory_cost;
          self.inventoryCostLastUpdated =
            vehicleData.inventory_cost_last_updated;
          self.totalInternalLocalInstallationLessOutOfStateDeliveryCost =
            vehicleData.local_installation;
          self.costOfVehicleAndEquipmentIncludingFlooringCost =
            vehicleData.veh_cost_equip;
          self.holdBackFixed = vehicleData.hold_back_fixed;
          self.percentage = vehicleData.percentage;
          self.tradeInAmount = vehicleData.trade_amount;
          self.backendSales = vehicleData.backend_sales;
          self.dealerFees = vehicleData.dealer_fees;
          self.totalSales = vehicleData.total_sales;
          self.otherCharges = vehicleData.other_charges;
          self.stateTaxAmount = vehicleData.state_tax_amt;
          self.stateTaxRate = vehicleData.state_tax_rate;
          self.applySalesTax1 = false;
          if(vehicleData.state_apply_sales_tax == "yes") {
            self.applySalesTax1 = true;
          }
          self.customTaxAmount = vehicleData.warranty_tax;
          self.customTaxRate = vehicleData.custom_tax_rate;
          self.applySalesTax2 = false;
          if(vehicleData.warrant_apply_sales_tax == "yes") {
            self.applySalesTax2 = true;
          }
          self.otherTax = vehicleData.other_tax;
          // self.totalCashDown = vehicleData.total_cash_down; // Removed based on client meeting on 12/14/2023
          self.vehicleNetPrice = vehicleData.vehicle_net_price;
          self.grossProfit = vehicleData.commission_gross_profit_amt;
          self.lessHolback = vehicleData.commission_less_holdback;
          self.overAllowance = vehicleData.commission_our_allowance;
          self.additionalCommission = vehicleData.additional_commission;
          self.fAndIBonusForSalesperson = vehicleData.fi_bonus_for_salesperson;
          self.flatCommissionOnly = vehicleData.flat_commission_only;
          self.totalCommission = vehicleData.total_commission;
          self.vehicleDataId = vehicleData.id;
          self.addOnPoList = vehicleData.add_on_deals;
          self.vehicleSalesPrice = vehicleData.base_sales_price;
          self.ofTires = vehicleData.of_tires;
          self.priceFetIncluded = vehicleData.price_fet;
          self.packed = vehicleData.is_packed;
          self.grossProfitDollarAmount = vehicleData.gross_profit_amt;
          self.grossProfitPercent = vehicleData.gross_profit_percent;
          self.grossProfitSalesPrice = vehicleData.gross_profit_sales_price;
          self.customHoldBack = false;
          if(vehicleData.custom_hold_back == "yes") {
            self.customHoldBack = true;
          }
          self.privateUse = vehicleData.is_private_use;
          self.businessUse = vehicleData.is_business_use;
          self.splitCommision = false;
          if(vehicleData.is_split_commission) {
            self.splitCommision = true;
          }
          self.noCommision = false;
          if(vehicleData.is_no_commission) {
            self.noCommision = true;
          }
          self.tireTax = vehicleData.tire_tax;
          self.frontendSales = vehicleData.frontend_sales;
          self.proposedDeliveryDate = vehicleData.prop_delivery_date
            ? moment(vehicleData.prop_delivery_date).format("MM/DD/YYYY")
            : "";
          self.isDelivered = vehicleData.is_delivered;
          /*##### VHC INFO SECTION #####*/
          self.vin = vehicleData.vin;
          self.manufacturerId = {
            text: vehicleData.manufacturer,
            value: vehicleData.make_id,
          };
          self.modelOEMId = {
            text: vehicleData.model,
            value: vehicleData.model_id,
          };
          self.typeId.text = vehicleData.new_used;
          self.odometer = vehicleData.odometer;
          self.vehicleDescription = vehicleData.vehicle_description;
          self.quantity = vehicleData.quantity;
          self.unitId = vehicleData.unit;
          self.year = vehicleData.year;
          self.color = vehicleData.color;
          self.bodyType = vehicleData.body_type;
          self.scaleWeight = vehicleData.scale_weight;
          self.ofBatteries = vehicleData.of_batteries;
          self.ofAxles = vehicleData.of_axles;
          self.holdBack = vehicleData.hold_back;

          const dutyConst = self.dutyArr.filter((duty) => {
            return duty.value == vehicleData.duty;
          });
          self.duty = dutyConst[0];

          const stockOrderConst = self.stockOrderArr.filter((stockOrder) => {
            return stockOrder.value == vehicleData.stock_or_order;
          });
          self.stockOrder = stockOrderConst[0];

          const stockTypeConst = self.stockTypeArr.filter((stockType) => {
            return stockType.value == vehicleData.service_plan;
          });
          self.stockTypeId = stockTypeConst[0];

          const fuelTypeConst = self.fuelTypeArr.filter((fuelType) => {
            return fuelType.text == vehicleData.fuel_type;
          });
          self.fuelTypeId = fuelTypeConst[0];
          self.tradeDetailsData = vehicleData.trade_detail;
          
          if(self.tradeDetailsData !== null) {
            self.tradevalue = self.tradeDetailsData.trade_in_value;
            self.tradepayoff = self.tradeDetailsData.payoff;
            self.isTradeSave = true;
          }
          
          self.flooringStartDate = vehicleData.flooring_start_date !== null ? moment(vehicleData.flooring_start_date).format("MM/DD/YYYY") : self.flooringStartDate;
          self.flooringEndDate = vehicleData.flooring_end_date !== null ? moment(vehicleData.flooring_end_date).format("MM/DD/YYYY") : self.flooringEndDate;
          self.flooringRate = vehicleData.flooring_rate;
          
          /*#### For Calculation When Delivery Delay Credit Exist ####*/
          self.deliveryDelayCredit = vehicleData.delivery_delay_credit;
          self.tempDeliveryDelayCredit = vehicleData.delivery_delay_credit;
          if(self.deliveryDelayCredit > self.flooring) {
            self.initFlooring = self.flooring;
            self.flooring = 0;
            self.tempDeliveryDelayCredit = self.flooring;
          } else {
            self.initFlooring = parseFloat(vehicleData.flooring);
          }
          resolve(response);
        })
        .catch(function (err) {
          console.log("ERROR!!! 3470", err);
          self.hideLoader();
        });
      });
    },
    /*##### END #####*/

    closeWorkSheet: function () {
      /*#### UNUSED VARIABLE ####
      let totalAmount = self.grandTotalCostSheet + self.sumOfFees;
      */
      if (self.saveNotNull === true) {
        EventBus.$emit(
          "trns-callback-after-saving",
          self.dealInventoryMapperId,
          // self.vin == "TBD" ? 'TBD' : self.vehicleId
          self.vehicleId !== null ? self.vehicleId : self.vin
        );
      } else {
        EventBus.$emit("trns-callback-after-saving", 0, self.vehicleId);
      }
    },

    expandCollapse: function (accordianPosition, positionNumber) {
      this[accordianPosition] = this[accordianPosition] == "vhc-collapse" ?  "vhc-expand" : "vhc-collapse";
      
      let expandCollapseImg = 'expandCollapseImg'+positionNumber;
      this[expandCollapseImg] = this[accordianPosition] == "vhc-collapse" ? "rotate-accordian-collapse" : "rotate-accordian-expand";
      return true;
    },

    /*##### DELETE VEHICLEWORKSHEET #####*/
    deleteVehicleWorksheet : function () {
      if(self.permissionNameArray.includes('can_delete_deal_inventory_mapper')) {
        let id = null;
        if (self.isVehicleEdit) {
          id = self.vehicleDataId;
        } else if (self.dealInventoryMapperId != null) {
          id = self.dealInventoryMapperId;
        }

        if(id !== null) {
          self.deleteVehicleWorksheetApi(id);
        }
      }
    },

    deleteVehicleWorksheetApi(id) {
      self.$swal({
        title: '',
        text: 'Are you sure that you want to delete this Vehicle Worksheet!',
        type: 'info',
        confirmButtonText: 'OK',
        showCancelButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
          if (result.value) {
              let config = {
                  headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                  data : {
                      id: id,
                  }
              };
              HTTP.delete("/vehicle-worksheet/delete", config)
                  .then(function (response) {
                      if (response.data.code == '200') {
                        EventBus.$emit("delete-vheicle-worksheet", response.data, id);
                      }
                  })
                  .catch(function (error) {
                    console.log('error=>',error);
                      self.catchHandler(error, function () {});
                  });
          }
      });
    },

    /*##### HOLDBACK #####*/
    fctHoldBackPercentUpdate() {
      if((self.packed === false || self.packed == 0) && !(self.typeId == "" || self.typeId == undefined)) {
        if(self.typeId.text.toLowerCase() == "new" && self.loadCount == 0)
        {
          alert("Reminder: 20% on Fleet Deals")
        }
        if (self.percentage > 0) {
          self.holdBackFixed = 0;
        }
      }
      self.calVehiclePrice();
    },

    fctFixedHoldBack() {
      if(!(self.typeId == "" || self.typeId == undefined) && self.typeId.text.toLowerCase() == "new" && self.loadCount == 0)
      {
        alert("Reminder: 20% on Fleet Deals")
      }
      if (self.holdBackFixed > 0) {
        self.percentage = 0;
      }
      self.calVehiclePrice();
    },

    validForm() {
      let valid = true;

      if(self.vehicleType !== "" && self.vehicleType !== self.typeId.text) {
        self.showSAlert(
          "The Vehicle Type Should Be "+self.vehicleType+" !!",
          "info",
          false,
          2
        );
        return false;
      }

      if (self.manufacturerId == "" || self.manufacturerId == null || self.manufacturerId == undefined) {
          self.manufacturerError = "This field is required!";
          if (valid) {
              valid = false;
          }
      } else {
          self.manufacturerError = '';
      }

      if (self.modelOEMId == "" || self.modelOEMId == null || self.modelOEMId == undefined) {
          self.modelError = "This field is required!";
          if (valid) {
              valid = false;
          }
      } else {
          self.modelError = '';
      }

      if (self.vin == "" || self.vin == null || self.vin == undefined) {
          self.vinError = "This field is required!";
          if (valid) {
              valid = false;
          }
      } else {
          self.vinError = '';
      }
      
      return valid;
    },

    openCalendar() {
      this.$refs.dateInput.$el.focus()
    },

    tradeDetailModal(type) {
      self.isClickAddTrade = true;
      self.currentVehicleWorksheetId = self.vehicleDataId !== null ? self.vehicleDataId : self.dealInventoryMapperId;
      self.isEditTradeDetail = false;
      if(type == 'Edit') {
        self.isEditTradeDetail = true;
      }
      $("#tradeDetailModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    addDeliveryDelayCredit() {
      if(self.tempDeliveryDelayCredit > 0 && self.initFlooring > 0) {
        let flooring = self.flooring + self.tempDeliveryDelayCredit;
        self.flooring = Math.max(0, flooring - self.deliveryDelayCredit);
      } else {
        self.flooring = Math.max(0, self.initFlooring - self.deliveryDelayCredit);
      }

      if(self.deliveryDelayCredit <= self.initFlooring) {
        self.tempDeliveryDelayCredit = self.deliveryDelayCredit;
      } else {
        self.tempDeliveryDelayCredit =  0;
      }
      self.calVehiclePrice();
    },

    newUsedCheck() {
      if(["310", "320", "330", "340"].includes(self.locationId.excedeLocId) && self.typeId.text.toLowerCase() == 'new') {
        self.isVisibleDDC = true;
        self.flooring = Math.max(0, self.initFlooring - self.deliveryDelayCredit); 
      } else {
        self.isVisibleDDC = false;
        self.flooring = Math.max(0, self.initFlooring);
      }
      self.calVehiclePrice();
    },

    showVhcDetail() {
      self.isClickVehicleDetail = true;
      if(self.vin !== "") {
        $("#vehicleDetailModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        self.showSAlert("Please Select Inventory", "info", false, 2);
      }
    },

    checkDelivered($event) {
      if($event.target.checked) {
        self.isDelivered = "yes"
      } else {
        self.isDelivered = "no"
      }
    },

    deleteAddOnItem(data,index) {
      self.$swal({
        title: '',
        text: 'Are you sure that you want to delete the Add On Item!',
        type: 'info',
        confirmButtonText: 'OK',
        showCancelButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
          if (result.value) {
            self.showTrnsLoader = true;
            if(Object.keys(data).includes('id') && data.id !== null) {
              const id = data.id;
              let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("api_token"),
                },
                data : {
                  purchase_order_id: data.purchase_order_id,
                  // ex_itm_id: data.ex_itm_id
                }
              };
              HTTP.delete("vehicle-worksheet/addon-list/item/"+id, config).then((response) => {
                if(response.data.status === "success") {
                  self.addOnPoList.splice(index, 1);
                  self.showSAlert(
                    response.data.message,
                    response.data.status,
                    false,
                    2
                  );
                  self.calVehiclePrice();
                }
                self.showTrnsLoader = false;
              })
              .catch((err) => {
                console.log("error=>", err);
                self.showTrnsLoader = false;
                this.catchHandler(err, function () {});
              });
            } else {
              // let dataIndex = data.ind - 1;

              // let dataIndex = data.ind;
              // if(data.ind > 0) {
              //   dataIndex = data.ind - 1;
              // }
              
              self.addOnPoList.splice(index, 1);
              let indexCount = 0;
              if(Object.keys(data).includes('po_index')) {
                self.poData[data.po_index].item_list.splice(data.po_temp_index,1);

                self.poData[data.po_index].item_list.map(po => {
                  po.po_temp_index = indexCount;
                  indexCount++;
                });

              } else {
                let updateAddOnTemp = [];
                // self.addOnPoList.splice(index, 1);
                self.addOnPoList.map(addon => {
                  if(Object.keys(addon).includes('temp_index')) {
                    addon.temp_index = indexCount;
                    indexCount++;
                    updateAddOnTemp.push(addon);
                  }
                });
                localStorage.setItem("AddOnTemp", JSON.stringify(updateAddOnTemp));
              }
            }
          }
      });
    },

    getItemList() {
      let config = {
          headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
      };
      HTTP.get("/vehicle-worksheet/addon-list/item/"+self.poEditAssetId, config)
          .then(function (response) {
              if (response.data.code == '200') {
                self.addOnPoList = response.data.content;
                self.calVehiclePrice();
              }
          })
          .catch(function (error) {
            console.log('error=>',error);
              self.catchHandler(error, function () {});
          });
    },

    createPoExcede(poData) {
      self.$swal({
        title: '',
        text: 'Confirm Creation Of Purchase Order In Excede?',
        type: 'info',
        confirmButtonText: 'OK',
        showCancelButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
          if (result.value) {
            self.showTrnsLoader = true;
            const config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
            };
            const data = {
              purchase_order_id: poData.purchase_order_id,
            };
            HTTP.post("po/excede", data, config).then((response) => {
              if(response.data.status === "success") {
                const responseData = response.data.content;
                self.addOnPoList.map(updatePo => {
                  if(updatePo.purchase_order_id==poData.purchase_order_id) {
                    const takeData = updatePo.description.split(' ');
                    const purId = responseData.purId+"#";
                    if(takeData[0]!==purId) {
                      updatePo.description = purId+" "+updatePo.description;
                    }
                  }
                });
                self.showMailModal(response.data.content);
              } else {
                if(response.status===204) {
                  self.showSAlert(
                    'You Are Not Registered In Excede!',
                    "error",
                    false,
                  );
                }
              }
              self.showTrnsLoader = false;
            })
            .catch((err) => {
              console.log("error=>", err);
              self.showTrnsLoader = false;
              this.catchHandler(err, function () {});
            });
          }
      });
    },

    showMailModal(data) {
      self.isClickMail = true;
      self.mailExcedePurId = data.purId;
      const countItems = data.po_items.length;
      self.mailDescription = `
          <p>The following item(s) have been authorized for stock number ${data.unit}.</p>
          <p>VIN: ${data.vin}</p>
          <p>Description: ${data.summary}</p>
          <p>Vendor: ${data.vendor_name}</p>
          <p>PO#: ${data.purId}</p>
      `;

      for (let i = 0; i < countItems; i++) {
          self.mailDescription += `
              <p>Line ${i + 1}: ${data.po_items[i].description}</p>
              <p>Cost: ${data.po_items[i].cost}</p>
          `;
      }

      self.mailDescription += `
          <p>Buyer: CITY OF GOODYEAR</p>
          <p>Deal No: ${self.dealNo}</p>
          <p>Sales Person: Cory Thompson</p>
      `;
      $("#mailModal").modal({ backdrop: "static", keyboard: false });
    }
  },
};

EventBus.$on("close-search-vin", function (status) {
  if (typeof self != "undefined") {
    self.isClickSearchVinModal = status;
    $("#searchVinModal").modal("hide");
  }
});

EventBus.$on("close-search-unit", function (status) {
  if (typeof self != "undefined") {
    self.isClickSearchUnitModal = status;
    $("#searchUnitModal").modal("hide");
  }
});

EventBus.$on("get-search-vin", function (data) {
  if (typeof self != "undefined") {
    self.isClickSearchVinModal = false;
    $("#searchVinModal").modal("hide");
    self.populateAssetData(data);
  }
});

EventBus.$on("get-search-unit", function (data) {
  if (typeof self != "undefined") {
    self.isClickSearchUnitModal = false;
    let getVin = {
      text: data.vin_no
    };
    $("#searchUnitModal").modal("hide");
    self.populateAssetData(getVin);
  }
});
/*##### AddOn #####*/
EventBus.$on("add-on-data", function (dest, type, isEdit) {
  if (typeof self != "undefined") {
    if(type == 'temp') {
      if(isEdit) {
        self.addOnPoList[self.addOnTempIndex] = dest;
      } else {
        self.addOnPoList.push(dest);
      }
    } else {
      self.addOnPoList = dest; //addOnPoList for both addOn and PO datas
    }
    self.isClickAddOnList = false;
    // if (addOnListData.length > 0 || ) {
      $("#addOnModal").modal("hide");
      self.calVehiclePrice();
    // }
  }
});
EventBus.$on("close-add-on-data-modal", function (status) {
  if (typeof self != "undefined") {
    self.isClickAddOnList = status;
    self.addOnEditData = {};
    $("#addOnModal").modal("hide");
  }
});

/*##### PURCHASE ORDER #####*/
EventBus.$on("close-purchase-order", function (status,isList) {
  if (typeof self != "undefined") {
    self.isClickPurchaseOrder = status;
    // self.poEditData = {};
    $("#vehiclePurchaseModal").modal("hide");
    if(isList) {
      self.getItemList();
    }
  }
});

EventBus.$on("purchase-item-list", function (poListData, type, indexList = null, tempDeleteIndex=null) {
  try {
    if (typeof self != "undefined") {
      let itmList;
      let message = "";
      switch (type) {
        case "save":
          itmList = poListData;
          self.poData = [];
          itmList.map((item) => {
            self.addOnPoList.push(item);
          });
          message = "Purchase Order Created Successfully!"
          break;
        case "update":
          self.poEditData = {};
          self.addOnPoList = poListData;
          message = "Purchase Order Updated Successfully!"
          break;
        case "tempSave":
          itmList = poListData.item_list;
          let totalPO = self.poData.length;
          self.poData.push(poListData);
          itmList.map((item) => {
            item['po_index'] = totalPO;
            self.addOnPoList.push(item);
          });
          message = "Purchase Order Created Temporarily!"
          break;
        case "tempUpdate":
          itmList = poListData.item_list;
          self.poData[self.poIndex] = poListData;
          itmList.map((item,index) => {
            item['po_index'] = self.poIndex;
            if(index < indexList.length) {
              let currentIndex = indexList[index];
              self.addOnPoList[currentIndex] = item;
            } else {
              self.addOnPoList.push(item);
            }
          });

          if(tempDeleteIndex !== null) {
            self.addOnPoList.splice(tempDeleteIndex,1);
          }
          message = "Purchase Order Updated Successfully!"
          break;
      }
      self.isClickPurchaseOrder = false;
      $("#vehiclePurchaseModal").modal("hide");
      self.showSAlert(
        message,
        "success",
        false,
        2
      );
      self.calVehiclePrice();
    }
  } catch (error) {
    console.log('error>>>', error.line,error);
  }
});

EventBus.$on("close-cost-sheet", function (status) {
  if (typeof self != "undefined") {
    self.isClickCostSheet = status;
    $("#defaultModal").modal("hide");
  }
});

EventBus.$on("flooring", function (calValue) {
  if (typeof self != "undefined") {
    if(calValue != null) {
      self.flooring = calValue.flooring;
      self.initFlooring = calValue.initFlooring;
      self.flooringStartDate = calValue.startDate;
      self.flooringEndDate = calValue.endDate;
      self.flooringRate = calValue.rate;
    }
    $("#calculateFlooring").modal("hide");
    self.isClickCalFlooring = false;
    self.calVehiclePrice();
  }
});

EventBus.$on("load-cost-sheet", (value) => {
  if (typeof self != "undefined"){
    self.currentInventoryCostFromProcede = value;
    self.inventoryCostLastUpdated = value;
    self.calVehiclePrice();
  }
});

EventBus.$on("close-trade-detail", function (status) {
  if (typeof self != "undefined") {
    self.isClickAddTrade = status;
    $("#tradeDetailModal").modal("hide");
  }
});

EventBus.$on("trade-details-data", function (data,type) {
  if (typeof self != "undefined") {
    self.isClickAddTrade = false;
    $("#tradeDetailModal").modal("hide");
    switch(type) {
      case 'Save':
        self.tradeDetailsData = data;
        self.tradevalue = data.trade_in_value;
        self.tradepayoff = data.payoff;
        self.isTradeSave = true;
        break;
      case 'Fresh':
        self.tradeDetailsData = data;
        self.tradevalue = data.trade_in_value;
        self.tradepayoff = data.payoff;
        break;
      case 'Delete':
        self.tradeDetailsData = data;
        self.tradevalue = 0;
        self.tradepayoff = 0;
        break;
    }
    self.calVehiclePrice();
  }
});

EventBus.$on("close-vehicle-detail", function (status) {
  if (typeof self != "undefined") {
    self.isClickVehicleDetail = status;
    $("#vehicleDetailModal").modal("hide");
  }
});

EventBus.$on("close-mail-modal", function (status) {
  if (typeof self != "undefined") {
    self.isClickMail = status;
    $("#mailModal").modal("hide");
  }
});
</script>

<style
  lang="css"
  scoped
  src="../../../../public/static/css/vehicleWorksheet.css"
></style>

<style>
.vhc-select .dropdown-toggle {
  height: 32px !important;
  background: #eff5ff !important;
}
</style>
