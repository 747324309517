<template>
    <div class="admin-content-area contact-dashboard m-2">
        
        <div class="">
            <paragraphsShimmer  style="--shimmer-color: #D3D3D3"  v-if="isLoading" :loading="isLoading"></paragraphsShimmer>
            <div v-else class="row">
                <div class="col-lg-3 col-md-6 form-group">
                    <div class="card default-bx-shadow cust-spend-box bg-purple">
                        <div class="card-body">
                            <div class="sub-title">
                                <i class="fa fa-file-text-o box-icon"></i>
                                {{ allLabelMessages.total_spending }}
                            </div>
                            <div class="sub-title">
                                {{ allLabelMessages.current_year }}
                                <div class="bold user-count">{{ currentYearData.total_fmt }}</div>
                            </div>
                            <div class="sub-title mt-2">
                                {{ allLabelMessages.current_month }}
                                <div class="bold user-count">{{ currentMonthData.total_fmt }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="settingValue.view_truck_sales_spending == 1" class="col-lg-3 col-md-6 form-group">
                    <div class="card default-bx-shadow cust-spend-box bg-green">
                        <div class="card-body">

                            <div class="sub-title">
                                <i class="fa fa-truck box-icon fa-flip-horizontal"></i>
                                {{ allLabelMessages.total_spend_on_truck_buy }}
                            </div>
                            <div class="sub-title">
                                {{ allLabelMessages.current_year }}
                                <div class="bold user-count">{{ currentYearData.trucks_fmt }}</div>
                            </div>
                            <div class="sub-title mt-2">
                                {{ allLabelMessages.current_month }}
                                <div class="bold user-count">{{ currentMonthData.truck_fmt }}</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div v-if="settingValue.view_service_sales_spending == 1" class="col-lg-3 col-md-6 form-group">
                    <div class="card default-bx-shadow cust-spend-box bg-orange">
                        <div class="card-body">

                            <div class="sub-title">
                                <i class="fa fa-wrench box-icon"></i>
                                {{ allLabelMessages.total_spend_on_services }}
                            </div>
                            <div class="sub-title">
                                {{ allLabelMessages.current_year }}
                                <div class="bold user-count">{{ currentYearData.service_fmt }}</div>
                            </div>
                            <div class="sub-title mt-2">
                                {{ allLabelMessages.current_month }}
                                <div class="bold user-count">{{ currentMonthData.service_fmt }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="settingValue.view_parts_sales_spending == 1" class="col-lg-3 col-md-6 form-group">
                    <div class="card default-bx-shadow cust-spend-box bg-purple">
                        <div class="card-body">

                            <div class="sub-title">
                                <i class="fa fa-gears box-icon"></i>
                                {{ allLabelMessages.total_spend_on_parts }}
                            </div>
                            <div class="sub-title">
                                {{ allLabelMessages.current_year }}
                                <div class="bold user-count">{{ currentYearData.parts_fmt }}</div>
                            </div>
                            <div class="sub-title mt-2">
                                {{ allLabelMessages.current_month }}
                                <div class="bold user-count">{{ currentMonthData.parts_fmt }}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
            <div class="row">
                <div v-if="settingValue.view_truck_sales_spending == 1" class="col-lg-2 col-md-4 form-group truck-order-count">
                    <paragraphsShimmer  style="--shimmer-color: #D3D3D3" v-if="isLoadingYrTruckBuyAnalysis" :loading="isLoadingYrTruckBuyAnalysis"></paragraphsShimmer>
                    <div v-else class="card default-bx-shadow">
                        <div class="card-body p-2">
                            <div class="truck-icon mt-3">
                                <i class="fa fa-truck"></i>
                            </div>
                            <div class="mt-4 truck-count">{{ totalTruckCount }}</div>
                            <div class="text-center mt-1">Total Trucks</div>
                            

                            <div class="mt-4 truck-count">{{ totalTruckBuyFromVvg }}</div>
                            <div class="text-center mt-1">Buy From VVG</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 form-group hide">
                    <div class="card default-bx-shadow">
                        <div class="card-body">
                            <div class="mb-4 d-flex justify-content-between">
                                <span>
                                    <strong class="bold">Truck Ownership & Status</strong>
                                </span>
                                <span class="position-relative">
                                    <!-- <date-picker
                                        class="form-control"
                                        placeholder="Date"
                                        autocomplete="off"
                                        name="excedeDateInvoice">
                                    </date-picker>
                                    <i class="fa fa-calendar calendar-icon"></i> -->
                                </span>
                            </div>
                            <div ref="chartData">
                            <GChart
                                id="chartData"
                                :chart-type="'PieChart'"
                                :type="'PieChart'"
                                :data="chartData"
                                :options="chartOptions"
                                :width="'100%'"
                                ></GChart>
                            </div>
                        </div>
                    </div>
                </div>

                <!--START: Yearly Truck Purchase Analysis -->
                
                    <div :class="settingValue.view_truck_sales_spending == 1 ? 'col-lg-10 col-md-8 form-group' : 'col-lg-12 col-md-12 form-group'">
                        <paragraphsShimmer  style="--shimmer-color: #D3D3D3"  v-if="isLoadingYrTruckBuyAnalysis" :loading="isLoadingYrTruckBuyAnalysis"></paragraphsShimmer>

                    
                        <div v-else class="card default-bx-shadow">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <strong class="bold">Yearly Purchase Analysis</strong>
                                    </span>
                                    <span class="position-relative">
                                        <!-- <date-picker
                                            class="form-control"
                                            placeholder="Date"
                                            autocomplete="off"
                                            name="excedeDateInvoice">
                                        </date-picker>
                                        <i class="fa fa-calendar calendar-icon"></i> -->

                                        <a @click="getYearlyTruckPurchaseCountStatistics" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2"><i aria-hidden="true" class="fa fa-undo"></i></a>
                                    </span>
                                </div>
                                <div ref="truckBuyCountChartData">
                                <GChart
                                    id="truckBuyCountChartData"
                                    :chart-type="'ColumnChart'"
                                    :type="'ColumnChart'"
                                    :data="truckBuyCountChartData"
                                    :options="truckBuyCountChartOptions"
                                    :width="'100%'"
                                    ></GChart>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                
                <!--END: Yearly Truck Purchase Analysis -->
                
                <!--START: Truck Purchase by Model -->
                <div v-if="shouldShowTruckPurchaseByModel" class="col-lg-6 col-md-12 form-group">
                    
                    <paragraphsShimmer  style="--shimmer-color: #D3D3D3"  v-if="isLoadingTruckByModels" :loading="isLoadingTruckByModels"></paragraphsShimmer>
                    
                    <div v-else class="card default-bx-shadow">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <span>
                                    <strong class="bold">Truck Purchase by Model</strong>
                                </span>
                                <span class="position-relative">
                                    <!-- <date-picker
                                        class="form-control"
                                        placeholder="Date"
                                        autocomplete="off"
                                        name="excedeDateInvoice">
                                    </date-picker>
                                    <i class="fa fa-calendar calendar-icon"></i> -->
                                    <a @click="getTruckPurchaseByModelStatistics" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2"><i aria-hidden="true" class="fa fa-undo"></i></a>

                                </span>
                            </div>
                            <!-- <GChart
                                :chart-type="'ComboChart'"
                                :type="'ComboChart'"
                                :data="truckBuyStaticsByModelTypeChartData"
                                :options="truckBuyStaticsByModelTypeChartOptions"
                                :width="'100%'"
                                ></GChart> -->
                                <div ref="truckBuyStaticsByModelTypeChartData">
                                    <GChart
                                    id="truckBuyStaticsByModelTypeChartData"
                                    :chart-type="'ColumnChart'"
                                    :type="'ColumnChart'"
                                    :data="truckBuyStaticsByModelTypeChartData"
                                    :options="truckBuyStaticsByModelTypeChartOptions"
                                    :width="'100%'"
                                    ></GChart>
                                </div>
                        </div>
                    </div>
                </div>
                <!--END: Truck Purchase by Model -->

                <!--START: Yearly Departmental Spending Analysis -->
                <div class="col-lg-12 col-md-12 form-group">
                    
                    <paragraphsShimmer  style="--shimmer-color: #D3D3D3"  v-if="isLoadingYearlyDepartmentalSpendingAnalysis" :loading="isLoadingYearlyDepartmentalSpendingAnalysis"></paragraphsShimmer>
                    <div v-else class="card default-bx-shadow">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <span>
                                    <strong class="bold">Yearly Departmental Spending Analysis</strong>
                                </span>
                                <span class="position-relative">
                                    <!-- <date-picker
                                        class="form-control"
                                        placeholder="Date"
                                        autocomplete="off"
                                        name="excedeDateInvoice">
                                    </date-picker>
                                    <i class="fa fa-calendar calendar-icon"></i> -->
                                    <a @click="getYearlyDepartmentalPurchasesStatistics" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2">
                                        <i aria-hidden="true" class="fa fa-undo"></i>
                                    </a>
                                </span>
                            </div>
                            <div ref="yearlySpendingByDepartmentChartData">

                                <GChart
                                    id="yearlySpendingByDepartmentChartData"
                                    :chart-type="'ComboChart'"
                                    :type="'ComboChart'"
                                    :data="yearlySpendingByDepartmentChartData"
                                    :options="yearlySpendingByDepartmentChartOptions"
                                    :width="'100%'">
                                </GChart>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END: Yearly Departmental Spending Analysis -->

                <!--START: Departmental Spending Analysis -->
                <div class="col-lg-12 col-md-12 form-group">
                    <paragraphsShimmer  style="--shimmer-color: #D3D3D3"  v-if="isLoadingDepartmentalSpendingAnalysis" :loading="isLoadingDepartmentalSpendingAnalysis"></paragraphsShimmer>
                    <div v-else class="card default-bx-shadow">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <span>
                                    <strong class="bold">Monthly Departmental Spending Analysis</strong>
                                </span>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="d-inline-block report-by-section">
                                            <label class="mb-0">{{allLabelMessages.year}}</label>
                                            <v-select
                                                    title="Select Year"
                                                    v-model="selectedYear"
                                                    @input="changeSelectedYear"
                                                    name="selectedYear"
                                                    id="selectedYear"
                                                    label="text"
                                                    :clearable="false"
                                                    :searchable="false"
                                                    :filterable="false"
                                                    :options="selectedYearOptions"
                                                    placeholder="Select Year"
                                                    class="report-by-options"
                                                >
                                            </v-select>
                                        </div>
                                    </div>
                                    <!-- <div :class="spendingByDepartmentTypeFilter.value === 'monthly' ? 'col-md-4' : 'mr-2'">
                                        <div class="d-inline-block report-by-section">
                                            <label class="mb-0">Report BY</label>
                                            <multiselect 
                                            v-model="spendingByDepartmentTypeFilter" 
                                            :options="spendingByDepartmentFilterTypeOptions" 
                                            :multiple="false" 
                                            :close-on-select="true" 
                                            :clear-on-select="false" 
                                            :preserve-search="true" 
                                            placeholder="Select one" 
                                            label="text" 
                                            track-by="value" 
                                            :preselect-first="true"
                                            class="form-control multiselect_display_input report-by-options"
                                            @input="getDepartmentalPurchasesStatistics()"
                                            >
                                                
                                                <template slot="option" slot-scope="props">
                                                    <div v-if="activityAppliedTypeFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                        <span>{{ props.option.text }}</span>
                                                    </div>
                                                    <div v-else class="multiple_select_item">
                                                        <span>{{ props.option.text }}</span>
                                                    </div>
                                                </template>

                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                                    <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                                    
                                                    <span class="multiselect__single pull-right" v-on:click="clearStatusFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div> -->
                                    <div :class="'col-md-1'"></div>
                                    <div :class="'col-md-6'">
                                        <!-- <span class="position-relative">
                                            <a @click="getLatestActivities" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2 mt-4"><i aria-hidden="true" class="fa fa-undo"></i></a>
                                        </span> -->
                                    
                                        <span class="position-relative">
                                            <a @click="getDepartmentalPurchasesStatistics(1)" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right mt-4"><i aria-hidden="true" class="fa fa-undo"></i></a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div ref="spendingByDepartmentChartData">
                            <GChart
                                id="spendingByDepartmentChartData"
                                :chart-type="'ComboChart'"
                                :type="'ComboChart'"
                                :data="spendingByDepartmentChartData"
                                :options="spendingByDepartmentChartOptions"
                                :width="'100%'"
                                ></GChart>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END: Departmental Spending Analysis -->

                <!--START: Latest activities -->
                <div class="col-lg-12 col-md-12 form-group">
                    
                    <paragraphsShimmer  style="--shimmer-color: #D3D3D3"  v-if="isLoadingLatestActivitys" :loading="isLoadingLatestActivitys"></paragraphsShimmer>
                    <div v-else class="card default-bx-shadow">
                        <div class="card-body ">
                            <div class="mb-4 d-flex justify-content-between">
                                <span>
                                    <strong class="bold">Latest Activities</strong>
                                </span>
                                <div>
                                    <div class="d-inline-block">
                                        <label class="mb-0">{{allLabelMessages.status}}</label>
                                        <div class="col-12 paddingleft0">
                                            <multiselect 
                                            v-model="activityTypeFilter" 
                                            :options="activityFilterTypeOptions" 
                                            :multiple="true" 
                                            :close-on-select="true" 
                                            :clear-on-select="false" 
                                            :preserve-search="true" 
                                            placeholder="Select one" 
                                            label="text" 
                                            track-by="text" 
                                            :preselect-first="true"
                                            class="form-control multiselect_display_input"
                                            @input="changeStatusFilter()"
                                            >
                                                <!--Dropdown options Custom Slot-->
                                                <template slot="option" slot-scope="props">
                                                    <div v-if="activityAppliedTypeFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                        <span>{{ props.option.text }}</span>
                                                    </div>
                                                    <div v-else class="multiple_select_item">
                                                        <span>{{ props.option.text }}</span>
                                                    </div>
                                                </template>
                                                <!-- Selection Custom Slot -->
                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                                    <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                                    <!--<span class="multiselect__single" v-else></span>-->
                                                    <span class="multiselect__single pull-right" v-on:click="clearStatusFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                    <span class="position-relative">
                                        <a @click="getLatestActivities" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2 mt-4"><i aria-hidden="true" class="fa fa-undo"></i></a>
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 form-group">
                                <table class="table">
                                    <thead>
                                        <th>Activity</th>
                                        <th>Date</th>
                                        <th>SlsId</th>
                                        <th>Vin</th>
                                    </thead>
                                    <tbody>
                                        <template v-if="latestActivities.length">
                                            <tr v-bind:key="'latest_activitie_'+index" v-for="(activity, index) in latestActivities">
                                                <td>{{ activity.activity }}</td>
                                                <td>{{ activity.display_date }}</td>
                                                <td>{{ activity.sls_id }}</td>
                                                <td>{{ activity.vin_no }}</td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <td  colspan="4" class="text-center bold">No Activity Found</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!--END: Latest activities -->

                <!--START: Customer Incidents -->
                <div class="col-lg-12 col-md-12 form-group">
                    
                    <paragraphsShimmer  style="--shimmer-color: #D3D3D3"  v-if="isLoadingCustomerIncident" :loading="isLoadingCustomerIncident"></paragraphsShimmer>
                    <div v-else class="card default-bx-shadow">
                        <div class="card-body">
                            <div class="mb-4 d-flex justify-content-between">
                                <span>
                                    <strong class="bold">Customer Incidents</strong>
                                </span>
                                <span class="position-relative">
                                    <a @click="getCustomerIncident" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2"><i aria-hidden="true" class="fa fa-undo"></i></a>
                                </span>
                            </div>
                            <div class="col-lg-12 col-md-12 form-group">
                                <table class="table">
                                    <thead>
                                        <th>Subject</th>
                                        <th>Time</th>
                                        <th>Vin</th>
                                        <th>Note</th>
                                    </thead>
                                    <tbody>
                                        <template v-if="customerIncidents.length">
                                        <tr v-bind:key="'customer_incident_'+index" v-for="(incident, index) in customerIncidents" >
                                            <td>{{ incident.subject }}</td>
                                            <td>{{ incident.time }}</td>
                                            <td>{{ incident.vin_no }}</td>
                                            <td>{{ incident.notes }}</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td class="text-center bold" colspan="4">No Incident Found</td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!--END: Customer Incidents -->
                
            </div>
        </div>
        
        <app-footer></app-footer>
    </div>
</template>
<script>
    var self;
    const currentYear = new Date().getFullYear();
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    Vue.component("app-footer", Footer);
    // import VueGoogleCharts from 'vue-google-charts';
    // Vue.use(VueGoogleCharts);
    import { GChart } from 'vue-google-charts/legacy'
    import { Datetime } from "vue-datetime";
    import "vue-datetime/dist/vue-datetime.css";
    import datePicker from "vue-bootstrap-datetimepicker";
    import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
    import Multiselect from 'vue-multiselect';
    import { blockShimmer, circleShimmer, imageShimmer, paragraphsShimmer, sentencesShimmer, textShimmer } from "vue-shimmer"

    export default {
        name: 'CustomerSpecificDashboard',
        mixins: [commonMixins],
        components: {
            GChart,
            datetime: Datetime,
            datePicker,
            Multiselect,
            'paragraphsShimmer': paragraphsShimmer
        },
        props: {
            customer: {
                type: [String, Number],
                required: false,
                default: undefined
            },
            redirectUrl: {
                type: String,
                required: false,
                default: undefined
            },
            activetab: {
                type: Number,
                required: false,
                default: undefined
            }
        },
        data () {
            return {
                chartData: [
                    ['Status', 'Trucks'],
                    ['Active', 1],
                    ['InActive', 1],
                ],
                chartOptions: {
                    pieSliceText: 'value',
                    // legend: 'none',
                    // colors:['red','#004411'],
                    title: '',
                    //bars: 'vertical', // or 'horizontal'
                    pieHole: 0.4,
                    height: 300,
                    // legend: {
                    //     position: 'labeled',
                    //     textStyle: {
                    //         color: 'black',
                    //         fontSize: 12
                                
                    //         }
                    // },
                    // tooltip: {
                    //     text: 'value'
                    // }
                },
                truckBuyCountChartData: [
                    ['Year', 'Vehicle'],
                    ['No Record', 0]
                ],
                truckBuyCountChartOptions: {
                    title: '',
                    //bars: 'vertical' // or 'horizontal'
                    height: 300,
                    vAxis: {
                        format: '0' // Display whole numbers
                    }
                },
                truckBuyStaticsByModelTypeChartData: [
                    ['Model', 'Asset Count'],
                    ['No Record', 0]
                ],
                truckBuyStaticsByModelTypeChartOptions: {
                    // title: '',
                    // hAxis: { title: 'Model' },
                    // vAxis: { title: 'Asset Count' },
                    // seriesType: 'bars',
                    // series: { 2: { type: 'line' } }
                    title: '',
                    //bars: 'vertical' // or 'horizontal'
                    height: 300,
                    vAxis: {
                        format: '0' // Display whole numbers
                    }
                },
                spendingByDepartmentData: {
                    monthlySalesData: [],
                    yearlySalesData: []
                },
                spendingByDepartmentChartData: [
                    ['Year', 'Service', 'Parts', 'Truck Sales'],
                    ['No Record', 0, 0, 0],
                ],
                spendingByDepartmentChartOptions: {
                    // title: 'Customer Count They Prefereed To Buy Truck Type',
                    hAxis: { title: 'Year' },
                    vAxis: { title: 'Spending' },
                    seriesType: 'bars',
                    series: { 3: { type: 'line' } }
                },
                spendingByDepartmentTypeFilter: {text: 'Monthly', value: 'monthly'},
                yearlySpendingByDepartmentChartData: [
                    ['Year', 'Truck Sales', 'Service', 'Parts'],
                    ['No Record', 0, 0, 0],
                ],

                yearlySpendingByDepartmentChartOptions: {
                    // title: 'Customer Count They Prefereed To Buy Truck Type',
                    hAxis: { title: 'Year' },
                    vAxis: { title: 'Spending' },
                    seriesType: 'bars',
                    series: { 3: { type: 'line' } }
                },

                avgSpendingOnServiceChartData: [
                    ['Amount', 'Customer'],
                    ['$1000', 100],
                    ['$2000', 225],
                    ['$1000', 100],
                    ['$2000', 225],
                    ['$1000', 100],
                    ['$2000', 225],
                ],
                avgSpendingOnServiceChartOptions: {
                    title: 'Customer Count By Avg. Spending On Service',
                    bars: 'vertical', // or 'horizontal'
                    height: 300
                },

                avgSpendingOnPartsChartData: [
                    ['Amount', 'Customer'],
                    ['$1000', 100],
                    ['$2000', 225],
                    ['$1000', 100],
                    ['$2000', 225],
                    ['$1000', 100],
                    ['$2000', 225],
                ],
                avgSpendingOnPartsChartOptions: {
                    title: 'Customer Count By Avg. Spending On Parts',
                    bars: 'vertical', // or 'horizontal'
                    height: 300,

                },
                totalTruckCount: 0,
                totalTruckBuyFromVvg: 0,
                latestActivities: [],
                customerIncidents: [],
                isNewCustomer: false,
                activityAppliedTypeFilter: [],
                activityFilterTypeOptions: [],
                spendingByDepartmentFilterTypeOptions: [{text: 'Yearly', value: 'yearly'},{text: 'Monthly', value: 'monthly'}],
                activityTypeFilter: '',
                total_spendings: {
                    total: 0,
                    on_service: 0,
                    on_parts: 0,
                    on_trucks: 0,
                    total_formatted: 0,
                    on_service_formatted: 0,
                    on_parts_formatted: 0,
                    on_trucks_formatted: 0,
                },
                isLoading: false,
                isLoadingYrTruckBuyAnalysis: false,
                isLoadingTruckByModels: false,
                isLoadingYearlyDepartmentalSpendingAnalysis: false,
                isLoadingDepartmentalSpendingAnalysis: false,
                isLoadingLatestActivitys: false,
                isLoadingCustomerIncident: false,
                shouldShowTruckPurchaseByModel: false,
                settingValue: 0,
                selectedYear: currentYear,
                selectedYearOptions: this.generateYearOptions(),
                currentYearData: [],
                currentMonthData: []
            }
        },
        computed: {
            spendingByDepartmentTypeFilterValues() {
                return self.spendingByDepartmentTypeFilter.value;
            },
        },
        beforeMount() {
            self = this;
        },
        created : function () {
            self = this;
        },
        watch: {
            "activityTypeFilter": function(){
               
            },
        },
        mounted: function() {
            if (this.$route.params.id == undefined){
                self.setActiveMenu('contact-dashboard');
                EventBus.$emit("menu-selection-changed", "contact-dashboard");
            }
            self.fetchCustomerSummary();
            self.getYearlyTruckPurchaseCountStatistics();
            setTimeout(function() {
                self.getYearlyDepartmentalPurchasesStatistics();
            }, 800);
            setTimeout(function() {
                self.getDepartmentalPurchasesStatistics();
            }, 1600);
            if(self.shouldShowTruckPurchaseByModel) {
                setTimeout(function() {
                    self.getTruckPurchaseByModelStatistics();
                }, 2400);
            }
            setTimeout(function() {
                self.getCustomerIncident();
            }, 3200);
        },
        methods: {
            generateYearOptions() {
                const years = [];
                for (let i = 0; i < 5; i++) {
                    years.push(currentYear - i);
                }
                return years;
            },
            clearStatusFilter() {
                self.activityAppliedTypeFilter = [];
                self.activityTypeFilter = "";
                self.getLatestActivities();
            },
            changeStatusFilter() {
                self.activityAppliedTypeFilter = [];
                $.each(self.activityTypeFilter, function (i) {
                    self.activityAppliedTypeFilter.push(self.activityTypeFilter[i].value);
                });
                self.getLatestActivities();
            },
            getYearlyTruckPurchaseCountStatistics: function() {
                self.isLoadingYrTruckBuyAnalysis = true;
                
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.get('/crm/yearlytruckpurchasecountstatistics/'+self.customer, config)
                    .then(response => {
                        
                        if (response.data.status == "success" && response.data.content) {
                            if(response.data.content.yearlyChartData) {
                                self.truckBuyCountChartData = response.data.content.yearlyChartData;
                            }
                        }
                        self.isLoadingYrTruckBuyAnalysis = false;
                        
                    })
                    .catch(function (err) {
                        
                        self.catchHandler(err, function () {});
                    });
            },
            fetchCustomerSummary : function() {
                self.isLoading = true;
                
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                HTTP.get('/crm/customerassetdetails/'+self.customer, config)
                    .then(response => {
                        if (response.data.status == "success") {
                            if(response.data.content) {
                                if(response.data.content.customer_data) {
                                    
                                    //Truck Chart display Active/InActive
                                    var truckStatusChart = [['Status', 'Trucks']];
                                    self.totalTruckCount = typeof response.data.content.customer_data.total_truck_count != 'undefined' ? response.data.content.customer_data.total_truck_count : 0;                                    
                                    self.totalTruckBuyFromVvg = typeof response.data.content.customer_data.total_truck_buy_count_from_vvg != 'undefined' ? response.data.content.customer_data.total_truck_buy_count_from_vvg : 0;
                                    if(typeof response.data.content.customer_data.active_truck_count!= 'undefined') {
                                        truckStatusChart.push(['Active', response.data.content.customer_data.active_truck_count]);   
                                    }
                                    self.isNewCustomer = response.data.content.customer_data.is_new_customer;
                                    self.$emit('isNewCustomerUpdate', self.isNewCustomer);

                                    if(typeof response.data.content.customer_data.inactive_truck_count != 'undefined') {
                                        truckStatusChart.push(['InActive', response.data.content.customer_data.inactive_truck_count]);   
                                    }

                                    self.chartData = truckStatusChart;
                                }
                            }
                        }
                        
                        self.isLoading = false;
                    })
                    .catch(function (err) {
                        
                        self.catchHandler(err, function () {});
                    });
            },
            getTruckPurchaseByModelStatistics: function() {
                self.isLoadingTruckByModels = true;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.get('/crm/truckpurchasebymodelstatistics/'+self.customer, config)
                    .then(response => {
                        
                        if (response.data.status == "success" && response.data.content) {
                            if(response.data.content.chartData) {
                                self.truckBuyStaticsByModelTypeChartData = response.data.content.chartData;
                            }
                        }
                        self.isLoadingTruckByModels = false;
                        
                    })
                    .catch(function (err) {
                        
                        self.catchHandler(err, function () {});
                    });
            },
            changeSelectedYear: function() {
                self.getDepartmentalPurchasesStatistics();
            },
            getDepartmentalPurchasesStatistics: function(isRefresh = 0) {
                self.isLoadingDepartmentalSpendingAnalysis = true;
                if(isRefresh) {
                    self.selectedYear = new Date().getFullYear();
                }
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        flag: self.spendingByDepartmentTypeFilterValues,
                        selectedYear: self.selectedYear
                    }
                };

                HTTP.get('/crm/departmentalpurchasesstatistics/'+self.customer, config)
                    .then(response => {
                        
                        if (response.data.status == "success" && response.data.content) {
                            if(response.data.content.chartData) {
                                self.spendingByDepartmentData = response.data.content.chartData;
                                if(self.spendingByDepartmentTypeFilterValues == 'yearly') {
                                    self.spendingByDepartmentChartData = response.data.content.chartData.yearlySalesData;
                                    self.spendingByDepartmentChartOptions.hAxis = { title: 'Year' };
                                } else{
                                    self.spendingByDepartmentChartData = response.data.content.chartData.monthlySalesData;
                                    self.spendingByDepartmentChartOptions.hAxis = { title: 'Months' };
                                    self.currentMonthData = response.data.content.currentMonthData;
                                }
                            }

                            // if(response.data.content.total_spending) {
                            //     self.total_spendings.total = response.data.content.total_spending.total ? response.data.content.total_spending.total : 0;
                            //     self.total_spendings.on_service = response.data.content.total_spending.service ? response.data.content.total_spending.service : 0;
                            //     self.total_spendings.on_parts = response.data.content.total_spending.parts ? response.data.content.total_spending.parts : 0;
                            //     self.total_spendings.on_trucks = response.data.content.total_spending.trucks ? response.data.content.total_spending.trucks : 0;
                            //     self.total_spendings.total_formatted = response.data.content.total_spending.total_formatted ? response.data.content.total_spending.total_formatted : 0;
                            //     self.total_spendings.on_service_formatted = response.data.content.total_spending.service_formatted ? response.data.content.total_spending.service_formatted : 0;
                            //     self.total_spendings.on_parts_formatted = response.data.content.total_spending.parts_formatted ? response.data.content.total_spending.parts_formatted : 0;
                            //     self.total_spendings.on_trucks_formatted = response.data.content.total_spending.trucks_formatted ? response.data.content.total_spending.trucks_formatted : 0;

                                
                            //     self.$emit('customerSpendingsUpdate', self.total_spendings);
                            // }

                        }
                        self.isLoadingDepartmentalSpendingAnalysis = false;
                        
                    })
                    .catch(function (err) {
                        
                        self.catchHandler(err, function () {});
                    });
            },
            getYearlyDepartmentalPurchasesStatistics: function() {
                self.isLoadingYearlyDepartmentalSpendingAnalysis = true;
                
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                HTTP.get('/crm/yearlydepartmentalpurchases/'+self.customer, config)
                    .then(response => {
                        
                        if (response.data.status == "success" && response.data.content) {
                            self.settingValue = response.data.content.settingValues;
                            self.currentYearData = response.data.content.currentYearData;
                            if(response.data.content.chartData) {
                                self.yearlySpendingByDepartmentChartData = response.data.content.chartData;
                            }
                            if(response.data.content.total_spending) {
                                self.total_spendings.total = response.data.content.total_spending.total ? response.data.content.total_spending.total : 0;
                                self.total_spendings.on_service = response.data.content.total_spending.service ? response.data.content.total_spending.service : 0;
                                self.total_spendings.on_parts = response.data.content.total_spending.parts ? response.data.content.total_spending.parts : 0;
                                self.total_spendings.on_trucks = response.data.content.total_spending.trucks ? response.data.content.total_spending.trucks : 0;
                                self.total_spendings.total_formatted = response.data.content.total_spending.total_formatted ? response.data.content.total_spending.total_formatted : 0;
                                self.total_spendings.on_service_formatted = response.data.content.total_spending.service_formatted ? response.data.content.total_spending.service_formatted : 0;
                                self.total_spendings.on_parts_formatted = response.data.content.total_spending.parts_formatted ? response.data.content.total_spending.parts_formatted : 0;
                                self.total_spendings.on_trucks_formatted = response.data.content.total_spending.trucks_formatted ? response.data.content.total_spending.trucks_formatted : 0;
                                
                                self.$emit('customerSpendingsUpdate', self.total_spendings);
                            }
                            
                            // Check and push latest activity filter options based on settings
                            if (self.settingValue.view_truck_sales_spending == 1) self.activityFilterTypeOptions.push({ checked: 0, text: 'Vehicle Buy', value: 'VehicleBuy' });
                            if (self.settingValue.view_service_sales_spending == 1) self.activityFilterTypeOptions.push({ checked: 0, text: 'Service', value: 'Service' });
                            if (self.settingValue.view_parts_sales_spending == 1) self.activityFilterTypeOptions.push({ checked: 0, text: 'Parts Purchase', value: 'PartsPurchase' });

                            self.changeStatusFilter();
                        }
                        self.isLoadingYearlyDepartmentalSpendingAnalysis = false;
                        
                    })
                    .catch(function (err) {
                        
                        self.catchHandler(err, function () {});
                    });
            },
            getLatestActivities: function() {
                self.isLoadingLatestActivitys = true;
                
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        types: self.activityAppliedTypeFilter
                    }
                };
                HTTP.get('/crm/latestactivities/'+self.customer, config)
                    .then(response => {
                        
                        if (response.data.status == "success" && response.data.content) {
                            if(response.data.content.activities) {
                                self.latestActivities = response.data.content.activities;
                            }
                        }
                        
                        self.isLoadingLatestActivitys = false;
                    })
                    .catch(function (err) {
                        
                        self.catchHandler(err, function () {});
                    });
            },
            getCustomerIncident: function() {
                
                self.isLoadingCustomerIncident = true;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.get('/crm/customerincidents/'+self.customer, config)
                    .then(response => {
                        
                        if (response.data.status == "success" && response.data.content) {
                            if(response.data.content.records) {
                                self.customerIncidents = response.data.content.records;
                            }
                        }
                        
                        self.isLoadingCustomerIncident = false;
                    })
                    .catch(function (err) {
                        
                        self.catchHandler(err, function () {});
                    });
            }

        }
    
    }
</script>
<style scoped>
.calendar-icon{
    position: absolute;
    top: 10px;
    right: 30px;
}
.bold{
    font-family: 'SanFranciscoDisplayBold' !important;
}
.contact-dashboard .card{
    border: 0;
    border-radius: 10px;
    height: 100%;
}
.contact-dashboard .card-body{
    padding: 20px;
}
.contact-dashboard .cust-spend-box{
    border-radius: 20px;
}
.contact-dashboard .cust-spend-box.bg-purple{
    background-color: #e7d4ff;
}
.contact-dashboard .cust-spend-box.bg-green{
    background-color: #c4fed8;
}
.contact-dashboard .cust-spend-box.bg-orange{
    background-color: #ffecc8;
}
.contact-dashboard .cust-spend-box .card{
    background: transparent;
    border: 0;
}
.contact-dashboard .cust-spend-box .box-icon{
    border-radius: 100%;
    color: #fff;
    margin-bottom: 20px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
}
.contact-dashboard .cust-spend-box.bg-purple .box-icon{
    background: #bf83ff;
}
.contact-dashboard .cust-spend-box.bg-green .box-icon{
    background: #3cd856;
}
.contact-dashboard .cust-spend-box.bg-orange .box-icon{
    background: #ff947a;
}
.contact-dashboard .cust-spend-box .users-count{
    font-size: 30px;
    margin-bottom: 10px;
}
.contact-dashboard .cust-spend-box .sub-title{
    font-size: 20px;
}
.contact-dashboard .truck-order-count .truck-icon{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background: #6FA4F5;
    color: #fff;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.contact-dashboard .truck-order-count .truck-icon i{
    font-size: 35px;
}
.contact-dashboard .truck-order-count .truck-count{
    text-align: center;
    font-size: 20px;
    font-family: 'SanFranciscoDisplayBold' !important;
}
.contact-dashboard .truck-order-count .total-trucks{
    font-size: 12px;
    color: #999;
    text-align: center;
}
#yearlySpendingByDepartmentChartData,
#spendingByDepartmentChartData {
    height: 300px;
}
.report-by-section label {
    display:block;
}
.report-by-section .report-by-options {
    width: 165px;
}
</style>
