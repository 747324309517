<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="(permissionNameArray.includes('can_add_used_truck_holds_submission_form')) && userCompany.includes(companyId)">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <template v-for="(data, vinDataIndex) in all_vin_data">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.vin}}</span>
                                        <div class="requiredstar">*</div>
                                    </div>
                                </div>
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.selling_price_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.selling_price}}</span>
                                        <div class="requiredstar">*</div>
                                    </div>
                                </div>
                            </template>
                            <!--Form Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.salesman_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="salesmanId"
                                        name="salesmanId"
                                        id="salesmanId"
                                        label="text"
                                        @change="changedValue"
                                        @input="salesmanIdError = ''"
                                        :filterable="false"
                                        :options="salesmanIdArr"
                                        @search="onSearchSalesmanUser"
                                        :placeholder="staticMessages.salesman_user_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSalesmanNameRecordNotFound">
                                                {{staticMessages.salesman_user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{salesmanIdError}}</span>
                                </div>
                            </div>

                            <!-- Vin -->
                            <!--<div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vin_digit_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="vinError = ''" id="vin" v-model="vin" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vinError}}</span>
                                </div>
                            </div>-->
                            
                            <!-- Selling Price -->
                            <!--<div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.selling_price_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="sellingPriceError = ''" id="sellingPrice" v-model="sellingPrice" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{sellingPriceError}}</span>
                                </div>
                            </div>-->
                            
                            <!-- Customer Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="customerNameError = ''" id="customerName" v-model="customerName" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{customerNameError}}</span>
                                </div>
                            </div>

                            <!-- Has seen truck in person -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.has_seen_truck_in_person_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="hasSeenTruckInPerson"
                                                        @input="customerSeenError = ''"
                                                        :options="hasSeenTruckInPersonOptions"
                                                        name="hasSeenTruckInPerson">
                                    </b-form-radio-group>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{customerSeenError}}</span>
                                </div>
                            </div>
                            
                            <!-- Has Approve Finance Terms -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.has_approve_finance_terms_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="hasApproveFinanceTerms"
                                                        @input="customerAppprovedError = ''"
                                                        :options="hasApproveFinanceTermsOptions"
                                                        name="hasApproveFinanceTerms">
                                    </b-form-radio-group>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{customerAppprovedError}}</span>
                                </div>
                            </div>

                            <!-- Completed -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.approve_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" v-model="completed" value="true" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span> -->
                                </div>
                            </div>
                            <!-- Complete By -->
                            <div v-if="completed" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.approve_by_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="completeById"
                                              name="completeById"
                                              id="completeById"
                                              label="text"
                                              @change="changedValue"
                                              :filterable="false"
                                              :options="completeByArr"
                                              @search="onSearchCompleteBy"
                                              :placeholder="staticMessages.complete_by_user_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompleteByRecordNotFound">
                                                {{staticMessages.complete_by_user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{completeByIdError}}</span>
                                </div>
                            </div>
                            
                            <!-- Note -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="note" v-model="note" class="form-control" maxlength="50000" @input="noteError=''"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{noteError}}</span>
                                </div>
                            </div>

                            <!-- Referral Source -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.referral_source}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="holdReferralSource" v-model="referral_source" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div> -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.referral_source}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="referralSource"
                                            name="referralSource"
                                            label="text"
                                            :options="referralSourceArr"
                                            @input="referralSourceError = ''"
                                            @search="onSearchReferralSource"
                                            :filterable="false"
                                            :clearable="false"
                                            :placeholder="staticMessages.select_referral_source">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isReferralSourceRecordNotFound">
                                                {{staticMessages.referral_source_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{ referralSourceError }}</span>
                                </div>
                            </div>
                            <!-- Phone -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.phone_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="phoneError = ''" id="phone" v-model="phone" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{phoneError}}</span>
                                </div>
                            </div>
                            <!-- Created At -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.created_at_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input disabled id="createdAt" v-model="createdAt"  class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span> -->
                                </div>
                            </div>
                            
                            <div class="col-12 forms-block" v-show="attachment.length > 0">
                                <div class="col-12 forms-block paddingleft0 deposit-check-field">
                                    <div class="col-12 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.approval_or_deposit_check_image}}</div>
                                    </div>
                                    <div class="col-12 paddingleft0">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                                    <template v-for="(doc, index1) in attachment">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display : none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addVehicleCheckIn()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';

    /* Swiper for submenu tebs start */
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    /* Swiper for submenu tebs end */

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUdpateVehicleCheckIn',
        components: {
            swiper,
            swiperSlide,
        },
        mixins: [commonMixins],
        data() {
            return {
                userCompany : [1],
                companyId : 1,
                ApprovalOrDepositCheck: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                attachment: "",
                staticInputLabelMessages: "",
                staticMessages: "",
                salesmanId: "",
                salesmanIdArr: [],
                isSalesmanNameRecordNotFound: false,
                vin: "",
                sellingPrice: "",
                customerName: "",
                /* referral_source : "", */
                hasSeenTruckInPerson: "yes",
                hasSeenTruckInPersonOptions:[
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                hasApproveFinanceTerms: "yes",
                hasApproveFinanceTermsOptions:[
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                    {text: 'Cash', value: 'cash'},
                    {text: 'OSB', value: 'osb'},
                ],
                completed: false,
                note: "",
                completeById: "",
                completeByArr: [],
                isCompleteByRecordNotFound: false,
                createdAt: "",

                phone: "",
                phoneError: "",
                
                // Error
                salesmanIdError: "",
                completeByIdError: "",
                vinError: "",
                sellingPriceError: "",
                customerNameError: "",
                // 
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: 'Add Used Truck Holds Submission Form',
                permissionNameArray: ['can_add_used_truck_holds_submission_form'],
                all_vin_data : "",
                referralSource : "",
                referralSourceArr : [],
                isReferralSourceRecordNotFound : false,
                customerSeenError : "",
                customerAppprovedError : "",
                noteError : "",
                referralSourceError : ""
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.getLoginIdPermissions();
            self.setActiveMenu('used_truck_holds_submission_form');
            EventBus.$emit("menu-selection-changed", "used_truck_holds_submission_form");
            $("#alertError").hide();
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                if (typeof (userJson.company_id) == 'undefined' && userJson.company_id == 0 && userJson.company_id == '') {
                    self.logout();
                }
            }
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getUsedTruckHoldsSubmissionFormDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.pageTitle = "Edit Used Truck Holds Submission Form";
            }
            //self.loadAllData(['User'], false, self.loadCompleteByDataCallback);
            self.loadAllData(['ReferralSource'], true, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            buttonBack() {
                self.$router.push({name: 'ListUsedTruckHoldsSubmissionForm'});
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadCompleteByDataCallback: function(callbackData) {
                self.completeByArr = callbackData.User;
            },
            onSearchCompleteBy(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getCompleteByData);
                }
            },
            getCompleteByData: function (callbackData) {
                self.isCompleteByRecordNotFound = false;
                self.completeByArr = callbackData;
                if(self.completeByArr.length == 0) {
                    self.isCompleteByRecordNotFound = true;
                }
            },
            onSearchSalesmanUser(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getSalesmanData);
                }
            },
            getSalesmanData: function(callbackData) {
                self.isSalesmanNameRecordNotFound = false;
                self.salesmanIdArr = callbackData;
                if(self.salesmanIdArr.length == 0) {
                    self.isSalesmanNameRecordNotFound = true;
                }
            },
            loadMasterDataCallback: function(callbackData){
                self.isReferralSourceRecordNotFound = false;
                self.referralSourceArr = callbackData.ReferralSource;
                if (self.referralSourceArr.length == 0) {
                    self.isReferralSourceRecordNotFound = true;
                }
            },
            onSearchReferralSource: function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['ReferralSource'], true, self.getReferralSourceData, loading, search);
                }
            },
            getReferralSourceData: function (callbackData) {
                self.isReferralSourceRecordNotFound = false;
                self.referralSourceArr = callbackData.ReferralSource;
                if (self.referralSourceArr.length == 0) {
                    self.isReferralSourceRecordNotFound = true;
                }
            },
            getUsedTruckHoldsSubmissionFormDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/usedtruckholdssubmissionform/get', config)
                        .then(response => {
                            if (response.data.content.salesmanId != null) {
                                self.salesmanId = {text: response.data.content.uSalesFName + " " + response.data.content.uSalesLName, value: response.data.content.salesmanId};
                            }
                            /* if (response.data.content.referral_source != 'null' && response.data.content.referral_source != null) {
                                self.referral_source = response.data.content.referral_source; 
                            } else {
                                self.referral_source = "";
                            } */
                            if(response.data.content.referral_source_id != null) {
                                self.referralSource = {text: response.data.content.referral_source_name, value: response.data.content.referral_source_id};
                            }
                            self.vin = response.data.content.vin;
                            self.sellingPrice = response.data.content.sellingPrice;
                            self.customerName = response.data.content.customerNmae;
                            self.hasSeenTruckInPerson = response.data.content.hasSeenTruckInPerson;
                            self.hasApproveFinanceTerms = response.data.content.hasApproveFinanceTerms;
                            self.completed = (response.data.content.completed == "no") ? false : true;
                            self.note = response.data.content.note;
                            if (response.data.content.completeById != null) {
                            self.completeById = {text: response.data.content.userCompleteByFName + ' ' + response.data.content.userCompleteByLName, value: response.data.content.completeById};    
                            }
                            
                            self.createdAt = response.data.content.createdAt;
                            // self.attachment = response.data.content.attachment_path;
                            self.attachment = response.data.attachmentData;
                            self.all_vin_data = response.data.content.all_vin_data;
                            self.companyId = response.data.content.company_id;
                            self.phone = response.data.content.phone;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                            self.buttonBack();
                        });
            },
            checkForm: function () {
                var checked = true;
                if (self.salesmanId == null || !self.salesmanId.value) {
                    self.salesmanIdError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.completed) {
                    if (self.completeById == null || !self.completeById.value) {
                        self.completeByIdError = "Please select user";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                /*if (!self.vin) {
                    self.vinError = "Please enter Vin";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.sellingPrice) {
                    self.sellingPriceError = "Please enter selling Price";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (self.sellingPrice && !self.numberFormateWithSpaceValidation(self.sellingPrice)) {
                    self.sellingPriceError = "Please enter valid selling Price";
                    if (checked) {
                        checked = false;
                    }
                }*/
                if (!self.customerName) {
                    self.customerNameError = "Please enter customer Name";
                    if (checked) {
                        checked = false;
                    }
                }
                self.phoneError = "";
                if (!self.phone) {
                    self.phoneError = "Please enter phone";
                    if (checked) {
                    checked = false;
                    }
                }else if(self.phone && !self.validContactNumber(self.phone)) {
                    self.phoneError = "Please enter valid phone";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.phone && !self.checkContactNoLength(self.phone)) {
                    self.phoneError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                self.customerAppprovedError = "";
                if (!self.hasApproveFinanceTerms) {
                    self.customerAppprovedError = "Please select has customer approved finance terms";
                    if (checked) {
                    checked = false;
                    }
                }

                self.customerSeenError = "";
                if (!self.hasSeenTruckInPerson) {
                    self.customerSeenError = "Please select has customer seen truck/trailer in person";
                    if (checked) {
                    checked = false;
                    }
                }

                self.referralSourceError = "";
                if (!self.referralSource) {
                    self.referralSourceError = "Please enter referral source";
                    if (checked) {
                    checked = false;
                    }
                }

                if (!self.note) {
                    self.noteError = "Please enter note";
                    if (checked) {
                    checked = false;
                    }
                }
                return checked;
            },
            addVehicleCheckIn: function () {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('id', self.$route.params.id);
                        data.append('salesman_id', self.salesmanId.value);
                        /* data.append('referral_source', self.referral_source); */
                        data.append('referral_source', self.referralSource.value);
                        data.append('vin', self.vin);
                        data.append('selling_price', self.sellingPrice);
                        data.append('customer_name', self.customerName);
                        data.append('has_seen_truck_in_person', self.hasSeenTruckInPerson);
                        data.append('has_approve_finance_terms', self.hasApproveFinanceTerms);
                        data.append('completed', (self.completed) ? 'yes' : 'no');
                        data.append('note', self.note);
                        data.append('complete_by_id', self.completeById.value);
                        data.append('created_at', self.createdAt);
                        data.append('phone', self.phone);

                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/usedtruckholdssubmissionform/add/update", data, config)
                                .then(function (response) {
                                    self.buttonNames.save = "Update";
                                    if (response.data.status == "success") {
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    } else {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (response.data.content.length > 0) {
                                            var err = "";
                                            for (var i = 0; i < response.data.content.length; i++) {
                                                err += response.data.content[i] + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                })
                                .catch(function (err) {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    /* console.log(err); */
                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (let i = 0; i < companies.length; i++) {
                self.userCompany.push(companies[i].company);
            }
        }
    });
</script>