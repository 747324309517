<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_add_location_master')">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
      </div>
      <!--page content-->
      <div class="admin-page-content">
        <!--Forms-->
        <div :class="alertClass" id="success-alert">
          <strong> {{ alertMessage }} </strong>
        </div>
        <div class="forms-container add_user_forms_container vtc-dealer-forms">
          <div class="row no-margin mb-3">
            <common-loader
              refId="commonLoaderPrintingDocFieldsForm"
              ref="commonLoaderPrintingDocFieldsForm"
            >
            </common-loader>
              <!-- Dealer Code -->
              <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.dealer_code_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  id="dealerCode"
                  v-model="dealerCode"
                  class="form-control"
                  type="text"
                  maxlength="15"
                />
              </div>
              </div>
              <!-- DTNA Dealer Code -->
              <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.dtna_dealer_code_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  id="DTNAdealerCode"
                  v-model="DTNAdealerCode"
                  class="form-control"
                  type="text"
                  maxlength="15"
                />
              </div>
              </div>
              <!-- CDTFA Permit Number -->
              <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.cdtfa_permit_number_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  id="CDTFAPermitNumber"
                  v-model="CDTFAPermitNumber"
                  class="form-control"
                  type="text"
                  maxlength="15"
                />
              </div>
              </div>
              <!-- Dealer License No -->
              <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.dealer_license_no_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  id="dealerlicenseno"
                  v-model="dealerlicenseno"
                  class="form-control"
                  type="text"
                  maxlength="15"
                />
              </div>
              </div>
              <!-- Delivery Receipt Text -->
              <div class="col-12 forms-block">
                <!-- <div class="row"> -->
                  <div class="col-3 paddingleft0" style="width:141px">
                    <div class="form-label">
                      {{ staticInputLabelMessages.delivery_receipt_text_label }}
                    </div>
                  </div>
                  <div class="col-10 paddingleft0" style="width:80.5%">
                    <textarea
                      name="deliveryReceiptText"
                      style="width:100%;height: 110px;"
                      id="deliveryReceiptText"
                      maxlength="10000"
                      class="form-control"
                      v-model="deliveryReceiptText"
                    ></textarea>
                  </div>
                <!-- </div> -->
              </div>
          </div>

          <!--Buttons-->
          <div class="row clearfix"></div>
          <div class="button-demo">
            <input
              id="addLocationBtn"
              type="submit"
              value="Update"
              class="btn btn-primary"
              v-on:click.prevent="addLocation()"
            />
            <input
              id="cancelLocation"
              type="submit"
              value="Cancel"
              class="btn btn-primary"
              v-on:click.prevent="buttonBack"
            />
          </div>
        </div>
        <!-- Forms -->
      </div>
      <!--page content-->
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
  <!-- </div> -->
</template>

<script>
var self;
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import CommonLoader from "@/components/partials/CommonLoader.vue";

export default {
  name: "PrintingDocumentsFields",
  mixins: [commonMixins],
  components: {
    "common-loader": CommonLoader,
  },
  data() {
    return {
      id: this.$route.params.id,
      staticInputLabelMessages: "",
      staticMessages: "",
      dealerlicenseno: "",
      dealerCode: "",
      DTNAdealerCode: "",
      CDTFAPermitNumber: "",
      deliveryReceiptText: "",
      locid: "",
      name: "",
      alertClass: "",
      alertMessage: "",
      pageTitle: "Add Printing Documents Fields",
      permissionNameArray: ["can_add_location_master"],
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticInputLabelMessages = self.allLabelMessages;
    self.staticMessages = self.allStaticMessages;

    self.getLoginIdPermissions();
    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.getLocationDetailsById(self.$route.params.id);
    }
  },
  methods: {
    buttonBack() {
      self.$router.push("/location/list");
    },
    getLocationDetailsById: function (id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: id,
        },
      };
      HTTP.get("/location/get/form", config)
        .then((response) => {
          self.name = response.data.content.name;
          self.dealerlicenseno = response.data.content.dealer_lic_no;
          self.dealerCode = response.data.content.dealer_code;
          self.DTNAdealerCode = response.data.content.DTNA_dealer_code;
          self.CDTFAPermitNumber = response.data.content.cdtfa_permit_number;
          self.deliveryReceiptText = response.data.content.delivery_receipt_text;
          if (
            typeof response.data.content.locid != "undefined" &&
            response.data.content.locid != 0 &&
            response.data.content.locid != "" &&
            response.data.content.locid != "null"
          ) {
            self.locid = response.data.content.locid;
          }
        })
        .catch(function (err) {
          self.catchHandler(err, function () {});
        });
    },
    returnData: function () {
      var data = {};
      if (
        typeof self.$route.params.id != "undefined" &&
        self.$route.params.id != 0 &&
        self.$route.params.id != ""
      ) {
        data = {
          id: self.$route.params.id,
          name: self.name,
          locid: self.locid,
          dealer_lic_no: self.dealerlicenseno,
          dealer_code: self.dealerCode,
          DTNA_dealer_code: self.DTNAdealerCode,
          cdtfa_permit_number: self.CDTFAPermitNumber,
          delivery_receipt_text: self.deliveryReceiptText,
        };
      } else {
        data = {
          locid: self.locid,
          name: self.name,
          dealer_lic_no: self.dealerlicenseno,
          dealer_code: self.dealerCode,
          DTNA_dealer_code: self.DTNAdealerCode,
          cdtfa_permit_number: self.CDTFAPermitNumber,
          delivery_receipt_text: self.deliveryReceiptText,
        };
      }
      return data;
    },
    addLocation(flag) {
      setTimeout(() => {
          $("#commonLoaderPrintingDocFieldsForm")
            .find(".loading-container.lodingTable")
            .show();

          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token"),
            },
          };

          var data = self.returnData();
          data.save_printing_documents_fields = true;
          HTTP.post("/location/add/update", data, config)
            .then(function (response) {
              $("#commonLoaderPrintingDocFieldsForm")
                .find(".loading-container.lodingTable")
                .hide();

              if (response.data.status == "success") {
                self.showSAlert(response.data.message, "success", false, 3);
                  setTimeout(function () {
                    self.buttonBack();
                  }, 3000);
              } else {
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              }
            })
            .catch(function (err) {
              $("#commonLoaderPrintingDocFieldsForm")
                .find(".loading-container.lodingTable")
                .hide();
              self.catchHandler(err, function () {});
            });
      }, 200);
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
