<template>
  <div class="modal" id="addOnModal" tabindex="-1" role="dialog" aria-labelledby="addOnModalLabel" aria-hidden="true">
  <div v-if="isClickAddOnList" class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addOnModalLongTitle">Add On Deal</h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <div class="row">
              <div class="col-md-3">
                  <label for="recipient-name" class="col-form-label float-right">Select Items:</label>
              </div>
              <div class="col-md-6">
                  <v-select v-model="itemName"
                          name="item"
                          id="item"
                          label="item"
                          :options="itemList"
                          @input="showAddOnForm"
                          placeholder="Select Item"
                    ></v-select>
              </div>
          </div>
        </div>

        <form v-if="showForm">
          <hr>
          <div class="form-group mb-1" v-if="itemName.panel == 2 || itemName.panel == 3">
            <div class="row">
                <div class="col-md-3">
                    <label for="recipient-name" class="col-form-label float-right">Equipment:</label>
                </div>
                <div class="col-md-6">
                    <v-select v-model="equipment"
                          name="equipment"
                          id="equipment"
                          label="eq_des"
                          :options="equipmentList"
                          @input="equipmentEffect"
                          placeholder="Select Equipment"
                    ></v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{equipmentError}}</span>
                </div>
            </div>
          </div>
          <div class="form-group mb-1">
            <div class="row">
                <div class="col-md-3">
                    <label for="description" class="col-form-label float-right">Description:</label>
                </div>
                <div class="col-md-6">
                    <textarea class="form-control" id="description" v-model="description"></textarea>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{descriptionError}}</span>
                </div>
            </div>
          </div>
          <div class="form-group mb-1">
            <div class="row">
                <div class="col-md-3">
                    <label for="price" class="col-form-label float-right">Price:</label>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <money v-bind="moneyMask" type="text" class="form-control" id="price" v-model="price" @keyup.native="event => {incrementDecrementNumber(event, 'price')}" />
                      </div>
                      <div class="col-md-8" v-if="itemName.panel == 2 || itemName.panel == 3">
                        <input type="checkbox" name="not_charged" v-model="isNotCharged">
                        <label for="not-charged" class="col-form-label">Not Charged</label>
                      </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="form-group mb-1" v-if="itemName.panel == 2 || itemName.panel == 3">
            <div class="row">
                <div class="col-md-3">
                    <label for="cost" class="col-form-label float-right">Cost:</label>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <money v-bind="moneyMask" type="text" class="form-control" id="cost" v-model="cost" @keyup.native="event => {incrementDecrementNumber(event, 'cost')}" />
                    </div>
                    <div class="col-md-8">
                      <input type="checkbox" name="no_cost" v-model="isNoCost">
                      <label for="no-cost" class="col-form-label">No Cost</label>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label for="recipient-name" class="col-form-label float-right">Tax:</label>
                </div>
                <div class="col-md-6">
                  <v-select v-model="tax"
                          name="name"
                          id="tax"
                          label="text"
                          :options="taxList"
                          @input="''"
                          placeholder="Select Tax"
                    ></v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{taxError}}</span>
                </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <div class="col-md-2">
                      <input type="checkbox" name="add_to_total_price" class="form-control" v-model="isAddToTotalPrice">
                    </div>
                    <div class="col-md-10">
                      <label for="add-to-total-price" class="col-form-label float-left">Add To Total Price</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="col-md-2">
                      <input type="checkbox" name="show_on_documents" class="form-control" v-model="isShowOnDocuments">
                    </div>
                    <div class="col-md-10">
                      <label for="show-on-documents" class="col-form-label float-left">Show On Documents</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="col-md-2">
                      <input type="checkbox" name="fet_exempt" class="form-control" v-model="isFetExempt">
                    </div>
                    <div class="col-md-10">
                      <label for="fet-exempt" class="col-form-label float-left">FET Exempt</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="col-md-2">
                      <input type="checkbox" name="do_not_add_to_gp" class="form-control" v-model="doNotAddToGp">
                    </div>
                    <div class="col-md-10">
                      <label for="do-not-add-to-gp" class="col-form-label float-left">Do Not Add To GP</label>
                    </div>
                </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer" v-show="showForm">
        <button type="button" class="btn btn-primary" @click="saveAddOnList()">Save</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import EventBus from "../../event-bus";
  import {HTTP} from '../../../http-common';
  import {Money} from 'v-money';
  export default {
    name: 'AddOnDealModal',
    props: ['addOnEditData','editAssetId','addOnTempIndex','customerCountyId','isClickAddOnList'],
    components: {
      Money
    },
    data() {
      return {
        // itemList: [
        //   {
        //     text: 'Adds',
        //     value: 1
        //   },
        //   {
        //     text: 'Battery Fee',
        //     value: 2
        //   },
        //   {
        //     text: 'Cash Down',
        //     value: 3
        //   },
        //   {
        //     text: 'Comment',
        //     value: 4
        //   },
        //   {
        //     text: 'Commission',
        //     value: 5
        //   },
        //   {
        //     text: 'Freight Fee',
        //     value: 6
        //   },
        //   {
        //     text: 'Incentive',
        //     value: 7
        //   },
        //   {
        //     text: 'Inspection Fee',
        //     value: 8
        //   },
        //   {
        //     text: 'Insurance',
        //     value: 9
        //   },
        //   {
        //     text: 'IPO',
        //     value: 10
        //   },
        //   {
        //     text: 'License Fee',
        //     value: 11
        //   },
        //   {
        //     text: 'Rebate',
        //     value: 12
        //   },
        //   {
        //     text: 'Registration Fee',
        //     value: 13
        //   },
        //   {
        //     text: 'Reserve',
        //     value: 14
        //   },
        //   {
        //     text: 'Service Contract',
        //     value: 15
        //   },
        //   {
        //     text: 'Smog Fee',
        //     value: 16
        //   },
        //   {
        //     text: 'Tire Credit',
        //     value: 17
        //   },
        //   {
        //     text: 'Tire Discount',
        //     value: 18
        //   },
        //   {
        //     text: 'Title Fee',
        //     value: 19
        //   },
        //   {
        //     text: 'Vehicle-Price',
        //     value: 20
        //   },
        // ],
        // equipmentList: [
        //   {
        //     text: 'No AA Rules**C-WSP: WALK AROUND (sport truck)',
        //     value: 1
        //   },
        //   {
        //     text: 'BUSINESS TAX <nashville only> [ONLY FOR ARCADIUM, NOT EXCEDE VOM]',
        //     value: 2
        //   },
        //   {
        //     text: 'C-AR: COS+  <AR> [COST ONLY]',
        //     value: 3
        //   },
        //   {
        //     text: 'C-ARIN: INV +  <AR> [COST ONLY]',
        //     value: 4
        //   },
        //   {
        //     text: 'C-BODY: BODY <ENTER DETAILS> [COST ONLY]',
        //     value: 5
        //   },
        //   {
        //     text: 'C-BPUR: BULK PURCHASE *CCTTS only* [COST ONLY]',
        //     value: 6
        //   },
        //   {
        //     text: 'C-COM: COMMISSION [COST ONLY]',
        //     value: 7
        //   },
        //   {
        //     text: 'C-FCOM: F&I COMMISSION [COST ONLY]',
        //     value: 8
        //   },
        //   {
        //     text: 'C-FETC: FET TIRE CREDIT [COST ONLY]',
        //     value: 9
        //   },
        //   {
        //     text: 'C-INC: INCENTIVE [COST ONLY]',
        //     value: 10
        //   },
        //   {
        //     text: 'C-MISC: COS+ <INV> [COST ONLY]',
        //     value: 11
        //   },
        //   {
        //     text: 'C-MRSV: MISC RESERVE [COST ONLY]',
        //     value: 12
        //   },
        //   {
        //     text: "C-OPO: OPEN PO's COS+ <INV> [COST ONLY]",
        //     value: 13
        //   },
        //   {
        //     text: 'C-RBC REBILLING COMMISSION [COST ONLY] *cctts only*',
        //     value: 14
        //   },
        //   {
        //     text: 'C-RBC REBILLING UNIT COST [COST ONLY] *cctts only*',
        //     value: 15
        //   },
        //   {
        //     text: 'C-RSV FINANCE RESERVE [COST ONLY]',
        //     value: 16
        //   },
        //   {
        //     text: 'E-ADA: ADA [PRICE ONLY]',
        //     value: 17
        //   },
        //   {
        //     text: 'E-DEL: OUT OF STATE DELIVERY [PRICE ONLY]',
        //     value: 18
        //   },
        //   {
        //     text: 'E-DELP: OUT OF STATE DELIVERY PROFIT [PRICE ONLY] *cctts only*',
        //     value: 19
        //   },
        //   {
        //     text: 'E-FETT: FET TIRE CREDIT (for Arcadium field only) [PRICE ONLY AS NEGATIVE]',
        //     value: 20
        //   },
        //   {
        //     text: 'E-FPE: FLOOR PLAN EXPENSE [PRICE ONLY]',
        //     value: 21
        //   },
        //   {
        //     text: 'E-FPT: FLOOR PLAN TRANSFER [PRICE ONLY AS NEGATIVE]',
        //     value: 22
        //   },
        //   {
        //     text: 'E-TT: TIRE TAX [PRICE ONLY]',
        //     value: 23
        //   },
        //   {
        //     text: 'FLEET RESERVE (New Reserve)',
        //     value: 24
        //   },
        //   {
        //     text: 'FLEET RESERVE (Used Reserve)',
        //     value: 25
        //   },
        //   {
        //     text: 'INSURANCE <ENTER DETAILS> [PRICE & COST]',
        //     value: 26
        //   },
        //   {
        //     text: 'TAX ON WARRANTY <nashville only> [ONLY FOR ARCADIUM, NOT EXCEDE VOM]',
        //     value: 27
        //   },
        //   {
        //     text: 'WARRANTY <ENTER DETAILS> [PRICE & COST]',
        //     value: 28
        //   },
        //   {
        //     text: 'WARRANTY ON FACTORY INVOICE  [ONLY FOR ARCADIUM, NOT EXCEDE VO]',
        //     value: 29
        //   },
        // ],
        itemList: [],
        equipmentList: [],

        equipment: '',
        itemName: '',
        showForm: false,

        price: 0,
        description: '',
        cost: 0,
        tax: '',
        isNotCharged: false,
        isNoCost: false,
        isAddToTotalPrice: true,
        isShowOnDocuments: true,
        isFetExempt: false,
        doNotAddToGp: false,
        dealInventoryMapperId: 0,
        id: null,
        isEdit: false,
        taxList: [
          {
            text: 'EXEMPT (0.00000)',
            value: 0
          },
        ],
        moneyMask: {
          thousands: ',',
          prefix: '$ ',
          precision: 2,
        },
        equipmentError: '',
        descriptionError: '',
        taxError: '',
        notCharged: [
          'C-COM',
          'C-FCOM',
          'C-FETC',
          'C-INC',
          'C-MISC',
          'C-MRSV',
          'C-OPO',
          'C-RBCM',
          'C-RBC',
          'C-RSV',
        ],
        noCost: [
          'E-ADA',
          'E-DEL',
          'E-DELP',
          'E-FPE',
          'E-TT',
        ],
        notAddToGP: [
          'E-DEL',
          'E-DELP',
          'E-FPE',
          'I-INS',
        ]
      }
    },
    methods: {
      showAddOnForm(event) {
        // if(event.text == 'Adds') {
          this.showForm = true;
          this.equipment = '';
          this.price = 0;
          this.description = (event.panel == 2 || event.panel == 3) ? '' : event.item;
          this.cost = 0;
          this.isNotCharged = false;
          this.isNoCost = false;
          this.isAddToTotalPrice = true;
          this.isShowOnDocuments = true;
          this.isFetExempt = false;
          this.doNotAddToGp = event.excede_item_id == 953 ? true : false;
        // } else {
        //   this.showForm = false;
        // }
      },
      saveAddOnList() {
        if (this.checkCustomerForm()) {
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          var data = {
            deal_inventory_id: this.dealInventoryMapperId,
            excede_item_id: this.itemName.id,
            equipment_id: !!this.equipment ? this.equipment.id : '',
            price: parseFloat(this.price),
            description: (!!this.equipment && this.description.indexOf(this.equipment.eq_id) == -1) ? (this.equipment.eq_id+' - '+this.description) : this.description,
            cost: parseFloat(this.cost),
            tax: this.tax.text,
            is_not_charged: this.isNotCharged,
            is_no_cost: this.isNoCost,
            is_add_to_total_price: this.isAddToTotalPrice,
            is_show_on_documents: this.isShowOnDocuments,
            is_fet_exempt: this.isFetExempt,
            is_do_not_add_to_gp: this.doNotAddToGp,
            id: this.id,
            is_edit: this.isEdit,
          };
          if(this.editAssetId == null) {
            var AddOnTemp = [];
            if(localStorage.getItem('AddOnTemp') != null) {
              AddOnTemp = JSON.parse(localStorage.getItem('AddOnTemp'));
            }

            if(this.isEdit == true) {
              AddOnTemp[this.addOnTempIndex] = data;
            } else {
              AddOnTemp.push(data);
            }
            localStorage.setItem('AddOnTemp', JSON.stringify(AddOnTemp));

            EventBus.$emit("add-on-list-data",AddOnTemp);
            Object.assign(this.$data, this.$options.data());
          } else {
            HTTP.post("store/add-on-deal", data, config)
            .then(response => {
              EventBus.$emit("add-on-list-data",response.data.content);
              Object.assign(this.$data, this.$options.data());
            })
            .catch(function (err) {
              console.log('error=>', err);
            });
          }
        }
      },
      checkCustomerForm() {
        var checked = true;
        if ((this.itemName.value == 1 || this.itemName.value == 9) && !this.equipment) {
          this.equipmentError = "Please Select Equipment";
          if (checked) {
            checked = false;
          }
        } else {
          this.equipmentError = '';
        }
        if (!this.description) {
          this.descriptionError = "Please Enter Description";
          if (checked) {
            checked = false;
          }
        } else {
          this.descriptionError = '';
        }
        if (!this.tax) {
          this.taxError = "Please Select Tax";
          if (checked) {
            checked = false;
          }
        } else {
          this.taxError = '';
        }
        return checked;
      },
      getExcedeEquipment() {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
          }
        };
        HTTP.get("get-add-on-excede-equipments", config)
        .then( response => {
          this.equipmentList = response.data.content.equipments;
          this.itemList = response.data.content.excede_items;
          return true;
        })
        .catch(function (err) {
          console.log('error=>', err);
        });
      },
      equipmentEffect(event) {
        this.description = event.eq_des;
        if(this.notCharged.includes(event.eq_id)) {
          this.isNotCharged = true;
          this.isNoCost = false;
        } else {
          this.isNotCharged = false;
        };

        if(this.noCost.includes(event.eq_id)) {
          this.isNoCost = true;
          this.isNotCharged = false;
        } else {
          this.isNoCost = false;
        };

        if(this.notAddToGP.includes(event.eq_id)) {
          this.doNotAddToGp = true;
        } else {
          this.doNotAddToGp = false;
        };
      },
      edit(addOnData) {
          var itemSelected = this.itemList.filter(currentData => {
            if(currentData.id == addOnData.excede_item_id) {
              return currentData;
            }
          });

          var taxSelected = this.taxList.filter(currentData => {
            if(currentData.text == addOnData.tax) {
              return currentData;
            }
          });

          this.itemName = itemSelected[0];
          this.price = addOnData.price;
          this.cost = addOnData.cost;
          this.isNotCharged = addOnData.is_not_charged;
          this.isNoCost = addOnData.is_no_cost;
          this.isAddToTotalPrice = addOnData.is_add_to_total_price;
          this.isShowOnDocuments = addOnData.is_show_on_documents;
          this.isFetExempt = addOnData.is_fet_exempt;
          this.doNotAddToGp = addOnData.is_do_not_add_to_gp;
          this.tax = taxSelected[0];

          var equipmentSelected = this.equipmentList.filter(currentData => {
            if(currentData.id == addOnData.equipment_id) {
              return currentData;
            }
          });
          this.equipment = equipmentSelected[0];
          console.log('tempData>>',addOnData.description);
          this.description = addOnData.description;
          console.log('addOnData>>', addOnData);
          console.log('description>>', this.description, this.equipment, (!!this.equipment ? 'true' : 'false'));
          this.id = addOnData.id;
          this.isEdit = true;
          this.showForm = true;
          return true;
      },
      incrementDecrementNumber(event, dataInstance) {
        switch (event.key) {
          case 'ArrowUp':
            this[dataInstance] += 1;
            break;
          case 'ArrowDown':
            this[dataInstance] -= 1;
            break;
        }
      }
    },
    watch: {
      addOnEditData(addOnData) {
        if(Object.keys(addOnData).length == 0 && this.isEdit == true) {
          Object.assign(this.$data, this.$options.data());
        }
        this.getExcedeEquipment();
        this.dealInventoryMapperId = this.editAssetId;
        var countyValue = this.customerCountyId.name.match(/\[?([+\.$\d]{0,150})\]?/);
        if(countyValue && this.taxList.length == 1) {
          this.taxList.push({
            text: this.customerCountyId.name,
            value: countyValue[1]
          });
        }

        if(Object.keys(addOnData).length > 0) {
          setTimeout(() => {
            this.edit(addOnData);
          }, 400);
        }
      }
    }
  }
</script>