<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_edit_settings')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="name" v-model="name" class="form-control" type="text" maxlength="190" readonly/>
                                </div>
                            </div>

                            <!--Value-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">
                                        {{allLabelMessages.tags_value}} 
                                        <i class="fa fa-info-circle info-icon" style="color: #145375" :data-tooltip="selectedNote"></i>
                                    </div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="typeId == 'Boolean'">
                                        <b-form-radio-group 
                                            v-model="value"
                                            :options="yesNoOptArr"
                                            name="value"
                                            @input="valueError=''">
                                        </b-form-radio-group>
                                    </template>
                                    <template v-else-if="typeId == 'Text'">
                                        <input id="value" v-model="value" class="form-control" type="text" maxlength="190" @input="valueError=''"/>
                                    </template>
                                    <template v-else-if="typeId == 'Number'">
                                        <input id="value" v-model="value" class="form-control" type="text" maxlength="10" @input="valueError=''"/>
                                    </template>
                                    <template v-else-if="typeId == 'Email' || typeId == 'SingleEmail'">
                                        <vue-tags-input
                                            v-model="value"
                                            :tags="valueEmlTagArr"
                                            :validation="validation"
                                            @tags-changed="handleValueEmlTagsChanged"
                                            @before-adding-tag="checkValueEmlTag"
                                            placeholder=""
                                            @input="valueError=''; isSingleEmlExceed=false"
                                        />
                                    </template>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{valueError}}</span>
                                </div>
                            </div>
                        </div>
                       
                        <div class="clearfix"></div>
                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="updateSettingBtn" :disabled="disableButtons.update" type="submit" :value="buttonNames.update" class="btn btn-primary" v-on:click.prevent="updateSetting()">
                            <input id="cancelSetting" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"
    import VueTagsInput from '@johmun/vue-tags-input';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'UpdateSetting',
        mixins: [commonMixins],
        components: { 
            VueTagsInput
        },
        data () {
            return {
                name : "",
                typeId: "",

                value: "",
                valueError: "",

                selectedNote: '',

                buttonNames : {
                    update : 'Update',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    update : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Edit Setting",
                permissionNameArray : ['can_edit_settings'],
                yesNoOptArr: [
                    {
                        text: "Yes",
                        value: "1"
                    },
                    {
                        text: "No",
                        value: "0"
                    }
                ],
                validation: [
                    {
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    }
                ],
                valueEmlTagArr: [],

                isValidValueEmlTag :true,
                isValueEmlEmpty: true,
                isEmlMaxLengthReach: false,
                isSingleEmlExceed: false
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('setting-list');
            EventBus.$emit("menu-selection-changed", "setting-list");

            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getSettingDetailsById();
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({ name: 'ListSetting'})
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getSettingDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };
                HTTP.get('/setting/get', config)
                    .then(response => {
                        self.name = response.data.content.data.name;
                        self.value = response.data.content.data.value;
                        self.typeId = response.data.content.data.type;
                        self.selectedNote = response.data.content.data.notes;

                        if(self.typeId == 'Email' || self.typeId == 'SingleEmail') {
                            self.value = '';
                            var valueEmlArr = response.data.content.data.value.split(",");
                            if (valueEmlArr.length > 0) {
                                self.valueEmlTagArr = valueEmlArr.map(email => ({
                                                                        "text": email.trim(), 
                                                                        "tiClasses": ["ti-valid"]
                                                                    }));
                                self.isValueEmlEmpty = false;
                            } else {
                                self.isValueEmlEmpty = true;
                            }
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                self.valueError = '';

                if(self.typeId) {
                    const type = self.typeId;
                    const valueRequiredTypes = ['Boolean', 'Text', 'Number'];
                    
                    if (valueRequiredTypes.includes(type)) {
                        if (!self.value) {
                            self.valueError = type === 'Boolean' ? "Please select value" : "Please enter value";
                            checked = false;
                        }
                    } else if (type === 'Email' || type === 'SingleEmail') {
                        if (self.isValueEmlEmpty) {
                            self.valueError = "Please enter value";
                            checked = false;
                        } else if(self.isEmlMaxLengthReach) {
                            self.valueError = "Please shorten or remove entries as they exceed the 190-character limit";
                            checked = false;
                        } else if (!self.isValidValueEmlTag) {
                            self.valueError = "Please enter valid value email";
                            checked = false;
                        } else if(type === 'SingleEmail' && self.isSingleEmlExceed) {
                            self.valueError = "Please enter only one email";
                            checked = false;
                        }
                    }
                }
                return checked;
            },
            updateSetting() {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };

                        var valueEmlData = "";
                        if(self.typeId == 'Email' || self.typeId == 'SingleEmail') {
                            if(self.valueEmlTagArr && self.valueEmlTagArr.length > 0) {
                                for(var index = 0; index < self.valueEmlTagArr.length; index++) {
                                    if(!valueEmlData.includes(self.valueEmlTagArr[index].text)) {
                                        valueEmlData += (self.valueEmlTagArr[index].text) + ',';
                                    }
                                }
                            }
                            valueEmlData = valueEmlData.replace(/,\s*$/, "");
                        }
                        var data = new FormData();
                        data.append('value', (self.typeId == 'Email' || self.typeId == 'SingleEmail') ? valueEmlData : self.value);

                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.update = "Updating ...";
                            self.disableButtons.update = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/setting/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.update = "Update";
                                    self.disableButtons.update = true;
                                    self.disableButtons.cancel = true;
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.update = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    
                                    setTimeout(function () {
                                        self.disableButtons.update = false;
                                        self.disableButtons.cancel = false;
                                        self.buttonBack();
                                    }, 3000);
                                } else {
                                    self.disableButtons.update = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.update = "Update";
                                    self.disableButtons.update = false;
                                    self.disableButtons.cancel = false;
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            handleValueEmlTagsChanged: function(newTags) {
                self.valueError = "";
                self.valueEmlTagArr = newTags;
                self.isValueEmlEmpty = false;
                self.isValidValueEmlTag = true;
                self.isEmlMaxLengthReach = false;
                self.isSingleEmlExceed = false;

                let totalLength = self.valueEmlTagArr.map(tag => tag.text).join(',').length;
                if (self.valueEmlTagArr.length === 0) {
                    self.isValueEmlEmpty = true;
                } else if (totalLength > 190) {
                    self.isEmlMaxLengthReach = true;
                } else {
                    let invalidTagFound = self.valueEmlTagArr.some(tag => !self.validEmail(tag.text));
                    if (invalidTagFound) {
                        self.isValidValueEmlTag = false;
                    }
                }
                if (self.typeId === 'SingleEmail' && self.valueEmlTagArr.length > 1) {
                    self.isSingleEmlExceed = true;
                }
            },
            checkValueEmlTag: function(obj) {
                self.valueError = "";
                self.isValueEmlEmpty = false;
                self.isValidValueEmlTag = true;
                self.isEmlMaxLengthReach = false;
                self.isSingleEmlExceed = false;

                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                let newTagText = tagEmail[1];
                let updatedTags = [...self.valueEmlTagArr, { text: newTagText }];
                let totalLength = updatedTags.map(tag => tag.text).join(',').length;
                if(!tagEmail[1]) {
                    self.isValueEmlEmpty = true;
                } else if (totalLength > 190) {
                    self.isEmlMaxLengthReach = true;
                } else if(tagEmail[1] && !self.validEmail(tagEmail[1])) {
                    self.isValidValueEmlTag = false;
                } else {
                    if (self.typeId === 'SingleEmail' && self.valueEmlTagArr.length >= 1) {
                        self.isSingleEmlExceed = true;
                    } else {
                        obj.addTag();
                    }
                }
            }
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>
<style lang="css" scoped>
    .help.is-danger {
        text-transform: none;
    }
    .info-icon {
        font-size: 16px;
        color: #145375;
        cursor: pointer;
        position: relative;
    }
    .info-icon:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #333;
        color: #fff;
        padding: 8px 12px;
        border-radius: 6px;
        font-size: 14px;
        white-space: nowrap;
        font-family: 'SanFranciscoDisplayRegular';
    }
</style>