<template
  xmlns:onfocusout="http://www.w3.org/1999/xhtml"
  xmlns:v-slot="http://www.w3.org/1999/XSL/Transform"
>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_inventory_master')">
      <div class="container-fluid xyz" v-if="showMainList">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>Inventory</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <router-link
              v-show="
                permissionNameArray.includes(
                  'can_manually_add_inventory_master'
                )
              "
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Add Inventory"
              v-bind:to="{ name: 'AddAsset' }"
            >
              <span>Add Inventory</span>
            </router-link>
            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
            <a
              v-show="
                permissionNameArray.includes('can_import_inventory_master')
              "
              id="importCrossroadInv"
              title="Import"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="loadImportModal('import_record')"
            >
              <i class="fa fa-cloud-upload" aria-hidden="true"></i>
            </a>
            <a
              id="inventoryFilters"
              title="Inventory Filters"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Inventory Filters', //Modal Title
                'filter', //Action
                'listInventory', //Module Name 
                filterMasterHeaders //Master Headers List
              )"
            >
              <span><i class="fa fa-cog" aria-hidden="true"></i> Filter</span>
            </a>
            <a
              id="exportAssetExpense"
              title="Export"
              v-show="
                permissionNameArray.includes('can_export_inventory_master')
              "
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Export Inventory', //Modal Title
                'export', //Action
                'exportInventory', //Module Name 
                exportHeaders //Master Headers List
              )"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>
            <a
              id="assetHeaderList"
              title="Inventory Header List"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Selected Inventory Header List', //Modal Title
                'list', //Action
                'listInventory', //Module Name 
                assetHeadersList //Master Headers List
              )"
            >
              <span>Inventory Header List</span>
            </a>
            <button
              id="exportAsset"
              title="Export"
              v-show="
                permissionNameArray.includes(
                  'can_export_available_date_inventory_master'
                )
              "
              class="extraFeature btn btn-success waves-effect pull-right ml-2"
              v-on:click.prevent="exportAssetAvailableData($event)"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i> Available Age Export
            </button>
            <router-link
              v-show="
                permissionNameArray.includes(
                  'can_show_delete_inventory_list_inventory_master'
                )
              "
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Deleted Inventory List"
              v-bind:to="{ name: 'DeleteAssetList' }"
            >
              <span>Deleted Inventory List</span>
            </router-link>
            <a
              v-show="
                permissionNameArray.includes('can_book_deal_inventory_master')
              "
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openBookDealLink()"
            >
              <i class="fa fa fa-handshake-o" aria-hidden="true">
                {{ allLabelMessages.create_quotation_action_label }}
              </i>
            </a>
            <a
              v-show="permissionNameArray.includes('can_add_cso_request')"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openCsoRequestLink()"
              v-if="roleId == 4"
            >
              <i class="fa fa-paper-plane" aria-hidden="true">
                {{ allLabelMessages.cso_request }}
              </i>
            </a>
          </div>

          <!-- Export file status -->
          <div class="col-12" v-if="batchProcess">
            <div class="alert alert-success">
              Export file is in process. batch no. is {{batchNum}}. <router-link class="color-blue" v-bind:to="{name: 'ListBatch'}" target="_blank" @click.native="batchProcess = 0"> Click Here</router-link> to check file status!
            </div>
          </div>

          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
            :moduleName="'listInventory'"
          ></filter-component>

          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <button
              v-show="
                permissionNameArray.includes('can_compare_inventory_master') &&
                selectedRows.length > 1 &&
                selectedRows.length < 6
              "
              type="button"
              v-on:click.prevent="compareAssets()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              Compare ({{ selectedRows.length }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_spec_sheet_inventory_master'
                ) && showSpecListBtn
              "
              type="button"
              v-on:click.prevent="specListSelectedEmail()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              <i class="fa fa-envelope-o" aria-hidden="true"></i> Spec List ({{
                selectedRows.length
              }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_spec_sheet_inventory_master'
                ) && showSpecSheetBtn
              "
              type="button"
              v-on:click.prevent="specSheetSelectedEmail()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              <i class="fa fa-envelope-o" aria-hidden="true"></i> Spec Sheet ({{
                selectedRows.length
              }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_spec_sheet_inventory_master'
                ) && showPromoSheetBtn
              "
              type="button"
              v-on:click.prevent="promoSheetSelectedEmail()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              <i class="fa fa-envelope-o" aria-hidden="true"></i> Promo Sheet
              ({{ selectedRows.length }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes('can_multi-print_inventory_master')
              "
              type="button"
              v-on:click.prevent="printSelected()"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Print Multiple"
            >
              <i class="fa fa-print" aria-hidden="true"></i> Multi-Print ({{
                selectedRows.length
              }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes('can_add_inventory_master') &&
                selectedRows.length > 1 &&
                selectedRows.length <= 75
              "
              type="button"
              v-on:click.prevent="editSelected()"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Edit Multiple"
            >
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              Multi-Edit ({{ selectedRows.length }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes('can_delete_inventory_master')
              "
              type="button"
              v-on:click.prevent="deleteAsset()"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Delete Multiple"
            >
              <i class="fa fa-trash-o" aria-hidden="true"></i> Delete ({{
                selectedRows.length
              }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_active_deactive_status_inventory_master'
                )
              "
              type="button"
              v-on:click.prevent="activeInactiveAsset(0, 'Active')"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Activate Selected"
            >
              <i class="fa fa-eye" aria-hidden="true"></i> Show Online ({{
                selectedRows.length
              }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_active_deactive_status_inventory_master'
                )
              "
              type="button"
              v-on:click.prevent="activeInactiveAsset(0, 'Inactive')"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Deactivate Selected"
            >
              <i class="fa fa-eye-slash" aria-hidden="true"></i> Hide From
              Online ({{ selectedRows.length }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_print_qr_code_inventory_master'
                )
              "
              type="button"
              v-on:click.prevent="generateQrCodes()"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Print QR Code"
            >
              <i class="fa fa-eye-slash" aria-hidden="true"></i> Print QR ({{
                selectedRows.length
              }})<!-- Record(s)-->
            </button>
          </div>
          <!-- * Sticky Filter and pagination logic Start * -->

          <!-- * Sticky Filter and pagination logic End * -->
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListAsset"
              ref="commonLoaderListAsset"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    ref="select"
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="assetArr && assetArr.length">
                    <template v-for="(asset, index) in assetArr">
                      <tr
                        class="mainDetails"
                        :key="'row_td_checkbox_' + index + '_' + asset.id"
                      >
                        <td class="">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="selectedRows"
                              :value="asset.id"
                              @change="inputCheckbox($event)"
                              class="custom-control-input"
                              :id="'customCheck' + asset.id"
                              name="case[]"
                            />
                            <label
                              class="custom-control-label"
                              :for="'customCheck' + asset.id"
                            ></label>
                          </div>
                        </td>

                        <td
                          v-show="actionDisplay"
                          :key="'row_td_actions_' + index + '_' + asset.id"
                        >
                          <div class="actions" style="width: 200px">
                            <a
                              :id="'accordion-' + asset.id"
                              v-on:click="toggleIcon(asset.id)"
                              v-b-toggle="'accordion-' + asset.id"
                              href="javascript:void(0)"
                              title="Show Extra details"
                              class="mar-right-0"
                            >
                              <i
                                v-if="accordianIds.includes(asset.id)"
                                class="exceptEdit fa fa-minus"
                                aria-hidden="true"
                              ></i>
                              <i
                                v-else
                                class="exceptEdit fa fa-plus"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <router-link
                              v-show="
                                permissionNameArray.includes(
                                  'can_add_inventory_master'
                                ) ||
                                permissionNameArray.includes(
                                  'can_view_inventory_master'
                                )
                              "
                              class="mar-right-0"
                              title="Edit Inventory"
                              v-bind:to="{
                                name: 'AssetTabView',
                                params: { id: asset.id },
                              }"
                            >
                              <i
                                aria-hidden="true"
                                class="fa fa-angle-right forward_icon"
                              ></i>
                            </router-link>
                            <a
                              href="javascript:void(0)"
                              class="mar-right-0"
                              @click.stop.prevent="
                                openContextMenu($event, asset.id, asset.slug)
                              "
                            >
                              <i
                                class="exceptEdit fa fa-cog"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a
                              v-show="
                                permissionNameArray.includes(
                                  'can_delete_inventory_master'
                                )
                              "
                              href="javascript:void(0)"
                              title="Delete Inventory"
                              class="mar-right-0"
                              v-on:click="deleteAsset(asset.id)"
                            >
                              <i
                                class="exceptEdit fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <template v-if="asset.is_active">
                              <a
                                v-show="
                                  permissionNameArray.includes(
                                    'can_active_deactive_status_inventory_master'
                                  )
                                "
                                href="javascript:void(0)"
                                title="Hide Inventory From Online"
                                class="mar-right-0"
                                v-on:click="
                                  activeInactiveAsset(asset.id, 'Inactive')
                                "
                              >
                                <i
                                  class="exceptEdit fa fa-eye color-71aa3f"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </template>
                            <template v-else>
                              <a
                                v-show="
                                  permissionNameArray.includes(
                                    'can_active_deactive_status_inventory_master'
                                  )
                                "
                                href="javascript:void(0)"
                                title="Show Inventory Online"
                                class="mar-right-0"
                                v-on:click="
                                  activeInactiveAsset(asset.id, 'Active')
                                "
                              >
                                <i
                                  class="exceptEdit fa fa-eye-slash color-c3202b"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </template>
                            <a
                              title="Image(s) available"
                              v-if="asset.image_count > 0"
                              href="javascript:void(0)"
                              class="mar-right-0"
                            >
                              <i
                                class="exceptEdit fa fa-file-image-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a
                              v-if="asset.image_count > 0"
                              class="mar-right-0"
                              href="javascript:void(0)"
                              title="Attachment Count"
                            >
                              <span
                                class="badge badge-primary badge-pill bg-color-0771b4"
                              >
                                {{ asset.image_count }}
                              </span>
                            </a>
                            <a
                              title="File(s) available"
                              v-show="
                                permissionNameArray.includes(
                                  'can_public_file_list_inventory_master'
                                ) ||
                                permissionNameArray.includes(
                                  'can_private_file_list_inventory_master'
                                )
                              "
                              href="javascript:void(0)"
                              class="mar-right-0"
                            >
                              <template
                                v-if="
                                  permissionNameArray.includes(
                                    'can_public_file_list_inventory_master'
                                  ) &&
                                  permissionNameArray.includes(
                                    'can_private_file_list_inventory_master'
                                  )
                                "
                              >
                                <i
                                  v-if="
                                    asset.private_file_count +
                                      asset.public_file_count !=
                                    0
                                  "
                                  class="exceptEdit fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                              </template>
                              <template
                                v-else-if="
                                  permissionNameArray.includes(
                                    'can_private_file_list_inventory_master'
                                  )
                                "
                              >
                                <i
                                  v-if="asset.private_file_count != 0"
                                  class="exceptEdit fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                              </template>
                              <template
                                v-else-if="
                                  permissionNameArray.includes(
                                    'can_public_file_list_inventory_master'
                                  )
                                "
                              >
                                <i
                                  v-if="asset.public_file_count != 0"
                                  class="exceptEdit fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                              </template>
                            </a>
                            <a
                              title="Manually Edited"
                              v-if="asset.manually_edit != 0"
                              href="javascript:void(0)"
                              class="mar-right-0"
                            >
                              <i
                                class="exceptEdit fa fa-pencil-square color-c3202b"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </td>
                        <template v-for="(headerData, headerIndex) in headers">
                          <td
                            v-if="headerData.column_name == 'status'"
                            :class="'cl_name_' + headerData.column_name"
                            :key="
                              'row_td_' +
                              headerData.column_name +
                              '_' +
                              index +
                              '_' +
                              asset.id +
                              '_' +
                              headerIndex
                            "
                          >
                            <span
                              v-if="
                                asset[headerData.column_name] == 'Active' ||
                                asset[headerData.column_name] == 'Live'
                              "
                              class="badge badge-primary badge-pill bg-color-71aa3f"
                            >
                              {{ asset[headerData.column_name] }}
                            </span>
                            <span
                              v-else-if="
                                asset[headerData.column_name] == 'Hold'
                              "
                              class="badge badge-primary badge-pill bg-color-0771b4"
                            >
                              {{ asset[headerData.column_name] }}
                              <template
                                v-if="
                                  asset['hold_status_id'] != null &&
                                  asset['hold_status_id'] != 'null'
                                "
                                >({{ asset["hold_status_name"] }})</template
                              >
                            </span>
                            <span
                              v-else-if="
                                asset[headerData.column_name] == 'Pending'
                              "
                              class="badge badge-primary badge-pill bg-color-cc9636"
                            >
                              {{ asset[headerData.column_name] }}
                            </span>
                            <span
                              v-else-if="asset[headerData.column_name] == null"
                              class=""
                            >
                            </span>
                            <span
                              v-else
                              class="badge badge-primary badge-pill bg-color-c3202b"
                            >
                              {{ asset[headerData.column_name] }}
                            </span>
                          </td>
                          <td
                            v-else-if="headerData.column_name == 'tags'"
                            :class="headerData.column_class"
                            :key="
                              'row_td_tag_' +
                              index +
                              '_' +
                              asset.id +
                              '_' +
                              headerIndex
                            "
                          >
                            <span
                              class="badge badge-secondary mr-1"
                              role="button"
                              @click="addTag(asset.id, asset.tags)"
                              ><i
                                class="fa fa-plus"
                                v-if="
                                  permissionNameArray.includes(
                                    'can_add_tag_mapper'
                                  )
                                "
                                :title="allLabelMessages.add_tag"
                              ></i
                            ></span>
                            <template v-for="(tagData, tagIndex) in asset.tags">
                              <span
                                :key="'asset_tag_' + asset.id + '_' + tagIndex"
                                :id="
                                  'asset_tag_' +
                                  asset.id +
                                  '_' +
                                  tagIndex +
                                  '_' +
                                  tagData.id
                                "
                                class="badge rounded-pill badge-secondary"
                                v-if="
                                  typeof asset.tags != 'undefined' &&
                                  tagData.tag &&
                                  tagData.tag.tag_label
                                "
                                >{{ tagData.tag.tag_label
                                }}<i
                                  class="fa fa-trash tag-trash ml-2"
                                  @click="
                                    deleteTag(
                                      tagData.id,
                                      tagIndex,
                                      'asset_tag_' +
                                        asset.id +
                                        '_' +
                                        tagIndex +
                                        '_' +
                                        tagData.id
                                    )
                                  "
                                  role="button"
                                  :title="deleteTagTitle"
                                  v-if="
                                    permissionNameArray.includes(
                                      'can_delete_tag_mapper'
                                    )
                                  "
                                ></i
                              ></span>
                            </template>
                          </td>
                          <td v-else-if="headerData.column_name == 'retail_ready'">
                            <span v-if="asset[headerData.column_name] == 'Yes-need-details'">
                              Yes/Needs Detail
                            </span>
                            <span v-else>
                              {{ asset[headerData.column_name] }}
                            </span>
                          </td>
                          <td
                            v-else-if="headerData.name != 'Action'"
                            :class="
                              headerData.column_class +
                              '   cl_name_' +
                              headerData.column_name
                            "
                            :key="'headerIndex_' + headerIndex"
                          >
                            {{ asset[headerData.column_name] }}
                          </td>
                        </template>
                      </tr>
                      <tr
                        class="extraDetails"
                        :key="'asset_id_' + asset.id"
                        :id="asset.id"
                      >
                        <td colspan="2"></td>
                        <td
                          :colspan="headers.length - 1"
                          class="text-alignment-left"
                        >
                          <list-accordian
                            v-if="accordianIds.includes(asset.id)"
                            :accordianData="asset"
                            :displayFields="returnAccordianDisplayDataKeys()"
                          ></list-accordian>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="'header_' + headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        :key="'header_' + headerIndex"
                        v-if="data.name != 'Action'"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    ref="select"
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <!-- Vue Context Menu Start -->
            <vue-context
              ref="menu"
              :close-on-click="closeOnClick"
              :close-on-scroll="closeOnScroll"
              :item-selector="itemSelector"
            >
              <li
                v-show="
                  permissionNameArray.includes(
                    'can_spec_sheet_inventory_master'
                  )
                "
              >
                <a
                  href="javascript:void(0)"
                  :title="allLabelMessages.print_spec_personal_action_label"
                  @click="openSpecSheetLink('personal')"
                >
                  <i class="fa fa-print" aria-hidden="true"></i>
                  {{ allLabelMessages.print_spec_personal_action_label }}
                </a>
              </li>
              <li
                v-show="
                  permissionNameArray.includes(
                    'can_spec_sheet_inventory_master'
                  )
                "
              >
                <a
                  href="javascript:void(0)"
                  :title="allLabelMessages.print_spec_generic_action_label"
                  @click="openSpecSheetLink('Generic')"
                >
                  <i class="fa fa-print" aria-hidden="true"></i>
                  {{ allLabelMessages.print_spec_generic_action_label }}
                </a>
              </li>
              <li
                v-show="
                  permissionNameArray.includes('can_email_inventory_master')
                "
              >
                <a
                  href="javascript:void(0)"
                  :title="allLabelMessages.email_spec_action_label"
                  v-on:click="moveToSpecificAssetTab(4)"
                >
                  <i class="fa fa-envelope-open" aria-hidden="true"></i>
                  {{ allLabelMessages.email_spec_action_label }}
                </a>
              </li>
              <li
                v-show="permissionNameArray.includes('can_add_expense_master')"
              >
                <a
                  href="javascript:void(0)"
                  :title="allLabelMessages.add_expense_action_label"
                  v-on:click="addExpense()"
                >
                  <i class="fa fa-usd color-71aa3f" aria-hidden="true"></i>
                  {{ allLabelMessages.add_expense_action_label }}
                </a>
              </li>
              <li
                v-show="
                  permissionNameArray.includes('can_close_inventory_master')
                "
              >
                <a
                  href="javascript:void(0)"
                  :title="allLabelMessages.close_or_hold_asset_action_label"
                  v-on:click="moveToSpecificAssetTab(6)"
                >
                  <i
                    class="fa fa-window-close-o color-c3202b"
                    aria-hidden="true"
                  ></i>
                  {{ allLabelMessages.close_or_hold_asset_action_label }}
                </a>
              </li>
              <li
                v-show="
                  permissionNameArray.includes('can_list_opportunity_master')
                "
              >
                <a
                  href="javascript:void(0)"
                  :title="allLabelMessages.opportunity_action_label"
                  v-on:click="moveToSpecificAssetTab(8)"
                >
                  <i
                    class="fa fa-plus-square color-71aa3f"
                    aria-hidden="true"
                  ></i>
                  {{ allLabelMessages.opportunity_action_label }}
                </a>
              </li>
            </vue-context>
            <!-- Vue Context Menu End -->
          </div>
        </div>
        <!-- Import Modal -->
        <div class="modal fade" id="defaultModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="defaultModalLabel">
                  {{
                    importFlag == "retail_price_update"
                      ? "Update Retail Price"
                      : "Import Inventory From Excel"
                  }}
                </h4>
              </div>
              <form
                class="form-inline"
                id="importCallform"
                enctype="multipart/form-data"
              >
                <div class="modal-body">
                  {{
                    importFlag == "retail_price_update"
                      ? "Select Excel File to Update Retail Price"
                      : "Select Excel File to Import Inventory"
                  }}
                  <input
                    type="file"
                    name="excl_file"
                    accept=".xlsx,.xls"
                    id="upload"
                    ref="files"
                    v-on:change="handleFilesUpload()"
                  />
                  <div class="form-group">
                    <label id="errorMsg" class="error"></label><br />
                  </div>
                  <a
                    v-show="importFlag != 'retail_price_update'"
                    id="demourl"
                    href="#" @click="getSignedUrlPath('demo/InventoryImportDemo.xlsx')"
                    class="btn general-button btn-info waves-effect m-r-20"
                  >
                    Download Demo
                  </a>
                  <a
                    v-show="importFlag == 'retail_price_update'"
                    id="demoretailpriceupdateurl"
                    href="#" @click="getSignedUrlPath('demo/InventoryUpdateRetailPriceDemo.xlsx')"
                    class="btn general-button btn-info waves-effect m-r-20"
                  >
                    Download Demo Update
                  </a>

                  <template v-if="inventoryImportRecordsWithErrors.length">
                    <div class="mt-20">
                      <h6>
                        The file may contain errors. Check the file for any
                        errors, such as missing fields or incorrect data.
                      </h6>
                      <template
                        v-for="(row, index) in inventoryImportRecordsWithErrors"
                      >
                        <template v-if="Object.keys(row.errors).length">
                          <div
                            :key="'inventoryImportRecordsWithErrors_' + index"
                            class=""
                          >
                            <table class="">
                              <tr>
                                <td class="bold">Record {{ index + 1 }}:</td>
                                <td>
                                  {{
                                    row.missing_error_msg
                                      ? row.missing_error_msg
                                      : ""
                                  }}
                                  {{
                                    row.duplicate_record
                                      ? row.duplicate_record
                                      : ""
                                  }}
                                  {{ row.other_error ? row.other_error : "" }}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </div>
                <div class="modal-footer width-full">
                    <button
                      type="button"
                      v-on:click="importInventory($event)"
                      class="btn general-button btn-success waves-effect"
                      id="invImportBtn"
                    >
                      {{ importBtn }}
                    </button>
                    <button type="button" 
                      class="btn general-button btn-danger waves-effect" 
                      data-bs-dismiss="modal" 
                      id="invImportBtn" 
                    >
                      {{ staticInputLabelMessages.close }}
                    </button>
                  </div>  
              </form>
            </div>
          </div>
        </div>
        <!-- Export Available Age Modal -->
        <div
          class="modal fade"
          id="exportAvailableAgeModal"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="exportAvailableAgeModallLabel">
                  Export Inventory
                </h4>
              </div>
              <form
                class="form-inline"
                id="exportAvailableAgeModalForm"
                enctype="multipart/form-data"
              >
                <common-loader
                  refId="commonLoaderExportAvailableAge"
                  ref="commonLoaderExportAvailableAge"
                >
                </common-loader>
                <div
                  class="col-md-12 modal-body"
                  id="modal-checkbox-availabel-age"
                >
                  <template
                    v-if="
                      exportAvailableAgeHeaders &&
                      exportAvailableAgeHeaders.length > 0
                    "
                  >
                    <!-- Select All -->
                    <div class="col-12 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          type="checkbox"
                          v-model="isSelectAllAvailableAgeExportHeader"
                          @click="selectAllAvailableAgeExportHeaders()"
                          class="form-control"
                          id="AvailableAgeExportList"
                        />
                        <strong v-if="isSelectAllAvailableAgeExportHeader"
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.deselect_all_label }}
                          </p></strong
                        >
                        <strong v-else
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.select_all_label }}
                          </p></strong
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <template
                      v-for="(
                        exportOpp, exportIndex
                      ) in exportAvailableAgeHeaders"
                    >
                      <div
                        :key="exportIndex"
                        class="col-md-6 col-sm-12 paddingleft0 custom-checkbox-v"
                      >
                        <label class="container">
                          <input
                            :value="exportOpp"
                            class="form-control"
                            type="checkbox"
                            v-model="exportSelectedAvailableAgeHeaders"
                            @change="updateCheckedAvailableAgeExportHeaders()"
                          />
                          <p class="modal-check-lbl">{{ exportOpp["name"] }}</p>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="row clearfix"></div>
                <div class="col-md-12 modal-footer">
                  <button
                    type="button"
                    v-on:click="exportSelectedAvailableAgeHeadersData($event)"
                    class="btn general-button btn-success waves-effect"
                  >
                    Export
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- Export and List Headers Modal -->
        <reorder-headers-list-modal 
          @call-is-export="exportAsset = true"  
          @call-reload-list="loadAsset"
          @call-apply-filters="applyFiltersOnSubmitSelection"
          ref="reorderHeadersListModalRef"
        >
        </reorder-headers-list-modal>

        <!-- Imported Duplicate assets move to other branch (CRLEASE) Modal -->
        <move-inventory-approval-request-modal
          :records="moveToOtherBranchRecords"
        ></move-inventory-approval-request-modal>
      </div>
      <template v-if="showExpenseForm">
        <add-asset-expense-group
          v-bind:selectedAssets="selectedRows"
          v-bind:assetId="assetId"
          :backButtonClicked="clickedBackFromChild"
        ></add-asset-expense-group>
      </template>
      <tag-mapper-modal
        :isClickAddTag="isClickAddTag"
        :modelType="'AssetMaster'"
        :modelId="listId"
        :selectedTags="selectedTags"
      />
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
let filterColumn = "age";
let filterType = "asc";
import Vue from "vue";
//import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import AssetExpenseGroup from "@/components/AssetMaster/AssetExpenseGroup";

import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import { VueContext } from "vue-context";
import "vue-context/dist/css/vue-context.css";
import ListAccordianComponent from "../ListAccrodianComponent/ListAccordianComponent";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Multiselect from "vue-multiselect";
import datePicker from "vue-bootstrap-datetimepicker";
import MoveInventoryApprovalRequestModal from "./MoveInventoryApprovalRequestModal.vue";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import TagMapperModal from "@/components/TagMaster/TagMapperModal";
import ReorderHeadersListModal from "../ReorderHeadersListModal/ReorderHeadersListModal";

Vue.component("app-footer", Footer);
//Vue.component("v-select", vSelect);

export default {
  name: "ListAsset",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "add-asset-expense-group": AssetExpenseGroup,
    VueContext,
    Multiselect,
    datetime: Datetime,
    "list-accordian": ListAccordianComponent,
    datePicker,
    MoveInventoryApprovalRequestModal,
    "common-loader": CommonLoader,
    "tag-mapper-modal": TagMapperModal,
    "reorder-headers-list-modal": ReorderHeadersListModal,
  },
  data() {
    return {
      findDuplicateBy: "unit",
      findDuplicateByArr: [
        { text: "Unit", value: "unit" },
        { text: "VIN", value: "vin_no" },
      ],
      filterDuplicate: false,
      fromOptions: {
        format: "YYYY",
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
      },
      toOptions: {
        format: "YYYY",
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
      },
      userCompany: [],
      isHoldSelected: false,
      datePickerOptions: {
        range: true,
        onlyDate: true,
        outputFormat: "YYYY-MM-DD",
        inputFormat: "YYYY-MM-DD",
        formatted:
          process.env
            .VUE_APP_SYSTEM_DISPLAY_DATERANGE_FILTER_FORMAT /*'YYYY-MM-DD',*/,
        noClearButton: true,
        maxDate: "",
        /*  Getting error when using the for date picker options */
        /*  'no-shortcuts': false, */
        /*customShortcuts : [
                        { label: 'Yesterday', value: '-day', isSelected: false },
                        { label: 'This Week', value: 'week', isSelected: false },
                        { label: 'Last Week', value: '-week', isSelected: false },
                        { key: 'last7Days', label: 'Last 7 days', value: 7 },
                        { key: 'last30Days', label: 'Last 30 days', value: 30 },
                        { label: 'This Month', value: 'month', isSelected: false },
                        { label: 'Last Month', value: '-month', isSelected: false },
                        { key: 'thisYear', label: 'This year', value: 'year' },
                        { key: 'lastYear', label: 'Last year', value: '-year' }
                    ]*/
      },
      perPageValue: 15,
      perPage: 15,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      assetArr: [],
      headers: [],
      filterMasterHeaders: [],
      filterheaders: [],
      filters: {
        sale_date: "",
        purchase_date: "",
        vin_no: "",
        stock_no: "",
        status: [],
        year: {
          fromYear: "",
          toYear: "",
        },
        salesman_name: "",
        price: {
          fromPrice: "",
          toPrice: "",
        },
        odometer: {
          fromOdometer: "",
          toOdometer: "",
        },
        lot: "",
        department: "",
        wheelbase: "",
        hp: "",
        gvw: "",
        exterior_color: "",
        acquire_on_date: "",
        lane_mitigation: "",
        tags: "",
        last_location_tracker_time: "",
        retail_ready: "",
        is_bailment: "",
      },
      yearFilterError: "",
      priceFilterError: "",
      odometerFilterError: "",
      filterStatusOptions: [],
      filterbodyTypeOptions: [],
      filterCompanyOptions: [],
      filterHoldStatusOptions: [],
      filterMakeOptions: [],
      filterModelOptions: [],
      filterEngineMakeOptions: [],
      filterEngineModelOptions: [],
      filterCabStyleOptions: [],
      filterCabTypeOptions: [],
      filterlocationOptions: [],
      filterSalesmanOptions: [],
      filterAcquiredByOptions: [],
      filterTransModelOptions: [],
      filterAxleTypeOptions: [],
      filterVendorOptions: [],
      filteracquireByOptions: [],
      filterFleetOptions: [],
      filterDTROptions: [],
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_inventory_master"],
      actionDisplay: true,
      showMainList: true,
      showExpenseForm: false,
      globalSlug: "",
      assetId: 0,
      /* when set to true, the context  menu will close when clicked on */
      closeOnClick: true,
      /* when set to true, the context  menu will close when the window is scrolled */
      closeOnScroll: true,
      /* This is how the component is able to find each menu item. Useful if you use non-recommended markup */
      itemSelector: [".custom-item-class"],
      loginId: 0,
      renderFilterComponent: true,
      isOpenFilterBlock: false,
      exportAsset: false,
      exportAssetAvailableDateData: false,
      accordianIds: [],
      locationIds: "",
      salesmanIds: "",
      showPromoSheetBtn: true,
      showSpecSheetBtn: true,
      showSpecListBtn: true,

      maxSelectionRecord: 10,
      /*  This logicToapplyForMaxRecordSelection variable has two value "alert" or "hide"
                    make value "hide" for hide button if value excede from maxSelectionRecord variable value
                    make value "alert" for display sweet alert if value excede from maxSelectionRecord variable value */
      logicToapplyForMaxRecordSelection: "alert",
      vendorIds: "",
      jumpToHistoryPage: 1,
      pageLoaded: false,
      filterCount: 0,
      appliedDepartmentFilter: [],
      appliedLocationFilter: [],
      appliedStatusFilter: [],
      appliedSalesmanFilter: [],
      appliedTagFilter: [],
      appliedAcquiredByFilter: [],
      appliedVendorFilter: [],
      appliedacquireByFilter: [],
      appliedHodStatusFilter: [],
      appliedAxleTypeFilter: [],
      appliedTransModelFilter: [],
      appliedBodyTypeFilter: [],
      appliedFleetFilter: [],
      appliedDTRFilter: [],
      appliedCabStyleFilter: [],
      appliedCabTypeFilter: [],
      appliedEngineModelFilter: [],
      appliedEngineMakeFilter: [],
      appliedModelFilter: [],
      appliedMakeFilter: [],
      strDepartmentId: "",
      loginUserLocation: [],
      isDepartmentSelected: false,
      isFirstLoad: 0,
      accessInventory: { value: "both", text: "Both" },
      exportHeaders: [],
      exportSelectedHeaders: [],
      exportAvailableAgeHeaders: [],
      exportSelectedAvailableAgeHeaders: [],
      isSelectAllExportHeader: false,
      isSelectAllAvailableAgeExportHeader: false,
      staticMessages: "",
      staticInputLabelMessages: "",
      assetHeadersList: [],
      selectedAssetHeadersList: [],
      isSelectAllAssetHeaderList: false,
      selectedAssetFilters: [],
      isSelectAllAssetFilters: false,
      assetDataArr: [],
      files: [],
      importBtn: "Import",
      importFlag: "",
      moveToOtherBranchRecords: [],
      inventoryImportRecordsWithErrors: [],
      filterCustomerOptions: [],
      appliedCustomerFilter: [],
      filterStorageLocationOptions: [],
      appliedStorageLocationFilter: [],
      filterApuMakeOptions: [],
      appliedApuMakeFilter: [],
      lane_mitigation_arr: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      retail_ready_arr: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
        { text: "Yes/Needs Detail", value: "yes-need-details" },
      ],
      isClickAddTag: false,
      listId: "",
      selectedTags: [],
      filterTagOptions: [],
      deleteTagTitle: "Delete Tag",
      importAssetExpenseInstance: null,
      exportAvailableAgeInstance: null,
      tagMapperModalInstance: null,
      batchProcess: 0,
      batchNum: "",
      is_bailment_arr: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    };
  },
  beforeMount() {
    self = this;
    let appliedFilters = JSON.parse(localStorage.getItem("filters"));
    if (
      appliedFilters != null &&
      appliedFilters != "" &&
      typeof appliedFilters != "undefined"
    ) {
        var fltr = appliedFilters["listInventory"].filters;
        var fields = {
          filters: fltr,
          per_page: appliedFilters["listInventory"].per_page,
          page: appliedFilters["listInventory"].page,
          sortColumnName: appliedFilters["listInventory"].sortColumnName,
          sortType: appliedFilters["listInventory"].sortType,
          is_export: appliedFilters["listInventory"].is_export,
          is_export_available_date_data:
            appliedFilters["listInventory"].is_export_available_date_data,
        };
        this.storeFilters("listInventory", fields, false);
    }
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  watch: {
    selectedRows: function () {
      if (self.logicToapplyForMaxRecordSelection == "hide") {
        self.maxSelectionAllowed();
      }
    },
  },
  mounted: function () {
    /* Redirect to login page logic start */
    let userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;
    
    //Default selected dropdown values for Filter
    let userJson = JSON.parse(userObj);
    let roleName = userJson ? userJson["roles"][0].name : "";
    if (roleName == 'System' || roleName == 'Administrator' || roleName == 'Accounting') {
      //For Higher Role
      self.filters.status = [
        {
          value: 1,
          text: "Active",
          checked: 0,
        },
        {
          value: 2,
          text: "Hold",
          checked: 0,
        },
        {
          value: 4,
          text: "Pending",
          checked: 0,
        },
      ];
    } else {
      //For Salesman and lower role
      self.filters.status = [
        {
          value: 1,
          text: "Active",
          checked: 0,
        },
        {
          value: 2,
          text: "Hold",
          checked: 0,
        },
      ];
    }
    self.filters.inventory_access = [
      {
        value: 'both',
        text: "Both",
      },
    ];
    self.setActiveMenu("asset-list");
    EventBus.$emit("menu-selection-changed", "asset-list");

    let listHistoryData = self.loadFilters("listInventory", self);
    /* if user logged in than remove login button and registration button */
    userObj = localStorage.getItem("user_obj");
    /* if user logged in than remove login button and registration button */
    if (userObj != null) {
      let userJson = JSON.parse(userObj);
      self.loginId = userJson["id"];
      self.roleId = userJson["roles"][0]["id"];
      self.accessInventory = {
        text: self.formatString(userJson["access_inventory"]),
        value: userJson["access_inventory"],
      };
    }

    let headersNames = [];
    headersNames["filters"] = self.filters;

    self.loadAllData(
      [
        "Status",
        "HoldStatus",
        "Make",
        "EngineMake",
        "User",
        "TransModel",
        "AxleType",
        "CabStyle",
        "CabType",
        "Fleet",
        "BodyType",
        "DtrTradeTerms",
        "Location",
        "ApuMake",
      ],
      false,
      self.loadMasterDataCallback
    );
    self.loadAllData(
      [
        "Model",
        "ContactFilterDropDown"
      ],
      false,
      self.loadMasterDataCallback
    );
    self.loadAllData(
      ["Tag"],
      false,
      self.loadTagsDataCallback,
      undefined,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "AssetMaster"
    );
    
    self.loadAllData(
      ["EngineModel"],
      false,
      self.engineModelDataCallback,
      "undefined",
      "",
      ""
    );
    self.loadAllData(
      ["Vendor"],
      false,
      self.loadVendorDataCallback,
      "undefined",
      "",
      0,
      1
    );
    self.pageLoaded = true;
    /* self.listExecutionState = new Date($.now()); */
    self.listExecutionState = false;
    self.loadAsset(1);
    self.$nextTick(() => {
      self.jumpToHistoryPage =
        listHistoryData === null ||
        typeof listHistoryData === "undefined" ||
        listHistoryData == ""
          ? 1
          : listHistoryData.page;
    });
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.importAssetExpenseInstance = new bootstrap.Modal(
        document.getElementById("defaultModal")
      );
      self.exportAvailableAgeInstance = new bootstrap.Modal(
        document.getElementById("exportAvailableAgeModal")
      );
      self.tagMapperModalInstance = new bootstrap.Modal(
          document.getElementById("tagMapperModal"),
            {
              backdrop: "static",
              keyboard: false,
            }
      );
    },
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.currentPage = 1; // Reset pagination to 1 when reset sorting is clicked
      self.changepage();
    },
    setInputFilter: function (textbox, inputFilter) {
      [
        "input",
        "keydown",
        "keyup",
        "mousedown",
        "mouseup",
        "select",
        "contextmenu",
        "drop",
      ].forEach(function (event) {
        textbox.addEventListener(event, function () {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(
              this.oldSelectionStart,
              this.oldSelectionEnd
            );
          } else {
            this.value = "";
          }
        });
      });
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.changepage();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadAsset();
      }
    },
    maxSelectionAllowed: function () {
      if (self.selectedRows.length > self.maxSelectionRecord) {
        if (self.logicToapplyForMaxRecordSelection == "alert") {
          self.$swal(
            "",
            "Only " +
              self.maxSelectionRecord +
              " Inventory can be selected for this functionality",
            "error"
          );
          return false;
        } else if (self.logicToapplyForMaxRecordSelection == "hide") {
          self.showPromoSheetBtn = false;
          self.showSpecSheetBtn = false;
          self.showSpecListBtn = false;
          return false;
        } else {
          self.$swal(
            "",
            "Only " +
              self.maxSelectionRecord +
              " Inventory can be selected for this functionality",
            "error"
          );
          return false;
        }
      } else {
        self.showPromoSheetBtn = true;
        self.showSpecSheetBtn = true;
        self.showSpecListBtn = true;
      }
      return true;
    },
    toggleFilterBlock: function () {
      $(window).scrollTop($(window).scrollTop() + 1);
      self.isOpenFilterBlock = !self.isOpenFilterBlock;
    },
    moveToSpecificAssetTab: function (flag) {
      if (typeof flag !== "undefined" && flag != "") {
        self.$router.push({
          name: "AssetTabView",
          params: { id: self.assetId, redirectTo: flag },
        });
      } else {
        self.$router.push({
          name: "AssetTabView",
          params: { id: self.assetId, redirectTo: 1 },
        });
      }
    },
    loadImportModal: function (flag) {
      self.files = [];
      self.importFlag = flag;
      self.inventoryImportRecordsWithErrors = [];
      self.resetImportModel();
      self.importAssetExpenseInstance.show();
    },
    handleFilesUpload: function () {
      /*
                 Get the uploaded files from the input.
                 */
      var self = this;
      let uploadedFiles = "";
      uploadedFiles = self.$refs.files.files;
      //console.log(uploadedFiles);
      /*
                 Assign the uploaded file to the files array
                 */
      /*this.files.pop();*/
      for (var i = 0; i < uploadedFiles.length; i++) {
        self.files = [uploadedFiles[i]];
      }
      /*this.getImagePreviews();*/
    },
    importInventory: function () {
      if (
        typeof self.files === "undefined" ||
        self.files == "null" ||
        self.files == ""
      ) {
        self.showSAlert("Please upload Excel file.", "info", false);
      } else {
        var data = new FormData();
        for (var i = 0; i < self.files.length; i++) {
          let file = self.files[0];
          data.append("excl_file", file);
        }

        data.append("flag", self.importFlag);

        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
            "Content-Type": "multipart/form-data"
          },
        };
        self.importBtn = "Importing ...";
        $("#invImportBtn").prop("disabled", true);
        $("#invImportClose").prop("disabled", true);
        self.showLoader();
        HTTP.post("/inventory/import", data, config)
          .then((response) => {
            self.hideLoader();
            if (response.data.code == 200) {
              self.loadAsset();
              self.inventoryImportRecordsWithErrors = [];
              self.moveToOtherBranchRecords =
                response.data.move_to_other_company;
                self.importAssetExpenseInstance.hide();
              self.showSAlert(
                response.data.message +
                  "<br>" +
                  response.data.messageSkipRecords,
                "success",
                false
              );

              if (response.data.move_to_other_company.length) {
                $("#defaultModalToMoveAssetsToOtherCompany").modal("show");
              }
            } else {
              self.showSAlert(response.data.message, "error", false);

              self.importBtn = "Import";
              $("#invImportBtn").prop("disabled", false);
              $("#invImportClose").prop("disabled", false);

              if (
                typeof response.data.status_type != "undefined" &&
                response.data.status_type == "inventory_record_error"
              ) {
                self.resetImportModel();
                self.inventoryImportRecordsWithErrors = response.data.records;
              }
            }
          })
          .catch(function (err) {
            self.importBtn = "Import";
            $("#invImportBtn").prop("disabled", false);
            $("#invImportClose").prop("disabled", false);
            self.hideLoader();
            self.catchHandler(err, function () {});
          });
      }
    },
    closeImportInventoryModal: function () {
      self.inventoryImportRecordsWithErrors = [];
    },
    resetImportModel: function () {
      const fileInput = document.querySelector("#upload");
      if (
        fileInput &&
        typeof fileInput === "object" &&
        typeof fileInput.value != "undefined"
      ) {
        fileInput.value = ""; // Remove File Name
      }
      self.files = []; // Remove File List
      self.importBtn = "Import";
      $("#invImportBtn").prop("disabled", false);
      $("#invImportClose").prop("disabled", false);
    },
    returnAccordianDisplayDataKeys: function () {
      let dataArray = [
        /* {
                        field : "vin",
                        label : self.staticInputLabelMessages.vin_no_label,
                        alt : ""
                    }, */
        {
          field: "hold_customer_name",
          label: self.staticInputLabelMessages.hold_customer_name_label,
          alt: "",
        },
        {
          field: "media_thumb",
          label: self.staticInputLabelMessages.inventory_image_label,
          alt: "",
        },
        {
          field: "private_comment",
          label: self.staticInputLabelMessages.comment_label,
          alt: "Comment Not Provided",
        },
        {
          field: "description",
          label: self.staticInputLabelMessages.description_label,
          alt: "Description Missing",
        },
        {
          field: "rear_ratio",
          label: self.staticInputLabelMessages.rear_ratio_label,
          alt: "",
        },
        {
          field: "wheelbase",
          label: self.staticInputLabelMessages.wheelbase_label,
          alt: "",
        },
        {
          field: "tire_size",
          label: self.staticInputLabelMessages.tire_size_label,
          alt: "",
        },
        {
          field: "apu_make_name",
          label: self.staticInputLabelMessages.apu_make_label,
          alt: "",
        },
        {
          field: "lot_location",
          label: self.staticInputLabelMessages.lot_location_label,
          alt: "",
        },
        {
          field: "area_on_yard_name",
          label: self.staticInputLabelMessages.area_on_yard_list_label,
          alt: "",
        },
        {
          field: "purchase_date",
          label: self.staticInputLabelMessages.purchase_date_label,
          alt: "",
        },
        {
          field: "exterior_length",
          label: self.staticInputLabelMessages.exterior_lenght_label,
          alt: "",
        },
        {
          field: "exterior_width",
          label: self.staticInputLabelMessages.exterior_width_label,
          alt: "",
        },
        {
          field: "exterior_height",
          label: self.staticInputLabelMessages.exterior_height_label,
          alt: "",
        },
        {
          field: "axle_type_name",
          label: self.staticInputLabelMessages.axle_type_label,
          alt: "",
        },
        {
          field: "suspension_name",
          label: self.staticInputLabelMessages.suspension_label,
          alt: "",
        },
        {
          field: "reefer_make_name",
          label: self.staticInputLabelMessages.reefer_make_label,
          alt: "",
        },
        /* {
                        field : "reefer_hours",
                        label : self.staticInputLabelMessages.reefer_hours_label,
                        alt : ""
                    }, */
        {
          field: "reefer_serial",
          label: self.staticInputLabelMessages.reefer_serial_label,
          alt: "",
        },
        {
          field: "lift_gate",
          label: self.staticInputLabelMessages.lift_gate_label,
          alt: "",
        },
        {
          field: "trans_type_name",
          label: self.staticInputLabelMessages.trans_type_label,
          alt: "",
        },
        {
          field: "trans_make_name",
          label: self.staticInputLabelMessages.trans_make_label,
          alt: "",
        },
        {
          field: "trans_model",
          label: self.staticInputLabelMessages.trans_model_label,
          alt: "",
        },
        {
          field: "trans_speed",
          label: self.staticInputLabelMessages.trans_speed_label,
          alt: "",
        },
        {
          field: "body_type_name",
          label: self.staticInputLabelMessages.body_type_label,
          alt: "",
        },
        {
          field: "exterior_color",
          label: self.staticInputLabelMessages.exterior_color_label,
          alt: "",
        },
        {
          field: "procede_branch",
          label: self.staticInputLabelMessages.procede_branch,
          alt: "",
        },
        {
          field: "dmv_notes",
          label: self.staticInputLabelMessages.dmv_notes_label,
          alt: "",
        },
      ];

      return dataArray;
    },
    toggleIcon: function (id) {
      self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.changepage();
    },
    clickedBackFromChild() {
      /* var self = this; */
      self.showMainList = true;
      self.showExpenseForm = false;
      self.$nextTick(() => {
        self.resetFilters();
      });
    },
    resetFilters: function (flag, date) {
      if (flag == "date") {
        if (date == "purchase_date") {
          self.filters.purchase_date = "";
        } else if (date == "sale_date") {
          self.filters.sale_date = "";
        } else if (date == "acquire_on_date") {
          self.acquire_on_date = "";
        } else if (date == "last_location_tracker_time") {
          self.filters.last_location_tracker_time = "";
        }
        self.$nextTick(() => {
          self.pageLoaded = true;
          self.changepage();
        });
      } else {
        var userObj = localStorage.getItem("user_obj");
        if (userObj != null) {
          var userJson = JSON.parse(userObj);
        }
        self.filters.status = [
          {
            value: 1,
            text: "Active",
            checked: 0,
          },
          {
            value: 2,
            text: "Hold",
            checked: 0,
          },
          {
            value: 4,
            text: "Pending",
            checked: 0,
          },
        ];
        self.pageLoaded = false;
        self.filters.age_from = "";
        self.filters.vin_no = "";
        self.filters.stock_no = "";
        self.filters.hold_status = "";
        self.filters.year = {
          fromYear: "",
          toYear: "",
        };
        self.filters.vendor = "";
        self.filters.acquireBy = "";
        self.filters.price = {
          fromPrice: "",
          toPrice: "",
        };
        self.filters.odometer = {
          fromOdometer: "",
          toOdometer: "",
        };
        self.filters.lot = "";
        self.filters.cab_type_name = "";
        self.filters.sale_date = "";
        self.filters.purchase_date = "";
        self.$nextTick(() => {
          self.pageLoaded = true;
          self.changepage();
        });
        self.filterDuplicate = false;
        self.findDuplicateBy = "unit";
        self.filters.wheelbase = "";
        self.filters.hp = "";
        self.filters.gvw = "";
        self.filters.area_on_yard = "";
        self.filters.exterior_color = "";
        self.filters.acquire_on_date = "";
        self.filters.customer_name = "";
        self.filters.lane_mitigation = "";
        self.filters.last_location_tracker_time = "";
        self.filters.retail_ready = "";
        self.filters.is_bailment = "";
      }
    },
    addExpense: function () {
      /* let selectedAssets= self.selectedAssets;
                alert(self.assetId);
                if (self.selectedRows.length > 1) { */
      self.showMainList = false;
      self.showExpenseForm = true;
      /* } else {
                    this.showSAlert('Select more than one asset', 'error', function () {});
                } */
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    editSelected: function () {
      self.$store.commit("changeSelectData", self.selectedRows);
      self.$router.push({ name: "MultyAssetTabView" });
    },
    generateQrCodes: function () {
      self.$store.commit("changeSelectData", self.selectedRows);
      self.$router.push({ name: "PrintQrCode" });
    },
    printSelected: function () {
      var selectedInventory = "";
      $.each(self.selectedRows, function (i) {
        selectedInventory += self.selectedRows[i];
        if (self.selectedRows.length - 1 != i) {
          selectedInventory += ":";
        }
      });
      window.open(
        "/pub/multi-print/?id=" + selectedInventory + "&uid=" + self.loginId
      );
    },
    /* Context Menu Logic Start */
    openContextMenu: function (e, id, slug) {
      self.globalSlug = slug;
      self.assetId = id;
      self.$refs.menu.open(e);
    },
    openSpecSheetLink: function (flag) {
      if (self.loginId > 0) {
        if (flag == "personal") {
          /* https://www.imanpro.net/pub/print/?co=sac&id=13407&uid=1004&key=b5241b4630506e90ecf9d060c28b92c3 */
          window.open(
            "/pub/print/?id=" +
              self.assetId +
              "&uid=" +
              self.loginId +
              "&key=" +
              self.globalSlug
          );
        } else {
          /* https://www.imanpro.net/pub/print/?co=sac&id=13407&key=b5241b4630506e90ecf9d060c28b92c3 */
          window.open(
            "/pub/print/?id=" + self.assetId + "&key=" + self.globalSlug
          );
        }
      }
    },
    openBookDealLink: function (flag) {
      window.open("/quotation/?id=" + self.assetId);
    },
    openCsoRequestLink() {
      self.$router.push({ name: "AddUpdateCSORequestSubmission" });
    },
    /* Context Menu Logic Close */
    compareAssets: function () {
      var strIdList = "";
      if (self.selectedRows.length < 2) {
        self.$swal("", "Select at least 2 Inventory to compare", "error");
        return false;
      }
      if (self.selectedRows.length > 5) {
        self.$swal("", "Only 5 Inventory can be compare at one time", "error");
        return false;
      }
      for (var index = 0; index < self.selectedRows.length; index++) {
        strIdList += self.selectedRows[index] + ":";
      }
      strIdList = strIdList.replace(/:\s*$/, "");
      if (self.loginId > 0) {
        localStorage.removeItem("compareAssetIds");
        self.changepage();
        localStorage.setItem("compareAssetIds", strIdList);
        /* window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + strIdList); */
        window.open("/compare/inventory");
      }
    },
    specListSelectedEmail: function () {
      if (self.maxSelectionAllowed()) {
        var strIdList = "";
        for (var index = 0; index < self.selectedRows.length; index++) {
          strIdList += self.selectedRows[index] + ":";
        }
        strIdList = strIdList.replace(/:\s*$/, "");
        if (self.loginId > 0) {
          localStorage.removeItem("promoSheetIdList");
          self.changepage();
          localStorage.setItem("promoSheetIdList", strIdList);
          /* window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + strIdList); */
          let routeData = self.$router.resolve({
            name: "EmailSpecSheetTabView",
            query: { redirectTo: 3 },
          });
          window.open(routeData.href, "_blank");
          /* window.open("/email/spec"); */
        }
      }
    },
    specSheetSelectedEmail: function () {
      if (self.maxSelectionAllowed()) {
        var strIdList = "";
        for (var index = 0; index < self.selectedRows.length; index++) {
          strIdList += self.selectedRows[index] + ":";
        }
        strIdList = strIdList.replace(/:\s*$/, "");
        if (self.loginId > 0) {
          localStorage.removeItem("promoSheetIdList");
          self.changepage();
          localStorage.setItem("promoSheetIdList", strIdList);
          /* window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + strIdList); */
          let routeData = self.$router.resolve({
            name: "EmailSpecSheetTabView",
            query: { redirectTo: 2 },
          });
          window.open(routeData.href, "_blank");
          /* window.open("/email/spec"); */
        }
      }
    },
    promoSheetSelectedEmail: function () {
      if (self.maxSelectionAllowed()) {
        var strIdList = "";
        for (var index = 0; index < self.selectedRows.length; index++) {
          strIdList += self.selectedRows[index] + ":";
        }
        strIdList = strIdList.replace(/:\s*$/, "");
        if (self.loginId > 0) {
          localStorage.removeItem("promoSheetIdList");
          self.changepage();
          localStorage.setItem("promoSheetIdList", strIdList);
          /* window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + strIdList); */
          let routeData = self.$router.resolve({
            name: "EmailSpecSheetTabView",
            query: { redirectTo: 1 },
          });
          window.open(routeData.href, "_blank");
          /* window.open("/email/spec"); */
        }
      }
    },
    activeInactiveAsset: function (id, state) {
      let statusArr = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        statusArr.push(id);
      } else {
        statusArr = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text:
            "Are you sure that you want to " + state + " this Inventory(s)!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          /* showCloseButton: true, */
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
            };
            var fields = {
              id: statusArr,
              state: state,
            };

            HTTP.post("/asset/activeordeative", fields, config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.changepage();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
    deleteAsset: function (id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Inventory(s)!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          /* showCloseButton: true, */
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                deleteArr: deleteArray,
              },
            };

            HTTP.delete("/asset/delete", config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.changepage();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.assetArr) {
            self.selectedRows.push(self.assetArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.assetArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    addAsset: function () {
      /* self.$router.push('/add/inventory'); */
    },
    applyFilterParentComponent: function (data) {
      self.filters = {}; // filters initialized
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.currentPage = 1; // Reset pagination to 1 when apply/reset filter is clicked
      self.changepage();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
      }
    },
    filterCountFunctionAsset: function (filters) {
      var count = 0;
      $.each(filters, function (i) {
        if (i === "year") {
          var check =
            filters[i]["fromYear"] === null ||
            typeof filters[i]["fromYear"] === "undefined" ||
            filters[i]["fromYear"] === ""
              ? ""
              : filters[i];
        } else if (i === "price") {
          var check =
            filters[i]["fromPrice"] === null ||
            typeof filters[i]["fromPrice"] === "undefined" ||
            filters[i]["fromPrice"] === ""
              ? ""
              : filters[i];
        } else if (i === "odometer") {
          var check =
            filters[i]["fromOdometer"] === null ||
            typeof filters[i]["fromOdometer"] === "undefined" ||
            filters[i]["fromOdometer"] === ""
              ? ""
              : filters[i];
        } else if (
          i === "status" ||
          i === "make" ||
          i === "model" ||
          i === "location" ||
          i === "salesman" ||
          i === "lot" ||
          i === "engine_make" ||
          i === "engine_model" ||
          i === "cab_style" ||
          i === "department" ||
          i === "dtr_trade_terms" ||
          i === "fleet" ||
          i === "body_type" ||
          i === "trans_model" ||
          i === "axle_type" ||
          i === "acquired_by"
        ) {
          var check = filters[i].length <= 0 ? "" : filters[i].length;
        } else {
          var check =
            filters[i] === null ||
            typeof filters[i] === "undefined" ||
            filters[i] === ""
              ? ""
              : filters[i];
        }

        if (check != "") {
          count++;
        }
      });
      return count;
    },
    selectAllAvailableAgeExportHeaders: function () {
      self.exportSelectedAvailableAgeHeaders = [];
      if (!self.isSelectAllAvailableAgeExportHeader) {
        for (let key in self.exportAvailableAgeHeaders) {
          self.exportSelectedAvailableAgeHeaders.push(
            self.exportAvailableAgeHeaders[key]
          );
        }
      }
    },
    updateCheckedAvailableAgeExportHeaders: function () {
      if (
        self.exportSelectedAvailableAgeHeaders.length ==
        self.exportAvailableAgeHeaders.length
      ) {
        self.isSelectAllAvailableAgeExportHeader = true;
      } else {
        self.isSelectAllAvailableAgeExportHeader = false;
      }
    },
    /* checkedExportAvailableAgeHeaders: function (e, index, value) {
                if (e.target.checked) {
                    // Vue.set(self.exportSelectedAvailableAgeHeaders, index, value);
                    self.exportSelectedAvailableAgeHeaders.push(Vue.util.extend({}, value));
                } else {
                    // Vue.delete(self.exportSelectedAvailableAgeHeaders, index);
                    for (var i = 0; i < self.exportSelectedAvailableAgeHeaders.length; i++) {
                        if (self.exportSelectedAvailableAgeHeaders[i]['name'] == value['name']) {
                            self.exportSelectedAvailableAgeHeaders.splice(i, 1);
                        }
                    }
                }
                // console.log(JSON.stringify(self.exportSelectedAvailableAgeHeaders));
            }, */
    exportAssetAvailableData: function () {
      self.exportAvailableAgeInstance.show();
      /* self.exportAssetAvailableDateData = true;
                if (self.exportAssetAvailableDateData == true) {
                    $('#exportAsset').prop("disabled", true);
                    self.loadAsset();
                } */
    },
    exportSelectedAvailableAgeHeadersData: function () {
      // if(Object.keys(self.exportSelectedAvailableAgeHeaders).length == 0) {
      if (self.exportSelectedAvailableAgeHeaders.length == 0) {
        self.showSAlert("Please Select at least one checkbox", "info", false);
        return false;
      }
      /* $('#modal-checkbox-availabel-age input:checkbox').prop('checked', false); */
      self.exportAssetAvailableDateData = true;
      if (self.exportAssetAvailableDateData == true) {
        $("#exportAsset").prop("disabled", true);
        self.loadAsset();
        /* self.isSelectAllAvailableAgeExportHeader = false; */
      }
      if (
        self.exportSelectedAvailableAgeHeaders != null &&
        self.exportSelectedAvailableAgeHeaders != "undefined" &&
        self.exportSelectedAvailableAgeHeaders.length > 0
      ) {
        self.storeExportHeaders(
          "exportAvailableAge",
          self.exportSelectedAvailableAgeHeaders
        );
      } else {
        self.exportSelectedAvailableAgeHeaders = [];
      }
    },
    applyFiltersOnSubmitSelection: function (data){
      self.applyFilterParentComponent(data);
    },
    loadAsset: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadAsset();
      }

      // Load Export Headers
      self.exportSelectedHeaders = self.loadExportHeaders(
        "exportInventory",
        self
      );
      self.exportSelectedHeaders =
        self.exportSelectedHeaders === null ||
        typeof self.exportSelectedHeaders === "undefined" ||
        self.exportSelectedHeaders == ""
          ? []
          : self.exportSelectedHeaders;
      
      // Load List Headers
      self.selectedAssetHeadersList = self.loadListHeaders(
        "listInventory",
        self
      );
      self.selectedAssetHeadersList =
        self.selectedAssetHeadersList === null ||
        typeof self.selectedAssetHeadersList === "undefined" ||
        self.selectedAssetHeadersList == ""
          ? []
          : self.selectedAssetHeadersList;

      // Load Filters
      self.selectedAssetFilters = self.loadFilterHeaders(
        "listInventory", 
        self
      );
      self.selectedAssetFilters =
        self.selectedAssetFilters === null ||
        typeof self.selectedAssetFilters === "undefined" ||
        self.selectedAssetFilters == ""
          ? []
          : self.selectedAssetFilters;

      if (self.exportAsset == true) {
        self.exportRecords();
      };
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      if (self.pageLoaded) {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        
        var fields = {
          filters: self.filters,
          per_page: self.perPage,
          page: self.currentPage,
          sortColumnName: self.columnName,
          sortType: self.sortType,
          is_export: self.exportAsset,
          export_action: self.exportAction,
          is_export_available_date_data: self.exportAssetAvailableDateData,
          selected_asset_header: self.selectedAssetHeadersList,
          export_selected_headers: self.exportSelectedHeaders,
          selected_asset_filters: self.selectedAssetFilters,
          export_selected_available_age_headers:
            self.exportSelectedAvailableAgeHeaders,
          duplicate_inventory: self.filterDuplicate,
          find_duplicate_by: self.findDuplicateBy,
        };
        this.storeFilters("listInventory", fields, false);
        let loaderId = "";
        if (self.exportAsset) {
          self.$refs.reorderHeadersListModalRef.exportLoaderShow();
        } else if (self.exportAssetAvailableDateData) {
          loaderId = "#commonLoaderExportAvailableAge";
        } else {
          loaderId = "#commonLoaderListAsset";
        }
        $(loaderId).find(".loading-container.lodingTable").show();
        self.filterCount = self.filterCountFunctionAsset(self.filters);
        if (self.filterCount) {
          self.isOpenFilterBlock = true;
        }
        if (self.assetArr.length >= 100) {
          self.assetArr = [];
        }
        HTTP.post("/asset/list", fields, config)
          .then(function (data) {
            self.listExecutionState = false;
            if (first == 1) {
              if (self.jumpToHistoryPage != 1) {
                self.currentPage = self.jumpToHistoryPage;
              }
            }
            let loaderId = "";
            if (self.exportAssetAvailableDateData) {
              loaderId = "#commonLoaderExportAvailableAge";
            } else {
              loaderId = "#commonLoaderListAsset";
            }
            $(loaderId).find(".loading-container.lodingTable").hide();
            self.resetImportModel();
            self.exportSelectedAvailableAgeHeaders = self.loadExportHeaders(
              "exportAvailableAge",
              self
            );
            self.exportSelectedAvailableAgeHeaders =
              self.exportSelectedAvailableAgeHeaders === null ||
              typeof self.exportSelectedAvailableAgeHeaders === "undefined" ||
              self.exportSelectedAvailableAgeHeaders == ""
                ? []
                : self.exportSelectedAvailableAgeHeaders;
            self.exportAvailableAgeInstance.hide();
            $("#exportAssetExpense").prop("disabled", false);
            $("#exportAsset").prop("disabled", false);
            
            self.exportAssetAvailableDateData = false;
            if (data.status == 200) {
              if (data.data.message == "success export queue") {
                self.exportAction = '';
                self.exportAsset = false;
                self.$refs.reorderHeadersListModalRef.exportLoaderHide();
                self.$refs.reorderHeadersListModalRef.closeModal();
                self.loadAsset();
                self.batchProcess = 1;
                self.batchNum = data.data.content;
              } else if (data.data.message == "success export") {
                self.exportAsset = false;
                self.$refs.reorderHeadersListModalRef.exportLoaderHide();
                self.$refs.reorderHeadersListModalRef.closeModal();
                self.downloadFile(data.data.content.web);
                self.loadAsset();
              } else {
                self.accordianIds = [];
                $(".collapse").hide();
                self.clearSelection();
                self.headers = [];
                self.totalRecords = data.data.content.result;
                if (
                  self.perPage == "All" &&
                  self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
                ) {
                  // alert(self.totalRecords);
                  let seconds = 5000;
                  self.$swal({
                    type: "error",
                    html: self.allStaticMessages
                      .too_many_data_to_display_browser_can_not_load,
                    showConfirmButton: false,
                    timer: seconds,
                  });
                  setTimeout(() => {
                    self.perPage = 500;
                    self.loadAsset();
                  }, seconds);
                  /* self.$swal({
                        title: '',
                        html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                        type: 'error',
                        confirmButtonText: 'OK',
                        showCancelButton: true,
                        showLoaderOnConfirm: true
                    }).then((result) => {
                        if (result.value) {
                            self.logout();
                        } else {
                            self.perPage = 15;
                            self.loadAsset();
                        }
                    }); */
                }
                self.assetDataArr = data.data.content.data;
                self.assetArr = data.data.content.invdata;
                self.headers = data.data.content.headers;
                self.assetHeadersList = data.data.content.assetHeadersList;
                if (
                  self.selectedAssetHeadersList.length ==
                  self.assetHeadersList.length
                ) {
                  self.isSelectAllAssetHeaderList = true;
                }
                self.exportHeaders = data.data.content.exportheaders;
                if (
                  self.exportSelectedHeaders.length == self.exportHeaders.length
                ) {
                  self.isSelectAllExportHeader = true;
                }
                self.exportAvailableAgeHeaders =
                  data.data.content.exportAvailableAgeHeaders;
                if (
                  self.exportSelectedAvailableAgeHeaders.length ==
                  self.exportAvailableAgeHeaders.length
                ) {
                  self.isSelectAllAvailableAgeExportHeader = true;
                }

                self.filterMasterHeaders = data.data.content.filterheaders;
                self.filterheaders = data.data.content.filterheaders.filter(item => item.isSelected === true);
                self.loadFilterInputs(self.filterheaders,self.filters);
                if (
                  self.selectedAssetFilters.length == self.filterheaders.length
                ) {
                  self.isSelectAllAssetFilters = true;
                }
                
                if (self.salesmanIds != data.data.content.salesmen_ids) {
                  self.salesmanIds = data.data.content.salesmen_ids;
                }
                if (self.acquiredByIds != data.data.content.salesmen_ids) {
                  self.acquiredByIds = data.data.content.salesmen_ids;
                }
                self.loginUserLocation = data.data.content.logged_user_location;

                self.addColumnClass(self.headers);

                let testArray = [
                  {
                    text: 1,
                    value: "2020-02-01",
                  },
                  {
                    text: 2,
                    value: "2020-02-02",
                  },
                  {
                    text: 3,
                    value: "2020-02-03",
                  },
                ];
                for (let i = 0; i < self.headers.length; i++) {
                  if (self.headers[i]["type"] == "drop_down") {
                    self.headers[i]["options_array"] = testArray;
                  }
                }
                if (data.data.content.per_page !== self.totalRecords) {
                  self.perPage = parseInt(data.data.content.per_page);
                  self.jumpToPage = parseInt(data.data.content.current_page);
                } else {
                  if (self.perPage == "All") {
                    self.jumpToPage = 1;
                  }
                  self.perPageValue = self.totalRecords;
                  self.currentPage = 1;
                }
                self.setAlreadyAppliedFilters();
                self.startEndRecord = self.calculateStartRecordEndRecord(
                  self.currentPage,
                  self.perPage,
                  self.totalRecords
                );
                self.sortFieldDisplay(self.columnName, self.sortType);
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          })
          .catch(function (error) {
            self.listExecutionState = false;
            $("#exportAsset").prop("disabled", false);
            self.exportAsset = false;
            self.exportAssetAvailableDateData = false;
            self.resetImportModel();
            let loaderId = "";
            if (self.exportAsset) {
              self.$refs.reorderHeadersListModalRef.exportLoaderHide();
            } else if (self.exportAssetAvailableDateData) {
              loaderId = "#commonLoaderExportAvailableAge";
            } else {
              loaderId = "#commonLoaderListAsset";
            }
            $(loaderId).find(".loading-container.lodingTable").hide();
            self.catchHandler(error, function () {});
          });
      }
    },
    loadModelDataCallback: function (callbackData) {
      self.filterModelOptions = callbackData.Model;
    },
    onFilterOptionsSearch: function (search, loading, modelName) {
      if (search.length > 0) {
        self.loadAllData(
          [modelName],
          true,
          self.loadMasterDataCallback,
          loading,
          search,
          0
        );
      }
    },
    onSearchTags(search, loading) {
      if (search.length >= 0) {
        if (
          loading === null ||
          typeof loading === "undefined" ||
          loading == ""
        ) {
        } else {
          loading(true);
        }
        self.loadAllData(
          ["Tag"],
          true,
          self.loadTagsDataCallback,
          loading,
          search,
          0,
          "",
          "",
          "",
          "",
          "",
          "AssetMaster"
        );
      }
    },
    loadCityDataCallback: function (callbackData) {
      self.filterlocationOptions = callbackData.Location;
    },
    loadSalesmanDataCallback: function (callbackData) {
      self.filterSalesmanOptions = callbackData.User;
    },
    loadVendorDataCallback: function (callbackData) {
      self.filterVendorOptions = callbackData.Vendor;
    },
    loadCompanyDataCallback: function (callbackData) {
      self.filterCompanyOptions = callbackData.Company;
    },
    loadAcquiredByCallback: function (callbackData) {
      self.filterAcquiredByOptions = callbackData.User;
    },
    loadTransModelDataCallback: function (callbackData) {
      self.filterTransModelOptions = callbackData.TransModel;
    },
    loadAxleTypeDataCallback: function (callbackData) {
      self.filterAxleTypeOptions = callbackData.AxleType;
    },
    loadacquireByDataCallback: function (callbackData) {
      self.filteracquireByOptions = callbackData.User;
    },
    loadCustomerDataCallback: function (callbackData) {
      self.filterCustomerOptions = callbackData.Contact;
    },
    loadStorageLocationDataCallback: function (callbackData) {
      self.filterStorageLocationOptions = callbackData.Location;
    },
    loadMasterDataCallback: function (callbackData) {

      if (typeof callbackData.Status != "undefined") {
        self.filterStatusOptions = callbackData.Status;
      }
      if (typeof callbackData.HoldStatus != "undefined") {
        self.filterHoldStatusOptions = callbackData.HoldStatus;
      }

      if (typeof callbackData.Make != "undefined") {
        self.filterMakeOptions = callbackData.Make;
      }
      if (typeof callbackData.Model != "undefined") {
        self.filterModelOptions = callbackData.Model;
      }
      if (typeof callbackData.EngineModel != "undefined") {
        self.filterEngineModelOptions = callbackData.EngineModel;
      }
      if (typeof callbackData.EngineMake != "undefined") {
        self.filterEngineMakeOptions = callbackData.EngineMake;
      }

      if (typeof callbackData.User != "undefined") {
        self.filterSalesmanOptions = callbackData.User;
      }
      if (typeof callbackData.User != "undefined") {
        self.filteracquireByOptions = callbackData.User;
      }
      if (typeof callbackData.CabStyle != "undefined") {
        self.filterCabStyleOptions = callbackData.CabStyle;
      }
      if (typeof callbackData.CabType != "undefined") {
        self.filterCabTypeOptions = callbackData.CabType;
      }
      if (typeof callbackData.Fleet != "undefined") {
        self.filterFleetOptions = callbackData.Fleet;
      }
      if (typeof callbackData.BodyType != "undefined") {
        self.filterbodyTypeOptions = callbackData.BodyType;
      }
      if (typeof callbackData.DtrTradeTerms != "undefined") {
        self.filterDTROptions = callbackData.DtrTradeTerms;
      }
      if (typeof callbackData.Location != "undefined") {
        self.filterlocationOptions = callbackData.Location;
      }
      if (typeof callbackData.AxleType != "undefined") {
        self.filterAxleTypeOptions = callbackData.AxleType;
      }
      if (typeof callbackData.TransModel != "undefined") {
        self.filterTransModelOptions = callbackData.TransModel;
      }
      if (typeof callbackData.ContactFilterDropDown != "undefined") {
        self.filterCustomerOptions = callbackData.ContactFilterDropDown;
      }
      if (typeof callbackData.Location != "undefined") {
        self.filterStorageLocationOptions = callbackData.Location;
      }
      if (typeof callbackData.Company != "undefined") {
        self.filterCompanyOptions = callbackData.Company;
      }
      if (typeof callbackData.Vendor != "undefined") {
        self.filterVendorOptions = callbackData.Vendor;
      }
      if (typeof callbackData.ApuMake != "undefined") {
        self.filterApuMakeOptions = callbackData.ApuMake;
      }
    },
    loadTagsDataCallback: function (callbackData) {
      self.filterTagOptions = callbackData.Tag;
    },
    engineModelDataCallback: function (callbackData) {
      self.filterEngineModelOptions = callbackData.EngineModel;
    },

    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.changepage();
      }
    },
    openRequestForm: function () {
      window.open("/request-form");
    },
    addTag(id, data) {
      self.isClickAddTag = true;
      self.listId = id;
      if (data) {
        self.selectedTags = data.map((tags) => tags.tag);
      }
      self.tagMapperModalInstance.show();
      self.loadAsset();
    },
    deleteTag(id, mainIndex, elmId) {
      self
        .$swal({
          title: "",
          text: self.staticMessages.delete_tag_alert,
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            let config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
            };
            HTTP.delete("/tags/mapper/" + id, config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.showSAlert(
                    response.data.message,
                    response.data.status,
                    false,
                    2
                  );
                  $("#" + elmId).remove();
                  // self.assetDataArr[mainIndex].tags.splice(indexToRemove, 1);
                }
              })
              .catch(function (error) {
                console.log("error=>", error);
                self.catchHandler(error, function () {});
              });
          }
        });
    },
  },
};

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (
      !self.permissionNameArray.includes("can_add_inventory_master") &&
      !self.permissionNameArray.includes("can_delete_inventory_master") &&
      !self.permissionNameArray.includes("can_view_inventory_master") &&
      !self.permissionNameArray.includes("can_spec_sheet_inventory_master") &&
      !self.permissionNameArray.includes("can_add_expense_master") &&
      !self.permissionNameArray.includes(
        "can_active_deactive_status_inventory_master"
      ) &&
      !self.permissionNameArray.includes("can_compare_inventory_master") &&
      !self.permissionNameArray.includes("can_multi-print_inventory_master")
    ) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
EventBus.$on("login-company", function (companies) {
  if (typeof self != "undefined") {
    self.userCompany = [];
    for (let i = 0; i < companies.length; i++) {
      self.userCompany.push(companies[i].company);
    }

    //Load user Company list
    if (!self.filterCompanyOptions.length) {
      self.loadAllData(
        ["Company"],
        false,
        self.loadCompanyDataCallback,
        "undefined",
        "",
        self.userCompany.join(",")
      );
    }
  }
});
EventBus.$on("close-tag-mapper", function (status) {
  if (typeof self != "undefined") {
    self.selectedTags = [];
    self.isClickAddTag = status;
    self.tagMapperModalInstance.hide();
  }
});

EventBus.$on("add-tag-mapper", function (status) {
  if (typeof self != "undefined") {
    self.isClickAddTag = status;
    self.tagMapperModalInstance.hide();
    self.refreshList();
  }
});
</script>
