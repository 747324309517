<template>
  <div class="payment-section">
    <div class="payment-section-secondary-row">
      <div>
        <div>
          <div class="select-container">
            <label for="branch" class="select-label"
              >Select Finance Company <span class="text-danger" title="REQUIRED FIELD">*</span></label
            >
            <v-select
              v-model="financeCompany"
              name="finance"
              label="text"
              :filterable="true"
              :options="financeCompanyArr"
              :clearable="false"
              @input="selectFinanceCompany()"
              placeholder="SELECT FINANCE"
              class="custom-ci-auto-search"
              id="select-finance-company"
            >
            </v-select>
          </div>
        </div>
        <div id="add-fnc-cmp" v-if="permissionNameArray.includes('can_add_finance_company_master')">
          <i
            class="fa fa-plus-circle"
            role="button"
            aria-hidden="true"
            title="Add Finance Company"
            @click="addFinanceCompany()"
          ></i>
        </div>
      </div>
    </div>

    <div class="payment-section-secondary-row" v-if="showAllFields">
      <div>
        <div class="fm-group">
          <label for="finance-company">Finance Company <span class="text-danger" title="REQUIRED FIELD">*</span></label>
          <input
            type="text"
            class="form-input payment-form-input"
            id="finance-company"
            v-model="financeCompanyName"
            @blur="emitFinanceData()"
          />
        </div>
      </div>

      <div>
        <div class="fm-group">
          <label for="address">Address <span class="text-danger" title="REQUIRED FIELD">*</span></label>
          <input
            type="text"
            class="form-input payment-form-input"
            id="finance-address"
            v-model="address"
            @blur="emitFinanceData()"
          />
        </div>
      </div>

      <div>
        <div class="fm-group">
          <label for="finance-city">City <span class="text-danger" title="REQUIRED FIELD">*</span></label>
          <input
            type="text"
            class="form-input payment-form-input"
            id="finance-city"
            v-model="city"
            @blur="emitFinanceData()"
          />
        </div>
      </div>
      
      <div>
        <div class="fm-group">
          <label for="finance-state">State <span class="text-danger" title="REQUIRED FIELD">*</span></label>
          <v-select
            v-model="state"
            name="state"
            label="text"
            :filterable="false"
            :options="stateArr"
            @search="onSearchState"
            :clearable="false"
            @input="emitFinanceData()"
            placeholder="Search & Select State"
            class="custom-ci-auto-search"
            id="select-finance-state"
          >
            <span slot="no-options" @click="$refs.select.open = false">
              <template v-if="!isStateRecordNotFound">
                Search State...
              </template>
              <template v-else>
                Sorry, no matching record found!
              </template>
            </span>
          </v-select>
        </div>
      </div>

      <div>
        <div class="fm-group">
          <label for="zip">Zip <span class="text-danger" title="REQUIRED FIELD">*</span></label>
          <input
            type="text"
            class="form-input"
            id="finance-zip"
            v-model="zipCode"
            @blur="emitFinanceData()"
            maxlength="7"
          />
        </div>
      </div>

      <div>
        <div class="fm-group">
          <label for="comment">Comment</label>
          <input
            type="text"
            class="form-input"
            id="finance-comment"
            v-model="comment"
            @blur="emitFinanceData()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
import NoAccessPage from "../../../NoAccessPage/NoAccessPage";
import EventBus from "../../../event-bus";
import { commonMixins } from "../../../../mixins/commonMixins";
import { HTTP } from "../../../../http-common";
var selfThis;

export default {
  name: "Finance",
  components: {
    "no-access": NoAccessPage,
    Money,
  },
  props: ['financeCompanyList', 'financeData', 'isEdit', 'triggerPayment', 'paymentCat', 'permissionNameArray'],
  mixins: [commonMixins],
  data() {
    return {
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
      },
      financeCompany: "",
      financeCompanyName: "",
      address: "",
      state: "",
      stateArr: [],
      city: "",
      cityArr: [],
      zipCode: "",
      comment: "",
      financeCompanyArr: [],
      financeId: null,
      showAllFields: false,
      isStateRecordNotFound: false,
    };
  },
  mounted() {
    selfThis = this;
  },
  methods: {
    addFinanceCompany() {
      EventBus.$emit("add-financial-company");
    },

    selectFinanceCompany() {
      this.showAllFields = true;
      this.stateArr = [];
      this.populateFinanceData(this.financeCompany);
    },

    populateFinanceData(data) {
      this.financeCompanyName = data.text;
      this.address = data.address;
      this.zipCode = data.zip_code;
      this.comment = data.comment;
      this.state = "";
      this.getStateCity(data.value);
      EventBus.$emit("close-add-financial-company", false);
      setTimeout(() => {
        this.emitFinanceData();
      }, 1000);
    },

    onSearchState(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.loadAllData(
          ["States"],
          true,
          this.loadStateDataCallback,
          loading,
          search
        );
      }
    },
    loadStateDataCallback: function (callbackData) {
      this.isStateRecordNotFound = false;
      this.stateArr = callbackData.States;
      if(this.stateArr.length == 0) {
          this.isStateRecordNotFound = true;
      }
    },

    onSearchCity(search, loading) {
      if (this.state == null || !this.state.value) {
        this.showSAlert("Please Select State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          this.loadAllData(
            ["Cities"],
            true,
            this.loadCityDataCallback,
            loading,
            search,
            this.state.value
          );
        }
      }
    },
    loadCityDataCallback: function (callbackData) {
      this.cityArr = callbackData.Cities;
    },

    getStateCity(financeCompanyId) {
      var config = {
          headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
      };

      HTTP.get("/get/transactionsheet-finance-state/"+financeCompanyId, config)
      .then(function (response) {
        const data = response.data.content;
        if(data.state != null) {
          selfThis.state = {
            text: data.state.name,
            value: data.state.id
          };
        }

        if(data.city != null) {
          selfThis.city = data.city.name;
        }
      })
      .catch(function (err) {
          console.log('ERROR!!', err);
      });
    },

    emitFinanceData() {
      let financeData = {
        id: this.financeId,
        finance_company_master_id: (this.financeCompany == null || this.financeCompany == "") ? null : this.financeCompany.value,
        finance_company_name: this.financeCompanyName,
        finance_company_address: this.address,
        finance_company_state_id: (this.state == null || this.state == "") ? null : this.state.value,
        finance_company_city: this.city,
        finance_company_zip: this.zipCode,
        comment: this.comment,
        tempState: this.state,
        tempFinanceCompany: this.financeCompany,
        showAllFields: this.showAllFields,
      };
      EventBus.$emit('emit-finance-data', financeData);
    },

    populateEditFinanceData() {
      let data = this.financeData;
      if(Object.keys(data).length > 0) {
        this.showAllFields = true;
        if(Object.keys(data).includes('finance_company')) {
          this.financeCompany = {
            text: data.finance_company.name,
            value: data.finance_company.id
          }
          this.state = (data.state !== null) ? {
            text: data.state.name,
            value: data.state.id
          } : "";
        } else {
          this.financeCompany = data.tempFinanceCompany;
          this.state = data.tempState;
        }
        this.address = data.finance_company_address;
        this.financeCompanyName = data.finance_company_name;
        this.city = data.finance_company_city;
        this.zipCode = data.finance_company_zip;
        this.comment = data.comment;
        this.financeId = data.id;

        /*##### REFACTOR THESE DATA FETCH LATER
          let financeCompany = this.financeCompanyList.filter((company) => {
            return company.id == data.finance_company_master_id;
          });
          this.financeCompany = financeCompany[0];

          let companyState = this.financeCompanyList.filter((company) => {
            return company.id == data.finance_company_master_id;
          });
        */
      }
    }
  },
  watch: {
    financeCompanyList:{
      handler(list){
        this.financeCompanyArr = list;
      },
      immediate:true,
    },

    isEdit(status) {
      if(status) {
        setTimeout(() => {
          this.populateEditFinanceData();
        }, 1000);
      }
    },

    triggerPayment:{
      handler(isTrigger){
        // console.log('isTriggerPayment>>', isTrigger);
        this.populateEditFinanceData();
      },
      immediate:true,
    }

  }
};

EventBus.$on("finance-company-added-data", function (data) {
  if (typeof self != "undefined") {
    if(selfThis.paymentCat == "finance") {
      selfThis.financeCompany = {
        text: data.text,
        value: data.value
      }
      selfThis.showAllFields = true;
      selfThis.financeCompanyArr.push(data);
      selfThis.populateFinanceData(data);
    }
  }
});
</script>

<style
  lang="css"
  scoped
  src="../../../../../public/static/css/trnsPayment.css"
></style>
