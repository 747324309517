<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_customer_master')">
      <div class="container-fluid xyz">
        <common-loader
          refId="commonLoaderContactSyncFromExcede"
          ref="commonLoaderContactSyncFromExcede"
        >
        </common-loader>
        <div class="spaced-container list-contact-page">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>{{ allLabelMessages.customers_lable }}</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a
              v-show="permissionNameArray.includes('can_add_customer_master')"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="addContact()"
            >
              <span>Add Customer</span>
            </a>
            <a
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="syncContact()"
            >
              <span>Sync from EXCEDE</span>
            </a>

            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>

            <a
              id="exportContactId"
              title="Export"
              v-show="permissionNameArray.includes('can_export_customer_master')"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Export Customer', //Modal Title
                'export', //Action
                'exportContact', //Module Name 
                exportHeaders //Master Headers List
              )"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>
            <button
              id="contactHeaderList"
              title="Customer Header List"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Select Customer Header', //Modal Title
                'list', //Action
                'listContact', //Module Name 
                contactHeadersList //Master Headers List
              )"
            >
              {{ allLabelMessages.customer_header_list }}
            </button>

          </div>
          
          <!-- Export file status -->
          <div class="col-12" v-if="batchProcess">
            <div class="alert alert-success">
              Export file is in process. batch no. is {{batchNum}}. <router-link class="color-blue" v-bind:to="{name: 'ListBatch'}" target="_blank" @click.native="batchProcess = 0"> Click Here</router-link> to check file status!
            </div>
          </div>

          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <button
              v-show="
                permissionNameArray.includes('can_delete_customer_master')
              "
              type="button"
              v-on:click.prevent="deleteContact()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              Delete {{ selectedRows.length }} Record(s)
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_active_inactive_customer_master'
                )
              "
              type="button"
              v-on:click.prevent="activeInactiveContact(0, 'Active')"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Activate Selected"
            >
              <i class="fa fa-eye" aria-hidden="true"></i> Activate ({{
                selectedRows.length
              }})<!-- Record(s)-->
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_active_inactive_customer_master'
                )
              "
              type="button"
              v-on:click.prevent="activeInactiveContact(0, 'Deactive')"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Deactivate Selected"
            >
              <i class="fa fa-eye-slash" aria-hidden="true"></i> Deactivate ({{
                selectedRows.length
              }})<!-- Record(s)-->
            </button>
            <button
              v-show="permissionNameArray.includes('can_email_customer_master')"
              data-toggle="modal"
              title="Send mail to selected"
              class="btn btn-primary"
              v-on:click.prevent="loadModel()"
            >
              <i class="fa fa-envelope" aria-hidden="true"></i> Send Mail
              {{ selectedRows.length }} Record(s)
            </button>
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListContact"
              ref="commonLoaderListContact"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="contactArr && contactArr.length > 0">
                    <tr v-for="(contact, index) in contactArr" :key="index">
                      <td class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            v-model="selectedRows"
                            :value="contact.id"
                            @change="inputCheckbox($event)"
                            class="custom-control-input"
                            :id="'customCheck' + contact.id"
                            name="case[]"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customCheck' + contact.id"
                          ></label>
                        </div>
                      </td>
                      <td v-show="actionDisplay">
                        <div class="actions" style="width: 120px">
                          <router-link
                            v-show="
                              permissionNameArray.includes(
                                'can_add_customer_master'
                              )
                            "
                            class="mar-right-0"
                            title="Edit Customer"
                            v-bind:to="{
                              name: 'UpdateContact',
                              params: { id: contact.id },
                            }"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-angle-right forward_icon"
                            ></i>
                          </router-link>

                          <template v-if="contact.status == 'active'">
                            <a
                              v-show="
                                permissionNameArray.includes(
                                  'can_active_inactive_customer_master'
                                )
                              "
                              href="javascript:void(0)"
                              :title="allLabelMessages.inactive_customer"
                              class="mar-right-0"
                              v-on:click="
                                activeInactiveContact(contact.id, 'Inactive')
                              "
                            >
                              <i
                                class="exceptEdit fa fa-eye color-71aa3f"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </template>
                          <template v-else>
                            <a
                              v-show="
                                permissionNameArray.includes(
                                  'can_active_inactive_customer_master'
                                )
                              "
                              href="javascript:void(0)"
                              :title="allLabelMessages.active_customer"
                              class="mar-right-0"
                              v-on:click="
                                activeInactiveContact(contact.id, 'Active')
                              "
                            >
                              <i
                                class="exceptEdit fa fa-eye-slash color-c3202b"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </template>

                          <a
                            href="javascript:void(0)"
                            class="mar-right-0"
                            title="Customer Settings"
                            @click.stop.prevent="
                              openContextMenu(
                                $event,
                                contact.id,
                                contact.full_name,
                                contact.customer_id
                              )
                            "
                          >
                            <i
                              class="exceptEdit fa fa-cog"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </td>
                      <template
                        v-if="contactDataArr && contactDataArr.length > 0"
                      >
                        <template
                          v-for="(
                            contactData, contactDataIndex
                          ) in contactDataArr"
                        >
                          <template v-if="index == contactDataIndex">
                            <template v-for="(data, dataIndex) in contactData">
                              <template
                                v-for="(headerData, headerIndex) in headers"
                              >
                                <template
                                  v-if="
                                    headerData.column_name == dataIndex &&
                                    (typeof headerData.display == 'undefined' ||
                                      headerData.display == true)
                                  "
                                >
                                  <td class="td-hover-handle" :key="dataIndex">
                                    <template v-if="headerData.column_name === 'tags'">
                                      <div v-if="data && data.length > 0">
                                        <span
                                          v-for="(tag, tagIndex) in data"
                                          :key="tagIndex"
                                          class="badge badge-primary"
                                          style="margin-right: 5px;"
                                        >
                                          {{ tag.tag.tag_label }}
                                        </span>
                                      </div>
                                    </template>
                                    <template v-else>
                                      {{ data }}
                                    </template>
                                    <span
                                      v-b-tooltip.hover
                                      title="Copy"
                                      class="d-inline-block"
                                      style="width: 14px"
                                      v-clipboard:copy="data"
                                      v-clipboard:success="onCopy"
                                      v-clipboard:error="onError"
                                      v-if="
                                        data &&
                                        (dataIndex == 'email' ||
                                          dataIndex == 'primary_phone' ||
                                          dataIndex == 'customer_id')
                                      "
                                      :id="
                                        'copy-icon_' +
                                        contactDataIndex +
                                        '_' +
                                        dataIndex
                                      "
                                    >
                                      <i
                                        aria-hidden="true"
                                        class="fa fa-clone copy-icon display-on-hover"
                                      ></i
                                    ></span>
                                  </td>
                                </template>
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name == 'Cus Id'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        CusID
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="
                          data.name != 'Action' &&
                          data.name != 'Cus Id' &&
                          (typeof data.display == 'undefined' ||
                            data.display == true)
                        "
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->

            <!-- Vue Context Menu Start -->
            <vue-context
              ref="menu"
              :close-on-click="closeOnClick"
              :close-on-scroll="closeOnScroll"
              :item-selector="itemSelector"
            >
              <li>
                <a
                  v-show="
                    permissionNameArray.includes('can_delete_customer_master')
                  "
                  href="javascript:void(0)"
                  title="Delete Customer"
                  class="mar-right-0"
                  v-on:click="deleteContact(contactId)"
                >
                  <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                  Delete Customer
                </a>
                <a
                  v-show="
                    permissionNameArray.includes('can_add_customer_master')
                  "
                  href="javascript:void(0)"
                  title="Copy Customer"
                  class="mar-right-0"
                  v-on:click="copyContact(contactId)"
                >
                  <i class="fa fa-files-o exceptEdit" aria-hidden="true"></i>
                  Copy Customer
                </a>
                <!-- ToDo Need to explore this more  -->
                <!-- <a v-show="permissionNameArray.includes('can_add_customer_master')"
                                    href="javascript:void(0)" title="Copy Customer To Vendor" class="mar-right-0"
                                    v-on:click="copyContactToVendor(contactId)">
                                    <i class="fa fa-clone exceptEdit" aria-hidden="true"></i> Copy Customer To Vendor
                                </a> -->
                <a
                  v-show="
                    permissionNameArray.includes('can_add_customer_master')
                  "
                  href="javascript:void(0)"
                  title="Click To Add Activity"
                  class="mar-right-0"
                  v-on:click="
                    openAddActivityPopup(
                      contactId,
                      contactName,
                      contactExcedeId
                    )
                  "
                >
                  <i
                    class="fa fa-plus-square exceptEdit"
                    aria-hidden="true"
                  ></i>
                  Add Activity
                </a>
                <a
                  v-show="
                    permissionNameArray.includes('can_add_opportunity_master')
                  "
                  href="javascript:void(0)"
                  title="Click To Add Opportunity"
                  class="mar-right-0"
                  v-on:click="
                    openAddOpportunityPopup(
                      contactId,
                      contactName,
                      contactExcedeId
                    )
                  "
                >
                  <i
                    class="fa fa-plus-square exceptEdit"
                    aria-hidden="true"
                  ></i>
                  Add Opportunity
                </a>
              </li>
            </vue-context>
            <!-- Vue Context Menu End -->
          </div>
        </div>

        <!--Send Mail Modal -->
        <div
          class="modal fade"
          id="queueEmail"
          style="opacity: 1"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <form
                id="emailNotification"
                method="POST"
                enctype="multipart/form-data"
              >
                <div class="modal-header">
                  <h4 class="modal-title" id="defaultModalLabel">
                    {{ modelTitle }}
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="text-danger">
                        NOTE: Notification Send To Available Email ID Of
                        Contact(s) Only.
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Subject</label>
                      <input
                        id="subject"
                        v-model="subject"
                        class="form-control"
                        type="text"
                        placeholder="Subject"
                      />
                      <font class="text-danger">{{ subjectError }}</font>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Body</label>
                      <textarea
                        id="body"
                        v-model="body"
                        class="form-control"
                        placeholder="Write Message"
                      ></textarea>
                      <font class="text-danger">{{ bodyError }}</font>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div
                        class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block"
                      >
                        <div class="form-group">
                          <label for="dropzone">Attachment</label>
                          <vue-dropzone
                            ref="dropzone"
                            id="dropzone"
                            :options="dropzoneOptions"
                            @vdropzone-success="uploadDocument"
                            @vdropzone-error="fileError"
                            @vdropzone-file-added="fileAdd"
                            @vdropzone-total-upload-progress="progressOfUload"
                            :useCustomSlot="true"
                          >
                            <div class="dropzone-custom-content">
                              <h4 class="dropzone-custom-title">
                                Drop files here or click to upload.
                              </h4>
                              <div class="subtitle">
                                (Select files from your computer.)
                              </div>
                            </div>
                          </vue-dropzone>
                        </div>
                        <span
                          v-if="fileUploadError != ''"
                          class="help is-danger"
                          >{{ fileUploadError }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <!--Error Alert-->
                  <div class="col-md-12">
                    <div class="alert alert-danger" id="alertError">
                      <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                      <strong id="errorMsg" class="error"></strong>
                    </div>
                  </div>
                  <!--<div class="modal-footer">-->
                  <div class="col-md-12 mb-4">
                    <button
                      type="button"
                      :disabled="isDisableSave"
                      id="modelButtonId"
                      v-on:click="sendMail($event)"
                      class="btn btn-primary"
                    >
                      Send Notification
                    </button>
                    <!--<button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>-->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <!-- Export and List Headers Modal -->
        <reorder-headers-list-modal 
          @call-is-export="exportContact = true"
          @call-reload-list="loadContact"
          ref="reorderHeadersListModalRef"
        >
        </reorder-headers-list-modal>

        <add-contact-form-popup
          ref="addContactFormPopup"
          @call-close-add-contact-popup="callCloseAddContactPopup"
        ></add-contact-form-popup>
        <add-update-activity-popup 
          v-if="showAddActivityForm"
          @call-close-add-activity-popup="callCloseAddActivityPopup"
          ref="addUpdateActivityPopupRef"
        ></add-update-activity-popup>
        <add-update-opportunity-popup
          v-if="showAddOpportunityForm"
          @call-close-add-activity-popup="callCloseAddOpportunityPopup"
          ref="addUpdateOpportunityPopupRef"
        ></add-update-opportunity-popup>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
//import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import AddContactFormPopup from "./AddContactFormPopup.vue";
import AddUpdateActivityPopup from "./AddUpdateActivityPopup.vue";
import { VueContext } from "vue-context";
import "vue-context/dist/css/vue-context.css";
import AddUpdateOpportunityPopup from "./AddUpdateOpportunityPopup.vue";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import ReorderHeadersListModal from "../ReorderHeadersListModal/ReorderHeadersListModal";

Vue.component("app-footer", Footer);
//Vue.component("v-select", vSelect);

export default {
  name: "ListContact",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    vueDropzone: vue2Dropzone,
    "add-contact-form-popup": AddContactFormPopup,
    VueContext,
    "add-update-activity-popup": AddUpdateActivityPopup,
    "add-update-opportunity-popup": AddUpdateOpportunityPopup,
    "common-loader": CommonLoader,
    "reorder-headers-list-modal": ReorderHeadersListModal,
  },
  data() {
    return {
      production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
      dropzoneOptions: {
        timeout: 3000000,
        url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
        method: "POST",
        paramName: "file",
        autoProcessQueue: true,
        maxFilesize: 10, //MB
        maxFiles: 1, //ToDo : to decide number of files allowed
        dictMaxFilesExceeded: "Only {{maxFiles}} file is allowed",
        dictFileTooBig:
          "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
        thumbnailWidth: 150, //px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        dictCancelUpload: "Cancel",
        dictRemoveFile: "Remove",
        acceptedFiles: "",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
          // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
          // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
          //'Content-Type': 'application/x-www-form-urlencoded'
        },
      },
      perPageAllRecords: 10,
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      contactArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_customer_master"],
      actionDisplay: true,
      exportContact: false,
      jumpToHistoryPage: 1,
      filterCount: 0,
      /* Model Variables*/
      subjectError: "",
      bodyError: "",
      body: "",
      modelTitle: "",
      subject: "",
      imagesPaths: [],
      imagesType: [],
      imagesSize: [],
      fileUploadError: "",
      isDisableSave: false,
      /* End Model Variables*/
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      contactHeadersList: [],
      selectedContactHeadersList: [],
      isSelectAllContactHeaderList: false,
      contactDataArr: [],
      filterheaders: [],
      contactId: "",
      contactName: "",
      /* when set to true, the context  menu will close when clicked on */
      closeOnClick: true,
      /* when set to true, the context  menu will close when the window is scrolled */
      closeOnScroll: true,
      /* This is how the component is able to find each menu item. Useful if you use non-recommended markup */
      itemSelector: [".custom-item-class"],
      contactExcedeId: "",
      showAddContactForm: false,
      showAddActivityForm: false,
      showAddOpportunityForm: false,
      queueEmailModalInstance: null,
      batchProcess: 0,
      batchNum: "",
    };
  },
  beforeMount() {
    self = this;
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  watch: {
    /*selectAll: function (selectAll) {
            self.clickHeaderCheckBox();
        },*/
    /*perPage: function () {
            self.currentPage = 1;
            if (self.perPage !== "All") {
                self.perPageValue = self.perPage;
            }
            self.loadContact();
        },
        currentPage: function () {
            self.loadContact();
        }*/
  },
  mounted: function () {
    // throw new Error('Test error');

    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;

    self.setActiveMenu("contact-list");
    EventBus.$emit("menu-selection-changed", "contact-list");
    var listHistoryData = self.loadFilters("listContact", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadContact(1);
    self.initializeModals();
    /*if (self.perPage === "All") {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                console.log(document.documentElement.scrollTop + window.innerHeight + "===>" + document.documentElement.offsetHeight);
                if (bottomOfWindow) {
                    self.perPageAllRecords = self.perPageAllRecords + 10;
                    self.loadContact();
                }
            };
        }
        */
  },
  methods: {
    initializeModals() {
      self.queueEmailModalInstance = new  bootstrap.Modal(
        document.getElementById("queueEmail")
      );
    },
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    /* Context Menu Logic Start */
    openContextMenu: function (e, id, fullName, excedeId) {
      self.contactId = id;
      self.contactExcedeId = excedeId;
      self.contactName = fullName;
      self.$refs.menu.open(e);
    },
    callCloseAddContactPopup: function () {
      //Reload the list
      self.loadContact();
    },
    callCloseAddActivityPopup: function () {
      //Action after activity added
    },
    openAddActivityPopup: function (id, contactName, excedeId) {
      //Open Add Activity popup code goes here
      let contactData = {
        id: id,
        excedeId: excedeId,
        name: contactName,
        flag: "addCustomerActivity",
      };
      this.showAddActivityForm = true;
      this.$nextTick(() => {
        this.$refs.addUpdateActivityPopupRef.openAddActivityPopup(contactData);
      });
    },
    openAddOpportunityPopup: function (id, contactName, excedeId) {
      //Open Add Activity popup code goes here
      let contactData = {
        id: id,
        excedeId: excedeId,
        name: contactName,
      };
      this.showAddOpportunityForm = true;
      this.$nextTick(() => {
        this.$refs.addUpdateOpportunityPopupRef.openAddOpportunityPopup(contactData);
      });
    },
    callCloseAddOpportunityPopup: function () {
      //Action after Opportunity added
    },
    /* Context Menu Logic End */
    checkForm: function () {
      var checked = true;

      if (!self.subject) {
        self.subjectError = "Subject Is Required";
        if (checked) {
          checked = false;
        }
      }
      if (!self.body) {
        self.bodyError = "Email Message Is Required";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    sendMail: function (e) {
      $("#alertError").hide();
      self.checkForm();
      if (self.checkForm()) {
        $(e.target).text("Sending...");
        $(e.target).prop("disabled", true);
        var usersArr = self.selectedRows;
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        var fields = new FormData();
        fields.append("subject", self.subject);
        fields.append("message", self.body);
        fields.append("users_arr", usersArr);
        fields.append("imagePath", self.imagesPaths);
        fields.append("send_email", e.target.id);
        this.$swal({
          title: "Are you sure?",
          text: "You want to send email notification",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes Send it!",
          cancelButtonText: "No, Don't send it!",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            self.showLoader();
            HTTP.post("sendemail", fields, config)
              .then(function (response) {
                $(e.target).text("Send Notification");
                $(e.target).prop("disabled", false);

                if (response.data.code == "200") {
                  self.subject = "";
                  self.body = "";
                  self.removeAllFiles();
                  $("#upload").val("");
                  self.queueEmailModalInstance.hide();
                  self.$swal({
                    type: "success",
                    text: "Email Notification Sent Successfully",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  self.loadContact(1);
                }
                self.hideLoader();
              })
              .catch(function (err) {
                $(e.target).text("Send Notification");
                $(e.target).prop("disabled", false);
                //self.queueEmailModalInstance.hide();
                if (err.response.status == 0) {
                  self.error = "Remote server can not be reachable";
                } else {
                  //redirect to login page if user not authenticated
                  if (err.response.status == 401) {
                    //clear previous data
                    localStorage.removeItem("user_obj");
                    localStorage.removeItem("api_token");
                    localStorage.removeItem("profile_image");
                    self.$router.push("/login");
                    //EventBus.$emit("logged-in", true, "", "");
                  } else {
                    $("#alertError").show();
                    $("#errorMsg").html(err.response.data.content);
                    $(".alert-danger").removeClass("hide");
                    $(".alert-danger").addClass("show");
                    setTimeout(function () {
                      $(".alert-danger").removeClass("show");
                      $(".alert-danger").addClass("hide");
                    }, 5000);
                  }
                }
                self.hideLoader();
              });
          } else {
            self.$swal(
              "Cancelled",
              "You have cancelled email notification",
              "info"
            );
            $(e.target).text("Send Notification");
            $(e.target).prop("disabled", false);
            self.subject = "";
            self.body = "";
            self.removeAllFiles();
            $("#upload").val("");
            self.queueEmailModalInstance.hide();
          }
        });
      }
    },
    removeAllFiles() {
      var self = this;
      self.$refs.dropzone.removeAllFiles();
    },
    loadModel: function (action) {
      self.subject = "";
      self.subjectError = "";
      self.body = "";
      self.bodyError = "";
      self.fileUploadError = "";
      self.imagesPaths = [];

      self.removeAllFiles();
      $("#alertError").hide();
      self.modelTitle = "Send Email To Selected";
      self.modelButtonId = action;
      self.queueEmailModalInstance.show();
    },

    uploadDocument: function (file, response) {
      var self = this;
      self.fileUploadError = "";
      self.imagesPaths.push(response.content.file_path);
      self.imagesType.push(file.type);
      self.imagesSize.push(file.size);
      // this.$refs.dropzone.setOption('maxFiles', null);
    },
    removedSelectedFile: function (file, xhr, error) {
      /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
      // this.$refs.dropzone.setOption('maxFiles', null);
      var self = this;
      var removeFile = "";
      if (this.$refs.dropzone.dropzone.options.maxFiles) {
        // this.$refs.dropzone.setOption('maxFiles', null);
      }
      /* if (self.imagesPaths.length <= 0) {
             self.isFileUploaded = false;
             } */
      if ("manuallyAdded" in file == true) {
        for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
          if (self.imagesPaths[i].indexOf(file.name) != -1) {
            removeFile = self.imagesPaths[i];
          }
        }
        // this.$refs.dropzone.setOption('maxFiles', null);
      } else {
        var filePath = JSON.parse(file.xhr.response);
        removeFile = filePath.content;
        self.removeFileFromServer(removeFile);
        removeFile = removeFile.media_path;
      }
      for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
        if (self.imagesPaths[i] === removeFile) {
          self.imagesPaths.splice(i, 1);
          self.imagesType.splice(i, 1);
          self.imagesSize.splice(i, 1);
          //break;       //<-- Uncomment  if only the first term has to be removed
        }
      }
      // this.$refs.dropzone.setOption('maxFiles', null);
      /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
    },
    removeFileFromServer: function (filePath) {
      var self = this;
      //console.log(filePath);return false;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        data: {
          filePath: filePath,
        },
      };
      HTTP.delete("removemedia", config)
        .then(function (response) {
          /*console.log("File removed successfully");*/
        })
        .catch(function (err) {
          //self.catchHandler(err, function () {});
          if (err.response.status == 0) {
            self.error = "Remote server can not be reachable";
          } else {
            //redirect to login page if user not authenticated
            if (err.response.status == 401) {
              //clear previous data
              localStorage.removeItem("user_obj");
              localStorage.removeItem("api_token");
              localStorage.removeItem("profile_image");
              self.$router.push("/login");
              //EventBus.$emit("logged-in", true, "", "");
            }
            self.error = err.response.data.message;
          }
        });
    },
    fileError: function (file, xhr, error) {
      if (xhr.code == 400) {
        /* console.log("File");
                console.log(file);
                console.log("XHR");
                console.log(xhr.code);
                console.log(xhr.status);
                console.log(xhr.message); */
        self.showSAlert(xhr.message, "error", false);
        /* console.log(xhr.content);
                console.log("Error");
                console.log(error);
                console.log(self.imagesPaths); */
      }
    },
    fileAdd: function (file, xhr, error) {
      if (this.$refs.dropzone.dropzone.options.maxFiles) {
        // this.$refs.dropzone.setOption('maxFiles', null);
      }
      /* console.log("File Add"); */
    },
    progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
      if (totaluploadprogress == 100) {
        self.isDisableSave = false;
      } else {
        self.isDisableSave = true;
      }
    },

    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadContact();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadContact();
      }
    },
    copyContact: function (id) {
      self.$router.push({
        name: "AddContact",
        params: { CopyId: id, CopyContact: "CopyContact" },
      });
    },
    copyContactToVendor: function (id) {
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to copy this Customer(s) inside Vendor!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
            };
            var fields = {
              copy_id: id,
            };
            HTTP.post("/contact/copy", fields, config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.showSAlert(response.data.message, "success", false);
                  self.loadContact();
                }
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadContact();
    },
    syncContact: function () {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      $("#commonLoaderContactSyncFromExcede")
        .find(".loading-container.lodingTable")
        .show();
      HTTP.get("/contact/sync", config)
        .then((response) => {
          if (response.data.status == "success") {
            self.jumpToPage = 1;
            self.keyMonitor();
            self.loadContact();
            self.showSAlert(response.data.message, "success", false, 3);
          }
          $("#commonLoaderContactSyncFromExcede")
            .find(".loading-container.lodingTable")
            .hide();
        })
        .catch(function (err) {
          self.catchHandler(err, function () {});
        });
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    deleteContact: function (id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Customer!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                idArr: deleteArray,
              },
            };

            HTTP.delete("/contact/delete", config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.loadContact();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.contactArr) {
            self.selectedRows.push(self.contactArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.contactArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    addContact: function () {
      self.$refs.addContactFormPopup.openContactFormPopupModal();
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadContact();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
        // self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    loadContact: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadContact();
      }
      
      self.exportSelectedHeaders = self.loadExportHeaders(
        "exportContact",
        self
      );
      self.exportSelectedHeaders =
        self.exportSelectedHeaders === null ||
        typeof self.exportSelectedHeaders === "undefined" ||
        self.exportSelectedHeaders == ""
          ? []
          : self.exportSelectedHeaders;

      self.selectedContactHeadersList = self.loadListHeaders(
        "listContact",
        self
      );
      self.selectedContactHeadersList =
        self.selectedContactHeadersList === null ||
        typeof self.selectedContactHeadersList === "undefined" ||
        self.selectedContactHeadersList == ""
          ? []
          : self.selectedContactHeadersList;

      if (self.exportContact == true) {
        self.exportRecords();
      }
          
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: self.exportContact,
        export_action: self.exportAction,
        per_page_all_record: self.perPageAllRecords,
        export_selected_headers: self.exportSelectedHeaders,
        selected_contact_header: self.selectedContactHeadersList,
      };
      this.storeFilters("listContact", fields, false);

      if (self.exportContact) {
        self.$refs.reorderHeadersListModalRef.exportLoaderShow();
      } else {
        $("#commonLoaderListContact")
          .find(".loading-container.lodingTable")
          .show();
      }
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/contact/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          if (data.status == 200) {
            if (data.data.message == "success export queue") {
              self.exportAction = '';
              self.exportContact = false;
              self.$refs.reorderHeadersListModalRef.exportLoaderHide();
              self.$refs.reorderHeadersListModalRef.closeModal();
              self.loadContact();
              self.batchProcess = 1;
              self.batchNum = data.data.content;
            } else if (data.data.message == "success export") {
              self.exportAction = '';
              self.exportContact = false;
              self.$refs.reorderHeadersListModalRef.exportLoaderHide();
              self.$refs.reorderHeadersListModalRef.closeModal();
              self.downloadFile(data.data.content.web);
              self.loadContact();
              // location.href = data.data.content.web;
            } else {
              $("#commonLoaderListContact")
              .find(".loading-container.lodingTable")
              .hide();
              self.clearSelection();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              if (
                self.perPage == "All" &&
                self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
              ) {
                // alert(self.totalRecords);
                let seconds = 5000;
                self.$swal({
                  type: "error",
                  html: self.allStaticMessages
                    .too_many_data_to_display_browser_can_not_load,
                  showConfirmButton: false,
                  timer: seconds,
                });
                setTimeout(() => {
                  self.perPage = 500;
                  self.loadContact();
                }, seconds);
                /* self.$swal({
                                    title: '',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    type: 'error',
                                    confirmButtonText: 'OK',
                                    showCancelButton: true,
                                    showLoaderOnConfirm: true
                                }).then((result) => {
                                    if (result.value) {
                                        self.logout();
                                    } else {
                                        self.perPage = 15;
                                        self.loadContact();
                                    }
                                }); */
              }
              self.contactArr = data.data.content.data;
              self.contactDataArr = data.data.content.contactListData;
              self.headers = data.data.content.headers;
              self.contactHeadersList = data.data.content.contactHeadersList;
              if (
                self.selectedContactHeadersList.length ==
                self.contactHeadersList.length
              ) {
                self.isSelectAllContactHeaderList = true;
              }
              self.exportHeaders = data.data.content.exportheaders;
              if (
                self.exportSelectedHeaders.length == self.exportHeaders.length
              ) {
                self.isSelectAllExportHeader = true;
              }
              self.loadFilterInputs(
                data.data.content.filterheaders,
                self.filters
              );
              self.filterheaders = data.data.content.filterheaders;

              self.addColumnClass(self.headers);

              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          if (self.exportContact) {
            self.$refs.reorderHeadersListModalRef.exportLoaderHide();
          } else {
            $("#commonLoaderListContact")
            .find(".loading-container.lodingTable")
            .hide();
          }
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadContact();
      }
    },
    activeInactiveContact: function (id, state) {
      let statusArr = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        statusArr.push(id);
      } else {
        statusArr = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to " + state + " this Customer(s)!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
            };
            var fields = {
              id: statusArr,
              state: state,
            };

            HTTP.post("/contact/activeordeative", fields, config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.loadContact();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (
      !self.permissionNameArray.includes("can_add_customer_master") &&
      !self.permissionNameArray.includes("can_delete_customer_master") &&
      !self.permissionNameArray.includes("can_active_inactive_customer_master")
    ) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
</script>
<style scoped>
.copy-icon {
  color: #aaaaaa;
  cursor: pointer;
}

.display-on-hover {
  display: none;
}

.td-hover-handle:hover .display-on-hover {
  display: block;
}
</style>
