<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_view_vendor_invoice_inventory_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-md-12 paddingnone">
                        <div class="col-md-12 paddingnone float-left">
                            <div class="clearfix mb-3"></div>
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <tbody>
                                        <template v-if="assetWiseVendorInvoiceArr && assetWiseVendorInvoiceArr.length > 0">
                                            <tr v-for="(invoiceData, index) in assetWiseVendorInvoiceArr" :key="index">
                                                <td>
                                                    <div class="actions">
                                                        <a href="javascript:void(0)" @click="getSignedUrlPath(invoiceData.document_path)" title='Download Invoice' class="mar-right-0">
                                                            <i class="exceptEdit fa fa-cloud-download" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td :class="rowClass.document_type">{{ invoiceData.document_type }}</td>
                                                <td :class="rowClass.vendor_name">{{ invoiceData.vendor_name }}</td>
                                                <td :class="rowClass.vendor_contact_name">{{ invoiceData.vendor_contact_name }}</td>
                                                <td :class="rowClass.vendor_phone">{{ invoiceData.vendor_phone }}</td>
                                                <td :class="rowClass.vendor_description">{{ invoiceData.vendor_description || '-' }}</td>
                                                <td :class="rowClass.updated_at">{{ invoiceData.updated_at }}</td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <td :colspan="headers.length + 1" class="text-alignment-center">
                                                    {{allStaticMessages.data_not_available}}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <thead>
                                    <tr>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- Pagination Start -->
                            <div class="vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPage"
                                                @input="changePerPage"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecord}} of {{totalRecords}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecords >= perPage" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changePage()" :per-page="perPageValue"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                        </div>
                    </div>
                </div>    
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'VendorInvoiceTab',
        mixins: [commonMixins],
        components: {
            'no-access' : NoAccessPage,
        },
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                assetWiseVendorInvoiceArr : [],
                headers: [],
                columnName: filterColumn,
                sortType: filterType,
                assetId: 0,
                jumpToPage : 1,
                perPageValue : 15,
                perPage:15,
                currentPage:1,
                totalRecords:0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                startEndRecord: ""
            }
        },
        beforeMount() {
            self = this;
        },
        props: ['permissionNameArray', 'asset', 'getData'],
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            self.listExecutionState = false;
            
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.assetId = self.$route.params.id;
                self.loadVendorInvoice(self.assetId);
            }
        },
        methods: {
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadVendorInvoice(self.assetId);
            },
            changePage : function(){
                self.loadVendorInvoice(self.assetId);
            },
            keyMonitor:function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadVendorInvoice(self.assetId);
                }
            },
            loadVendorInvoice: function(assetId) {
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadVendorInvoice();
                }
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    assetId : assetId
                };
                self.showLoader();
                HTTP.post("/asset/vendor/invoice/list", fields, config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {
                            self.headers = [];
                            self.totalRecords = data.data.content.result;
                            if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPage = 500;
                                    self.loadVendorInvoice();
                                }, seconds);
                            }
                            self.assetWiseVendorInvoiceArr = data.data.content.data;
                            self.headers = data.data.content.headers;
            
                            self.addColumnClass(self.headers);
                            
                            if (data.data.content.per_page !== self.totalRecords) {
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPage == 'All'){
                                    self.jumpToPage = 1;
                                }
                                self.perPageValue = self.totalRecords;
                                self.currentPage = 1;
                            }
                            self.perPage = parseInt(data.data.content.per_page);
                            self.jumpToPage = parseInt(data.data.content.current_page);
                            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                            self.sortFieldDisplay(self.columnName,self.sortType);
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    })
            }
        }
    }
</script>