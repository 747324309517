<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_po_request')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <template v-if="permissionNameArray.includes('can_list_po_request')">
                        <button id="backBtn" type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </template>
                </div>

                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!--New Trailer Request -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{ staticInputLabelMessages.new_trailer_request }}</div>
                                </div>
                                <div class="col-9 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="newTrailerRequest" v-model="newTrailerRequest" value="false"
                                            class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!--Sales Person -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.seles_person}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="userId"
                                            name="asset"
                                            @input="userError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="userArr"
                                            @search="onSearchUser"
                                            :clearable="false"
                                            :placeholder="staticMessages.sales_person_place_holder"
                                            :disabled="true">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{userError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                              <!-- Type of Request -->
                              <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.type_of_request_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="typeOfRequestId"
                                            @input="typeOfRequestError=''"
                                            name="typeOfRequestId"
                                            id="typeOfRequestId"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="typeOfRequestArr"
                                            :placeholder="staticMessages.type_of_request_place_holder"
                                            @change="changeTypeOfRequest">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{typeOfRequestError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!-- Vendor Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vendor_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="vendorNameError = ''" id="vendorName" v-model="vendorName" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vendorNameError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!-- Location-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="locationId"
                                            @input="locationError = ''"
                                            name="locationId"
                                            id="locationId"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="locationArr"
                                            :placeholder="staticMessages.location_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!-- To Email -->
                            <div class="col-6 forms-block">
                              <div class="col-3 paddingleft0">
                                  <div class="form-label">{{staticInputLabelMessages.po_request_notify_email_label}}</div>
                              </div>
                              <div class="col-9 paddingleft0 po-email-block">
                                <vue-tags-input
                                          v-model="poEmail"
                                          :tags="poEmailTagsArr"
                                          :validation="validation"
                                          @tags-changed="newTags => poEmailTagsArr = newTags"
                                          @before-adding-tag="checkEmailTag"
                                          placeholder=""
                                          @input="changePOEmailValue()"
                                    />
                                <span class="help is-danger">{{poEmailError}}</span>
                              </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <template v-for="(input, index) in inputs">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="input.asset"
                                                name="asset"
                                                @input="input.assetError = ''"
                                                label="text"
                                                :filterable="false"
                                                :options="assetArr"
                                                @search="onSearchAsset"
                                                :clearable="false"
                                                :placeholder="staticMessages.asset_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isAssetRecordNotFound">
                                            {{staticMessages.asset_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{input.assetError}}</span>
                                    </div>
                                </div>
                                <div class="col-6 forms-block"></div>

                                <!-- VIN Description -->
                                <div class="col-6  forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.description_of_po}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="input.vinDescriptionError = ''" id="vinDescription" v-model="input.vinDescription" class="form-control" type="text"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{input.vinDescriptionError}}</span>
                                    </div>
                                </div>
                                <div class="col-6 forms-block"></div>

                                <!--Amount Requested -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.amount_requested}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="input.amountRequestedError = ''" id="amountRequested" v-model="input.amountRequested" class="form-control" type="text" maxlength="17"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{input.amountRequestedError}}</span>
                                    </div>
                                </div>
                                <template v-if="plusCount != 0">
                                    <div :key="index" class="col-6 forms-block">
                                        <button class="btn btn-danger" @click="deleteRow(index)"><i class="fa fa-times"></i></button>
                                    </div>
                                </template>
                                <template v-else>
                                    <div :key="index" class="col-6 forms-block"></div>
                                </template>
                            </template>

                            <div class="col-6 forms-block"></div>

                            <div class="col-12 forms-block">
                                <button class="btn btn-success" @click="checkInput"> <i class="fa fa-plus" aria-hidden="false"></i> </button>
                                <span> {{staticInputLabelMessages.click_to_add_more}} </span>
                            </div>

                            <!--Attach Document-->
                            <input id="isShowAttachment" type="hidden" :value="isShowAttachment"/>
                            <div v-if="isShowAttachment" class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.attach_document}}</label>
                                    <div class="requiredstar">*</div>
                                    <vue-dropzone
                                            ref="dropzone"
                                            id="dropzone"
                                            :options="dropzoneOptions"
                                            @vdropzone-success = "uploadDocument"
                                            @vdropzone-removed-file="removedSelectedFile"
                                            @vdropzone-error="fileError"
                                            @vdropzone-file-added="fileAdd"
                                            @vdropzone-total-upload-progress="progressOfUload"
                                            :useCustomSlot=true
                                            :destroyDropzone="false"
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                            <div class="subtitle">(Select files from your computer.)</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="help is-danger">{{fileUploadError}}</span>
                            </div>

                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display: none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addUpdatePORequestBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUpdatePORequest()">
                            <input v-if="permissionNameArray.includes('can_list_po_request')" id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>


                    <!-- Forms -->
                </div>
            </div>
            <!--page content-->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage"
    import VueTagsInput from '@johmun/vue-tags-input';

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddPORequest',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
            VueTagsInput,
        },
        mixins: [commonMixins],
        data() {
            return {
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: "",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },

                descriptionOfPO  : '',
                descriptionOfPOError : '',

                imagesPaths: [],
                imagesType: [],
                imagesSize: [],

                staticMessages: "",
                staticInputLabelMessages: "",
                contentClass: "admin-page-content",
                inputs: [
                  {
                    asset: '',
                    assetError: '',
                    amountRequested : "",
                    amountRequestedError : "",
                    vinDescription : "",
                    vinDescriptionError : "",
                  }
                ],
                tempInputsObject : {
                  asset: '',
                  assetError: '',
                  amountRequested : "",
                  amountRequestedError : "",
                  vinDescription : "",
                  vinDescriptionError : "",
                },

                notes : "",
                noteError : "",
                fileUploadError : "",

                asset : "",
                assetArr : [],
                userId : "",
                userArr : [],
                userError : "",
                isAssetRecordNotFound: false,
                isUserRecordNotFound : false,
                isSalesManagerRecordNotFound : false,
                amountRequested : "",

                vendorName : "",
                vendorNameError : "",

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                maxInputLength : 2,
                userArrayLength : 0,
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add PO Request",

                permissionNameArray : ['can_add_po_request'],
                selectedVinArr : [],

                ApprovStatus:"pending",
                ApprovStatusArr: [
                    {
                        text: "Approve",
                        value: "approve"
                    },
                    {
                        text: "Pending",
                        value: "pending"
                    },
                    {
                        text: "Reject",
                        value: "reject"
                    }
                ],
                username : '',
                typeOfRequestId : '',
                typeOfRequestArr : [],
                typeOfRequestError : '',
                locationId : '',
                locationId : '',
                locationArr : [],
                locationError : '',
                isShowAttachment : false,
                plusCount : 0,
                poEmail : '',
                poEmailError : '',
                poEmailTagsArr: [],
                validation: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                isValidPOEmailTag : true,
                allowTypeOfRequest: ['external_repairs', 'internal_repairs'],
                newTrailerRequest: false
            }
        },
        beforeMount() {
            self = this;
        },
        watch : {
            inputs: {
                handler(val){
                    for (let i = 0; i < val.length; i++) {
                        self.assetArr = self.removeByAttr(self.assetArr, "text", val[i].asset.text)
                    }
                },
                deep: true
            },
            typeOfRequestId : function (val) {
                if (self.allowTypeOfRequest.includes(val.alias)) {
                    self.isShowAttachment = true;
                } else {
                    self.isShowAttachment = false;
                }
            },
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */

            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.username= userJson['username'];
                if (typeof (userJson.company_id) == 'undefined' && userJson.company_id == 0 && userJson.company_id == '') {
                    self.logout();
                }
            }
            self.selectDefaultUser();
            self.selectDefaultSalesManager();
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('po-request');
            EventBus.$emit("menu-selection-changed", "po-request");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.contentClass = "spaced-container";
                self.buttonNames.save = "Update";
                $("#addAndContinueAssetBtn").hide();
                self.pageTitle = "Edit Inventory";
            }
            self.loadAllData(['User', 'Location', 'PoTypeOfRequest'], true, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            checkEmailTag: function(obj) {
                self.poEmailError = "";
                self.isValidPOEmailTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidPOEmailTag = false;
                    self.poEmailError = "Please enter valid Email";
                } else obj.addTag();
            },
            changePOEmailValue : function(poEmail) {
              if(!poEmail) {
                self.poEmailError = "";
              }
            },
            removeAllFiles() {
                var self = this;
                self.$refs.dropzone.removeAllFiles();
            },
            removeByAttr : function(arr, attr, value){
                var i = arr.length;
                while(i--){
                    if( arr[i]
                        && arr[i].hasOwnProperty(attr)
                        && (arguments.length > 2 && arr[i][attr] === value ) ){

                        arr.splice(i,1);

                    }
                }
                return arr;
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.fileUploadError = "";
                self.imagesPaths.push(response.content.file_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
            },
            removedSelectedFile: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                var removeFile = "";
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    // this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                // this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removedocument', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.cancel = false;
                    self.disableButtons.saveAndContinue = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.cancel = true;
                    self.disableButtons.saveAndContinue = true;
                }
            },
            selectDefaultSalesManager : function () {
                let userObj = JSON.parse(localStorage.getItem("user_obj"));
                let firstName = "";
                let lastName = "";
                let userId = 0;
                let fullName = "";
                if (userObj != null && userObj != "" && typeof userObj != "undefined") {
                    firstName = userObj['first_name'];
                    lastName = userObj['last_name'];
                    userId = userObj['id'];
                    fullName = firstName + " " + lastName;
                }
            },
            selectDefaultUser : function () {
                let userObj = JSON.parse(localStorage.getItem("user_obj"));
                let firstName = "";
                let lastName = "";
                let userId = 0;
                let fullName = "";
                if (userObj != null && userObj != "" && typeof userObj != "undefined") {
                    firstName = userObj['first_name'];
                    lastName = userObj['last_name'];
                    userId = userObj['id'];
                    fullName = firstName + " " + lastName;

                    self.userId = {text : fullName , value : userId};
                }
            },
            deleteRow(index) {
                self.plusCount = self.plusCount + 1;
                this.inputs.splice(index,1);
                self.resetSelectedAsset(1);
            },
            resetSelectedAsset(flag) {
                if(flag == 1) {
                    self.selectedVinArr = [];
                    for (let i = 0; i < self.inputs.length-1; i++) {
                        if(self.inputs[i].asset != "" || typeof self.inputs[i].asset === "object" ) {
                            if (self.inputs[i].asset.value != "" || self.inputs[i].asset.value != null || typeof self.inputs[i].asset.value !== "undefined") {
                                self.selectedVinArr.push(self.inputs[i].asset.value);
                            }
                        }
                    }
                } else {
                    self.selectedVinArr = [];
                    for (let i = 0; i < self.inputs.length; i++) {
                        if(self.inputs[i].asset != "" || typeof self.inputs[i].asset === "object" ) {
                            if (self.inputs[i].asset.value != "" || self.inputs[i].asset.value != null || typeof self.inputs[i].asset.value !== "undefined") {
                                self.selectedVinArr.push(self.inputs[i].asset.value);
                            }
                        }
                    }
                }
            },
            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            onSearchUser : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadUserTypeCallback, loading, search);
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.isUserRecordNotFound = self.isSalesManagerRecordNotFound = false;
                self.userArr = callbackData.User;
                if (self.userArr.length == 0) {
                    self.isUserRecordNotFound = self.isSalesManagerRecordNotFound = true;
                }

                self.locationArr = callbackData.Location;
                self.typeOfRequestArr = callbackData.PoTypeOfRequest;
            },
            loadUserTypeCallback: function (callbackData) {
                self.isUserRecordNotFound = false;
                self.userArr = callbackData.User;
                if (self.userArr.length == 0) {
                    self.isUserRecordNotFound = true;
                }
            },
            returnData: function () {
                var emailData = "";
                if(self.poEmailTagsArr && self.poEmailTagsArr.length > 0) {
                    for(var index = 0;index<self.poEmailTagsArr.length;index++) {
                        if(!emailData.includes(self.poEmailTagsArr[index].text)) {
                            var strLowerCase = self.poEmailTagsArr[index].text.toLowerCase();
                            emailData += strLowerCase + ',';
                        }
                    }
                }
                emailData = emailData.replace(/,\s*$/, "");

                var data = {
                    user : self.userId,
                    vendor_name : self.vendorName,
                    inputs : self.inputs,
                    imagePath: self.imagesPaths,
                    imageSize: self.imagesSize,
                    imageType: self.imagesType,
                    description_of_po : self.descriptionOfPO,
                    type_of_request_id : self.typeOfRequestId,
                    location_id : self.locationId,
                    to_email : emailData,
                    new_trailer_request : self.newTrailerRequest
                };


                return data;
            },
            buttonBack() {
                self.$router.push({name: "ListPORequest"});
            },
            clearDataOnSaveAndContinue: function () {
                self.selectDefaultUser();
                self.vendorName = "";

                for (let i = (self.inputs.length - 1); i >= 0; i--) {
                    self.deleteRow(i);
                }
                self.descriptionOfPO = "";
                self.selectDefaultSalesManager();

                self.imagesPaths = [];
                self.imagesType = [];
                self.imagesSize = [];

                self.typeOfRequestId = '';
                self.locationId = '';
                self.isShowAttachment = false;
                self.plusCount = 0;
                self.poEmailTagsArr = [];
                self.inputs = [
                  {
                    asset: '',
                    assetError: '',
                    amountRequested : "",
                    amountRequestedError : "",
                    vinDescription : "",
                    vinDescriptionError : "",
                  }
                ];
                self.newTrailerRequest = false;
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },
            checkInput: function () {
                let checked = true;
                for (let i = 0; i < self.inputs.length; i++) {
                    if (self.inputs[i].asset.value === "" || self.inputs[i].asset.value === null || typeof self.inputs[i].asset.value === "undefined") {
                        self.inputs[i].assetError = "Please select VIN No.";
                        if (checked) {
                            checked = false;
                        }
                    } else if(i > 0 && self.inputs[i].asset.value && self.selectedVinArr.includes(self.inputs[self.inputs.length - 1].asset.value)) {
                        self.inputs[i].assetError = "Selected inventory is already available";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if (!self.inputs[i].amountRequested) {
                        self.inputs[i].amountRequestedError = "Please enter Amount Requested";
                        if (checked) {
                            checked = false;
                        }
                    } else if (self.inputs[i].amountRequested && !self.numberFormateValidation(self.inputs[i].amountRequested)) {
                        self.inputs[i].amountRequestedError = "Please enter numeric value for Amount Requested";
                        if (checked) {
                            checked = false;
                        }
                    }  else if (self.inputs[i].amountRequested && self.inputs[i].amountRequested <= 0) {
                        self.inputs[i].amountRequestedError = "Please enter valid Amount Requested";
                        if (error) {
                            error = false;
                        }
                    }

                    if (self.inputs[i].vinDescription === "" || self.inputs[i].vinDescription === null || typeof self.inputs[i].vinDescription === "undefined") {
                        self.inputs[i].vinDescriptionError = "Please enter Description of PO.";
                        if (checked) {
                            checked = false;
                        }
                    }
                }

                if(checked) {
                    self.inputs.push(Vue.util.extend({}, self.tempInputsObject));
                    self.plusCount = self.plusCount - 1;
                }
                return checked;
            },
            checkForm: function () {
                let checked = true;
                self.typeOfRequestError = '';
                self.locationError = '';
                self.poEmailError = "";

                for (let i = 0 ; i < self.inputs.length; i++) {
                    if (self.inputs[i].asset.value === "" || self.inputs[i].asset.value === null || typeof self.inputs[i].asset.value === "undefined") {
                        self.inputs[i].assetError = "Please select VIN No.";
                        if (checked) {
                            checked = false;
                        }
                    } else if(i > 0 && self.inputs[i].asset.value && self.selectedVinArr.includes(self.inputs[self.inputs.length - 1].asset.value)) {
                        self.inputs[i].assetError = "Selected inventory is already available";
                        if (checked) {
                            checked = false;
                        }
                    }

                    if (!self.inputs[i].amountRequested) {
                        self.inputs[i].amountRequestedError = "Please enter Amount Requested";
                        if (checked) {
                            checked = false;
                        }
                    } else if (self.inputs[i].amountRequested && !self.numberFormateValidation(self.inputs[i].amountRequested)) {
                        self.inputs[i].amountRequestedError = "Please enter numeric value for Amount Requested";
                        if (checked) {
                            checked = false;
                        }
                    } else if (self.inputs[i].amountRequested && self.inputs[i].amountRequested <= 0) {
                        self.inputs[i].amountRequestedError = "Please enter valid Amount Requested";
                        if (error) {
                            error = false;
                        }
                    }

                    if (self.inputs[i].vinDescription === "" || self.inputs[i].vinDescription === null || typeof self.inputs[i].vinDescription === "undefined") {
                        self.inputs[i].vinDescriptionError = "Please enter Description of PO.";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (self.userId == null || !self.userId.value) {
                    self.userError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.vendorName) {
                    self.vendorNameError = "Please enter vendor name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.isShowAttachment && self.imagesPaths.length === 0)  {
                    self.fileUploadError = "Please upload file";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.typeOfRequestId == null || !self.typeOfRequestId.value) {
                    self.typeOfRequestError = "Please select Type of Request";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.locationId == null || !self.locationId.value) {
                    self.locationError = "Please select Location";
                    if (checked) {
                        checked = false;
                    }
                }

                if(self.poEmail && !self.isValidPOEmailTag) {
                  self.poEmailError = "Please enter valid Email";
                  if (checked) {
                    checked = false;
                  }
                }
                return checked;
            },
            addUpdatePORequest(flag) {
                setTimeout(() => {
                    self.resetSelectedAsset(1);
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/porequest/add/update/vtc", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {

                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        self.clearDataOnSaveAndContinue();
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            if (self.permissionNameArray.includes('can_list_po_request')) {
                                                self.buttonBack();
                                            }
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
            changeTypeOfRequest: function() {
                if(self.typeOfRequestId && (self.typeOfRequestId.alias == 'building_maintenance' 
                                            || self.typeOfRequestId.alias == 'other' 
                                            || self.typeOfRequestId.alias == 'recon')) {
                    if (self.$refs.dropzone) {
                        self.$refs.dropzone.removeAllFiles();
                    }
                    self.imagesPaths = [];
                    self.imagesType = [];
                    self.imagesSize = [];
                    self.fileUploadError = '';
                }
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>

<style>
.po-email-block .vue-tags-input {
  max-width: initial;
}
</style>
