// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import { store } from './store';
import * as Sentry from "@sentry/vue";

/* IE Support start*/
// import 'core-js/es6/number';
// import 'core-js/shim';
/* IE Support End*/

import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';

/* Vue moment Vuectkdatepicker */
import VueMoment  from 'vue-moment';
import moment from 'moment-timezone'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
/* Vue moment Vuectkdatepicker */
Vue.use(BootstrapVue);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
// import 'vue-select/dist/vue-select.css';

import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, { locale: 'en' });

Vue.use(VueMoment, {
    moment,
});

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.config.productionTip = false;

Vue.filter('capitalize', function (value) {
	if (!value) {
		return ''
	}
	value = value.replace(/_/g, ' ')
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('truncate', function (string) {
  if (string) {
    if (string.length <= 15) {
      return string
    }
    return string.slice(0, 15) + '...'
  } else {
    return string
  }
})

Vue.filter('formatPrice', function (value) {
  let val = (value/1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+?(?!\d))/g, ",")
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

import EventBus from './components/event-bus';
import { HTTP } from './http-common';
// for browser notification 
/**
 * First we check if the Browser supports Notification
 * If yes, we initialize Service Worker
 * If no, no need to do anything
 */
let isSubscribed = false;
let swRegistration = null;

Vue.prototype.$requestNotificationPermission = function() {
	
	if ( ('Notification' in window) ) {
	
		// console.log('Web Notification is supported');
		if (Notification.permission === "granted") {
			
			initServiceWorker();
		} else if ( Notification.permission === 'denied' || Notification.permission === "default" ) {
			
			  Vue.swal({
				title: '',
				text: 'Please Allow Browser Notifications to get updates on Calendar events.',
				type: 'info',
				confirmButtonText: 'Allow',
				showCancelButton: true,
				// showCloseButton: true,
				allowOutsideClick: false,
				showLoaderOnConfirm: true
			}).then((result) => {
	
				if (result.value) {
					
					Notification.requestPermission(function (status) {
						console.log('Notification permission status:', status);
						if (status == "granted") {
							localStorage.setItem("is-notification-enabled", true);
							EventBus.$emit("notification-allow", true);
							initServiceWorker();
						} else {
							EventBus.$emit("notification-allow", false);
							localStorage.setItem("is-notification-enabled", false);
							updateSubscriptionOnServer(null);
						}
					});
	
				} else {
	
					EventBus.$emit("notification-allow", false);
					localStorage.setItem("is-notification-enabled", false);
				}
			});
	
		} else {
	
			alert(Notification.permission)
		}
	}  
}

function initServiceWorker() {
	
	if ('serviceWorker' in navigator && 'PushManager' in window) {
		
		navigator.serviceWorker.register('../service-worker.js')
			.then(function (swReg) {
				swRegistration = swReg;
				swReg.update();
		
				var serviceWorker;
		
				if (swReg.installing) {
					serviceWorker = swReg.installing;
					//console.log('Service worker installing');
				} else if (swReg.waiting) {
					serviceWorker = swReg.waiting;
					//console.log('Service worker installed & waiting');
				} else if (swReg.active) {
					serviceWorker = swReg.active;
					//console.log('Service worker active');
				}
		
				if (serviceWorker) {
					// console.log("sw current state", serviceWorker.state);
					if (serviceWorker.state == "activated") {
						//If push subscription wasnt done yet have to do here
						//console.log("sw already activated - Do watever needed here");
						initializeUI();
					}
					serviceWorker.addEventListener("statechange", function (e) {
						// console.log("sw statechange : ", e.target.state);
						if ( e.target.state == "activated" ) {
							// use pushManger for subscribing here.
							//console.log("Just now activated. now we can subscribe for push notification")
							// subscribeForPushNotification(reg);
							initializeUI();
						}
					});
		  		}
		  		// console.log('Service Worker is registered', swReg);
			})
			.catch(function (error) {
				console.error('Service Worker Error', error);
			});
			//created broadcast channel
			const broadcast = new BroadcastChannel('notifychat');
		
			//this is listening message from service worker once service worker publish message it will recieved 
			
			//realod page with url got from service worker
			broadcast.onmessage = (event) => {
				// console.log(event.data);
				window.location.href = event.data.payload;
			};
		} else {
			console.warn('Push messaging is not supported');
		}
  }
function initializeUI() {
	
	// Set the initial subscription value
	swRegistration.pushManager.getSubscription()
		.then(function (subscription) {
			isSubscribed = !(subscription === null);
			updateSubscriptionOnServer(subscription);
			if (!isSubscribed) {
				subscribeUser();  
			} else {
				// console.log('User is NOT subscribed.');
			}
		});
}
function subscribeUser() {
	const applicationServerKey = urlB64ToUint8Array(process.env.VUE_APP_PUBLIC_VAPID_KEY);
		swRegistration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: applicationServerKey
									})
									.then(function (subscription) {
										//console.log('User is subscribed.');
										updateSubscriptionOnServer(subscription);
										isSubscribed = true;
									})
									.catch(function (err) {
										//console.log('Failed to subscribe the user: ', err);
									});
}  
function updateSubscriptionOnServer(subscription) {  
	if (subscription) {
	  //subscriptionJson.textContent = JSON.stringify(subscription);
	  sendSubscriptionToServer(JSON.stringify(subscription));
	  //subscriptionDetails.classList.remove('is-invisible');
	} else {
	}
}
function sendSubscriptionToServer(subscription) {

	var fields = {
		'subscription': subscription,
	};

	var config = {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("api_token")
		}
	};

	HTTP.post("browser/subscription", fields, config)
		.then(function (data) {
			// console.log(JSON.stringify(data.data));
		})
		.catch(function (error) {
			// console.log(error);
		})
}
function urlB64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracing: true,
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    trackComponents: ["Header", "Navigation", "Footer"],
    hooks: ["create", "mount"],
  });


/* eslint-disable no-new */
new Vue({
  	el: '#app',
  	router,
        store,
  	// components: { App },
	  render: h => h(App),
  	template: '<App/>'
});
