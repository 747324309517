<template>
    <!--<div class="spaced-container">-->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_used_truck_moved_submission_form') || permissionNameArray.includes('can_add_new_trailer_move_submission_form')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div v-if="formType == 'used_truck'" class="page-name">{{pageTitle}}</div>
                    <div v-if="formType == 'new_trailer'" class="page-name">{{newTrailerPageTitle}}</div>
                    <template v-if="permissionNameArray.includes('can_list_used_truck_moved_submission_form')">
                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </template>
                </div>

                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!--Seles Person -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.salesman_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="userId"
                                            name="asset"
                                            @input="userError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="userArr"
                                            @search="onSearchUser"
                                            :clearable="false"
                                            :placeholder="staticMessages.sales_person_place_holder"
                                            :disabled="true">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{userError}}</span>
                                </div>
                            </div>

                            <!-- Type of Request -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.type_of_request_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="typeOfRequestId"
                                            name="typeOfRequestId"
                                            @input="typeOfRequestIdError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="typeOfRequestIdArr"
                                            :clearable="false"
                                            :placeholder="staticMessages.type_of_request_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{typeOfRequestIdError}}</span>
                                </div>
                            </div>

                            <!-- Location of Unit -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_of_unit_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                  <v-select
                                          v-model="locationOfUnit"
                                          @change="locationOfUnitError =''"
                                          @input="locationOfUnitError = ''"
                                          name="locationOfUnit"
                                          id="locationOfUnit"
                                          label="text"
                                          :clearable="false"
                                          :searchable="false"
                                          :filterable="false"
                                          :options="locationOfUnitArr"
                                          :placeholder="staticMessages.location_of_unit_place_holder">
                                  </v-select>
                                  <div class="requiredstar">*</div>
                                  <span class="help is-danger">{{locationOfUnitError}}</span>
                                </div>
                            </div>

                            <!--
                                Field: Send To Manager1 
                                Date: 11 March 2025
                                Author: Bhumi Shah
                                Change Reason: AB#7586 - New Trailer Request Based Change
                                Old Logic: Used Truck users' request form uses send to Manager 1 for Level-1 approval.
                                New Logic: New Trailer users no longer require send to manager field for approval; used truck workflow remains unchanged.
                            -->
                            <template v-if="formType == 'used_truck'">
                                <div class="col-md-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.send_to_manager1_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="salesManagerId"
                                                name="asset"
                                                @input="salesManagerError = ''"
                                                label="text"
                                                :filterable="false"
                                                :options="salesManagerArr"
                                                @search="onSearchSalesManager"
                                                :clearable="false"
                                                :placeholder="staticMessages.send_to_manager1_search">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isSalesManagerRecordNotFound">
                                                    {{staticMessages.send_to_manager1_search}}
                                                </template>
                                                <template v-else>
                                                    {{staticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{salesManagerError}}</span>
                                    </div>
                                </div>
                            </template>

                            <!-- Is unit ready for pick up -->
                            <div class="col-6 forms-block">
                              <div class="col-3 paddingleft0">
                                <div class="form-label"> {{staticInputLabelMessages.is_unit_ready_for_pickup_label}} </div>
                                <div class="requiredstar">*</div>
                              </div>
                              <div class="col-9 paddingleft0">
                                <b-form-radio-group
                                    @input="isUnitReadyForPickupError = ''"
                                    v-model="isUnitReadyForPickup"
                                    :options="isUnitReadyForPickupOptions"
                                    name="isUnitReadyForPickup"
                                  >
                                </b-form-radio-group>
                                <span class="help is-danger">{{isUnitReadyForPickupError}}</span>
                              </div>
                            </div>

                            <!-- Description of Request -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_of_request_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea v-model="descriptionOfReq" name="descriptionOfReq" id="descriptionOfReq" class="form-control" maxlength="190"></textarea>
                                </div>
                            </div>

                            <!-- Pickup Date -->
                            <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.pickup_date_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <date-picker
                                                v-model="pickUpDate"
                                                :config="optionsPickUp"
                                                autocomplete="off"
                                                class="form-control"
                                                :placeholder="staticMessages.pickup_date_place_holder"
                                                name="pickUpDate"
                                                @input="pickUpDateError=''"
                                                @dp-change="changePickupDate()"
                                        >
                                        </date-picker>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{pickUpDateError}}</span>
                                    </div>
                                </div>

                            <!-- Pickup Location -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.pickup_location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="pickupLocation" v-model="pickupLocation" class="form-control" type="text" maxlength="190" @input="pickupLocationError=''"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{pickupLocationError}}</span>
                                </div>
                            </div>

                            <!-- 
                                Task: AB#7097
                                Author: Bhumi Shah
                                Description: 
                                - Original Field: Drop Off Date
                                - Requested Field Label: Delivery Date
                                Reason: Changing Drop Off Date as Delivery Date Requested By James Gorel, Ryan Spence
                            -->
                            <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.delivery_date}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <date-picker
                                                v-model="dropOffDate"
                                                :config="optionsDropOff"
                                                autocomplete="off"
                                                class="form-control"
                                                :placeholder="staticMessages.delivery_date_place_holder"
                                                name="dropOffDate"
                                                @input="dropOffDateError=''"
                                                @dp-change="changeDropOffDate()"
                                        >
                                        </date-picker>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{dropOffDateError}}</span>
                                    </div>
                                </div>

                            <!-- Drop off Location -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.drop_off_location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="dropOffLocation" v-model="dropOffLocation" class="form-control" type="text" maxlength="190" @input="dropOffLocationError=''"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dropOffLocationError}}</span>
                                </div>
                            </div>

                            <!--
                                Field: Moved Submission Request Notify Email
                                Date: 11 March 2025
                                Author: Bhumi Shah
                                Change Reason: AB#7586 - New Trailer Request Based Change
                                Old Logic: Used Truck user uses Notify Email users field for request updates.
                                New Logic: New Trailer users no longer require the notify email field for updates; used truck workflow remains unchanged.
                            -->
                            <template v-if="formType == 'used_truck'">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.moved_submission_request_notify_email_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0 moved-email-block">
                                        <vue-tags-input
                                                v-model="movedSubmissionNotifyEmail"
                                                :tags="movedSubmissionNotifyEmailTagsArr"
                                                :validation="validation"
                                                @tags-changed="newTags => movedSubmissionNotifyEmailTagsArr = newTags"
                                                @before-adding-tag="checkEmailTag"
                                                placeholder=""
                                                @input="movedSubmissionNotifyError=''"
                                            />
                                        <span class="help is-danger">{{movedSubmissionNotifyError}}</span>
                                    </div>
                                </div>
                            </template>
                            <div class="col-6 forms-block"></div>

                            <template v-for="(input, index) in inputs">
                              <!--VIN No. -->
                              <div class="col-6 forms-block" :key='"VinNo" + index'>
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <v-select
                                              v-model="input.asset"
                                              name="asset"
                                              @input="input.assetError = ''"
                                              label="text"
                                              :filterable="false"
                                              :options="assetArr"
                                              @search="onSearchAsset"
                                              :clearable="false"
                                              :placeholder="staticMessages.asset_place_holder">
                              <span slot="no-options" @click="$refs.select.open = false">
                                  <template v-if="!isAssetRecordNotFound">
                                      {{staticMessages.asset_search}}
                                  </template>
                                  <template v-else>
                                      {{staticMessages.search_record_not_found}}
                                  </template>
                              </span>
                                      </v-select>
                                      <div class="requiredstar">*</div>
                                      <span class="help is-danger">{{input.assetError}}</span>
                                  </div>
                              </div>

                                <template v-if="formType == 'used_truck'">
                                    <div class="col-6 forms-block"></div>

                                    <!--
                                        Field: Selling Price
                                        Date: 11 March 2025
                                        Author: Bhumi Shah
                                        Change Reason: AB#7586 - New Trailer Request Based Change
                                        Old Logic: Used Truck users had an optional selling price field.
                                        New Logic: New Trailer users no longer have a selling price field; used truck workflow remains unchanged.
                                    -->
                                    <div class="col-6 forms-block" :key='"SellingPrice" + index'>
                                        <div class="col-3 paddingleft0">
                                        <div class="form-label">
                                            {{ staticInputLabelMessages.selling_price_label }}
                                        </div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                        <input
                                            @input="input.sellingPriceError = ''"
                                            id="sellingPrice"
                                            v-model="input.sellingPrice"
                                            class="form-control"
                                            type="text"
                                            maxlength="17"
                                        />
                                        <span class="help is-danger">{{input.sellingPriceError}}</span>
                                        </div>
                                    </div>
                                </template>

                              <template v-if="plusCount != 0">
                                <div class="col-6 forms-block" :key='"Delete" + index'>
                                    <button class="btn btn-danger" @click="deleteRow(index)"><i class="fa fa-times"></i></button>
                                </div>
                              </template>
                              <template v-else>
                                  <div :key="index" class="col-6 forms-block"></div>
                              </template>
                            </template>

                            <div class="col-12 forms-block">
                                <button class="btn btn-success" @click="checkInput"> <i class="fa fa-plus" aria-hidden="false"></i> </button>
                                <span class="pl-2">{{staticInputLabelMessages.click_to_add_more}}</span>
                            </div>

                            <div class="clearfix"></div>

                            <!--
                                Field: Approval or deposit check image (Attachment)
                                Date: 11 March 2025
                                Author: Bhumi Shah
                                Change Reason: AB#7586 - New Trailer Request Based Change
                                Old Logic: Used Truck users had an optional attachment field.
                                New Logic: New Trailer users no longer have a attachment field; used truck workflow remains unchanged.
                            -->
                            <div v-if="formType == 'used_truck'" class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.attach_approval_or_deposite_check_image}}</label>
                                    <vue-dropzone
                                            ref="dropzone"
                                            id="dropzone"
                                            :options="dropzoneOptions"
                                            @vdropzone-success = "uploadDocument"
                                            @vdropzone-removed-file="removedSelectedFile"
                                            @vdropzone-error="fileError"
                                            @vdropzone-file-added="fileAdd"
                                            @vdropzone-total-upload-progress="progressOfUload"
                                            :useCustomSlot=true
                                            :destroyDropzone="false"
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                            <div class="subtitle">(Select files from your computer.)</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display: none;">
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addUpdateUsedTruckHoldSubmissionBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUpdateUsedTruckHoldSubmission()">
                            <input v-if="permissionNameArray.includes('can_list_used_truck_moved_submission_form')" id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>


                    <!-- Forms -->
                </div>
            </div>
            <!--page content-->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!--</div>-->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage"
    import VueTagsInput from '@johmun/vue-tags-input';
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
            VueTagsInput
        },
        mixins: [commonMixins],
        data() {
            return {
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 50, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: "",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },

                imagesPaths: [],
                imagesType: [],
                imagesSize: [],

                staticMessages: "",
                staticInputLabelMessages: "",
                contentClass: "admin-page-content",
                inputs: [
                  {
                    asset: '',
                    assetError : '',

                    sellingPrice : '',
                    sellingPriceError : ''
                  }
                ],

                tempInputsObject : {
                  asset: '',
                  assetError : '',

                  sellingPrice : '',
                  sellingPriceError : ''
                },
                reason : "",
                reasonDefaultValue : "sold",
                reasonError : "",
                fromLocationId : "",
                fromLocationArr : [],
                fromLocationError : "",
                toLocationId : "",
                toLocationArr : [],
                isTruckReadyForDelivery : "",
                isTruckReadyForDeliveryDefaultValue : "no",

                commonError : "",

                asset : "",
                assetArr : [],
                userId : "",
                userArr : [],
                userError : "",
                isAssetRecordNotFound: false,
                isUserRecordNotFound : false,
                isFromLocationNotFound : false,

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },
                estimateDeliveryDate : "",
                estimateDateDeliveryDateError : "",

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                maxInputLength : 2,
                userArrayLength : 0,
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Used Truck Move Submission Form",
                newTrailerPageTitle: "Add New Trailer Move Submission Form",
                isTruckReadyForDeliveryOptions:[
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                reasonOptions:[
                    {text: 'Sold', value: 'sold'},
                    {text: 'Docs', value: 'docs'},
                    {text: 'Inventory Stock', value: 'inventory stock'},
                    {text: 'Shop', value: 'shop'},
                    {text: 'OSD', value: 'osd'},
                    {text: 'New Purchase', value: 'new purchase'},
                ],
                isOSDSelected : false,
                transferInAccountingArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                destPhoneError: "",
                destEmailError: "",
                pickupPhoneError: "",
                pickupEmailError: "",
                permissionNameArray : ['can_add_used_truck_moved_submission_form'],

                typeOfRequestId : "",
                typeOfRequestIdError : "",
                typeOfRequestIdArr : [
                  {text: 'OSD', value: 'osd'},
                  {text: 'Store to Store', value: 'store_to_store'},
                  {text: 'Vendor to Store', value: 'vendor_to_store'}
                ],
                locationOfUnit : "",
                locationOfUnitError : "",
                locationOfUnitArr : [],
                salesManagerId : "",
                salesManagerError : "",
                salesManagerArr : [],
                isUnitReadyForPickup : "",
                isUnitReadyForPickupError : "",
                isUnitReadyForPickupOptions : [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                descriptionOfReq : "",
                pickUpDate : "",
                pickUpDateError : "",
                pickupLocation : "",
                pickupLocationError : "",
                dropOffDate : "",
                dropOffDateError : "",
                dropOffLocation : "",
                dropOffLocationError : "",
                isSalesManagerRecordNotFound : false,
                optionsPickUp: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                    maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1000)),
                },
                optionsDropOff: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                    minDate: new Date(),
                },
                selectedVinArr : [],
                movedSubmissionNotifyEmail : '',
                movedSubmissionNotifyError : '',
                movedSubmissionNotifyEmailTagsArr: [],
                validation: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                plusCount : 0,
                formType: ''
            }
        },
        beforeMount() {
            self = this;

            const section = self.$route.path.split('/')[1];
            self.formType = (section === 'newtrailermovesubmission') ? 'new_trailer' : 'used_truck';
        },
        watch : {
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.selectDefaultUser();
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('used_truck_move_submission_form');
            EventBus.$emit("menu-selection-changed", "used_truck_move_submission_form");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            
            self.loadAllData(['User', 'Location'], true, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            checkEmailTag: function(obj) {
              self.movedSubmissionNotifyError = "";
              var tagEmail = JSON.stringify(obj.tag.text).split('"');
              if(!self.validEmail(tagEmail[1])) {
                  self.movedSubmissionNotifyError = "Please enter valid Email";
              } else obj.addTag();
            },
            changePickupDate : function(e) {
                self.optionsDropOff.minDate = self.pickUpDate;
            },
            changeDropOffDate : function(e) {
                self.optionsPickUp.maxDate = self.dropOffDate;
            },
            loadMasterDataCallback: function (callbackData) {
                self.isSalesManagerRecordNotFound = false;
                self.salesManagerArr = callbackData.User;
                if (self.salesManagerArr.length == 0) {
                  self.isSalesManagerRecordNotFound = true;
                }

                self.locationOfUnitArr = callbackData.Location;
            },
            onSearchSalesManager : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalesManagerTypeCallback, loading, search);
                }
            },
            loadSalesManagerTypeCallback: function (callbackData) {
                self.isSalesManagerRecordNotFound = false;
                self.salesManagerArr = callbackData.User;
                if (self.salesManagerArr.length == 0) {
                    self.isSalesManagerRecordNotFound = true;
                }
            },
            changeEstimateDeliveryDateTime: function () {
                self.estimateDateDeliveryDateError = "";
            },
            removeAllFiles() {
                // self.$refs.dropzone.removeAllFiles();
            },
            removeByAttr : function(arr, attr, value){
                var i = arr.length;
                while(i--){
                    if( arr[i]
                        && arr[i].hasOwnProperty(attr)
                        && (arguments.length > 2 && arr[i][attr] === value ) ){

                        arr.splice(i,1);

                    }
                }
                return arr;
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.imagesPaths.push(response.content.file_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                // this.$refs.dropzone.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
                // this.$refs.dropzone.setOption('maxFiles', null);
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                var removeFile = "";
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    // this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                // this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                //console.log(filePath);return false;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removedocument', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
            },
            progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.cancel = false;
                    self.disableButtons.saveAndContinue = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.cancel = true;
                    self.disableButtons.saveAndContinue = true;
                }
            },
            selectDefaultUser : function () {
                let userObj = JSON.parse(localStorage.getItem("user_obj"));
                let firstName = "";
                let lastName = "";
                let userId = 0;
                let fullName = "";
                if (userObj != null && userObj != "" && typeof userObj != "undefined") {
                    firstName = userObj['first_name'];
                    lastName = userObj['last_name'];
                    userId = userObj['id'];
                    fullName = firstName + " " + lastName;

                    self.userId = {text : fullName , value : userId};
                }
            },
            deleteRow(index) {
                self.plusCount = self.plusCount + 1;
                this.inputs.splice(index,1);
                self.resetSelectedAsset();
            },
            resetSelectedAsset(flag) {
                self.selectedVinArr = [];
                var maxAssetCount = 0;
                if(flag == 1) {
                  maxAssetCount = self.inputs.length-1;
                } else {
                  maxAssetCount = self.inputs.length;
                }

                for (let i = 0; i < maxAssetCount; i++) {
                  if(self.inputs[i].asset != "" || typeof self.inputs[i].asset === "object" ) {
                      if (self.inputs[i].asset.value != "" || self.inputs[i].asset.value != null || typeof self.inputs[i].asset.value !== "undefined") {
                          self.selectedVinArr.push(self.inputs[i].asset.value);
                      }
                  }
                }
            },
            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search , 'undefined');
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            onSearchUser : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadUserTypeCallback, loading, search);
                }
            },
            loadUserTypeCallback: function (callbackData) {
                self.isUserRecordNotFound = false;
                self.userArr = callbackData.User;
                if (self.userArr.length == 0) {
                    self.isUserRecordNotFound = true;
                }
            },
            returnData: function () {
                var emailData = "";
                if(self.movedSubmissionNotifyEmailTagsArr && self.movedSubmissionNotifyEmailTagsArr.length > 0) {
                  for(var index = 0;index<self.movedSubmissionNotifyEmailTagsArr.length;index++) {
                    if(!emailData.includes(self.movedSubmissionNotifyEmailTagsArr[index].text)) {
                      var strLowerCase = self.movedSubmissionNotifyEmailTagsArr[index].text.toLowerCase();
                      emailData += strLowerCase + ',';
                    }
                  }
                }
                emailData = emailData.replace(/,\s*$/, "");

                var data = {
                    user : self.userId,
                    type_of_request_id : self.typeOfRequestId.value,
                    location_of_unit_id : self.locationOfUnit.value,
                    inputs : self.inputs,
                    send_to_manager_id: self.salesManagerId.value,
                    is_ready_for_pickup: self.isUnitReadyForPickup,
                    description_of_request: self.descriptionOfReq,
                    pick_up_date: self.apiRequestDateFormat(self.pickUpDate),
                    pick_up_location : self.pickupLocation,
                    drop_off_date : self.apiRequestDateFormat(self.dropOffDate),
                    drop_off_location : self.dropOffLocation,
                    imagePath: self.imagesPaths,
                    imageSize: self.imagesSize,
                    imageType: self.imagesType,
                    to_email : emailData,
                    formType : self.formType
                };

                return data;
            },

            changedValue: function (value) {
                /*console.log(value);*/
            },
            buttonBack() {
                self.$router.push({name: 'ListUsedTruckMovedSubmission'});
            },
            clearDataOnSaveAndContinue: function () {
                self.selectDefaultUser();

                for (let i = (self.inputs.length - 1); i >= 0; i--) {
                    self.deleteRow(i);
                }
                self.imagesPaths = [];
                self.imagesType = [];
                self.imagesSize = [];
                self.removeAllFiles();
                self.inputs = [
                  {
                    asset: '',
                    assetError : '',

                    sellingPrice : '',
                    sellingPriceError : ''
                  }
                ];
                self.movedSubmissionNotifyEmailTagsArr = [];
                self.plusCount = 0;

                self.userId = '';
                self.typeOfRequestId = '';
                self.locationOfUnit = '';
                self.salesManagerId = '';
                self.isUnitReadyForPickup = '';
                self.descriptionOfReq = '';
                self.pickUpDate = '';
                self.pickupLocation = '';
                self.dropOffDate = '';
                self.dropOffLocation = '';
                self.movedSubmissionNotifyEmail = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },


            checkInput: function () {
                let checked = true;
                 
                for (let i = 0; i < self.inputs.length; i++) {
                    self.inputs[i].assetError = "";
                    self.inputs[i].sellingPriceError = "";

                    if (self.inputs[i].asset.value == "" || self.inputs[i].asset.value == null || typeof self.inputs[i].asset.value == "undefined") {
                        self.inputs[i].assetError = "Please select VIN No.";
                        if (checked) {
                            checked = false;
                        }
                    } else if(i > 0 && self.inputs[i].asset.value && self.selectedVinArr.includes(self.inputs[self.inputs.length - 1].asset.value)) {
                        self.inputs[i].assetError = "Selected inventory is already available";
                        if (checked) {
                            checked = false;
                        }
                    }

                    // Enable "Selling Price" Validation for Used Truck users only
                    if(self.formType == 'used_truck') {
                        if (self.inputs[i].sellingPrice && !self.numberFormateValidation(self.inputs[i].sellingPrice)) {
                            self.inputs[i].sellingPriceError = "Please enter numeric value for selling price";
                            if (checked) {
                                checked = false;
                            }
                        } else if (self.inputs[i].sellingPrice && self.numberFormateValidation(self.inputs[i].sellingPrice) && self.inputs[i].sellingPrice <= 0) {
                            self.inputs[i].sellingPriceError = "Please enter valid selling price";
                            if (checked) {
                                checked = false;
                            }
                        }
                    }
                }

                if(checked) {
                  self.plusCount = self.plusCount - 1;
                  self.inputs.push(Vue.util.extend({}, self.tempInputsObject));
                }
                return checked;
            },
            checkForm: function () {
                let checked = true;
                self.userError = '';
                self.typeOfRequestIdError = '';
                self.locationOfUnitError = '';
                self.salesManagerError = '';
                self.isUnitReadyForPickupError = '';
                self.pickUpDateError = '';
                self.pickupLocationError = '';
                self.dropOffDateError = '';
                self.dropOffLocationError = '';
                self.movedSubmissionNotifyError = '';

                for (let i = 0; i < self.inputs.length; i++) {
                    self.inputs[i].assetError = "";
                    self.inputs[i].sellingPriceError = "";

                    if (self.inputs[i].asset.value == "" || self.inputs[i].asset.value == null || typeof self.inputs[i].asset.value == "undefined") {
                        self.inputs[i].assetError = "Please select VIN No.";
                        if (checked) {
                            checked = false;
                        }
                    } else if(i > 0 && self.inputs[i].asset.value && self.selectedVinArr.includes(self.inputs[self.inputs.length - 1].asset.value)) {
                        self.inputs[i].assetError = "Selected inventory is already available";
                        if (checked) {
                            checked = false;
                        }
                    }

                    // Enable "Selling Price" Validation for Used Truck users only
                    if(self.formType == 'used_truck') { 
                        if (self.inputs[i].sellingPrice && !self.numberFormateValidation(self.inputs[i].sellingPrice)) {
                            self.inputs[i].sellingPriceError = "Please enter numeric value for selling price";
                            if (checked) {
                                checked = false;
                            }
                        } else if (self.inputs[i].sellingPrice && self.numberFormateValidation(self.inputs[i].sellingPrice) && self.inputs[i].sellingPrice <= 0) {
                            self.inputs[i].sellingPriceError = "Please enter valid selling price";
                            if (checked) {
                                checked = false;
                            }
                        }
                    }
                }

                if (self.userId == null || !self.userId.value) {
                    self.userError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.typeOfRequestId == null || !self.typeOfRequestId.value) {
                    self.typeOfRequestIdError = "Please select Type of Request";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.locationOfUnit == null || !self.locationOfUnit.value) {
                    self.locationOfUnitError = "Please select Location of Unit";
                    if (checked) {
                        checked = false;
                    }
                }

                // Enable "Send to manager1" & "Notify email" Validation for Used Truck users only
                if(self.formType == 'used_truck') {
                    if (self.salesManagerId == null || !self.salesManagerId.value) {
                        self.salesManagerError = "Please select Send to Manager1";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if(self.movedSubmissionNotifyEmail && !self.validEmail(self.movedSubmissionNotifyEmail)) {
                        self.movedSubmissionNotifyError = "Please enter valid Email";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (!self.isUnitReadyForPickup) {
                  self.isUnitReadyForPickupError = "Please select Is Unit Ready For Pick Up";
                  if (checked) {
                    checked = false;
                  }
                }
                if (!self.pickUpDate) {
                  self.pickUpDateError = "Please select Pick up Date";
                  if (checked) {
                    checked = false;
                  }
                }
                if (!self.pickupLocation) {
                  self.pickupLocationError = "Please select Pick up Location";
                  if (checked) {
                    checked = false;
                  }
                }
                if (!self.dropOffDate) {
                  self.dropOffDateError = "Please select Delivery Date";
                  if (checked) {
                    checked = false;
                  }
                }
                if (!self.dropOffLocation) {
                  self.dropOffLocationError = "Please select Drop off Location";
                  if (checked) {
                    checked = false;
                  }
                }
                
                return checked;
            },
            addUpdateUsedTruckHoldSubmission(flag) {
                setTimeout(() => {
                    self.resetSelectedAsset(1);
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/usedtruckmovedsubmissionform/add/update/vtc", data, config)
                            .then(function (response) {
                                if (response.data.code == '201') {
                                    self.buttonNames.save = "Save";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    return self.$swal({
                                            title: '',
                                            text: response.data.content,
                                            type: 'info',
                                            confirmButtonText: "OK",
                                            showCancelButton: false,
                                            showLoaderOnConfirm: true,
                                            backdrop: 'static',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false
                                        }).then((result) => {
                                            if (result.value) {
                                                self.buttonBack();
                                            }
                                        });
                                }
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {

                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        self.clearDataOnSaveAndContinue();
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            if (self.permissionNameArray.includes('can_list_used_truck_moved_submission_form')) {
                                                self.buttonBack();
                                            }
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>

<style scoped>
.moved-email-block .vue-tags-input {
  max-width: initial;
}
</style>
