<template>
  <div>
    <div
      class="modal"
      v-bind:style="{ zIndex: parentModalShowHide }"
      id="defaultModal"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-centered vhc-cost-modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="defaultModalLabel">
              Vehicle Cost Sheet
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click.prevent="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body vhc-cost-modal-body">
            <div class="vhc-cost-custom-row">
              <div class="vhc-cost-custom-column">
                <div class="vhc-cost-fm-group">
                  <label for="unitId">Stock#</label>
                  <input type="text" class="modal-input" v-model="unitId" />
                </div>
                <div class="vhc-cost-fm-group">
                  <label for="calculatedTotal">Calculated Total</label>
                  <money
                    v-bind="moneyMask"
                    type="text"
                    class="modal-input"
                    v-model="calculateTotal"
                    disabled
                  />
                </div>
                <div id="btn-chkbox-gp">
                  <button
                    class="vhc-cost-modal-btn"
                    v-on:click="loadTotalInVehicleWorksheet()"
                  >
                    Load Cost in Deal
                  </button>
                  <div>
                    <input type="checkbox" />
                    <label for="post-detail">Only Posted Detail</label>
                  </div>
                </div>
              </div>
            </div>

            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    v-on:click="activetab = 1"
                    v-bind:class="
                      'nav-link ' + [activetab === 1 ? 'active' : '']
                    ">Cost Details</a>
                </li>
                <li class="nav-item">
                  <a
                    v-on:click="activetab = 2"
                    v-bind:class="
                      'nav-link ' + [activetab === 2 ? 'active' : '']
                    ">GL Details</a>
                </li>
            </ul>
            <div
              v-if="activetab === 1"
              class="tabcontent"
              id="tab1"
            >
              <vehicle-pos v-bind:assetWiseExpensesArr="assetWiseExpensesArr" v-bind:allStaticMessages="allStaticMessages" />
              <vehicle-ros v-bind:repairOrderArr="repairOrderArr" v-bind:allStaticMessages="allStaticMessages" />
              <estimates v-bind:estimateArr="estimateArr" v-bind:allStaticMessages="allStaticMessages" />
              <history v-bind:historyArr="historyArr" v-bind:allStaticMessages="allStaticMessages" />
            </div>
            <div
              v-if="activetab === 2"
              class="tabcontent"
              id="tab2"
            >
              <gl-details v-bind:assetId="assetId" v-bind:vin="vin" v-bind:unitId="unitId" />
            </div>

            
            
          </div>
        </div>
      </div>
    </div>
    <div v-show="isClickRo">
        <ro-detail></ro-detail>
    </div>
    <div v-show="isClickPo">
        <po-detail></po-detail>
    </div>
    <div v-show="isClickEstimate">
        <estimate-detail></estimate-detail>
    </div>
  </div>
</template>

<script>
let self;
import { commonMixins } from "../../../../mixins/commonMixins";
import EventBus from "../../../event-bus";
import { HTTP } from "../../../../http-common";
import NoAccessPage from "../../../NoAccessPage/NoAccessPage";
import VehicleRos from "./CostSheet/VehicleROSModal.vue";
import VehiclePos from "./CostSheet/VehiclePOSModal.vue";
import Estimates from "./CostSheet/EstimateModal.vue";
import History from "./CostSheet/HistoryModal.vue";
import GLDetails from "./CostSheet/GLDetails.vue";
import { Money } from "v-money";
  import PoDetailModal from './CostSheet/Detail/PoDetailModal.vue';
  import RoDetailModal from './CostSheet/Detail/RoDetailModal.vue';
  import EstimateDetailModal from './CostSheet/Detail/EstimateDetailModal.vue';

export default {
  name: "VehicleWorkSheet",
  components: {
    "no-access": NoAccessPage,
    "vehicle-ros": VehicleRos,
    "vehicle-pos": VehiclePos,
    "estimates": Estimates,
    "history": History,
    'po-detail': PoDetailModal,
    'ro-detail': RoDetailModal,
    'estimate-detail': EstimateDetailModal,
    Money,
    'gl-details' : GLDetails,
  },
  mixins: [commonMixins],
  props: ["assetId", "vin", "isClickCostSheet", "unitId", "dealCostId", "dealInvMapperId"],
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      repairOrderArr: [],
      assetWiseExpensesArr: [],
      totalRoAmount: "",
      totalPoAmount: "",
      isShowVehicleCostModal: true,
      estimateArr: [],
      historyArr: [],
      calculateTotal: this.totalPoAmount,
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        precision: 2,
      },
      isClickPo: false,
      isClickRo: false,
      isClickEstimate: false,
      parentModalShowHide: 1042,
      selectedPOIds: "",
      selectedROIds: "",
      activetab: 1,
    };
  },
  beforeMount() {
    self = this;
  },
  methods: {
    sumRoAndPo: function () {
      let total = parseFloat(self.totalPoAmount + self.totalRoAmount).toFixed(
        2
      );
      EventBus.$emit(
        "grand-total-cost-sheet",
        total,
        self.isShowVehicleCostModal
      );
    },
    getData: async function () {
      this.loadRepairOrders();
      this.loadAssetExpenses();
      /*#### to-do only after confirm, not required for now ####
      this.sumRoAndPo();
      */
    },
    closeModal() {
      EventBus.$emit("close-vhc-cost-sheet");
    },
    addSubCalulateTotal(status, value) {
      let amount = value.replace("$", "").replace(",", "");
      amount = parseFloat(amount);
      const total = parseFloat(this.calculateTotal);
      if (status) {
        this.calculateTotal = parseFloat(total + amount).toFixed(2);
      } else {
        this.calculateTotal = parseFloat(total - amount).toFixed(2);
      }
      return true;
    },
    showDetails(data, type) {
      switch (type) {
        case "ro":
          self.isClickRo = type;
          break;
        case "po":
          self.isClickPo = type;
          break;
        case "estimate":
          self.isClickEstimate = type;
          break;
      }
      const modalElement = document.getElementById(type + "DetailModal");
      const modalInstance = new Modal(modalElement, { backdrop: "static", keyboard: false });
      modalInstance.show();
    },
    loadTotalInVehicleWorksheet() {
       // Collect selected POs
    const selectedPOs = this.assetWiseExpensesArr.filter(item => item.isSelected);
    
    // Collect selected ROs
    const selectedROs = this.repairOrderArr.filter(item => item.isSelected);
    
    // Prepare the data to be sent to the API
    const selectedPOIds = selectedPOs.map(item => item.id);
    const selectedROIds = selectedROs.map(item => item.id);

    // // Emit the load cost sheet event with the selected PO and RO IDs
    // EventBus.$emit("load-cost-sheet", this.calculateTotal, selectedPOIds, selectedROIds);
    
    // Call the storeCostSheet API if required
    this.storeCostSheet(selectedPOIds, selectedROIds);
  },

    async storeCostSheet(selectedPOIds, selectedROIds) {
      try {
          const response = await HTTP.post('/cost/sheet/store', {
              poIds: selectedPOIds, 
              roIds: selectedROIds,
              deal_id: self.dealCostId, 
              deal_inv_mapper_id: self.dealInvMapperId,
          }, {
              headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('api_token'),
              },
          });

          if (response.status === 200) {
              self.showSAlert(response.data.message, "success", false);
          } else {
            self.showSAlert(response.data.message, "error", false);
          }
      } catch (err) {
        self.catchHandler(err, function () {});
      }
      EventBus.$emit("load-cost-sheet", this.calculateTotal);
    },
    loadRepairOrders: async function () {
      self.pageTitle = self.allLabelMessages.repair_orders_label;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };

      let fields = {
        asset_id: self.assetId,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: "id",
        sortType: "desc",
        vin: self.vin,
      };
      return new Promise(function (resolve, reject) {
        HTTP.post("/repairorder/list", fields, config)
          .then(function (data) {
            if (data.status == 200) {
              self.repairOrderArr = data.data.content.data.rod;
              self.estimateArr = data.data.content.data.est;
              self.totalRoAmount = data.data.totalRoAmount;
              if (
                self.totalRoAmount == null ||
                typeof self.totalRoAmount === "undefined"
              ) {
                self.totalRoAmount = 0;
              }
              resolve(data);
            }
          })
          .catch(function (error) {
            self.listExecutionState = false;
            console.log("repair error!");
            self.hideLoader();
            self.catchHandler(error, function () {});
            reject(error);
          });
      });
    },
    loadAssetExpenses: async function () {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      let fields = {
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: "id",
        sortType: "desc",
        asset_id: self.assetId,
      };

      return new Promise(function (resolve, reject) {
        HTTP.post("/assetwiseexpenses/list", fields, config)
          .then(function (data) {
            if (data.status == 200) {
              self.assetWiseExpensesArr = data.data.content.data;
              self.totalPoAmount = data.data.totalPoAmount;
              if (
                self.totalPoAmount == null ||
                typeof self.totalPoAmount === "undefined"
              ) {
                self.totalPoAmount = 0;
              }
              resolve(data);
            }
          })
          .catch(function (error) {
            self.listExecutionState = false;
            self.catchHandler(error, function () {});
            reject(error);
          });
      });
    },
  },
  computed: {

  },
  watch: {
    totalRoAmount(newVal) {
      this.calculateTotal = this.totalPoAmount + newVal;
    },
    totalPoAmount(newVal) {
      this.calculateTotal = this.totalRoAmount + newVal;
    },
    isClickCostSheet: function (currentStatus) {
      if (currentStatus) {
        // this.calculateTotal = 0.0;
        this.getData();
      } else if (!currentStatus) {
        this.closeModal();
      }
    },
  },
};

EventBus.$on("add-subtract-total", (status, value) => {
  if (typeof self != "undefined") {
    self.addSubCalulateTotal(status, value);
  }
});

EventBus.$on("show-details", (value, type) => {
  if (typeof self != "undefined") {
    self.parentModalShowHide = 1040;
    self.showDetails(value, type);
  }
});

EventBus.$on("close-details", function (status, type) {
  if (typeof self != "undefined") {
    switch (type) {
      case "ro":
        self.isClickRo = status;
        break;
      case "po":
        self.isClickPo = status;
        break;
      case "estimate":
        self.isClickEstimate = status;
        break;
    }
    self.parentModalShowHide = 1042;
     const modalElement = document.getElementById(type + "DetailModal");
      const modalInstance = new Modal(modalElement, { backdrop: "static", keyboard: false });
      modalInstance.hide();
  }
});
</script>

<style scoped>
.vhc-cost-modal-dialog {
  overflow-y: initial !important;
}
.vhc-cost-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.vhc-cost-custom-row {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 14px;
}
.vhc-cost-custom-column {
  display: grid;
  grid-template-columns: 3fr 3fr 7fr;
  grid-column-gap: 80px;
}

#btn-chkbox-gp {
  display: flex;
  flex-direction: row;
  align-items: end;
  column-gap: 30px;
}

#btn-chkbox-gp > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.vhc-cost-modal-btn {
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  height: 30px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  background: linear-gradient(174deg, #076eb0 0%, rgba(7, 110, 176, 0.57) 100%);
  color: #fff;
  text-align: center;
  font-family: "SF UI Display", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
}

.vhc-cost-fm-group {
  display: flex;
  flex-direction: column;
}

.vhc-cost-fm-group-checkbox {
  margin-top: 9px;
}

.vhc-cost-fm-group-checkbox > div {
  padding: 2px;
}

.input-text {
  color: #242731;
  text-align: right;
  font-family: SF UI Display, Arial, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nav-tabs {
  margin-bottom: 10px;
}
.tabcontent > .custom-row {
  margin-bottom: 10px;
}

label[for="post-detail"] {
  margin-bottom: unset;
}

.vhc-cost-modal-btn:hover {
  background: linear-gradient(174deg, #012a44 0%, rgb(13 137 216 / 57%) 100%);
}

.modal-input {
  text-align: right;
}
</style>
