<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_settings')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>{{ allLabelMessages.settings }}</span>
                            <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
                        </div>
                        <a
                            id="refreshList"
                            title="Refresh"
                            class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                            v-on:click.prevent="refreshList()">
                            <i class="fa fa-undo" aria-hidden="true"></i>
                        </a>
                    </div>
                    <filter-component
                        :key="filterCount"
                        :filterCounts="filterCount"
                        :parentHeaders="headers"
                        @applyFilterParentComponent="applyFilterParentComponent"
                        @resetSorting="resetSorting">
                    </filter-component>
                    <div class="col-12 paddingnone">
                        <common-loader refId="commonLoaderListSetting" ref="commonLoaderListSetting"></common-loader>
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select
                                        title="Select Per Page"
                                        v-model="perPage"
                                        @input="changePerPage"
                                        name="customPerPage"
                                        id="customPerPage"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="paginationDropDownArr"
                                        placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                    {{ startEndRecord }} of {{ totalRecords }}
                                    </p>
                                </div>
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination
                                        v-if="totalRecords > 0"
                                        align="center"
                                        :limit="5"
                                        prev-text="‹ Previous"
                                        next-text="Next ›"
                                        :total-rows="totalRecords"
                                        v-model="currentPage"
                                        @input="changepage()"
                                        :per-page="perPageValue">
                                    </b-pagination>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page :</p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input
                                        v-model="jumpToPage"
                                        type="text"
                                        class="form-control form-control-sm"
                                        name="jumpToPage"
                                        id="jumpToPage"
                                        v-on:keyup.enter="keyMonitor()"
                                        placeholder="Page"/>
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                    <template v-if="settingArr && settingArr.length > 0">
                                        <template v-for="(setting, index) in settingArr">
                                            <tr :key="index">
                                                <td v-show="actionDisplay">
                                                    <div class="actions">
                                                        <router-link v-show="permissionNameArray.includes('can_edit_settings')"
                                                                    class="mar-right-0"
                                                                    title="Edit Setting"
                                                                    v-bind:to="{
                                                                    name: 'UpdateSetting',
                                                                    params: { id: setting.id },
                                                                    }">
                                                            <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                        </router-link>
                                                    </div>
                                                </td>
                                                <td :class="rowClass.name">{{ setting.name }}</td>
                                                <td :class="rowClass.value">{{ setting.value }}</td>
                                                <td :class="rowClass.updated_at">{{ setting.updated_at }}</td>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                                {{ allStaticMessages.data_not_available }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <thead>
                                    <tr>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">
                                                {{ data.name }}
                                            </th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">
                                                {{ data.name }}
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select
                                        title="Select Per Page"
                                        v-model="perPage"
                                        @input="changePerPage"
                                        name="customPerPage"
                                        id="customPerPage"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="paginationDropDownArr"
                                        placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                    {{ startEndRecord }} of {{ totalRecords }}
                                    </p>
                                </div>
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination
                                        v-if="totalRecords > 0"
                                        align="center"
                                        :limit="5"
                                        prev-text="‹ Previous"
                                        next-text="Next ›"
                                        :total-rows="totalRecords"
                                        v-model="currentPage"
                                        @input="changepage()"
                                        :per-page="perPageValue">
                                    </b-pagination>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page :</p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input
                                    v-model="jumpToPage"
                                    type="text"
                                    class="form-control form-control-sm"
                                    name="jumpToPage"
                                    id="jumpToPage"
                                    v-on:keyup.enter="keyMonitor()"
                                    placeholder="Page"/>
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>
  
<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
//import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
//Vue.component("v-select", vSelect);

export default {
    name: "ListSetting",
    mixins: [commonMixins],
    components: {
        "filter-component": FilterComponent,
        "no-access": NoAccessPage,
        "common-loader": CommonLoader,
    },
    data() {
        return {
            perPageValue: 25,
            perPage: 25,
            currentPage: 1,
            totalRecords: 0,
            paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
            settingArr: [],
            headers: [],
            filters: {},
            appliedHeaders: [],
            jumpToPage: 1,
            columnName: filterColumn,
            sortType: filterType,
            startEndRecord: "",
            permissionNameArray: ["can_list_settings"],
            actionDisplay: true,
            jumpToHistoryPage: 1,
            filterCount: 0,
        };
    },
    beforeMount() {
        self = this;
    },
    mounted: function () {
        /* Redirect to login page logic start */
        var userObj = localStorage.getItem("user_obj");
        if (userObj == null) {
        self.$router.push("/");
        }
        /* Redirect to login page logic end */

        self.setActiveMenu("setting-list");
        EventBus.$emit("menu-selection-changed", "setting-list");

        var listHistoryData = self.loadFilters("listSetting", self);
        self.jumpToHistoryPage = listHistoryData === null || typeof listHistoryData === "undefined" || listHistoryData == "" ? 1 : listHistoryData.page;

        let headersNames = [];
        headersNames["filters"] = self.filters;
        self.listExecutionState = false;
        self.loadSettings(1);
        self.getLoginIdPermissions();
    },
    methods: {
        resetSorting: function () {
            self.columnName = filterColumn;
            self.sortType = filterType;
            self.changepage();
        },
        changePerPage: function () {
            self.currentPage = 1;
            if (self.perPage !== "All") {
                self.perPageValue = self.perPage;
            }
            self.loadSettings();
        },
        changepage: function () {
            if (!self.listExecutionState) {
                self.loadSettings();
            }
        },
        refreshList: function () {
            self.jumpToPage = 1;
            self.keyMonitor();
            self.loadSettings();
        },
        keyMonitor: function () {
            var tempNumber = self.jumpToInputPage(
                self.jumpToPage,
                self.totalRecords,
                self.perPage
            );
            self.currentPage = self.jumpToPage = parseInt(tempNumber);
        },
        applyFilterParentComponent: function (data) {
            let key;
            let value;
            for (let i = 0; i < data.length; i++) {
                if (data[i]["column_name"] != "null") {
                    key = data[i]["column_name"];
                    value = data[i]["field"];
                    self.filters[key] = value;
                }
            }
            self.appliedHeaders = data;
            self.loadSettings();
        },
        setAlreadyAppliedFilters: function () {
            if (self.appliedHeaders.length > 0) {
                self.headers = self.appliedHeaders;
            }
        },
        loadSettings: function (first) {
            self.listExecutionState = true;
            if (self.perPage == "All" && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA) {
                self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, "info", false);
                return false;
            }
            first = first === null || typeof first === "undefined" || first == "" ? 0 : first;
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token"),
                }
            };
            var fields = {
                filters: self.filters,
                per_page: self.perPage,
                page: self.currentPage,
                sortColumnName: self.columnName,
                sortType: self.sortType,
            };
            this.storeFilters("listSetting", fields, false);
            $("#commonLoaderListSetting").find(".loading-container.lodingTable").show();
            self.filterCount = self.filterCountFunction(self.filters);
            HTTP.post("/setting/list", fields, config)
                .then(function (data) {
                    self.listExecutionState = false;
                    if (first) {
                        self.currentPage = self.jumpToHistoryPage;
                    }
                    $("#commonLoaderListSetting").find(".loading-container.lodingTable").hide();
                    if (data.status == 200) {
                        self.headers = [];
                        self.totalRecords = data.data.content.result;
                        self.settingArr = data.data.content.data;
                        self.headers = data.data.content.headers;
                        self.loadFilterInputs(data.data.content.headers, self.filters);

                        self.addColumnClass(self.headers);
                        if (data.data.content.per_page !== self.totalRecords) {
                            self.perPage = parseInt(data.data.content.per_page);
                            self.jumpToPage = parseInt(data.data.content.current_page);
                        } else {
                            if (self.perPage == "All") {
                                self.jumpToPage = 1;
                            }
                            self.perPageValue = self.totalRecords;
                            self.currentPage = 1;
                        }
                        self.setAlreadyAppliedFilters();
                        self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                        self.sortFieldDisplay(self.columnName, self.sortType);
                    }
                })
                .catch(function (error) {
                    self.listExecutionState = false;
                    $("#commonLoaderListSetting").find(".loading-container.lodingTable").hide();
                    self.catchHandler(error, function () {});
                });
        },
        sortTableData: function (name, isSortAllowed) {
            if (isSortAllowed == "nosort") {
                if (self.columnName == name) {
                self.sortType = self.sortType == "desc" ? "asc" : "desc";
                } else {
                self.sortType = "asc";
                }
                self.columnName = name;
                self.loadSettings();
            }
        }
    }
};
EventBus.$on("login-permissions", function (permissions) {
    if (typeof self != "undefined") {
        self.permissionNameArray = [];
        for (let i = 0; i < permissions.length; i++) {
            self.permissionNameArray.push(permissions[i].permission_name);
        }
        if (!self.permissionNameArray.includes("can_edit_settings")) {
            self.headers.pop();
            self.actionDisplay = false;
        }
    }
});
</script>  