<template>
  <div>
    <div class="vdc-filters-container">
      <div v-on:click="toggleFilterBlock()" class="vdc-filters-block">
        <span>{{ allLabelMessages.filters }}</span>
        <span
          class="badge badge-primary badge-pill bg-color-098de1"
          v-if="filterCount > 0"
          >{{ filterCount }} Filter<template v-if="filterCount > 1">s</template>
          applied</span
        >
        <button>
          <img src="/static/images/filter_icon.png" />
        </button>
      </div>
      <div v-show="isOpenFilterBlock" class="vdc-filters-area vtc-dealer-forms">
        
        <div v-for="(row, rowIndex) in getChunkedData(parentHeaders, 6)" :key="rowIndex" class="row">
        <div v-for="(data, index) in row" :key="index" class="col-2 pr-0">
          
          <div
            v-if="
              data.name != 'Action' &&
              data.filter_able != 'no' &&
              (typeof data.display == 'undefined' || data.display == true)
            "
            :key="index + 'field'"
            class="vdc-filters-area-block"
          >
            <label>{{ data.name == "Cus Id" ? "CusID" : data.name }}</label>
            <template v-if="data.type == 'odometer_range'">
              <div class="col-6 paddingleft0">
                <input
                  class="form-control"
                  placeholder="From"
                  v-model="fromOdometer"
                  name="fromOdometer"
                  id="fromOdometer"
                  type="number"
                  @blur="validateOdometerRange(data)"
                />
              </div>
              <div class="col-6 paddingleft0">
                <input
                  class="form-control"
                  placeholder="To"
                  v-model="toOdometer"
                  name="toOdometer"
                  id="toOdometer"
                  type="number"
                  @blur="validateOdometerRange(data)"
                />
              </div>
              <span class="help is-danger">{{ odometerFilterError }}</span>
            </template>
            <template v-else-if="data.type == 'price_range'">
              <div class="col-6 paddingleft0">
                <input
                  class="form-control"
                  placeholder="From"
                  v-model="fromPrice"
                  name="fromPrice"
                  id="fromPrice"
                  type="number"
                  @blur="validatePriceRange(data)"
                />
              </div>
              <div class="col-6 paddingleft0">
                <input
                  class="form-control"
                  placeholder="To"
                  v-model="toPrice"
                  name="toPrice"
                  id="toPrice"
                  type="number"
                  @blur="validatePriceRange(data)"
                />
              </div>
              <span class="help is-danger">{{ priceFilterError }}</span>
            </template>
            <template v-else-if="data.type == 'year'">
              <div class="col-12 paddingleft0">
                <date-picker
                  id="year"
                  v-model="data.field"
                  :config="yearOptions"
                  class="form-control"
                  autocomplete="off"
                  placeholder="Year"
                  name="year"
                >
                </date-picker>
              </div>
            </template>
            <template v-else-if="data.type == 'year_range'">
              <div class="col-6 paddingleft0">
                <date-picker
                  id="fromYear"
                  v-model="fromYear"
                  :config="fromYearOptions"
                  class="form-control"
                  autocomplete="off"
                  placeholder="From"
                  name="fromYear"
                  @input="changeFilterFromYear(data)"
                >
                </date-picker>
              </div>
              <div class="col-6 paddingleft0">
                <date-picker
                  id="toYear"
                  v-model="toYear"
                  :config="toYearOptions"
                  class="form-control"
                  autocomplete="off"
                  placeholder="To"
                  name="toYear"
                  @input="changeFilterToYear(data)"
                  :disabled="!fromYear"
                >
                </date-picker>
              </div>
            </template>
            <template v-else-if="data.type == 'date' || data.type == 'datetime'">
              <div class="clearfix"></div>
              <div :class="'col-12 paddingleft0 ' + data.column_class">
                <VueCtkDateTimePicker
                  v-model="data.field"
                  range
                  :only-date="datePickerOptions.onlyDate"
                  :only-time="false"
                  :output-format="datePickerOptions.outputFormat"
                  :format="datePickerOptions.inputFormat"
                  :formatted="datePickerOptions.formatted"
                  :noClearButton="datePickerOptions.noClearButton"
                  :custom-shortcuts="datePickerOptions.customShortcuts"
                  :maxDate="datePickerOptions.maxDate"
                ></VueCtkDateTimePicker>
                <button
                  title="Reset"
                  v-on:click="
                    data.field = '';
                    resetFilters(data.type, data.field);
                  "
                  class="btn btn-primary clearDateFilter"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                  <!--<i class="fa fa-refresh" aria-hidden="true"></i>-->
                </button>
              </div>
            </template>
            <template v-else-if="data.type == 'drop_down'">
              <div class="col-12 paddingleft0">
                <v-select
                  :refreshFlag="refreshFlag"
                  v-model="data.field"
                  name="data.field"
                  placeholder="Type here"
                  label="text"
                  :options="Array.isArray(data.options) ? data.options : []"
                  class=""
                >
                </v-select>
              </div>
            </template>
            <template v-else-if="data.type == 'drop_down_multi_select_and_search'">
              <div class="col-12 paddingleft0">
                <v-select
                  :refreshFlag="refreshFlag"
                  v-model="data.field"
                  name="data.field"
                  placeholder="Select"
                  label="text"
                  :options="Array.isArray(data.options) ? data.options : []"
                  multiple
                  :key="data.modal"
                  @search="
                    (search, loading) => onSearchForMultiSelect(search, loading, data)
                  "
                  @change="changeMultiSelectAndSearchVal(data.name, data.field)"
                >
                </v-select>
              </div>
            </template>
            <template v-else-if="data.type == 'drop_down_multi_select'">
              <div class="col-12 paddingleft0">
                <v-select
                  :refreshFlag="refreshFlag"
                  v-model="data.field"
                  name="data.field"
                  placeholder="Select"
                  label="text"
                  :options="Array.isArray(data.options) ? data.options : []"
                  multiple
                  :key="data.modal"
                >
                </v-select>
              </div>
            </template>
            <template v-else-if="data.type == 'search_select'">
              <div class="col-12 paddingleft0">
                <multiselect
                  v-model="data.field"
                  :options="searchDataArr"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="false"
                  placeholder="Search & Select"
                  label="text"
                  track-by="text"
                  :preselect-first="true"
                  class="form-control multiselect_display_input mlt-sel-fltr"
                  @search-change="
                    (search, loading) => searchData(search, loading, data.modal)
                  "
                  @input="onOptionSelectDeselect(data.field)"
                >
                  <template slot="option" slot-scope="props">
                    <div
                      v-if="filteredData.includes(props.option.value)"
                      class="multiple_select_item selected_multiple_item"
                    >
                      <span>{{ props.option.text }}</span>
                    </div>
                    <div v-else class="multiple_select_item">
                      <span>{{ props.option.text }}</span>
                    </div>
                  </template>
                  <!-- Selection Custom Slot -->
                  <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                  >
                    <span
                      class="multiselect__single"
                      v-if="values.length > 1 && !isOpen"
                      >{{ values.length }} selected</span
                    >
                    <span
                      class="multiselect__single"
                      v-else-if="values.length > 0 && values.length <= 1"
                      >{{ values[0].text }}</span
                    >
                    <span
                      class="multiselect__single pull-right"
                      v-on:click="clearSelectedFilter()"
                      ><i class="fa fa-close" aria-hidden="true"></i>
                    </span>
                  </template>
                  <template slot="noOptions">
                    <span>Search & Select</span>
                  </template>
                </multiselect>
              </div>
            </template>
            <template v-else-if="data.type == 'check_duplicate_veh'">
              <div class="col-12 paddingleft0">
                <b-form-radio-group
                  v-model="data.field"
                  :options="findDuplicateByArr"
                  v-b-tooltip.hover
                  title="This Filter will only consider status filter."
                  name="findDuplicateBy"
                >
                </b-form-radio-group>
              </div>
            </template>
            <template v-else-if="data.type == 'radio_button'">
              <div class="col-12 paddingleft0">
                <!-- <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.show_truck_online_label}}</div>
                                    </div> -->
                <div class="col-9 paddingleft0 custom-checkbox-v">
                  <label class="container">
                    <input
                      v-model="data.field"
                      value="true"
                      class="form-control"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-12 paddingleft0">
                <input
                  class="form-control"
                  v-on:keyup.enter="applyFilter()"
                  :placeholder="data.name == 'Cus Id' ? 'CusID' : data.name"
                  v-model="data.field"
                  :name="data.name"
                  :id="data.field"
                  type="text"
                />
              </div>
              
            </template>
          </div>

        </div>
        </div>

        <div class="clearfix"></div>
        <div class="">
          <button class="btn btn-primary" v-on:click="applyFilter()">
            Apply Filters
          </button>
          <button
            class="btn btn-primary"
            v-on:click="resetFilters('reset', '')"
          >
            Reset Filters
          </button>
          <button v-show="isResetSorting" class="btn btn-primary" v-on:click="resetSorting()">
            Reset Sorting
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var self;
import Vue from "vue";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
//import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
import DatePicker from "vue-bootstrap-datetimepicker";
export default {
  name: "FilterComponent",
  components: {
    datetime: Datetime,
    //vSelect,
    Multiselect,
    DatePicker,
  },
  props: {
    parentHeaders: {
      type: Array,
      required: false,
      default: undefined,
    },
    filterCounts: 0,
    currentPageNameFilter: "",
    isResetSorting: {
      type: Boolean,
      default: true
    },
    moduleName: {
      type: String,
      default: ""
    },
  },
  mixins: [commonMixins],
  data() {
    return {
      refreshFlag: true,
      filters: {},
      time_zone: "",
      selectedLocation: "",
      findDuplicateBy: "unit",
      findDuplicateByArr: [
        { text: "Unit", value: "unit" },
        { text: "VIN", value: "vin_no" },
      ],
      yearOptions: {
        format: "YYYY",
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
      },
      fromYear: "",
      fromYearOptions: {
        format: "YYYY",
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
      },
      toYear: "",
      toYearOptions: {
        format: "YYYY",
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
      },
      fromPrice:"",
      toPrice:"",
      priceFilterError: "",
      fromOdometer:"",
      toOdometer:"",
      odometerFilterError: "",
      datePickerOptions: {
        range: true,
        onlyDate: true,
        outputFormat: "YYYY-MM-DD",
        inputFormat: "YYYY-MM-DD",
        formatted: process.env.VUE_APP_SYSTEM_DISPLAY_DATERANGE_FILTER_FORMAT, //'YYYY-MM-DD',
        noClearButton: true,
        maxDate: "",
        // Getting error when using the for date picker options
        // 'no-shortcuts': false,
        /*customShortcuts : [
                        { label: 'Yesterday', value: '-day', isSelected: false },
                        { label: 'This Week', value: 'week', isSelected: false },
                        { label: 'Last Week', value: '-week', isSelected: false },
                        { key: 'last7Days', label: 'Last 7 days', value: 7 },
                        { key: 'last30Days', label: 'Last 30 days', value: 30 },
                        { label: 'This Month', value: 'month', isSelected: false },
                        { label: 'Last Month', value: '-month', isSelected: false },
                        { key: 'thisYear', label: 'This year', value: 'year' },
                        { key: 'lastYear', label: 'Last year', value: '-year' }
                    ]*/
      },
      isOpenFilterBlock: false,
      filterCount: 0,
      searchDataArr: [],
      selectedData: null,
      filteredData: [],
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function () {
    self.staticInputLabelMessages = self.allLabelMessages;
    self.setDatePickerDateToCurrentDate();
    self.filterCount = self.filterCounts;
    if (
      self.filterCount ||
      self.currentPageNameFilter === localStorage.getItem("currentPageName")
    ) {
      self.isOpenFilterBlock = true;
    }
  },
  watch: {
    parentHeaders: function () {
      self.setDropDownValues();
    },
  },
  methods: {
    toggleFilterBlock: function () {
      $(window).scrollTop($(window).scrollTop() + 1);
      /* setTimeout(function () {
                    $(window).scrollTop($(window).scrollTop()-1);
                    $(".bottom_pagination").css({
                        'top' : ($(".page_title").outerHeight()  + $(".vdc-filters-container").outerHeight() + 55) + 'px'
                    });
                }, 100); */
      /* if($('.vdc-filters-area').is(':visible')) {
                    self.isOpenFilterBlock = false;
                } else {
                    self.isOpenFilterBlock = true;
                } */
      self.isOpenFilterBlock = !self.isOpenFilterBlock;
      /* self.$nextTick(function () {
                    var heightToListBody = (screen.availHeight - ($(".page_title").outerHeight() + $(".vdc-filters-container").outerHeight() + $(".vgt-wrap__footer").outerHeight() + $(".admin-footer").outerHeight()));
                    if(heightToListBody <= 500){
                        heightToListBody = 500;
                    }
                    $('.table-listing').css('max-height', (heightToListBody - 150) + 'px');
                }); */
    },
    applyFilter: function () {
      let data = self.parentHeaders;
      self.$emit("applyFilterParentComponent", data);
    },
    resetSorting: function () {
      self.$emit("resetSorting");
    },
    resetFilters: function (field, value) {
      let userObj = localStorage.getItem("user_obj");
      let roleName = "";
      if (userObj != null) {
        let userJson = JSON.parse(userObj);
        roleName = userJson ? userJson["roles"][0].name : "";
      }

      if (field == "date") {
      } else if (field == "datetime") {
      } else if (field == "drop_down") {
      } else if (field == "reset") {
        for (let i = 0; i < self.parentHeaders.length; i++) {
          self.parentHeaders[i]["field"] = "";
          
          //Resetting the dropdown values to Default Selected
          if(self.moduleName == 'listInventory' && self.parentHeaders[i]["column_name"] == 'status'){
            if (roleName == 'System' || roleName == 'Administrator' || roleName == 'Accounting') {
              //For Higher Role
              self.parentHeaders[i]["field"] = [
                {
                  value: 1,
                  text: "Active",
                  checked: 0,
                },
                {
                  value: 2,
                  text: "Hold",
                  checked: 0,
                },
                {
                  value: 4,
                  text: "Pending",
                  checked: 0,
                },
              ];
            } else {
              //For Salesman and lower role
              self.parentHeaders[i]["field"] = [
                {
                  value: 1,
                  text: "Active",
                  checked: 0,
                },
                {
                  value: 2,
                  text: "Hold",
                  checked: 0,
                },
              ];
            }
          } else if(self.moduleName == 'listInventory' && self.parentHeaders[i]["column_name"] == 'inventory_access'){
            self.parentHeaders[i]["field"] = [
                {
                  value: 'both',
                  text: "Both",
                },
              ];
          }          

        }
      }
      self.applyFilter();
    },
    changeFilterFromYear: function (data) {
      self.$set(self.toYearOptions, 'minDate', self.fromYear);
      var index = self.parentHeaders.findIndex(header => header.column_name === data.column_name);
      if(index != -1){
        self.$nextTick(() => {
          self.parentHeaders[index].field = {fromYear: self.fromYear, toYear: self.toYear};
        });
      }
    },
    changeFilterToYear: function (data) {
      self.$set(self.fromYearOptions, 'maxDate', self.toYear);
      var index = self.parentHeaders.findIndex(header => header.column_name === data.column_name);
      if(index != -1){
        self.$nextTick(() => {
          self.parentHeaders[index].field = {fromYear: self.fromYear, toYear: self.toYear};
        });
      }
    },
    validateOdometerRange: function (data) {
      self.odometerFilterError = "";
      if (
        parseInt(self.fromOdometer) >
        parseInt(self.toOdometer)
      ) {
        self.toOdometer = "";
        self.odometerFilterError = "To Miles/Hrs can not be smaller than From Miles/Hrs";
      }else{
        var index = self.parentHeaders.findIndex(header => header.column_name === data.column_name);
        if(index != -1){
          self.$nextTick(() => {
            self.parentHeaders[index].field = {fromOdometer: self.fromOdometer, toOdometer: self.toOdometer};
          });
        }
      }
    },
    validatePriceRange: function (data) {
      self.priceFilterError = "";
      if (
        parseInt(self.fromPrice) >
        parseInt(self.toPrice)
      ) {
        self.toPrice = "";
        self.priceFilterError = "To Price can not be smaller than From Price";
      }else{
        var index = self.parentHeaders.findIndex(header => header.column_name === data.column_name);
        if(index != -1){
          self.$nextTick(() => {
            self.parentHeaders[index].field = {fromPrice: self.fromPrice, toPrice: self.toPrice};
          });
        }
      }
    },
    setDatePickerDateToCurrentDate: function () {
      var date = new Date();
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      self.today = today;
      self.datePickerDates = {
        start: today,
        end: today,
      };
      // self.datePickerOptions.maxDate = today;
    },
    setDropDownValues: function () {
      self.parentHeaders.forEach((header, index) => {
        const modalName = header["modal"];
        var type = "";
        if(modalName == 'Tag'){
          type = 'AssetMaster';
        }
        let optionsConditions = typeof header['options_conditions'] != 'undefined' ? header['options_conditions'] : {};
        if (modalName && modalName !== "modal") {
          self.loadAllData(
            [modalName],
            false,
            (callbackData) => self.loadDropdownDataCallback(callbackData, modalName, index),
            "undefined",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            type,
            optionsConditions
          );
        }
      });
    },
    loadDropdownDataCallback: function (callbackData, modalName, index) {
      // check weather the modalName is present in the callbackData
      if ( self.parentHeaders[index]) {
        self.parentHeaders[index]["options"] = callbackData[modalName];
        self.refreshFlag = !self.refreshFlag;
      }
      
    },
    changeDatePickerDate: function () {
      self.applyFilter();
    },
    changedValue: function (value) {
      self.applyFilter();
    },
    changedCategoryFor: function () {
      self.applyFilter();
    },
    onSearchForMultiSelect: function(search, loading, data) {
      if (search !== undefined && search.length > 2) {
          self.loadAllData(
            [data.modal],
            true,
            function (callbackData) {
              self.loadDataCallbackMultiSelect(callbackData, data);
            },
            loading,
            search
          );
      }
    },
    loadDataCallbackMultiSelect: function (callbackData, data) {
      var index = self.parentHeaders.findIndex(header => header.column_name === data.column_name);
      if(index != -1){
        self.loadDropdownDataCallback(callbackData, data.modal, index);
      }
    },
    searchData: function (search, loading, modalName) {
      if (search !== undefined && search.length > 2) {
        self.loadAllData(
          [modalName],
          true,
          function (callbackData) {
            self.loadDataCallback(callbackData, modalName);
          },
          loading,
          search
        );
      } else {
        self.searchDataArr = [];
      }
    },
    loadDataCallback: function (callbackData, modalName) {
      self.searchDataArr = callbackData[modalName];
    },
    onOptionSelectDeselect(selectedOptions) {
      self.filteredData = [];
      selectedOptions.map((data) => {
        self.filteredData.push(data.value);
      });
    },
    clearSelectedFilter() {
      self.filteredData = [];
      self.parentHeaders.map((data) => {
        if (data.type == "search_select") {
          data.field = [];
          return true;
        }
      });
    },
    getChunkedData(arr, chunkSize) {
      return arr.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!result[chunkIndex]) {
          result[chunkIndex] = [];
        }
        result[chunkIndex].push(item);
        return result;
      }, []);
    },
    changeMultiSelectAndSearchVal(filterLblName, filterDataVal) {
      if(self.moduleName == 'listInventory') {

        // Autoloads the Model data based on selected Make IDs
        if(filterLblName == 'Make') {
          if (typeof(filterDataVal) != "undefined" && filterDataVal != null && filterDataVal != "") {
            let make_id_arr = filterDataVal.map(item => item.value);
            self.loadAllData(['Model'], false, function (callbackData) { self.autoloadDropdownData(callbackData, 'model', 'Model'); }, "undefined", '', '', make_id_arr);
          } else {
            self.loadAllData(['Model'], false, function (callbackData) { self.autoloadDropdownData(callbackData, 'model', 'Model'); });
          }
        }

        // Autoloads the Location data based on selected Company IDs
        if(filterLblName == 'Company') {
          if (typeof(filterDataVal) != "undefined" && filterDataVal != null && filterDataVal != "") {
            let company_id_arr = filterDataVal.map(item => item.value);
            self.loadAllData(['Location'], false, function (callbackData) { self.autoloadDropdownData(callbackData, 'lot_city_lot', 'Location'); }, "undefined", '', '', company_id_arr);
          } else {
            self.loadAllData(['Location'], false, function (callbackData) { self.autoloadDropdownData(callbackData, 'lot_city_lot', 'Location'); });
          }
        }
      }
    },
    autoloadDropdownData(callbackData, listHeaderColumnName, filterHeaderColumnName) {
      var index = self.parentHeaders.findIndex(header => header.column_name == listHeaderColumnName);
      self.loadDropdownDataCallback(callbackData, filterHeaderColumnName, index);
    }
  },
};

EventBus.$on("is-open-filter-component", function (isOpenFlag) {
  if (typeof self != "undefined") {
    self.isOpenFilterBlock = isOpenFlag;
  }
});
</script>
<style scoped>
.resetButton {
  float: right;
  margin-top: -41px;
}

.mlt-sel-fltr > .multiselect__tags > .pull-right {
  right: 5px !important;
}
</style>

<style>
.vdc-filters-area .calender-left-0 .datepicker.flex.flex-direction-column {
  left: 0 !important;
}

.vdc-filters-area .calender-right-0 .datepicker.flex.flex-direction-column {
  right: 0 !important;
}

.vdc-filters-area-block {
  width: inherit;
}
.vdc-filters-area-block input {
  width: inherit;
}
.vdc-filters-area-block input::placeholder {
  color: #8f8f8f !important;
}

.vdc-filters-area .vdc-filters-area-block .mlt-sel-fltr .multiselect__tags {
  min-height: 33px !important;
}

.vdc-filters-area
  .vdc-filters-area-block
  .mlt-sel-fltr
  .multiselect__tags
  .multiselect__placeholder {
  color: #8f8f8f !important;
}

.vdc-filters-area
  .vdc-filters-area-block
  .mlt-sel-fltr
  .multiselect__option
  > span {
  padding-left: 10px;
  color: #8f8f8f;
}
</style>
